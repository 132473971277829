import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./MessagesContainer.module.css";

import { RiMessage2Line } from "react-icons/ri";
import { IoChevronBackOutline } from "react-icons/io5";

//STORE
import { chatActions } from "../../store/redux-store";

//COMPONENTS
import ChatsList from "./ChatsList";
import OpenedChat from "./OpenedChat";

const MessagesContainer = (props) => {
  const dispatch = useDispatch();
  const { newMessageReceived, openedChat } = useSelector((state) => state.chat);
  const [showChats, setShowChats] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (newMessageReceived) {
      dispatch(chatActions.setNewMessageReceived({ value: false }));
    }
  }, [dispatch, newMessageReceived]);

  useEffect(() => {
    if (openedChat._id && showChats) {
      setShowChats(false);
    }
  }, [openedChat, showChats]);
  useEffect(() => {
    if (!openedChat._id && !showChats) {
      setShowChats(true);
    }
  }, [openedChat, showChats]);

  const closeOpenedChatHandler = (e) => {
    e.preventDefault();
    setShowChats(true);
    dispatch(chatActions.setOpenedChat({ data: {} }));
  };

  return (
    <div className={classes.container}>
      {width < 700 && (
        <div className={classes.btns}>
          {showChats ? (
            <div className={classes.btn} style={{ backgroundColor: "#fff" }}>
              <RiMessage2Line />
            </div>
          ) : (
            <div
              className={`${classes["back-btn"]} ${classes.btn}`}
              onClick={closeOpenedChatHandler}
            >
              <IoChevronBackOutline />
              <p className={classes.back}>Back</p>
            </div>
          )}
        </div>
      )}
      <ChatsList
        style={
          width < 700 && !showChats ? { display: "none" } : { display: "flex" }
        }
      />
      <OpenedChat
        setRefresh={props.setRefresh}
        style={
          width < 700 && showChats ? { display: "none" } : { display: "flex" }
        }
      />
    </div>
  );
};

export default MessagesContainer;
