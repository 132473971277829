import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./UserData.module.css";

//COMPONENTS
import SingleDetail from "./SingleDetail";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const IMAGE_URL = process.env.REACT_APP_USER_LOGOS;

const UserData = (props) => {
  const { translations } = useTranslation();
  const { fetchedUser } = useSelector((state) => state.user);

  return (
    <div className={classes.container}>
      <div className={classes["inner-container"]}>
        <div className={classes["image-container"]}>
          <img
            src={`${IMAGE_URL}/${fetchedUser.image}`}
            alt={fetchedUser.username}
          />
          <h4>{fetchedUser.username}</h4>
        </div>
        {fetchedUser.username && (
          <div className={classes.details}>
            <h3 style={{ paddingTop: "0" }}>
              {translations.msg_profilebody_details || "Details:"}
            </h3>
            {fetchedUser.username && (
              <SingleDetail
                data={{
                  label: translations.msg_profilebody_username || "Username",
                  value: fetchedUser.username,
                }}
              />
            )}
            {fetchedUser.createdAt && (
              <SingleDetail
                data={{
                  label:
                    translations.msg_headerstats_user_since || "User Since",
                  value: new Date(fetchedUser.createdAt).toLocaleDateString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  ),
                }}
              />
            )}
            {fetchedUser.isCompany && (
              <SingleDetail
                data={{
                  label:
                    translations.msg_profilebody_is_company || "Is Company",
                  value: fetchedUser.isCompany,
                  bool: true,
                }}
              />
            )}
            {fetchedUser.website && (
              <SingleDetail
                data={{ label: "Website", value: fetchedUser.website }}
              />
            )}
            {(fetchedUser.address.city ||
              fetchedUser.address.zip ||
              fetchedUser.address.street1) && (
              <h3>{translations.msg_profilebody_address || "Address:"}</h3>
            )}
            {fetchedUser.address.city && (
              <SingleDetail
                data={{
                  label: translations.msg_profilebody_city || "City",
                  value: fetchedUser.address.city,
                }}
              />
            )}
            {fetchedUser.address.zip && (
              <SingleDetail
                data={{
                  label: translations.msg_profilebody_zip || "Zip",
                  value: fetchedUser.address.zip,
                }}
              />
            )}
            {fetchedUser.address.street1 && (
              <SingleDetail
                data={{
                  label: translations.msg_profilebody_street1 || "Street 1",
                  value: fetchedUser.address.street1,
                }}
              />
            )}
            {/* {fetchedUser.address.street2 && (
              <SingleDetail
                data={{ label: "Street 2", value: fetchedUser.address.street2 }}
              />
            )} */}
            {/* {(fetchedUser.email || fetchedUser.phone) && <h3>Contact:</h3>} */}
            {fetchedUser.phone && (
              <h3>{translations.msg_profilebody_contact || "Contact:"}</h3>
            )}
            {/* {fetchedUser.email && (
              <SingleDetail
                data={{ label: "Email", value: fetchedUser.email }}
              />
            )} */}
            {fetchedUser.phone && (
              <SingleDetail
                data={{
                  label: translations.msg_profilebody_phone || "Phone",
                  value: fetchedUser.phone,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserData;
