import { useCallback, useEffect, useState } from "react";

import categories, { Categories } from "../utils/categories";

// CUSTOM HOOKS
import { useTranslation } from "../TranslationContext";

const useCategoryTranslator = () => {
  const _categories = new Categories(categories);
  const { translations } = useTranslation();
  const [_catList, _setCatList] = useState([]);
  const [_catListForm, _setCatListForm] = useState([]);

  const [_subCatList, _setSubCatList] = useState([]);
  const [_subCatListForm, _setSubCatListForm] = useState([]);

  useEffect(() => {
    if (!translations["category_1"]) return;
    let _translatedCatList = [];
    categories.forEach((c) =>
      _translatedCatList.push({
        value: c.index,
        label: translations[`category_${c.index}`],
      })
    );
    _setCatList(_translatedCatList);

    let _translatedCatListForm = [];
    categories.forEach((c) => {
      if (c.index !== 0)
        _translatedCatListForm.push({
          value: c.index,
          label: translations[`category_${c.index}`],
        });
    });
    _setCatListForm(_translatedCatListForm);
  }, [translations]);

  const getTranslatedSubCatList = useCallback(
    (ci) => {
      const selectedCatSubcategories =
        _categories.getFormatedSubcategoriesList(ci);
      // console.log(selectedCatSubcategories);
      const translatedSubCatList = [];
      translatedSubCatList.push({ value: 0, label: translations["all"] });
      selectedCatSubcategories.forEach((sc) =>
        translatedSubCatList.push({ value: sc.value, label: sc.label })
      );
      return translatedSubCatList;
    },
    [_categories, translations]
  );

  const getTranslatedSubCatFormList = (ci) => {
    const selectedCatSubcategories =
      _categories.getFormatedSubcategoriesList(ci);
    const translatedSubCatList = [];

    selectedCatSubcategories.forEach((sc) =>
      translatedSubCatList.push({ value: sc.value, label: sc.label })
    );
    return translatedSubCatList;
  };

  return {
    _catList,
    _catListForm,
    getTranslatedSubCatList,
    getTranslatedSubCatFormList,
  };
};

export default useCategoryTranslator;
