import React from "react";
import { useLocation, Link } from "react-router-dom";

import classes from "./Nav.module.css";

// TRANSLATIONS HOOK
import { useTranslation } from "../../TranslationContext";

const Nav = (props) => {
  const location = useLocation();
  const { translations } = useTranslation();

  const paths = {
    aboutUs: "about-us",
    tos: "tos",
    privacyPolicy: "privacy-policy",
    marketing: "marketing",
    imprint: "imprint",
    contact: "contact",
  };
  return (
    <div className={classes.container}>
      <div className={classes["inner-container"]}>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.aboutUs}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.aboutUs}`}
        >
          {translations.tos_about_us || "About Us"}
        </Link>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.tos}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.tos}`}
        >
          {translations.tos_agb || "Terms of Use"}
        </Link>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.privacyPolicy}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.privacyPolicy}`}
        >
          {translations.tos_privacy_policy || "Privacy Policy"}
        </Link>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.marketing}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.marketing}`}
        >
          {translations.tos_marketing || "Marketing"}
        </Link>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.imprint}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.imprint}`}
        >
          {translations.tos_imprint || "Imprint"}
        </Link>
        <Link
          className={classes.nav}
          style={
            location.hash === `#${paths.contact}`
              ? { backgroundColor: "var(--background)" }
              : { backgroundColor: "var(--white)" }
          }
          to={`#${paths.contact}`}
        >
          {translations.tos_contact || "Contact"}
        </Link>
      </div>
    </div>
  );
};

export default Nav;
