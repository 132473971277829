import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let URL;

URL = process.env.REACT_APP_URL;

const initialUserState = {
  errorUser: false,
  messageUser: false,
  fetchedUser: {},
  fetchedUserArticles: [],
  numOfArticles: 0,
  articlesPerPage: 30,
  currentArticlePage: 1,
  numOfPages: 1,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setErrorUser(state, action) {
      state.errorUser = action.payload.value;
    },
    setMessageUser(state, action) {
      state.messageUser = action.payload.value;
    },
    setFetchedUser(state, action) {
      state.fetchedUser = action.payload.data;
    },
    setArticles(state, action) {
      state.fetchedUserArticles = action.payload.data;
    },
    setNumOfArticles(state, action) {
      state.numOfArticles = action.payload.value;
    },
    setCurrentArticlePage(state, action) {
      state.currentArticlePage = action.payload.value;
    },
    setNumOfPages(state, action) {
      const _numOfPages = Math.ceil(
        action.payload.value / state.articlesPerPage
      );
      state.numOfPages = _numOfPages;
    },
  },
});

export const getUserData = (userInputs) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/users/getSingleUser/${userInputs.userId}`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          userSlice.actions.setFetchedUser({ data: response.data.data })
        );
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          userSlice.actions.setErrorUser({ value: err.response.data.message })
        );
      }
    }
  };
};

export const getFetchedUserArticles = (userInputs = false, add = false) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/getArticles${
          userInputs.queryString ? userInputs.queryString : ""
        }`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          userSlice.actions.setArticles({
            data: response.data.data.articles.articles,
          })
        );
        dispatch(
          userSlice.actions.setNumOfPages({
            value: response.data.data.totalAmountOfArticles,
          })
        );
        dispatch(
          userSlice.actions.setNumOfArticles({
            value: response.data.data.totalAmountOfArticles,
          })
        );
      }
    } catch (err) {
      console.log(err);
      if (!err.response) return;
      dispatch(
        userSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export default userSlice;
