import React from "react";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./FirstForm.module.css";

// COMPONENTS
import Category from "./Category";
import TextInput from "../../UI/TextInput";
import TextAreaInput from "../../UI/TextAreaInput";

const FirstForm = (props) => {
  const { translations } = useTranslation(); // Use translations
  const {
    titleInput,
    descriptionInput,
    titleHandler,
    descriptionHandler,
    checkTitleValidation,
    checkDescriptionValidation,
    titleError,
    descriptionError,
  } = props.data;

  return (
    <div className={classes.container}>
      <h3>{translations.msg_firstform_main || "Main"}</h3>
      <Category data={props.data} formType={props.formType} />
      <div className={classes.divider} />
      <TextInput
        data={{
          name: translations.msg_firstform_title || "title",
          value: titleInput,
          error: titleError,
        }}
        onKeyDown={props.onKeyDown}
        onChange={titleHandler}
        onBlur={checkTitleValidation}
      />
      <TextAreaInput
        data={{
          name: translations.msg_firstform_description || "description",
          error: descriptionError,
          value: descriptionInput,
        }}
        onKeyDown={props.onKeyDown}
        onChange={descriptionHandler}
        onBlur={checkDescriptionValidation}
      />
    </div>
  );
};

export default FirstForm;
