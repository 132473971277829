import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./Auth.module.css";

//COMPONENTS
import Login from "../components/auth/Login";
import Signup from "../components/auth/Signup";
import AuthCard from "../components/auth/AuthCard";
import VerifyEmail from "../components/auth/VerifyEmail";
import CheckEmail from "../components/auth/CheckEmail";

const Auth = (props) => {
  const navigate = useNavigate();
  const [checkEmail, setCheckEmail] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated && user._id) {
      navigate("/");
    }
  });

  return !isAuthenticated && !user._id ? (
    <div className={classes.container}>
      <Routes>
        <Route
          path="/login"
          element={<Login setCanConnect={props.setCanConnect} />}
        />
        <Route
          path="/signup"
          element={
            <Signup
              setCheckEmail={setCheckEmail}
              checkEmail={checkEmail}
              setCanConnect={props.setCanConnect}
            />
          }
        />
        <Route path="/signup/verify-email" element={<CheckEmail />} />
        <Route
          path="/signup/:token"
          element={
            <VerifyEmail
              setCanConnect={props.setCanConnect}
              setCheckEmail={setCheckEmail}
              checkEmail={checkEmail}
            />
          }
        />
      </Routes>
      <AuthCard />
    </div>
  ) : null;
};

export default Auth;
