import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./UserArticles.module.css";

//COMPONENTS
import Search from "../header/Search";
import ArticleCard from "../main/articleCard/ArticleCard";
import Pagination from "../../UI/paggination/Pagination";

const UserArticles = (props) => {
  const {
    fetchedUserArticles,
    currentArticlePage,
    numOfPages,
    numOfArticles,
    fetchedUser,
  } = useSelector((state) => state.user);
  const [_articleRenderer, _setArticleRenderer] = useState([]);

  // console.log(currentArticlePage, numOfPages, numOfArticles);

  useEffect(() => {
    let _articlesForRender = fetchedUserArticles.map((a) => (
      <ArticleCard data={a} key={a._id} id={a._id} />
    ));
    _setArticleRenderer(_articlesForRender);
  }, [fetchedUserArticles]);

  return (
    <div className={classes.container}>
      <form
        onSubmit={props.hook.fetchArticlesHandler}
        className={classes.header}
      >
        <Search
          data={props.data}
          onChange={props.hook.changeSeatchInputHandler}
        />
        <div className={classes.description}>
          <p>{`User`}</p>
          <span>{`${fetchedUser.username}`}</span>
          <p>{`have`}</p>
          <span>{`${numOfArticles}`}</span>
          <p>{`active Articles.`}</p>
        </div>
      </form>
      <div className={classes.body}>
        <div className={classes["articles-container"]}>{_articleRenderer}</div>
      </div>
      <div className={classes.footer}>
        <Pagination
          currentPage={currentArticlePage}
          numOfPages={numOfPages}
          firstPageHandler={props.hook.firstPageHandler}
          lastPageHandler={props.hook.lastPageHandler}
          pageHandler={props.hook.pageHandler}
        />
      </div>
    </div>
  );
};

export default UserArticles;
