import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./PauseOverlay.module.css";

//STORE
import { changeStatus } from "../../store/reducers/user-article-slice";

const PauseOverlay = (props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { translations } = useTranslation(); // Use translations

  const changeArticleStatusHandler = (e) => {
    e.preventDefault();

    dispatch(
      changeStatus({ status: 3, articleId: props.data._id }, token, "paused")
    );
  };

  return (
    <div className={classes["pause-overlay__container"]}>
      <div className={classes["pause-overlay__content"]}>
        <h5>
          {translations.msg_pause_overlay_question ||
            "Do you want to pause the current article?"}
        </h5>
        <div className={classes["pause-overlay__btns"]}>
          <p
            className={classes["pause-overlay__btn"]}
            onClick={props.hidePauseOverlayHandler}
          >
            {translations.msg_pause_overlay_no || "No"}
          </p>
          <p
            className={classes["pause-overlay__btn"]}
            onClick={changeArticleStatusHandler}
          >
            {translations.msg_pause_overlay_yes || "Yes"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PauseOverlay;
