import { useState } from "react";
import { useTranslation } from "../../TranslationContext";
import { useDispatch } from "react-redux";

import classes from "./ArticleBody.module.css";

//HELPER
import categories from "../../utils/categories";

import { FaRegEye } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineDateRange, MdKeyboardDoubleArrowRight } from "react-icons/md";

//COMPONENTS
import UserCard from "./UserCard";
import ArticleDetails from "./ArticleDetails";
import AditionalDetails from "./AditionalDetails";

import cars from "../../utils/cars";
import { doors } from "../../utils/vehicle-filters";

import { articleActions } from "../../store/redux-store";

const ArticleBody = (props) => {
  const dispatch = useDispatch();
  const { translations } = useTranslation();
  const category = categories.find((c) => c.index === props.data.category);
  const subcategory =
    category.subcategories.length > 0
      ? category.subcategories.find((sc) => sc.index === props.data.subcategory)
      : null;

  let _carCompany, _carModel;

  if (props.data.category === 6) {
    _carCompany =
      props.data.carcompany &&
      cars.filter((car) => car.index === props.data.carcompany);

    _carModel =
      _carCompany[0].subcategories.length > 1 &&
      _carCompany[0].subcategories.filter(
        (sc) => sc.index === props.data.carmodel
      );
  }

  const copyArticleIDHandler = (e) => {
    e.preventDefault();
    const articleId = e.target.id;

    if (!articleId) return;

    navigator.clipboard
      .writeText(articleId)
      .then(() => {
        dispatch(
          articleActions.setMessageArticle({
            value:
              translations.msg_articleBody_copy_success ||
              `Article ID is copied to clipboard!`,
          })
        );
      })
      .catch((err) => {
        dispatch(
          articleActions.setErrorMessage({
            value:
              translations.msg_articleBody_copy_fail ||
              `Failed to copy article ID!`,
          })
        );
      });
  };
  console.log(props.data.subCategory, typeof props.data.subCategory);
  return (
    <div className={classes.container}>
      <h4>
        {props.data.title}
        <div
          className={classes["item-condition"]}
          style={
            props.data.itemCondition === "new"
              ? { borderColor: "var(--new)" }
              : null
          }
        >
          {props.data.itemCondition === "new"
            ? translations.msg_articleBody_condition_new || "Neu"
            : translations.msg_articleBody_condition_used || "Gebraucht"}
        </div>
      </h4>
      <div className={classes["basic-info"]}>
        <div className={classes["single-info"]}>
          <IoLocationOutline />
          <p>{`${props.data.city} / ${props.data.zip}`}</p>
        </div>
        <div
          id={props.data._id}
          className={`${classes["article-id"]} ${classes["single-info"]}`}
          onClick={copyArticleIDHandler}
        >
          <p
            id={props.data._id}
          >{`${translations.msg_articleBody_article_id}`}</p>
          {/* <p>{`ID: ${props.data._id}`}</p> */}
        </div>
        <div className={classes["single-info"]}>
          <FaRegEye />
          <p>{props.data.clicks}</p>
        </div>
        <div className={classes["single-info"]}>
          <MdOutlineDateRange />
          <p>
            {new Date(props.data.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
        </div>
      </div>
      <div className={classes["category"]}>
        <h5>{translations.msg_articleBody_category || "KATEGORIE"}:</h5>
        <div className={classes["category-container"]}>
          <p>{translations[`category_${props.data.category}`]}</p>
          <MdKeyboardDoubleArrowRight className={classes["separator"]} />
          {props.data.subCategory &&
          props.data.subCategory !== 0 &&
          props.data.subCategory !== 100 ? (
            <p>
              {
                translations[
                  `subcat_${props.data.category}_${props.data.subCategory}`
                ]
              }
            </p>
          ) : null}
        </div>
      </div>
      {/* <h4>
        {props.data.title}
        <div
          className={classes["item-condition"]}
          style={
            props.data.itemCondition === "new"
              ? { borderColor: "var(--new)" }
              : null
          }
        >
          {props.data.itemCondition === "new"
            ? translations.msg_articleBody_condition_new || "Neu"
            : translations.msg_articleBody_condition_used || "Gebraucht"}
        </div>
      </h4> */}
      <div className={classes["body"]}>
        {props.data.description && <ArticleDetails data={props.data} />}
        <UserCard data={props.data.creator} show={props.show} />
      </div>
      {props.data.category === 6 && (
        <div className={classes["more-details"]}>
          <AditionalDetails
            data={{
              carcompany:
                props.data.carcompany === 0
                  ? translations.msg_articledetails_other || "Other"
                  : _carCompany[0]?.name,
              model: _carModel.length === 0 ? null : _carModel[0]?.name,
              engine: translations[`v_engine_${props.data.engynetype}`] || "-",
              transmition:
                translations[`v_transmition_${props.data.transmition}`] || "-",
              carcolor:
                translations[`v_color_${props.data["car_color"]}`] || "None",
              cardoors: props.data["car_doors"]
                ? doors.find((d) => d.value === props.data["car_doors"]).label
                : null,
              cc: props.data.cc,
              kw: props.data.kw,
              miles: props.data.miles,
              year: props.data.year,
            }}
          />
        </div>
      )}
      {props.data.category === 11 && (
        <div className={classes["more-details"]}>
          <AditionalDetails
            data={{
              res:
                props.data.realestatestatus === 0
                  ? translations.msg_articledetails_other || "Other"
                  : translations[`restatus_${props.data.realestatestatus}`] ||
                    "-",
              rooms: props.data.rooms || "-",
              area: props.data.area || "-",
              balcony: props.data.balcony || false,
              garage: props.data.garage || false,
              elevator: props.data.elevator || false,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ArticleBody;
