const config = {
  companyName: "Schweizer Gratis Inserate",
  privacyPolicyVersion: "sgi-1.0",
  initialCookieExpireDays: 365,
  contactEmail: "info@schweizer-gratis-inserate.ch",
  marketingEmail: "marketing@schweizer-gratis-inserate.ch",
  contactPhone: "+00000000000001",
  contactLocation: `Route de Meyrin 385
Geneva, 1217`,
  maxNumOfImagesToUpload: 12,
};

export default config;
