// utils/load-languages.js
export const loadLanguage = (lang) => {
  switch (lang) {
    case "de":
      return import("../lang/de.js").then((module) => module.default);
    case "fr":
      return import("../lang/fr.js").then((module) => module.default);
    case "it":
      return import("../lang/it.js").then((module) => module.default);
    case "en":
      return import("../lang/en.js").then((module) => module.default);
    case "ba":
      return import("../lang/ba.js").then((module) => module.default);
    case "jp":
      return import("../lang/jp.js").then((module) => module.default);
    default:
      return import("../lang/de.js").then((module) => module.default); // Default to German
  }
};
