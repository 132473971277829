import React from "react";

import classes from "./ImagesUpload.module.css";
import { MdOutlineStar, MdDelete } from "react-icons/md";

//COMPONENTS
import InsertNewImages from "./InsertNewImages";

//SINGLE IMAGE COMPONENT
export const SingleImage = (props) => {
  const deleteImageHandler = (e) => {
    e.preventDefault();
    const btnId = e.target.closest("div").id;
    const filteredImages = props.imagesForUpload.filter(
      (k) => k.name !== btnId
    );
    const filteredImagesPreviewURL = props.imagesPreviewURL.filter(
      (k) => k.name !== btnId
    );
    const filteredImagesNames = props.imagesNames.filter((k) => k !== btnId);

    props.setImagesForUpload(filteredImages);
    props.setImagesPreviewURL(filteredImagesPreviewURL);
    props.setImagesNames(filteredImagesNames);

    if (btnId === props.mainImage) {
      props.setMainImage("");
    }
  };

  const setMainImageHandler = (e) => {
    e.preventDefault();
    const btnId = e.target.closest("div").id;

    if (props.mainImage === btnId) {
      props.setMainImage("");
    } else {
      props.setMainImage(btnId);
    }
  };

  return (
    <div className={classes["preview-img-container"]}>
      <div className={classes["preview-img"]}>
        <img
          src={props.src}
          id={props.name}
          alt={props.name}
          className={classes.img}
        />
      </div>
      <div id={props.name} className={classes["preview-img-btns"]}>
        <div
          onClick={setMainImageHandler}
          id={props.name}
          className={classes["preview-img-btn"]}
        >
          <MdOutlineStar
            className={classes["icon"]}
            style={
              props.name === props.mainImage
                ? { color: `var(--main-image)` }
                : {}
            }
          />
        </div>
        <div
          onClick={deleteImageHandler}
          id={props.name}
          className={classes["preview-img-btn"]}
        >
          <MdDelete className={classes["icon"]} />
        </div>
      </div>
    </div>
  );
};

const ImagesUpload = (props) => {
  return (
    <div className={classes["images-upload__container"]}>
      {props.data.fetchedImgsRenderer}
      {props.data.imagesPREV}
      <InsertNewImages
        setImages={props.data.setImages}
        data={props.data}
        formType={props.formType}
      />
    </div>
  );
};

export default ImagesUpload;
