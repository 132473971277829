import React, { useState } from "react";
import classes from "./Carousel.module.css";
// import { useSwipeable } from "react-swipeable";
const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_SUFFIX_LARGE;
const IMAGE_URL_PATH = process.env.REACT_APP_IMAGE_URL;

const Carousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % props.images.length);
  };

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + props.images.length) % props.images.length
    );
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStartX) return;

    const touchEndX = e.touches[0].clientX;
    const deltaX = touchStartX - touchEndX;

    if (deltaX > 50) {
      goToNext();
      setTouchStartX(null);
    } else if (deltaX < -50) {
      goToPrevious();
      setTouchStartX(null);
    }
  };

  return (
    <div className={classes.container}>
      <div
        className={classes["carousel"]}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {props.images.length > 0 ? (
          <img
            src={`${IMAGE_URL_PATH}/articles${IMAGE_PREFIX}/${props.images[currentIndex]}`}
            alt={props.images[currentIndex]}
            className={classes["carousel-image"]}
          />
        ) : (
          <p className={classes["no-images"]}>
            There is no images for this article.
          </p>
        )}
      </div>
      {props.images.length > 0 && (
        <div className={classes["num__of__images"]}>
          <p>
            {currentIndex + 1} / {props.images.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default Carousel;
