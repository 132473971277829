import "./App.css";

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import Error from "./UI/Error";
import Message from "./UI/Message";
import Header from "./components/header/Header";
import RedirectToLogin from "./components/redirect-to-login/RedirectToLogin";
import Cookie from "./components/cookie/Cookie";
import ScrollToTop from "./components/ScrollToTop";

// PAGES
import Home from "./pages/Home";
import Article from "./pages/Article"; // Make sure the path matches where your file is located
import Auth from "./pages/Auth";
import CreateArticle from "./pages/CreateArticle";
import Messages from "./pages/Messages";
import UserArticles from "./pages/UserArticles";
import Profile from "./pages/Profile";
import EditArticle from "./pages/EditArticle";
import AdminPanel from "./pages/AdminPanel";
import User from "./pages/User";
import TermsOfUse from "./pages/TermsOfUse";

//CUSTOM HOOKS
import useAlert from "./custom-hooks/useAlert";
import useQueryString from "./custom-hooks/useQueryString";
import useCookie from "./custom-hooks/useCookie";
import useSocket from "./custom-hooks/useSocket";
import useFilterArticles from "./custom-hooks/useFilterArticles";
import useUser from "./custom-hooks/useUser";

//CONTEXT
import { TranslationProvider } from "./TranslationContext";
import { ModalProvider } from "./ModalContext";

function App() {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { numOfArticles } = useSelector((state) => state.article);

  //HOOKS
  const { queryStrCreator } = useQueryString(); //NE KOMENTIRATI
  const filterArticles = useFilterArticles({
    type: "home",
    numOfArticles: numOfArticles,
    for: "home-page",
  });
  const { error, message } = useAlert();
  const { setInitialCookieHandler, showCookieQuestion } = useCookie();
  const { refreshChat, setRefreshChat, sck, setCanConnect } = useSocket();
  const fetchedUser = useUser();

  //STATES AND REFS
  const [headerHeight, setHeaderHeight] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const headerRef = useRef(null);

  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVH();

    window.addEventListener("resize", setVH);

    return () => {
      window.removeEventListener("resize", setVH);
    };
  }, []);

  useEffect(() => {
    const headerElement = headerRef.current;

    if (!headerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === headerElement) {
          setHeaderHeight(entry.contentRect.height);
        }
      }
    });

    resizeObserver.observe(headerElement);

    return () => {
      resizeObserver.unobserve(headerElement);
    };
  }, []);

  return (
    <TranslationProvider>
      <ModalProvider>
        <div className="App" style={{ paddingTop: `${headerHeight}px` }}>
          <ScrollToTop />
          <Error data={{ message, error }} />
          <Message data={{ message, error }} />
          <Header socket={sck} refHeader={headerRef} />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  cardWidth={cardWidth}
                  setCardWidth={setCardWidth}
                  hook={filterArticles}
                />
              }
            />
            <Route path="/article" element={<Article />} />{" "}
            {/* Add this line */}
            <Route
              path="/articles/create-article"
              element={<CreateArticle />}
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route
              path="/messages/"
              element={
                isAuthenticated && user._id ? (
                  <Messages refresh={refreshChat} setRefresh={setRefreshChat} />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
            <Route
              path="/auth/*"
              element={<Auth setCanConnect={setCanConnect} />}
            />
            <Route
              path="/userArticles/*"
              element={
                isAuthenticated && user._id ? (
                  <UserArticles />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
            <Route
              path="/profile"
              element={
                isAuthenticated && user._id ? <Profile /> : <RedirectToLogin />
              }
            />
            <Route
              path="/admin/*"
              element={
                isAuthenticated && user._id ? (
                  <AdminPanel />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
            <Route
              path="/article/edit/:articleId"
              element={
                isAuthenticated && user._id ? (
                  <EditArticle />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
            <Route path="/user/:userId" element={<User hook={fetchedUser} />} />
          </Routes>
          {showCookieQuestion && (
            <Cookie setInitialCookieHandler={setInitialCookieHandler} />
          )}
        </div>
      </ModalProvider>
    </TranslationProvider>
  );
}

export default App;

// STATUSI ARTIKALA
// 1: active
// 2: pending
// 3: paused
// 4: deleted
// 5: blocked.
