import React, { useEffect } from "react";

import classes from "./RealEstateFields.module.css";

// COMPONENTS
import SelectInput from "../../UI/SelectInput";
import NumberInput from "../../UI/NumberInput";
import CheckboxInput from "../../UI/CheckboxInput";

// CUSTOM HOOKS
import useRealEstateTranslator from "../../custom-hooks/useRealEstateTranslator";
import { useTranslation } from "../../TranslationContext";

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const SingleCheckbox = (props) => {
  return (
    <div className={classes.checkbox} onClick={props.onClick}>
      <p className={classes.label}>{props.data.label}</p>
      {props.data.value ? (
        <MdCheckBox className={classes.icon} />
      ) : (
        <MdCheckBoxOutlineBlank className={classes.icon} />
      )}
    </div>
  );
};

const RealEstateFields = (props) => {
  const { translations } = useTranslation();
  const RET = useRealEstateTranslator();
  const {
    // REFS
    REStatusRef,
    // VALUES
    REStatusInput,
    roomsInput,
    areaInput,
    elevatorInput,
    garageInput,
    balconyInput,
    // ERROR
    roomsError,
    areaError,
    // HANDLERS
    changeREStatusHandler,
    changeRoomsHandler,
    changeAreaHandler,
    changeElevatorHandler,
    changeGarageHandler,
    changeBalconyHandler,
    // VALIDATORS
    checkRoomsValidation,
    checkAreaValidation,
  } = props.data;

  useEffect(() => {
    if (!REStatusRef.current || RET.translatedRESFORM.length === 0) return;
    let pickedREStatus = RET.translatedRESFORM.find(
      (s) => s.value === REStatusInput?.value
    );
    REStatusRef.current.setValue(pickedREStatus);
  }, [RET.translatedRESFORM, REStatusInput, REStatusRef]);

  const handler = () => {};
  return (
    <div className={classes.container}>
      <h3>{translations.msg_real_estate_title || "Real Estate"}</h3>
      <SelectInput
        data={{
          name: `${translations.msg_real_estate_status || "Stanje Nekretnine"}`,
          options: RET.translatedRESFORM,
          isSearchable: true,
          defaultValue: { label: "test", value: 0 },
          ref: REStatusRef,
        }}
        onChange={handler}
      />
      <NumberInput
        data={{
          name: `${translations.msg_real_estate_rooms || "Rooms"}`,
          value: roomsInput,
          placeholder: "",
          error: roomsError,
        }}
        onKeyDown={props.onKeyDown}
        onChange={changeRoomsHandler}
        onBlur={checkRoomsValidation}
      />
      <NumberInput
        data={{
          name: `${translations.msg_real_estate_area || "Area"} (m2)`,
          value: areaInput,
          placeholder: "",
          error: areaError,
        }}
        onKeyDown={props.onKeyDown}
        onChange={changeAreaHandler}
        onBlur={checkAreaValidation}
      />
      <div className={classes["checkboxes"]}>
        <SingleCheckbox
          data={{
            label: `${translations.msg_real_estate_elevator || "Elevator"}`,
            value: elevatorInput,
          }}
          onClick={changeElevatorHandler}
        />
        <SingleCheckbox
          data={{
            label: `${translations.msg_real_estate_garage || "Garage"}`,
            value: garageInput,
          }}
          onClick={changeGarageHandler}
        />
        <SingleCheckbox
          data={{
            label: `${translations.msg_real_estate_balcony || "Balcony"}`,
            value: balconyInput,
          }}
          onClick={changeBalconyHandler}
        />
      </div>
    </div>
  );
};

export default RealEstateFields;
