import classes from "./UserDropdown.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../TranslationContext"; // Import useTranslation
import { logout } from "../../../store/reducers/auth-store";

const UserDropdown = (props) => {
  const { translations } = useTranslation(); // Use translations
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    props.socket.disconnect();
    props.expandUserDropdown(false);
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  return (
    <div className={classes.userDropdown}>
      <Link
        to={isAuthenticated && user._id ? "/profile" : "/auth/login"}
        className={classes.userDropdown__link}
        onClick={() => {
          props.expandUserDropdown(false);
        }}
      >
        <p>{translations.msg_userdropdown_my_profile || "Mein Profil"}</p>
      </Link>

      <Link
        to={
          isAuthenticated && user._id ? "/userArticles/active" : "/auth/login"
        }
        className={classes.userDropdown__link}
        onClick={() => {
          props.expandUserDropdown(false);
        }}
      >
        <p>{translations.msg_userdropdown_my_articles || "Meine Inserate"}</p>
      </Link>

      {user.role === "admin" && (
        <Link
          to={
            isAuthenticated && user._id && user.role === "admin"
              ? "/admin/general"
              : "/auth/login"
          }
          className={classes.userDropdown__link}
          onClick={() => {
            props.expandUserDropdown(false);
          }}
        >
          <p>{translations.msg_userdropdown_admin_panel || "Admin Panel"}</p>
        </Link>
      )}

      <Link
        className={classes.userDropdown__link}
        to="/"
        onClick={handleLogout}
      >
        {translations.msg_userdropdown_logout || "Log out"}
      </Link>
    </div>
  );
};

export default UserDropdown;
