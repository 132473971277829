import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./ArticleOptions.module.css";

//STORE
import { changeStatus } from "../../store/reducers/user-article-slice";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

import {
  MdOutlineOutlinedFlag,
  MdBlockFlipped,
  MdCheck,
  MdClear,
} from "react-icons/md";

import { GoDash } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";

const ArticleOptions = (props) => {
  const { translations } = useTranslation();
  const dispatch = useDispatch();
  const [showQuestion, setShowQuestion] = useState(false);
  const [showQuestionUnblock, setShowQuestionUnblock] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (showDropdown && !e.target.closest(".article-status")) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showDropdown]);

  const articleStatusList = [
    translations.article_options_status_active || "active",
    translations.article_options_status_pending || "pending",
    translations.article_options_status_paused || "paused",
    translations.article_options_status_deleted || "deleted",
    translations.article_options_status_blocked || "blocked",
  ];

  // const articleStatusList = [
  //   "active",
  //   "pending",
  //   "paused",
  //   "deleted",
  //   "blocked",
  // ];
  const articleStatus = articleStatusList[props.data.status - 1];

  const toggleQuestionHandler = (e) => {
    e.preventDefault();
    setShowQuestion(!showQuestion);
  };

  const toggleQuestionUnblockHandler = (e) => {
    e.preventDefault();
    setShowQuestionUnblock(!showQuestionUnblock);
  };

  const hideQuestionHandler = (e) => {
    e.preventDefault();
    setShowQuestion(false);
  };

  const hideQuestionUnblockHandler = (e) => {
    e.preventDefault();
    setShowQuestionUnblock(false);
  };

  const blockArticleHandler = (e) => {
    e.preventDefault();
    if (user.role === "admin") {
      dispatch(
        changeStatus(
          { status: 5, articleId: props.data._id },
          token,
          "blocked",
          () => {
            props.refresh(true);
          }
        )
      );
      // props.refresh(true);
      setShowQuestion(false);
    }
  };

  const unblockArticleHandler = (e) => {
    e.preventDefault();
    if (user.role === "admin") {
      dispatch(
        changeStatus(
          { status: 1, articleId: props.data._id },
          token,
          "unblocked",
          () => {
            props.refresh(true);
          }
        )
      );
      // props.refresh(true);
      setShowQuestionUnblock(false);
    }
  };

  const toggleStatusHandler = (e) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const changeStatusHandler = (e) => {
    e.preventDefault();
    let statusId = +e.target.id;
    if (user._id === props.data.creator._id) {
      dispatch(
        changeStatus(
          { status: statusId, articleId: props.data._id },
          token,
          `${articleStatusList[statusId - 1]}`,
          () => {
            props.refresh(true);
            setShowDropdown(false);
          }
        )
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.container}>
        {props.data.status === 5 && (
          <div className={classes.description}>
            <p style={{ border: "0.2rem solid var(--blocked)" }}>
              {translations.article_options_blocked || `Article is blocked!`}
            </p>
          </div>
        )}
        {props.data.status === 3 && (
          <div className={classes.description}>
            <p style={{ border: "0.2rem solid var(--paused)" }}>
              {translations.article_options_paused || `Article is paused.`}
            </p>
          </div>
        )}
        {props.data.status === 2 && (
          <div className={classes.description}>
            <p style={{ border: "0.2rem solid var(--pending)" }}>
              {translations.article_options_pending || `Pending...`}
            </p>
          </div>
        )}
        {props.data?.creator?._id !== user._id && (
          <Fragment>
            {user.role === "admin" &&
              showQuestion &&
              props.data.status !== 5 && (
                <div className={classes.question}>
                  <p>
                    {translations.article_options_block_question ||
                      "Are you sure you want to block this article?"}
                  </p>
                  <MdCheck
                    className={classes.icon}
                    onClick={blockArticleHandler}
                  />
                  <MdClear
                    className={classes.icon}
                    onClick={hideQuestionHandler}
                  />
                </div>
              )}
            {user.role === "admin" &&
              showQuestionUnblock &&
              props.data.status === 5 && (
                <div className={classes.question}>
                  <p>
                    {translations.article_options_unblock_question ||
                      "Are you sure you want to unblock this article?"}
                  </p>
                  <MdCheck
                    className={classes.icon}
                    onClick={unblockArticleHandler}
                  />
                  <MdClear
                    className={classes.icon}
                    onClick={hideQuestionUnblockHandler}
                  />
                </div>
              )}
            {user.role === "admin" && props.data.status !== 5 && (
              <div
                className={classes["single-button"]}
                onClick={toggleQuestionHandler}
              >
                <MdBlockFlipped style={{ color: "var(--block-article)" }} />
              </div>
            )}
            {user.role === "admin" && props.data.status === 5 && (
              <div
                className={classes["single-button"]}
                onClick={toggleQuestionUnblockHandler}
              >
                <MdCheck style={{ color: "var(--unblock-article)" }} />
              </div>
            )}
            <div className={classes["report-button"]} onClick={props.showModal}>
              <MdOutlineOutlinedFlag style={{ color: "var(--flag-article)" }} />
              <p className={classes["report-article-text"]}>
                {`${translations.report_report_article || "Report Article"}`}
              </p>
            </div>
          </Fragment>
        )}
        {user._id === props.data.creator?._id && (
          <div className={`article-status ${classes["article-status"]}`}>
            <div
              className={classes["article-status__button"]}
              onClick={toggleStatusHandler}
            >
              <p>
                {`${
                  translations.article_options_status || "Status"
                }: ${articleStatus}`}
              </p>
              {showDropdown && (
                <GoDash style={{ color: "var(--text2)", fontSize: "1.8rem" }} />
              )}
              {!showDropdown && (
                <IoIosArrowDown
                  style={{ color: "var(--text2)", fontSize: "1.8rem" }}
                />
              )}
            </div>
            {showDropdown && (
              <div className={classes["article-status__drowdown"]}>
                {props.data.status !== 1 && (
                  <p id={1} onClick={changeStatusHandler}>
                    {translations.article_options_active || "Active"}
                  </p>
                )}
                {props.data.status !== 3 && (
                  <p id={3} onClick={changeStatusHandler}>
                    {translations.article_options_pause || "Pause"}
                  </p>
                )}
                {props.data.status !== 4 && (
                  <p id={4} onClick={changeStatusHandler}>
                    {translations.article_options_delete || "Delete"}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ArticleOptions;
