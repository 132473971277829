import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./PendingArticlesContainer.module.css";

//COMPONENTS
import SingleUserArticle from "./SingleUserArticle";

const PendingArticlesContainer = (props) => {
  const { articles } = useSelector((state) => state.userArticle);
  const [pendingArticles, setPendingArticles] = useState([]);
  const [articlesRenderer, setArticlesRenderer] = useState([]);

  useEffect(() => {
    let _pendingArticles = [];
    articles.forEach((a) => {
      if (a.status === 2) _pendingArticles.push(a);
    });
    setPendingArticles(_pendingArticles);
  }, [articles]);

  useEffect(() => {
    setArticlesRenderer(
      pendingArticles.map((a) => {
        return <SingleUserArticle key={a._id} data={a} />;
      })
    );
  }, [pendingArticles]);

  return <div className={classes.container}>{articlesRenderer}</div>;
};

export default PendingArticlesContainer;
