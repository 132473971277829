import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import classes from "./AdminPanelNav.module.css";

import { FiPlus, FiMinus } from "react-icons/fi";

const AdminPanelNav = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showReports, setShowReports] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showReports && !event.target.closest(".container")) {
        setShowReports(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showReports]);

  const toogleShowReportsHandler = (e) => {
    e.preventDefault();
    setShowReports(!showReports);
  };

  const hideDropdown = () => {
    setShowReports(false);
  };

  return (
    <div className={`container ${classes.container}`}>
      <Link
        className={classes.link}
        style={
          location.pathname === "/admin/general"
            ? { backgroundColor: "var(--admin-panel-btn)" }
            : {}
        }
        to="/admin/general"
        onClick={() => {
          hideDropdown();
        }}
      >
        General
      </Link>
      <div className={classes.tab} onClick={toogleShowReportsHandler}>
        <p>Reports</p>
        {showReports ? <FiMinus /> : <FiPlus />}
      </div>
      {showReports && (
        <div className={classes.sublinks}>
          <Link
            className={classes.sublink}
            style={
              location.pathname === "/admin/reports/articles"
                ? { backgroundColor: "var(--background)" }
                : {}
            }
            onClick={hideDropdown}
            to="/admin/reports/articles"
          >
            Articles
          </Link>
          <Link
            className={classes.sublink}
            style={
              location.pathname === "/admin/reports/users"
                ? { backgroundColor: "var(--background)" }
                : {}
            }
            to="/admin/reports/users"
            onClick={hideDropdown}
          >
            Users
          </Link>
          <Link
            className={classes.sublink}
            style={
              location.pathname === "/admin/reports/chats"
                ? { backgroundColor: "var(--background)" }
                : {}
            }
            to="/admin/reports/chats"
            onClick={hideDropdown}
          >
            Chats
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdminPanelNav;
