import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { useTranslation } from "../../../../TranslationContext";

import classes from "./FiltersContainer.module.css";

// COMPONENTS
import Search from "../../../header/Search";
import FilterButtons from "./FilterButtons";
import PriceInput from "./PriceInput";
import CheckboxInput from "../../../../UI/CheckboxInput";
import VehiclesFilters from "./VehiclesFilters";
import RealEstateFilters from "./RealEstateFilters";
import CategoriesList from "../../promoted-articles/CategoriesList";
import StateContainer from "./StateContainer";
import MainAd from "../../../advertisment/MainAd";

import { LiaFilterSolid } from "react-icons/lia";

//STORE
import {
  articleActions,
  userArticleActions,
} from "../../../../store/redux-store";

// CUSTOM HOOKS
import useCategoryTranslator from "../../../../custom-hooks/useCategoryTranslator";

// import adsClass from "../../../../adsList";
import categories, { Categories } from "../../../../utils/categories";
const _categories = new Categories(categories);

// let _ads = adsClass.getAdsFor25x40();

// let startingAdLeft,
//   startingAdRight = 0;

// startingAdLeft = Math.floor(Math.random() * (_ads.length - 1));

// const generateStartingAd = (listOfads) => {
//   let _randomNum = 0;
//   let includes = false;

//   const checkNewRandomNumber = () => {
//     _randomNum = Math.floor(Math.random() * (_ads.length - 1));
//     includes = listOfads.includes(_randomNum);
//   };

//   checkNewRandomNumber();

//   while (includes) {
//     checkNewRandomNumber();
//   }

//   return _randomNum;
// };

// startingAdRight = generateStartingAd([startingAdLeft]);

const FiltersContainer = (props) => {
  const dispatch = useDispatch();
  const { translations } = useTranslation();
  const CT = useCategoryTranslator();

  const { numOfArticles, currentSubCategory } = useSelector((state) =>
    props.for === "user-articles" ? state.userArticle : state.article
  );
  // console.log(currentSubCategory);
  const [_sortOptions, _setSortOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(
    window.innerWidth <= 800 ? false : true
  );
  const [_subCategoryList, _setSubCategoryList] = useState([]);
  const [subCatOptionSelected, setSubCatOptionSelected] = useState({
    value: 0,
    label: "All",
  });
  const [subCatDisabled, setSubCategoryDisabled] = useState(true);

  // CATEGORY SETUP ON (LANG CHANGE, CAT CHANGE)
  useEffect(() => {
    if (!props.hook.categoryRef.current || CT._catList.length === 0) return;
    const _currCat = CT._catList.find(
      (c) => c.value === props.hook.currentCategory
    );
    props.hook.categoryRef.current.setValue(_currCat);
  }, [props.hook.categoryRef, props.hook.currentCategory, CT]);

  // SUBCATEGORY SETUP ON (CAT CHANGE, SUBCAT CHANGE, LANG CHANGE)
  useEffect(() => {
    if (!props.hook.subcategoryRef.current) return;
    // GET SUBCATEGORIES LIST BASED ON CURR CAT
    const _subCatList = _categories.getSubcategories(
      props.hook.currentCategory
    );

    // SET SELECT DISABLED IF SUBCAT LIST IS EMPTY
    setSubCategoryDisabled(_subCatList.length === 0);

    // SETUP SUB CAT LIST
    const translatedAndFormatedSubCatList = [];
    translatedAndFormatedSubCatList.push({
      value: 0,
      label: translations["all"],
    });
    if (_subCatList.length > 0) {
      _subCatList.forEach((sc) =>
        translatedAndFormatedSubCatList.push({
          value: sc.index,
          // CHANGE LABEL WHEN TRANSLATIONS IS DONE (translations[`subcat_${currentCategory}_${currentSubCategory}`])
          // label: sc.name,
          label:
            translations[`subcat_${props.hook.currentCategory}_${sc.index}`],
        })
      );
      // UPDATE SUBCAT LIST
      _setSubCategoryList(translatedAndFormatedSubCatList);
      // FIND CURRENT SELECTED SUB CAT
      const pickedSubCat = translatedAndFormatedSubCatList.filter(
        (sc) => sc.value === currentSubCategory
      );

      if (!pickedSubCat) return;
      // IF THERE IS PICKEDSUBCAT SET UPDATED VALUE TO subcategoryRef
      props.hook.subcategoryRef.current.setValue(pickedSubCat);
    }
  }, [
    dispatch,
    props.for,
    props.hook.subcategoryRef,
    props.hook.currentCategory,
    currentSubCategory,
    translations,
  ]);

  useEffect(() => {
    if (_subCategoryList.length === 0) return;
    const pickedSubCat = _subCategoryList.find(
      (sc) => sc.value === currentSubCategory
    );

    if (!pickedSubCat) return;
    props.hook.subcategoryRef.current.setValue(pickedSubCat);
  }, [currentSubCategory, props.hook.subcategoryRef, _subCategoryList]);

  // UPDATE SUB CAT IN REDUX STORE AND FILTER CUSTOM HOOK WHEN subCatOptionSelected CHANGE
  useEffect(() => {
    if (!subCatOptionSelected.value) return;
    // UPDATE VALUE IN CUSTOM HOOK
    props.hook.setSelectedSubCatOption(subCatOptionSelected.value);

    // UPDATE VALUES IN REDUX STORE
    if (props.for === "home-page") {
      dispatch(
        articleActions.setCurrentSubCategory({
          value: subCatOptionSelected.value,
        })
      );
    } else if (props.for === "user-articles") {
      dispatch(
        userArticleActions.setCurrentSubCategory({
          value: subCatOptionSelected.value,
        })
      );
    }
  }, [
    subCatOptionSelected,
    dispatch,
    props.for,
    props.hook.setSelectedSubCatOption,
  ]);

  useEffect(() => {
    _setSortOptions([
      {
        value: "-createdAt",
        label: translations.msg_filterscontainer_recent || "Recent",
      },
      {
        value: "createdAt",
        label: translations.msg_filterscontainer_old || "Old",
      },
      {
        value: "price",
        label: translations.msg_filterscontainer_cheapest || "Cheapest",
      },
      {
        value: "-price",
        label: translations.msg_filterscontainer_expensive || "Expensive",
      },
    ]);
  }, [translations]);

  useEffect(() => {
    _sortOptions.length > 0 &&
      props.hook.sortRef?.current &&
      props.hook.sortRef.current.setValue(
        _sortOptions.find((s) => s.value === props.hook.sort)
      );
  }, [_sortOptions, props.hook.sort, props.hook.sortRef]);

  const toggleFiltersHandler = (e) => {
    e.preventDefault();
    setShowFilters(!showFilters);
  };

  const getFormattedArticlesText = () => {
    const template =
      translations.msg_filterscontainer_found_articles || `{0} articles in {1}`;
    const category = props.hook.selectedCategoryOption;

    return template.replace(/{(\d)}/g, (match, i) => {
      return i == 0 ? numOfArticles : category.label;
    });
  };

  const onSubmitFormHandler = (e) => {
    e.preventDefault();
    if (props.for === "home-page") {
      dispatch(articleActions.setFetchArticles({ value: true }));
    }
    if (props.for === "user-articles") {
      dispatch(userArticleActions.setFetchArticles({ value: true }));
    }
    props.hook.searchFilteredArticlesHandler();
  };

  const setBothHandler = (e) => {
    e.preventDefault();
    props.hook.setIsBothHandler(true);
  };
  // console.log(ads.slice(Math.ceil(ads.length / 2), ads.length));

  return (
    <form className={classes.container} onSubmit={onSubmitFormHandler}>
      {props.for === "home-page" && (
        <CategoriesList
          setSelectedCategoryOption={props.hook.setSelectedCategoryOption}
          selectedCategoryOption={props.hook.selectedCategoryOption}
        />
      )}
      {/* <MainAd
        type={"left"}
        size="25x40"
        ads={_ads}
        startingAd={startingAdLeft}
      /> */}
      <div className={classes["inner-container"]}>
        {/* <MainAd type={"horizontal"} size="104x90" ads={_ads} startingAd={0} /> */}
        <Search
          data={{
            search: { set: props.hook.setSearch, get: props.hook.search },
          }}
        />
        <div className={classes.card}>
          <div className={classes.header} onClick={toggleFiltersHandler}>
            <h4>{translations.msg_filterscontainer_filters || "FILTERS"}</h4>
            <LiaFilterSolid />
          </div>
          {showFilters && (
            <div className={classes.body}>
              <div className={classes.divider} />
              <div className={classes["filters-body"]}>
                <div className={classes.main}>
                  <div className={classes["filter-footer"]}>
                    <div className={classes.category}>
                      <h5>
                        {translations.msg_filterscontainer_category ||
                          "Category"}
                        :
                      </h5>
                      <Select
                        className={classes.select}
                        ref={props.hook.categoryRef}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "4rem",
                            fontSize: "1.6rem",
                            border: "0.1rem solid var(--background)",
                            backgroundColor: "var(--background)",
                            borderTopRightRadius: "0.4rem",
                            borderBottomRightRadius: "0.4rem",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--text-color)",
                            fontSize: "1.6rem",
                            backgroundColor: state.isSelected
                              ? "var(--background)"
                              : "",
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--main-color)",
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            zIndex: "20",
                          }),
                        }}
                        label={
                          translations.msg_filterscontainer_category ||
                          "Category"
                        }
                        isSearchable={window.innerWidth <= 800 ? false : true}
                        defaultValue={CT._catList.find(
                          (cr) => cr.value === props.hook.currentCategory
                        )}
                        onChange={props.hook.setSelectedCategoryOption}
                        options={CT._catList}
                      ></Select>
                    </div>
                    <div className={classes.subcategory}>
                      <h5>
                        {translations.msg_filterscontainer_subcategory ||
                          "Subcategory"}
                        :
                      </h5>
                      <Select
                        className={classes.select}
                        ref={props.hook.subcategoryRef}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "4rem",
                            fontSize: "1.6rem",
                            border: "0.1rem solid var(--background)",
                            backgroundColor: "var(--background)",
                            borderTopRightRadius: "0.4rem",
                            borderBottomRightRadius: "0.4rem",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--text-color)",
                            fontSize: "1.6rem",
                            backgroundColor: state.isSelected
                              ? "var(--background)"
                              : "",
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--main-color)",
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            zIndex: "20",
                          }),
                        }}
                        label={
                          translations.msg_filterscontainer_category ||
                          "Category"
                        }
                        isDisabled={subCatDisabled}
                        isSearchable={window.innerWidth <= 800 ? false : true}
                        defaultValue={
                          { value: 0, label: "All" }
                          //   CT.getTranslatedSubCatList(
                          //   props.hook.currentCategory
                          // ).find(
                          //   (cr) => cr.value === props.hook.currentSubCategory
                          // )
                        }
                        onChange={setSubCatOptionSelected}
                        options={_subCategoryList}
                      ></Select>
                    </div>
                    <div className={classes.sort}>
                      <h5>
                        {translations.msg_filterscontainer_sort || "Sort"}:
                      </h5>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "4rem",
                            fontSize: "1.6rem",
                            border: "0.1rem solid var(--background)",
                            backgroundColor: "var(--background)",
                            borderTopRightRadius: "0.4rem",
                            borderBottomRightRadius: "0.4rem",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--text-color)",
                            fontSize: "1.6rem",
                            backgroundColor: state.isSelected
                              ? "var(--background)"
                              : "",
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "var(--main-color)",
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            zIndex: "20",
                          }),
                        }}
                        className={classes.select}
                        label={translations.msg_filterscontainer_sort || "Sort"}
                        ref={props.hook.sortRef}
                        isSearchable={false}
                        defaultValue={_sortOptions.find(
                          (s) => s.value === props.hook.sort
                        )}
                        onChange={props.hook.setSelectedSortOption}
                        options={_sortOptions}
                      ></Select>
                    </div>
                  </div>
                </div>
                <div className={classes["single-filter"]}>
                  <h5>{translations.msg_filterscontainer_price || "Price"}</h5>
                  <PriceInput
                    data={{
                      minPrice: {
                        get: props.hook.minPrice,
                        set: props.hook.setMinPrice,
                      },
                      maxPrice: {
                        get: props.hook.maxPrice,
                        set: props.hook.setMaxPrice,
                      },
                    }}
                  />
                </div>
                <div className={classes["single-filter"]}>
                  <h5>
                    {translations.msg_filterscontainer_articles_state ||
                      "Articles state"}
                  </h5>
                  <StateContainer
                    data={{
                      isNew: {
                        value: props.hook.isNew,
                        description:
                          translations.msg_filterscontainer_new || "New",
                        onClick: props.hook.setIsNewHandler,
                      },
                      isBoth: {
                        value: props.hook.isNew === props.hook.isUsed,
                        description:
                          translations.msg_filterscontainer_both || "Both",
                        onClick: setBothHandler,
                      },
                      isUsed: {
                        value: props.hook.isUsed,
                        description:
                          translations.msg_filterscontainer_used || "Used",
                        onClick: props.hook.setIsUsedHandler,
                      },
                    }}
                  />
                </div>
                {props.hook.selectedCategoryOption.value === 6 && (
                  <VehiclesFilters
                    data={{
                      carCompany: {
                        get: props.hook.carCompany,
                        set: props.hook.setCarCompany,
                      },
                      carModel: {
                        get: props.hook.carModel,
                        set: props.hook.setCarModel,
                      },
                      year: { get: props.hook.year, set: props.hook.setYear },
                      miles: {
                        get: props.hook.miles,
                        set: props.hook.setMiles,
                      },
                      engine: {
                        get: props.hook.engine,
                        set: props.hook.setEngine,
                      },
                      transmition: {
                        get: props.hook.transmition,
                        set: props.hook.setTransmition,
                      },
                      carDoors: {
                        get: props.hook.carDoors,
                        set: props.hook.setCarDoors,
                      },
                      carColor: {
                        get: props.hook.carColor,
                        set: props.hook.setCarColor,
                      },
                      minCc: {
                        get: props.hook.minCc,
                        set: props.hook.setMinCc,
                      },
                      maxCc: {
                        get: props.hook.maxCc,
                        set: props.hook.setMaxCc,
                      },
                      minKw: {
                        get: props.hook.minKw,
                        set: props.hook.setMinKw,
                      },
                      maxKw: {
                        get: props.hook.maxKw,
                        set: props.hook.setMaxKw,
                      },
                    }}
                  />
                )}
                {props.hook.selectedCategoryOption.value === 11 && (
                  <RealEstateFilters
                    data={{
                      realEstateStatus: {
                        get: props.hook.realEstateStatus,
                        set: props.hook.setRealEstateStatus,
                      },
                      rooms: {
                        get: props.hook.rooms,
                        set: props.hook.setRooms,
                      },
                      minArea: {
                        get: props.hook.minArea,
                        set: props.hook.setMinArea,
                      },
                      maxArea: {
                        get: props.hook.maxArea,
                        set: props.hook.setMaxArea,
                      },
                      balcony: {
                        get: props.hook.balcony,
                        set: props.hook.setBalcony,
                      },
                      elevator: {
                        get: props.hook.elevator,
                        set: props.hook.setElevator,
                      },
                      garage: {
                        get: props.hook.garage,
                        set: props.hook.setGarage,
                      },
                      REaditionalFilters: {
                        get: props.hook.REaditionalFilters,
                        set: props.hook.setREaditionalFilters,
                      },
                    }}
                  />
                )}
              </div>
              <FilterButtons />
            </div>
          )}
        </div>
      </div>
      {/* <MainAd
        type={"right"}
        size="25x40"
        ads={_ads}
        startingAd={startingAdRight}
      /> */}
      {props.for === "home-page" && (
        <div className={classes.info}>
          <p>{getFormattedArticlesText()}</p>
        </div>
      )}
    </form>
  );
};

export default FiltersContainer;
