import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Home.module.css";

//STORE
import { articleActions } from "../store/redux-store";

// COMPONENTS
import ArticlesContainer from "../components/main/articles/ArticlesContainer";
import FiltersContainer from "../components/main/articles/filters/FiltersContainer";
import PromotedArticlesContainer from "../components/main/promoted-articles/PromotedArticlesContainer";
import MainAd from "../components/advertisment/MainAd";
import Loading from "../UI/Loading";

import { IoMdCar } from "react-icons/io";
import { FaBuilding, FaToolbox, FaDog } from "react-icons/fa6";
import { GiClothes } from "react-icons/gi";
import { MdComputer, MdChair } from "react-icons/md";

//CUSTOM HOOKS
// import useQueryString from "../custom-hooks/useQueryString";

const Home = (props) => {
  // const { queryStrCreator } = useQueryString();
  const dispatch = useDispatch();
  const { numOfPages, currentArticlePage, numOfArticles } = useSelector(
    (state) => state.article
  );

  useEffect(() => {
    let isFetching = false;
    const scrollHandler = () => {
      if (numOfPages === currentArticlePage || isFetching) return;

      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.offsetHeight;
      const threshold = 100;
      if (scrollPosition >= documentHeight - threshold) {
        isFetching = true;
        setTimeout(() => {
          dispatch(articleActions.setUpdateAllArticles({ value: true }));
        }, 300);

        window.removeEventListener("scroll", scrollHandler);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [currentArticlePage, dispatch, numOfPages]);

  return (
    <div className={classes.container}>
      {/* <MainAd /> */}
      {/* <PromotedArticlesContainer cardWidth={props.cardWidth} /> */}
      <FiltersContainer
        // actions={articleActions}
        numOfArticles={numOfArticles}
        for="home-page"
        hook={props.hook}
      />
      <ArticlesContainer setCardWidth={props.setCardWidth} />
      <div className={classes.divider} />
      {!(numOfPages === currentArticlePage) && numOfPages !== 0 && <Loading />}
    </div>
  );
};
//
export default Home;
