import React from "react";

import classes from "./PaginationBtn.module.css";

const PaginationBtn = (props) => {
  return (
    <li
      key={props.curPage}
      onClick={props.onClick}
      id={props.curPage}
      className={props.className ? props.className : ""}
    >
      {props.curPage}
    </li>
  );
};

export default PaginationBtn;
