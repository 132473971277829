import React, { useState, useEffect } from "react";

import classes from "./StateContainer.module.css";

const StateContainer = (props) => {
  const [blockPosition, setBlockPosition] = useState({
    left: "50%",
    transform: "translateX(-50%)",
  });

  useEffect(() => {
    if (props.data.isUsed.value === props.data.isNew.value)
      setBlockPosition({
        left: "50%",
        transform: "translateX(-50%)",
      });
    if (props.data.isUsed.value && !props.data.isNew.value)
      setBlockPosition({ left: "0%", transform: "translateX(0%)" });
    if (!props.data.isUsed.value && props.data.isNew.value)
      setBlockPosition({ left: "100%", transform: "translateX(-100%)" });
  }, [props.data.isNew, props.data.isUsed]);

  return (
    <div className={classes.container}>
      <div className={classes.block} style={blockPosition} />
      <div
        className={classes.option}
        onClick={props.data.isUsed.onClick}
        style={
          props.data.isUsed.value && !props.data.isNew.value
            ? // ? {}
              { color: "#fff" }
            : {}
        }
      >
        {props.data.isUsed.description}
      </div>
      <div
        className={classes.option}
        onClick={props.data.isBoth.onClick}
        style={
          props.data.isUsed.value && props.data.isNew.value
            ? // ? {}
              { color: "#fff" }
            : {}
        }
      >
        {props.data.isBoth.description}
      </div>
      <div
        className={classes.option}
        onClick={props.data.isNew.onClick}
        style={
          !props.data.isUsed.value && props.data.isNew.value
            ? // ? {}
              { color: "#fff" }
            : {}
        }
      >
        {props.data.isNew.description}
      </div>
    </div>
  );
};

export default StateContainer;
