import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./Footer.module.css";
import { useTranslation } from "../../TranslationContext";

import { PiCopyright } from "react-icons/pi";

import config from "../../website-config";

import logoPath from "../../assets/logo/logo.png";

const Footer = (props) => {
  const { translations, language, setLanguage } = useTranslation();
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const changeLanguageHandler = (e) => {
    e.preventDefault();
    if (e.target.id) {
      setLanguage(e.target.id);
      localStorage.setItem("language", e.target.value);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div className={classes.links}>
          <div className={classes.group}>
            <h4>{translations.footer_pages || "Pages"}</h4>
            {!isAuthenticated && !user._id && (
              <Link to="/auth/signup" className={classes.link}>
                {`${translations.msg_login_title || "Login"} / ${
                  translations.msg_signup_title || "Sign up"
                }`}
              </Link>
            )}
            <Link to="/" className={classes.link}>
              {`${translations.msg_footer_artikli || "Artikli"}`}
            </Link>
            <Link to="/articles/create-article" className={classes.link}>
              {`${
                translations.msg_articleformcontainer_create_title ||
                "Create New Article"
              }`}
            </Link>
            {isAuthenticated && user._id && (
              <Link to="/messages" className={classes.link}>
                {`${translations.msg_header_messages || "Messages"}`}
              </Link>
            )}
            {isAuthenticated && user._id && (
              <Link to="/userArticles/active" className={classes.link}>
                {`${translations.msg_header_my_ads || "My Articles"}`}
              </Link>
            )}
            {isAuthenticated && user._id && (
              <Link to="/profile" className={classes.link}>
                {`${translations.msg_userdropdown_my_profile || "My Profile"}`}
              </Link>
            )}
          </div>
          <div className={classes.group}>
            <h4>
              {translations.footer_info_and_contact || "Info and Contact"}
            </h4>
            <Link to="/terms-of-use#about-us" className={classes.link}>
              {`${translations.tos_about_us || "About Us"}`}
            </Link>
            <Link to="/terms-of-use#agb" className={classes.link}>
              {`${translations.tos_agb || "AGB"}`}
            </Link>
            <Link to="/terms-of-use#privacy-policy" className={classes.link}>
              {`${translations.tos_privacy_policy || "Privacy Policy"}`}
            </Link>
            <Link to="/terms-of-use#marketing" className={classes.link}>
              {`${translations.tos_marketing || "Marketing"}`}
            </Link>
            <Link to="/terms-of-use#imprint" className={classes.link}>
              {`${translations.tos_imprint || "Impressum"}`}
            </Link>
            <Link to="/terms-of-use#contact" className={classes.link}>
              {`${translations.tos_contact || "Contact"}`}
            </Link>
          </div>
          <div className={classes["third-column"]} />
          <div className={classes.details}>
            <div
              className={classes["details-single"]}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <p className={classes.logo}>H&P</p> */}
              <div className={classes["logo-box"]}>
                <img
                  src={logoPath}
                  alt={`${config.companyName} logo`}
                  className={classes.logo}
                />
              </div>
              <p
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  fontSize: "1.4rem",
                  color: "var(--text-color)",
                }}
              >
                {config.companyName}
              </p>
            </div>
            <div className={classes.separator} />
            <div className={classes["details-single"]}>
              <h5>{translations.footer_language || "Language"}</h5>
              <div className={classes.languages}>
                <p
                  id={"de"}
                  onClick={changeLanguageHandler}
                  className={classes.lang}
                  style={
                    language === "de"
                      ? { borderColor: "var(--accent-secondary-color)" }
                      : {}
                  }
                >
                  DE
                </p>
                <p
                  id={"fr"}
                  onClick={changeLanguageHandler}
                  className={classes.lang}
                  style={
                    language === "fr"
                      ? { borderColor: "var(--accent-secondary-color)" }
                      : {}
                  }
                >
                  FR
                </p>
                <p
                  id={"it"}
                  onClick={changeLanguageHandler}
                  className={classes.lang}
                  style={
                    language === "it"
                      ? { borderColor: "var(--accent-secondary-color)" }
                      : {}
                  }
                >
                  IT
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["sub-footer"]}>
        <p>{`Copyright`}</p>
        <PiCopyright />
        <p>{`${new Date(Date.now()).getFullYear()} - ${config.companyName}`}</p>
      </div>
    </div>
  );
};

export default Footer;
