import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./PausedArticlesContainer.module.css";

//COMPONENTS
import SingleUserArticle from "./SingleUserArticle";

const PausedArticlesContainer = (props) => {
  const { articles } = useSelector((state) => state.userArticle);
  const [pausedArticles, setPausedArticles] = useState([]);
  const [articlesRenderer, setArticlesRenderer] = useState([]);

  useEffect(() => {
    let _pausedArticles = [];
    articles.forEach((a) => {
      if (a.status === 3) _pausedArticles.push(a);
    });
    setPausedArticles(_pausedArticles);
  }, [articles]);

  useEffect(() => {
    setArticlesRenderer(
      pausedArticles.map((a) => {
        return <SingleUserArticle key={a._id} data={a} />;
      })
    );
  }, [pausedArticles]);

  return <div className={classes.container}>{articlesRenderer}</div>;
};

export default PausedArticlesContainer;
