import { configureStore } from "@reduxjs/toolkit";

//REDUCERS
import articleSlice from "./reducers/article-slice";
import userArticleSlice from "./reducers/user-article-slice";
import authSlice from "./reducers/auth-store";
import chatSlice from "./reducers/chat-slice";
import alertSlice from "./reducers/alert-slice";
import reportSlice from "./reducers/report-slice";
import adminSlice from "./reducers/admin-slice";
import userSlice from "./reducers/user-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    article: articleSlice.reducer,
    userArticle: userArticleSlice.reducer,
    chat: chatSlice.reducer,
    alert: alertSlice.reducer,
    report: reportSlice.reducer,
    admin: adminSlice.reducer,
    user: userSlice.reducer,
  },
});

export const articleActions = articleSlice.actions;
export const userArticleActions = userArticleSlice.actions;
export const authActions = authSlice.actions;
export const chatActions = chatSlice.actions;
export const alertActions = alertSlice.actions;
export const reportActions = reportSlice.actions;
export const adminActions = adminSlice.actions;
export const userActions = userSlice.actions;

export default store;
