import React from "react";
import { Link } from "react-router-dom";

import classes from "./Cookie.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const Cookie = (props) => {
  const { translations } = useTranslation();

  const acceptCookiesHandler = (e) => {
    e.preventDefault();

    props.setInitialCookieHandler("C0001;C0201;AS1;GPT1");
  };

  return (
    <div className={classes.container}>
      <div className={classes.opacity} />
      <div className={classes.footer}>
        <div className={classes.body}>
          <h5>
            {translations.cookie_title || "Your privacy is important to us!"}
          </h5>
          <p>{translations.cookie_text || ""}</p>
          <Link className={classes.link} to="/privacy-policy">
            {translations.cookie_link_text || "Check out the privacy policy"}
          </Link>
        </div>
        <div className={classes.btns}>
          <button className={classes.btn}>
            {translations.cookie_decline || "Decline"}
          </button>
          <button
            className={`${classes.accept} ${classes.btn}`}
            onClick={acceptCookiesHandler}
          >
            {translations.cookie_accept || "Accept"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
