import { useState } from "react";

import classes from "./TextAreaInput.module.css";

const TextAreaInput = (props) => {
  return (
    <div className={`${classes.input} ${classes.position}`}>
      {props.data.name && (
        <label htmlFor={props.data.name}>{props.data.name}</label>
      )}
      <textarea
        type="text"
        name={props.data.name}
        disabled={props.data.isDisabled}
        // onKeyDown={props.onKeyDown}
        placeholder={props.data.placeholder}
        spellCheck={false}
        value={props.data.value}
        style={props.data.error && { borderColor: "red" }}
        onChange={props.onChange}
        onBlur={props.onBlur}
      ></textarea>
      <p className={classes.counter}>{props.data?.value?.length} / 5000</p>
      {props.data.error && (
        <p className={classes.error}>
          {props.data.error && `* ${props.data.error}`}
        </p>
      )}
    </div>
  );
};

export default TextAreaInput;
