import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./UserContainer.module.css";

//COMPONENTS
import UserData from "./UserData";
import UserArticles from "./UserArticles";

const UserContainer = (props) => {
  return (
    <div className={classes.container}>
      <UserData hook={props.hook} />
      <UserArticles data={props.data} hook={props.hook} />
    </div>
  );
};

export default UserContainer;
