import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./SingleChat.module.css";

//STORE
import { chatActions } from "../../store/redux-store";

let IMAGE_URL;

IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const SingleChat = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { openedChat } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [chatter, setChatter] = useState(null);
  const [article, setArticle] = useState(null);
  const [unread, setUnread] = useState(0);

  useEffect(() => {
    if (props.data.articleId === null) return;
    setArticle(props.data.articleId);
  }, [props.data.articleId]);

  useEffect(() => {
    if (!user._id || !props.data.userOne || !props.data.userTwo) return;
    setChatter(
      user._id === props.data.userTwo._id
        ? props.data.userOne
        : props.data.userTwo
    );
  }, [user._id, props.data.userOne, props.data.userTwo]);

  let title;
  if (article) {
    title =
      article.title?.length > 30
        ? `${article.title?.slice(0, 30)}...`
        : article.title;
  }

  const setOpenChatHandler = (e) => {
    e.preventDefault();
    dispatch(chatActions.setOpenedChat({ data: props.data }));
    dispatch(chatActions.resetSendMessageTemplate());
    // setCurrentChat(props.data._id);
  };

  const imagePath = `${article?.mainImage}`.includes("http")
    ? article?.mainImage
    : `${IMAGE_URL}/articles/${article?.mainImage}`;

  useEffect(() => {
    let notSeen = 0;
    props.data.messages.forEach((msg) => {
      if (msg.recipient === user._id && !msg.seen) notSeen++;
    });
    setUnread(notSeen);
  }, [props.data.messages, user._id]);

  let text = props.data.messages[props.data.messages.length - 1].text;
  text = text.length > 20 ? `${text.slice(0, 20)}...` : text;

  return (
    <div className={classes.container} onClick={setOpenChatHandler}>
      {unread > 0 && (
        <div className={classes["unread-message"]}>{/* <p>{unread}</p> */}</div>
      )}
      <div className={classes["image-container"]}>
        <img
          className={classes.img}
          src={
            article && article.mainImage
              ? imagePath
              : `${IMAGE_URL}/articles/default.jpeg`
          }
          // src={article ? imagePath : `${IMAGE_URL}/articles/default.jpeg`}
          alt={article ? article?.title : props.data.title}
        ></img>
        <div className={classes.overlay} />
      </div>
      <div className={classes.details}>
        <div
          className={classes["chat-user"]}
          style={
            props.data?._id === openedChat?._id
              ? { backgroundColor: "var(--main-color)", color: "#fff" }
              : null
          }
        >
          {/* <img
            src={
              chatter?.image
                ? `${IMAGE_URL}/users/${chatter?.image}`
                : `${IMAGE_URL}/users/default.jpeg`
            }
            alt={`${chatter?.username}`}
            className={classes["chat-user__image"]}
            style={!seen ? { borderColor: "red" } : {}}
          /> */}
          <p className={classes["chat-user__static"]}>{chatter?.username}</p>
          <p className={classes["chat-user__static"]}>
            {new Date(props.data.lastMessageDate).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
        </div>
        <p className={classes["chat-article__title"]}>
          {title ? title : "None"}
        </p>
        <p className={classes["chat-article__msg"]}>{text}</p>
      </div>
    </div>
  );
};

export default SingleChat;
