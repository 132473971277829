import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//STORE
import { articleActions } from "../store/redux-store";
import queryStringCreator from "../helper/query-str-creator";
import { getArticles } from "../store/reducers/article-slice";

let init = true;

const useQueryString = () => {
  const dispatch = useDispatch();
  const {
    //TRIGGER FETCH
    updateAllArticles,
    fetchArticles,
    refreshArticles,
    fetchAfterCreateArticle,
    //ARTICLE QUERY FIELDS
    currentArticlePage,
    searchFilters,
    search,
    sort,
    currentCategory,
    currentSubCategory,
    numOfPages,
    articlesPerPage,
    //SET QUERY
    queryStr,
  } = useSelector((state) => state.article);

  const [triggerFirstPageFetch, setTriggerFirstPageFetch] = useState(false);

  // searchFilteredArticlesHandler
  //INCREMENT CURRENT ARTICLE PAGE
  const incrementCurrentArticlePage = useCallback(
    (page) => {
      let int;
      if (currentArticlePage >= numOfPages) {
        int = numOfPages;
      } else {
        int = currentArticlePage + 1;
      }
      dispatch(articleActions.setCurrentArticlePage({ value: int }));
      return int;
    },
    [dispatch, currentArticlePage, numOfPages]
  );

  //CREATE QUERY STRING WITH INCREMENT CURRENT PAGE
  const queryStrCreator = useCallback(() => {
    const currPage = incrementCurrentArticlePage();

    return queryStringCreator(
      currPage,
      searchFilters,
      currentCategory,
      search,
      sort,
      articlesPerPage,
      currentSubCategory
    );
  }, [
    searchFilters,
    currentCategory,
    search,
    sort,
    articlesPerPage,
    currentSubCategory,
    incrementCurrentArticlePage,
  ]);

  //CREATE QUERY STRING WITH CURRENT PAGE OF 1
  const queryStrCreatorFirstPage = useCallback(
    (currPage = 1) => {
      return queryStringCreator(
        currPage,
        searchFilters,
        currentCategory,
        search,
        sort,
        articlesPerPage,
        currentSubCategory
      );
    },
    [
      currentCategory,
      search,
      sort,
      searchFilters,
      articlesPerPage,
      currentSubCategory,
    ]
  );

  const fetchArticlesWithFirstPage = useCallback(() => {
    const queryString = queryStrCreatorFirstPage();
    dispatch(articleActions.setQueryStr({ value: queryString }));
    dispatch(getArticles({ queryString }));
  }, [dispatch, queryStrCreatorFirstPage]);

  //FETCH WHEN CATEGORY CHANGE
  useEffect(() => {
    if (!init) {
      setTriggerFirstPageFetch(true);
    }
  }, [currentCategory, currentSubCategory, sort]);

  //FETCH WHEN SUBCATEGORY CHANGE
  // useEffect(() => {
  //   if (!init) {
  //     setTriggerFirstPageFetch(true);
  //   }
  // }, [currentSubCategory]);

  //FETCH WHEN SORT CHANGE
  // useEffect(() => {
  //   if (!init) {
  //     setTriggerFirstPageFetch(true);
  //   }
  // }, [sort]);

  //FIRST INIT
  useEffect(() => {
    if (init) {
      fetchArticlesWithFirstPage();
      init = false;
    }
  });

  //FETCH ARTICLES AFTER TRIGGERFIRSTPAGEFETCH IS TRUE
  useEffect(() => {
    if (triggerFirstPageFetch) {
      fetchArticlesWithFirstPage();
      setTriggerFirstPageFetch(false);
    }
  }, [fetchArticlesWithFirstPage, triggerFirstPageFetch]);

  //FETCH ARTICLES WHEN FETCHARTICLES IS SET TO TRUE IN STORE
  useEffect(() => {
    if (fetchArticles) {
      fetchArticlesWithFirstPage();
      dispatch(articleActions.setFetchArticles({ value: false }));
    }
  }, [fetchArticlesWithFirstPage, dispatch, fetchArticles]);

  //FETCH ANOTHER X AMOUNT OF ARTICLES WHEN YOU SCROLL TO THE BOTTOM OF THE PAGE
  useEffect(() => {
    if (updateAllArticles) {
      const queryString = queryStrCreator();
      dispatch(getArticles({ queryString }, true));
      dispatch(articleActions.setUpdateAllArticles({ value: false }));
    }
  }, [updateAllArticles, dispatch, queryStrCreator]);

  useEffect(() => {
    if (refreshArticles) {
      dispatch(articleActions.setRefreshArticles({ value: false }));
      dispatch(getArticles({ queryString: queryStr }));
    }
  }, [dispatch, refreshArticles, queryStr]);

  //FETCH AFTER NEW ARTICLE WAS CREATED
  useEffect(() => {
    if (fetchAfterCreateArticle) {
      const queryString = queryStrCreatorFirstPage();
      dispatch(getArticles({ queryString }));
      dispatch(articleActions.setFetchAfterCreateArticle({ value: false }));
    }
  }, [fetchAfterCreateArticle, dispatch, queryStrCreatorFirstPage]);

  return {
    queryStrCreator,
    incrementCurrentArticlePage,
    queryStrCreatorFirstPage,
  };
};

export default useQueryString;
