import { useState } from "react";
import Select from "react-select";

import classes from "./SelectInput.module.css";

const SelectInput = (props) => {
  return (
    <div className={classes.container}>
      {props.data.name && (
        <label className={classes.label}>{props.data.name}</label>
      )}
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: "4rem",
            color: "var(--text-color)",
            fontSize: "1.6rem",
            borderColor: "var(--background)",
            backgroundColor: state.isDisabled
              ? "var(--disabled)"
              : "var(--background)",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "1.6rem",
            color: "var(--text-color)",
            backgroundColor: state.isSelected ? "var(--background)" : "#fff",
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            color: "var(--text-color)",
            height: "4rem",
            width: "100%",
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            height: "4rem",
            padding: "0 8px",
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            margin: "0",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: "var(--main-color)",
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isDisabled ? "var(--border)" : "var(--text-color)",
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "20",
          }),
        }}
        label={props.data.name}
        ref={props.data.ref}
        isSearchable={props.data.isSearchable}
        isDisabled={props.data.isDisabled}
        defaultValue={props.data.defaultValue}
        onChange={props.onChange}
        options={props.data.options}
      ></Select>
    </div>
  );
};

export default SelectInput;
