const categories = [
  {
    index: 0,
    name: "Alle",
    subcategories: [],
  },
  {
    index: 1,
    name: "Antiquitäten & Kunst",
    subcategories: [],
  },
  {
    index: 2,
    name: "Baby & Kind",
    subcategories: [
      { index: 1, name: "Baby Pflege" },
      { index: 2, name: "Kinderwagen & Sitze" },
      { index: 3, name: "Kinderzimmer" },
      { index: 4, name: "Kleider & Schuhe" },
    ],
  },
  {
    index: 3,
    name: "Bücher",
    subcategories: [
      { index: 1, name: "Comics" },
      { index: 2, name: "Romane" },
      { index: 3, name: "Sachbücher & Ratsgeber" },
      { index: 4, name: "Sonstige Bücher" },
    ],
  },
  {
    index: 4,
    name: "Büro & Gewerbe",
    subcategories: [
      { index: 1, name: "Büromaterial & Büromöbel" },
      { index: 2, name: "Geschäftseinrichtungen" },
    ],
  },
  {
    index: 5,
    name: "Computer & Zubehör",
    subcategories: [
      { index: 1, name: "Computer" },
      { index: 2, name: "Komponenten & Zubehör" },
      { index: 3, name: "Software" },
      { index: 4, name: "Tablets" },
    ],
  },
  {
    index: 6,
    name: "Fahrzeuge",
    subcategories: [
      { index: 1, name: "Autos" },
      { index: 2, name: "Autozubehör" },
      { index: 3, name: "Boot & Zubehör" },
      { index: 4, name: "Motorradzubehöer" },
    ],
  },
  {
    index: 7,
    name: "Filme",
    subcategories: [],
  },
  {
    index: 8,
    name: "Foto & Video",
    subcategories: [
      { index: 1, name: "Fotokameras" },
      { index: 2, name: "Videokameras" },
      { index: 3, name: "Zubehör" },
    ],
  },
  {
    index: 9,
    name: "Garten & Handwerk",
    subcategories: [
      { index: 1, name: "Baumaterial" },
      { index: 2, name: "Gartenausstattung" },
      { index: 3, name: "Werkzeuge & Maschinen" },
    ],
  },
  {
    index: 10,
    name: "Haushalt",
    subcategories: [
      { index: 1, name: "Beleuchtung" },
      { index: 2, name: "Deko & Accessoires" },
      { index: 3, name: "Geräte & Utensilien" },
      { index: 4, name: "Lebensmittel" },
      { index: 5, name: "Möbel" },
    ],
  },
  {
    index: 11,
    name: "Immobilien",
    subcategories: [],
  },
  {
    index: 12,
    name: "Kleidung & Accessoires",
    subcategories: [
      { index: 1, name: "Accessoires & Beauty" },
      { index: 2, name: "Kleidung für Damen" },
      { index: 3, name: "Kleidung für Herren" },
      { index: 4, name: "Schuhe für Damen" },
      { index: 5, name: "Schuhe für Herren" },
      { index: 6, name: "Taschen & Portemonnaies" },
      { index: 7, name: "Uhren & Schmuck" },
    ],
  },
  {
    index: 13,
    name: "Musik",
    subcategories: [
      { index: 1, name: "CD, Vinyl & Kassetten" },
      { index: 2, name: "Instrumente" },
    ],
  },
  {
    index: 14,
    name: "Sammeln",
    subcategories: [],
  },
  {
    index: 15,
    name: "Spielzeuge & Basteln",
    subcategories: [
      { index: 1, name: "Basteln" },
      { index: 2, name: "Modellbau" },
      { index: 3, name: "Spielkonsolen & Games" },
      { index: 4, name: "Spielzeuge" },
    ],
  },
  {
    index: 16,
    name: "Sport & Outdoor",
    subcategories: [
      { index: 1, name: "Camping" },
      { index: 2, name: "Fitness" },
      { index: 3, name: "Sonstige Sportarten" },
      { index: 4, name: "Velos" },
      { index: 5, name: "Wintersport" },
    ],
  },
  {
    index: 17,
    name: "Stellen & Kurse",
    subcategories: [],
  },
  {
    index: 18,
    name: "TV & Audio",
    subcategories: [
      { index: 1, name: "Audio & Hi-Fi" },
      { index: 2, name: "DVD-Player" },
      { index: 3, name: "Fernseher" },
    ],
  },
  {
    index: 19,
    name: "Telefon & Navigation",
    subcategories: [
      { index: 1, name: "Festnetztelefone" },
      { index: 2, name: "Handys" },
      { index: 3, name: "Navigationssysteme" },
      { index: 4, name: "Zubehör" },
    ],
  },
  {
    index: 20,
    name: "Tickets & Gutscheine",
    subcategories: [],
  },
  {
    index: 21,
    name: "Tiere",
    subcategories: [
      { index: 1, name: "Fische" },
      { index: 2, name: "Hasen & Nagetiere" },
      { index: 3, name: "Hunde" },
      { index: 4, name: "Hunde Zubehör" },
      { index: 5, name: "Katzen" },
      { index: 6, name: "Pferde" },
      { index: 7, name: "Reptilien" },
      { index: 8, name: "Sonstige Tiere" },
      { index: 9, name: "Vögel" },
    ],
  },
  {
    index: 22,
    name: "Sonstiges",
    subcategories: [],
  },
];

//console.log(categories);
export class Categories {
  cats;

  constructor(cat) {
    this.cats = cat;
  }

  getCategory(i) {
    return this.cats.filter((c) => c.index === i)[0];
  }
  getSubcategories(i) {
    return this.cats.filter((c) => c.index === i)[0].subcategories;
  }
  getFormatedSubcategoriesList(i) {
    const subCatListFormated = [];
    const subCatList = this.cats.filter((c) => c.index === i)[0].subcategories;
    subCatList.forEach((sc) =>
      subCatListFormated.push({ value: sc.index, label: sc.name })
    );
    return subCatListFormated;
  }
  getSelectedSubcategory(ci, sci) {
    const selectedCat = this.cats.filter(c => c.index === ci)[0];
    const selectedSubCat = selectedCat.subcategories.filter(sc => sc.index === sci)[0];
    return selectedSubCat;
  }
}

export default categories;
