import React from "react";

import classes from "./Loading.module.css";

const Loading = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes["outer-circle"]}>
        <div className={classes["inner-circle"]} />
      </div>
      <div className={classes["rotation"]} />
    </div>
  );
};

export default Loading;
