//
const cars = [
  {
    index: 0,
    name: "Alle",
    subcategories: [],
  },
  {
    index: 1,
    name: "Alfa Romeo",
    subcategories: [
      { index: 1, name: "4C" },
      { index: 2, name: "8C" },
      { index: 3, name: "Alfa 145" },
      { index: 4, name: "Alfa 146" },
      { index: 5, name: "Alfa 147" },
      { index: 6, name: "Alfa 155" },
      { index: 7, name: "Alfa 156" },
      { index: 8, name: "Alfa 159" },
      { index: 9, name: "Alfa 164" },
      { index: 10, name: "Alfa 166" },
      { index: 11, name: "Alfa 33" },
      { index: 12, name: "Alfa 75" },
      { index: 13, name: "Alfa 90" },
      { index: 14, name: "Alfasud" },
      { index: 15, name: "Alfetta" },
      { index: 16, name: "Brera" },
      { index: 17, name: "Crosswagon" },
      { index: 18, name: "Giulia" },
      { index: 19, name: "Giulietta" },
      { index: 20, name: "GT" },
      { index: 21, name: "GTV" },
      { index: 22, name: "Junior" },
      { index: 23, name: "MiTo" },
      { index: 24, name: "RZ/SZ" },
      { index: 25, name: "Spider" },
      { index: 26, name: "Sprint" },
      { index: 27, name: "Stelvio" },
      { index: 28, name: "Tonale" },
      { index: 29, name: "Sonstige" },
    ],
  },
  {
    index: 2,
    name: "Audi",
    subcategories: [
      { index: 1, name: "A1" },
      { index: 2, name: "A2" },
      { index: 3, name: "A3" },
      { index: 4, name: "A3 Allstreet" },
      { index: 5, name: "A4" },
      { index: 6, name: "A4 Allroad" },
      { index: 7, name: "A5" },
      { index: 8, name: "A6" },
      { index: 9, name: "A6 Allroad" },
      { index: 10, name: "A7" },
      { index: 11, name: "A8" },
      { index: 12, name: "Cabriolet" },
      { index: 13, name: "Coupé" },
      { index: 14, name: "e-tron" },
      { index: 15, name: "e-tron GT" },
      { index: 16, name: "Q4 e-tron" },
      { index: 17, name: "Q8 e-tron" },
      { index: 18, name: "Q1" },
      { index: 19, name: "Q2" },
      { index: 20, name: "Q3" },
      { index: 21, name: "Q5" },
      { index: 22, name: "Q7" },
      { index: 23, name: "Q8" },
      { index: 24, name: "QUATTRO" },
      { index: 25, name: "R8" },
      { index: 26, name: "RS2" },
      { index: 27, name: "RS3" },
      { index: 28, name: "RS4" },
      { index: 29, name: "RS5" },
      { index: 30, name: "RS6" },
      { index: 31, name: "RS7" },
      { index: 32, name: "RSQ3" },
      { index: 33, name: "RSQ8" },
      { index: 34, name: "S1" },
      { index: 35, name: "S2" },
      { index: 36, name: "S3" },
      { index: 37, name: "S4" },
      { index: 38, name: "S5" },
      { index: 39, name: "S6" },
      { index: 40, name: "S7" },
      { index: 41, name: "S8" },
      { index: 42, name: "SQ2" },
      { index: 43, name: "SQ5" },
      { index: 44, name: "SQ7" },
      { index: 45, name: "SQ8" },
      { index: 46, name: "TT" },
      { index: 47, name: "TT RS" },
      { index: 48, name: "TTS" },
      { index: 49, name: "V8" },
      { index: 50, name: "80" },
      { index: 51, name: "90" },
      { index: 52, name: "100" },
      { index: 53, name: "200" },
      { index: 54, name: "Sonstige" },
    ],
  },
  {
    index: 3,
    name: "BMW",
    subcategories: [
      { index: 1, name: "114" },
      { index: 2, name: "116" },
      { index: 3, name: "118" },
      { index: 4, name: "120" },
      { index: 5, name: "123" },
      { index: 6, name: "125" },
      { index: 7, name: "128" },
      { index: 8, name: "130" },
      { index: 9, name: "135" },
      { index: 10, name: "214 Active Tourer" },
      { index: 11, name: "214 Gran Tourer" },
      { index: 12, name: "216" },
      { index: 13, name: "216 Active Tourer" },
      { index: 14, name: "216 Gran Tourer" },
      { index: 15, name: "218" },
      { index: 16, name: "218 Active Tourer" },
      { index: 17, name: "218 Gran Tourer" },
      { index: 18, name: "220" },
      { index: 19, name: "220 Active Tourer" },
      { index: 20, name: "220 Gran Tourer" },
      { index: 21, name: "223 Active Tourer" },
      { index: 22, name: "225" },
      { index: 23, name: "225 Active Tourer" },
      { index: 24, name: "315" },
      { index: 25, name: "316" },
      { index: 26, name: "318" },
      { index: 27, name: "318 Gran Turismo" },
      { index: 28, name: "320" },
      { index: 29, name: "320 Gran Turismo" },
      { index: 30, name: "323" },
      { index: 31, name: "324" },
      { index: 32, name: "325" },
      { index: 33, name: "325 Gran Turismo" },
      { index: 34, name: "328" },
      { index: 35, name: "328 Gran Turismo" },
      { index: 36, name: "330" },
      { index: 37, name: "330 Gran Turismo" },
      { index: 38, name: "335" },
      { index: 39, name: "335 Gran Turismo" },
      { index: 40, name: "340 Gran Turismo" },
      { index: 41, name: "ActiveHybrid 3" },
      { index: 42, name: "418" },
      { index: 43, name: "418 Gran Coupe" },
      { index: 44, name: "420" },
      { index: 45, name: "420 Gran Coupe" },
      { index: 46, name: "425" },
      { index: 47, name: "425 Gran Coupe" },
      { index: 48, name: "428" },
      { index: 49, name: "428 Gran Coupe" },
      { index: 50, name: "430" },
      { index: 51, name: "430 Gran Coupe" },
      { index: 52, name: "435" },
      { index: 53, name: "435 Gran Coupe" },
      { index: 54, name: "440" },
      { index: 55, name: "440 Gran Coupe" },
      { index: 56, name: "518" },
      { index: 57, name: "520" },
      { index: 58, name: "520 Gran Turismo" },
      { index: 59, name: "523" },
      { index: 60, name: "524" },
      { index: 61, name: "525" },
      { index: 62, name: "528" },
      { index: 63, name: "530" },
      { index: 64, name: "530 Gran Turismo" },
      { index: 65, name: "535" },
      { index: 66, name: "535 Gran Turismo" },
      { index: 67, name: "540" },
      { index: 68, name: "545" },
      { index: 69, name: "550" },
      { index: 70, name: "550 Gran Turismo" },
      { index: 71, name: "ActiveHybrid 5" },
      { index: 72, name: "620 Gran Turismo" },
      { index: 73, name: "628" },
      { index: 74, name: "630" },
      { index: 75, name: "630 Gran Turismo" },
      { index: 76, name: "633" },
      { index: 77, name: "635" },
      { index: 78, name: "640" },
      { index: 79, name: "640 Gran Coupe" },
      { index: 80, name: "640 Gran Turismo" },
      { index: 81, name: "645" },
      { index: 82, name: "650" },
      { index: 83, name: "650 Gran Coupe" },
      { index: 84, name: "725" },
      { index: 85, name: "728" },
      { index: 86, name: "730" },
      { index: 87, name: "732" },
      { index: 88, name: "735" },
      { index: 89, name: "740" },
      { index: 90, name: "745" },
      { index: 91, name: "750" },
      { index: 92, name: "760" },
      { index: 93, name: "ActiveHybrid 7" },
      { index: 94, name: "ActiveHybrid X6" },
      { index: 95, name: "X1" },
      { index: 96, name: "X2" },
      { index: 97, name: "X3" },
      { index: 98, name: "X4" },
      { index: 99, name: "X5" },
      { index: 100, name: "X6" },
      { index: 101, name: "X7" },
      { index: 102, name: "M135" },
      { index: 103, name: "M140i" },
      { index: 104, name: "M2" },
      { index: 105, name: "M235" },
      { index: 106, name: "M240i" },
      { index: 107, name: "M3" },
      { index: 108, name: "M340i" },
      { index: 109, name: "M4" },
      { index: 110, name: "M440" },
      { index: 111, name: "M5" },
      { index: 112, name: "M550" },
      { index: 113, name: "M6" },
      { index: 114, name: "M760" },
      { index: 115, name: "M8" },
      { index: 116, name: "M850" },
      { index: 117, name: "X3 M" },
      { index: 118, name: "X3 M40" },
      { index: 119, name: "X4 M" },
      { index: 120, name: "X4 M40" },
      { index: 121, name: "X5 M" },
      { index: 122, name: "X5 M50" },
      { index: 123, name: "X6 M" },
      { index: 124, name: "X6 M50" },
      { index: 125, name: "X7 M50" },
      { index: 126, name: "XM" },
      { index: 127, name: "Z3 M" },
      { index: 128, name: "Z4 M" },
      { index: 129, name: "i3" },
      { index: 130, name: "i4" },
      { index: 131, name: "i4 M50" },
      { index: 132, name: "i5" },
      { index: 133, name: "i5 M60" },
      { index: 134, name: "i7" },
      { index: 135, name: "i8" },
      { index: 136, name: "iX" },
      { index: 137, name: "iX M60" },
      { index: 138, name: "iX1" },
      { index: 139, name: "iX2" },
      { index: 140, name: "iX3" },
      { index: 141, name: "Z1" },
      { index: 142, name: "Z3" },
      { index: 143, name: "Z4" },
      { index: 144, name: "Z8" },
      { index: 145, name: "840" },
      { index: 146, name: "850" },
      { index: 147, name: "2002" },
      { index: 148, name: "Sonstige" },
    ],
  },
  {
    index: 4,
    name: "Citroen",
    subcategories: [
      { index: 1, name: "2 CV" },
      { index: 2, name: "AX" },
      { index: 3, name: "Berlingo" },
      { index: 4, name: "BX" },
      { index: 5, name: "C1" },
      { index: 6, name: "C2" },
      { index: 7, name: "C3" },
      { index: 8, name: "C3 Aircross" },
      { index: 9, name: "C3 Picasso" },
      { index: 10, name: "C3 Pluriel" },
      { index: 11, name: "C4" },
      { index: 12, name: "C4 Aircross" },
      { index: 13, name: "C4 Cactus" },
      { index: 14, name: "C4 Picasso" },
      { index: 15, name: "C4 Spacetourer" },
      { index: 16, name: "C4 X" },
      { index: 17, name: "C5" },
      { index: 18, name: "C5 Aircross" },
      { index: 19, name: "C5 X" },
      { index: 20, name: "C6" },
      { index: 21, name: "C8" },
      { index: 22, name: "C-Crosser" },
      { index: 23, name: "C-Elysee" },
      { index: 24, name: "CX" },
      { index: 25, name: "C-Zero" },
      { index: 26, name: "E-Berlingo" },
      { index: 27, name: "E-C3" },
      { index: 28, name: "E-C4" },
      { index: 29, name: "E-C4 X" },
      { index: 30, name: "E-MEHARI" },
      { index: 31, name: "E-SpaceTourer" },
      { index: 32, name: "DS" },
      { index: 33, name: "DS3" },
      { index: 34, name: "DS4" },
      { index: 35, name: "DS4 Crossback" },
      { index: 36, name: "DS5" },
      { index: 37, name: "DS7" },
      { index: 38, name: "Evasion" },
      { index: 39, name: "Grand C4 Picasso / C4 SpaceTourer" },
      { index: 40, name: "GSA" },
      { index: 41, name: "Jumper" },
      { index: 42, name: "Jumpy" },
      { index: 43, name: "Nemo" },
      { index: 44, name: "SAXO" },
      { index: 45, name: "SM" },
      { index: 46, name: "SpaceTourer" },
      { index: 47, name: "Visa" },
      { index: 48, name: "Xantia" },
      { index: 49, name: "XM" },
      { index: 50, name: "Xsara" },
      { index: 51, name: "Xsara Picasso" },
      { index: 52, name: "ZX" },
      { index: 53, name: "Sonstige" },
    ],
  },
  {
    index: 5,
    name: "Cupra",
    subcategories: [
      { index: 1, name: "Arona" },
      { index: 2, name: "Ateca" },
      { index: 3, name: "Born" },
      { index: 4, name: "Formentor" },
      { index: 5, name: "Ibiza" },
      { index: 6, name: "Leon" },
    ],
  },
  {
    index: 6,
    name: "Fiat",
    subcategories: [
      { index: 1, name: "124" },
      { index: 2, name: "126" },
      { index: 3, name: "127" },
      { index: 4, name: "130" },
      { index: 5, name: "131" },
      { index: 6, name: "500" },
      { index: 7, name: "124 Spider" },
      { index: 8, name: "124 Spider Abarth" },
      { index: 9, name: "500 Abarth" },
      { index: 10, name: "500C" },
      { index: 11, name: "500C Abarth" },
      { index: 12, name: "500E" },
      { index: 13, name: "500L" },
      { index: 14, name: "500L Cross" },
      { index: 15, name: "500L Living" },
      { index: 16, name: "500L Trekking" },
      { index: 17, name: "500L Urban" },
      { index: 18, name: "500L Wagon" },
      { index: 19, name: "500S" },
      { index: 20, name: "500X" },
      { index: 21, name: "595 Abarth" },
      { index: 22, name: "595 Competizione" },
      { index: 23, name: "595 Turismo" },
      { index: 24, name: "595C Abarth" },
      { index: 25, name: "600 Hybrid" },
      { index: 26, name: "695 Abarth" },
      { index: 27, name: "695C Abarth" },
      { index: 28, name: "Aegea" },
      { index: 29, name: "Albea" },
      { index: 30, name: "Barchetta" },
      { index: 31, name: "Brava" },
      { index: 32, name: "Bravo" },
      { index: 33, name: "Campagnola" },
      { index: 34, name: "Cinquecento" },
      { index: 35, name: "Coupe" },
      { index: 36, name: "Croma" },
      { index: 37, name: "Dino" },
      { index: 38, name: "Doblo" },
      { index: 39, name: "E-Doblo" },
      { index: 40, name: "Ducato" },
      { index: 41, name: "EGEA" },
      { index: 42, name: "Fiorino" },
      { index: 43, name: "Freemont" },
      { index: 44, name: "Fullback" },
      { index: 45, name: "Grande Punto" },
      { index: 46, name: "Grande Punto Abarth" },
      { index: 47, name: "Idea" },
      { index: 48, name: "Linea" },
      { index: 49, name: "Marea" },
      { index: 50, name: "Marengo" },
      { index: 51, name: "Multipla" },
      { index: 52, name: "New Panda" },
      { index: 53, name: "Palio" },
      { index: 54, name: "Panda" },
      { index: 55, name: "Punto" },
      { index: 56, name: "Punto Evo" },
      { index: 57, name: "Punto Evo Abarth" },
      { index: 58, name: "Qubo" },
      { index: 59, name: "Regata" },
      { index: 60, name: "Ritmo" },
      { index: 61, name: "Scudo" },
      { index: 62, name: "Sedici" },
      { index: 63, name: "Seicento" },
      { index: 64, name: "Siena" },
      { index: 65, name: "Spider Europa" },
      { index: 66, name: "Stilo" },
      { index: 67, name: "Strada" },
      { index: 68, name: "Talento" },
      { index: 69, name: "Telento" },
      { index: 70, name: "Tempra" },
      { index: 71, name: "Tipo" },
      { index: 72, name: "Topolino" },
      { index: 73, name: "Ulysse" },
      { index: 74, name: "E-Ulysse" },
      { index: 75, name: "Uno" },
      { index: 76, name: "X 1/9" },
      { index: 77, name: "Sonstige" },
    ],
  },
  {
    index: 7,
    name: "Ford",
    subcategories: [
      { index: 1, name: "Aerostar" },
      { index: 2, name: "B-Max" },
      { index: 3, name: "Bronco" },
      { index: 4, name: "Capri" },
      { index: 5, name: "C-Max" },
      { index: 6, name: "Cougar" },
      { index: 7, name: "Courier" },
      { index: 8, name: "Crown" },
      { index: 9, name: "Econoline" },
      { index: 10, name: "Econovan" },
      { index: 11, name: "EcoSport" },
      { index: 12, name: "Edge" },
      { index: 13, name: "Escape" },
      { index: 14, name: "Escort" },
      { index: 15, name: "Excursion" },
      { index: 16, name: "Expedition" },
      { index: 17, name: "Explorer" },
      { index: 18, name: "Explorer-E" },
      { index: 19, name: "Express" },
      { index: 20, name: "F 100" },
      { index: 21, name: "F 150" },
      { index: 22, name: "F 250" },
      { index: 23, name: "F 350" },
      { index: 24, name: "Fairlane" },
      { index: 25, name: "Falcon" },
      { index: 26, name: "Fiesta" },
      { index: 27, name: "Flex" },
      { index: 28, name: "Focus" },
      { index: 29, name: "Fusion" },
      { index: 30, name: "Galaxy" },
      { index: 31, name: "Granada" },
      { index: 32, name: "Grand C-Max" },
      { index: 33, name: "Grand Tourneo" },
      { index: 34, name: "GT" },
      { index: 35, name: "Ka / Ka+" },
      { index: 36, name: "Kuga" },
      { index: 37, name: "Maverick" },
      { index: 38, name: "Mercury" },
      { index: 39, name: "Mondeo" },
      { index: 40, name: "Mustang" },
      { index: 41, name: "Mustang Mach-E" },
      { index: 42, name: "Orion" },
      { index: 43, name: "Probe" },
      { index: 44, name: "Puma" },
      { index: 45, name: "Ranger" },
      { index: 46, name: "Scorpio" },
      { index: 47, name: "Sierra" },
      { index: 48, name: "S-Max" },
      { index: 49, name: "Sportka" },
      { index: 50, name: "Streetka" },
      { index: 51, name: "Taunus" },
      { index: 52, name: "Taurus" },
      { index: 53, name: "Thunderbird" },
      { index: 54, name: "Tourneo" },
      { index: 55, name: "Tourneo Connect" },
      { index: 56, name: "Transit" },
      { index: 57, name: "Transit (Svi)" },
      { index: 58, name: "Transit Connect" },
      { index: 59, name: "Windstar" },
      { index: 60, name: "Sonstige" },
    ],
  },
  {
    index: 8,
    name: "Hyundai",
    subcategories: [
      { index: 1, name: "Accent" },
      { index: 2, name: "Atos" },
      { index: 3, name: "Azera" },
      { index: 4, name: "Bayon" },
      { index: 5, name: "Coupe" },
      { index: 6, name: "Elantra" },
      { index: 7, name: "Excel" },
      { index: 8, name: "Galloper" },
      { index: 9, name: "Genesis" },
      { index: 10, name: "Getz" },
      { index: 11, name: "Grand Santa Fe" },
      { index: 12, name: "Grandeur" },
      { index: 13, name: "GSvioper" },
      { index: 14, name: "H 100" },
      { index: 15, name: "H 200" },
      { index: 16, name: "H 350" },
      { index: 17, name: "H-1" },
      { index: 18, name: "H-1 Starex" },
      { index: 19, name: "i10" },
      { index: 20, name: "i20" },
      { index: 21, name: "i30" },
      { index: 22, name: "i40" },
      { index: 23, name: "i50" },
      { index: 24, name: "IONIQ" },
      { index: 25, name: "IONIQ 5" },
      { index: 26, name: "IONIQ 6" },
      { index: 27, name: "ix20" },
      { index: 28, name: "ix35" },
      { index: 29, name: "ix55" },
      { index: 30, name: "Kona" },
      { index: 31, name: "Lantra" },
      { index: 32, name: "Matrix" },
      { index: 33, name: "i20 N" },
      { index: 34, name: "i30 N" },
      { index: 35, name: "i30 Fastback N" },
      { index: 36, name: "IONIQ 5 N" },
      { index: 37, name: "Nexo" },
      { index: 38, name: "Pony" },
      { index: 39, name: "Santa Fe" },
      { index: 40, name: "Santamo" },
      { index: 41, name: "S-Coupe" },
      { index: 42, name: "Sonata" },
      { index: 43, name: "Staria" },
      { index: 44, name: "Terracan" },
      { index: 45, name: "Trajet" },
      { index: 46, name: "Tucson" },
      { index: 47, name: "Veloster" },
      { index: 48, name: "Veracruz" },
      { index: 49, name: "XG 30" },
      { index: 50, name: "XG 350" },
      { index: 51, name: "Sonstige" },
    ],
  },
  {
    index: 9,
    name: "Jeep",
    subcategories: [
      { index: 1, name: "Avenger" },
      { index: 2, name: "Cherokee" },
      { index: 3, name: "CJ" },
      { index: 4, name: "Comanche" },
      { index: 5, name: "Commander" },
      { index: 6, name: "Compass" },
      { index: 7, name: "Gladiator" },
      { index: 8, name: "Grand Cherokee" },
      { index: 9, name: "Liberty" },
      { index: 10, name: "Patriot" },
      { index: 11, name: "Renegade" },
      { index: 12, name: "Renegade Sport" },
      { index: 13, name: "Wagoneer" },
      { index: 14, name: "Willys" },
      { index: 15, name: "Wrangler" },
      { index: 16, name: "Sonstige" },
    ],
  },
  {
    index: 10,
    name: "Kia",
    subcategories: [
      { index: 1, name: "Besta" },
      { index: 2, name: "Borrego" },
      { index: 3, name: "Carens" },
      { index: 4, name: "Carnival" },
      { index: 5, name: "cee'd / Ceed" },
      { index: 6, name: "cee'd Sportswagon" },
      { index: 7, name: "Cerato" },
      { index: 8, name: "Clarus" },
      { index: 9, name: "Elan" },
      { index: 10, name: "EV6 / EV6 GT" },
      { index: 11, name: "EV9" },
      { index: 12, name: "Joice" },
      { index: 13, name: "K2500" },
      { index: 14, name: "K2700" },
      { index: 15, name: "Leo" },
      { index: 16, name: "Magentis" },
      { index: 17, name: "Mentor" },
      { index: 18, name: "Mini" },
      { index: 19, name: "Niro" },
      { index: 20, name: "Opirus" },
      { index: 21, name: "Optima" },
      { index: 22, name: "Picanto" },
      { index: 23, name: "Pregio" },
      { index: 24, name: "Pride" },
      { index: 25, name: "pro cee'd / ProCeed" },
      { index: 26, name: "Retona" },
      { index: 27, name: "Rio" },
      { index: 28, name: "Roadster" },
      { index: 29, name: "Rocsta" },
      { index: 30, name: "Sephia" },
      { index: 31, name: "Shuma" },
      { index: 32, name: "Sorento" },
      { index: 33, name: "Soul" },
      { index: 34, name: "Sportage" },
      { index: 35, name: "Stinger" },
      { index: 36, name: "Stonic" },
      { index: 37, name: "Venga" },
      { index: 38, name: "XCeed" },
      { index: 39, name: "Sonstige" },
    ],
  },
  {
    index: 11,
    name: "Mazda",
    subcategories: [
      { index: 1, name: "2" },
      { index: 2, name: "3" },
      { index: 3, name: "3 FAST­BACK" },
      { index: 4, name: "5" },
      { index: 5, name: "6" },
      { index: 6, name: "121" },
      { index: 7, name: "323" },
      { index: 8, name: "626" },
      { index: 9, name: "929" },
      { index: 10, name: "B series" },
      { index: 11, name: "Bongo" },
      { index: 12, name: "BT-50" },
      { index: 13, name: "CX-3" },
      { index: 14, name: "CX-30" },
      { index: 15, name: "CX-5" },
      { index: 16, name: "CX-60" },
      { index: 17, name: "CX-7" },
      { index: 18, name: "CX-9" },
      { index: 19, name: "Demio" },
      { index: 20, name: "E series" },
      { index: 21, name: "Millenia" },
      { index: 22, name: "MPV" },
      { index: 23, name: "MX-3" },
      { index: 24, name: "MX-30" },
      { index: 25, name: "MX-5" },
      { index: 26, name: "MX‑5 RF" },
      { index: 27, name: "MX-6" },
      { index: 28, name: "Premacy" },
      { index: 29, name: "Protege" },
      { index: 30, name: "RX-6" },
      { index: 31, name: "RX-7" },
      { index: 32, name: "RX-8" },
      { index: 33, name: "Tribute" },
      { index: 34, name: "Xedos" },
      { index: 35, name: "Sonstige" },
    ],
  },
  {
    index: 12,
    name: "Mercedes-Benz",
    subcategories: [
      { index: 1, name: "A 140" },
      { index: 2, name: "A 150" },
      { index: 3, name: "A 160" },
      { index: 4, name: "A 170" },
      { index: 5, name: "A 180" },
      { index: 6, name: "A 190" },
      { index: 7, name: "A 200" },
      { index: 8, name: "A 210" },
      { index: 9, name: "A 220" },
      { index: 10, name: "A 250" },
      { index: 11, name: "A 35 AMG" },
      { index: 12, name: "A 45 AMG" },
      { index: 13, name: "B 150" },
      { index: 14, name: "B 160" },
      { index: 15, name: "B 170" },
      { index: 16, name: "B 180" },
      { index: 17, name: "B 200" },
      { index: 18, name: "B 220" },
      { index: 19, name: "B 250" },
      { index: 20, name: "B Electric Drive" },
      { index: 21, name: "CE 200" },
      { index: 22, name: "CE 220" },
      { index: 23, name: "CE 230" },
      { index: 24, name: "CE 280" },
      { index: 25, name: "CE 300" },
      { index: 26, name: "CE 320" },
      { index: 27, name: "Citan" },
      { index: 28, name: "C 160" },
      { index: 29, name: "C 180" },
      { index: 30, name: "C 200" },
      { index: 31, name: "C 220" },
      { index: 32, name: "C 230" },
      { index: 33, name: "C 240" },
      { index: 34, name: "C 250" },
      { index: 35, name: "C 270" },
      { index: 36, name: "C 280" },
      { index: 37, name: "C 30 AMG" },
      { index: 38, name: "C 300" },
      { index: 39, name: "C 32 AMG" },
      { index: 40, name: "C 320" },
      { index: 41, name: "C 350" },
      { index: 42, name: "C 36 AMG" },
      { index: 43, name: "C 400" },
      { index: 44, name: "C 43 AMG" },
      { index: 45, name: "C 450 AMG" },
      { index: 46, name: "C 55 AMG" },
      { index: 47, name: "C 63 AMG" },
      { index: 48, name: "CLA 180" },
      { index: 49, name: "CLA 180 Shooting Brake" },
      { index: 50, name: "CLA 200" },
      { index: 51, name: "CLA 200 Shooting Brake" },
      { index: 52, name: "CLA 220" },
      { index: 53, name: "CLA 220 Shooting Brake" },
      { index: 54, name: "CLA 250" },
      { index: 55, name: "CLA 250 Shooting Brake" },
      { index: 56, name: "CLA 35 AMG" },
      { index: 57, name: "CLA 35 AMG Shooting Brake" },
      { index: 58, name: "CLA 45 AMG" },
      { index: 59, name: "CLA 45 AMG Shooting Brake" },
      { index: 60, name: "CLA Shooting Brake" },
      { index: 61, name: "CLC 160" },
      { index: 62, name: "CLC 180" },
      { index: 63, name: "CLC 200" },
      { index: 64, name: "CLC 220" },
      { index: 65, name: "CLC 230" },
      { index: 66, name: "CLC 250" },
      { index: 67, name: "CLC 350" },
      { index: 68, name: "CLE 200" },
      { index: 69, name: "CLE 220" },
      { index: 70, name: "CLE 300" },
      { index: 71, name: "CLE 450" },
      { index: 72, name: "CLE 53 AMG" },
      { index: 73, name: "CLK 200" },
      { index: 74, name: "CLK 220" },
      { index: 75, name: "CLK 230" },
      { index: 76, name: "CLK 240" },
      { index: 77, name: "CLK 270" },
      { index: 78, name: "CLK 280" },
      { index: 79, name: "CLK 320" },
      { index: 80, name: "CLK 350" },
      { index: 81, name: "CLK 430" },
      { index: 82, name: "CLK 500" },
      { index: 83, name: "CLK 55 AMG" },
      { index: 84, name: "CLK 63 AMG" },
      { index: 85, name: "CL 160" },
      { index: 86, name: "CL 180" },
      { index: 87, name: "CL 200" },
      { index: 88, name: "CL 220" },
      { index: 89, name: "CL 230" },
      { index: 90, name: "CL 320" },
      { index: 91, name: "CL 420" },
      { index: 92, name: "CL 500" },
      { index: 93, name: "CL 55 AMG" },
      { index: 94, name: "CL 600" },
      { index: 95, name: "CL 63 AMG" },
      { index: 96, name: "CL 65 AMG" },
      { index: 97, name: "CLS 220" },
      { index: 98, name: "CLS 220 Shooting Brake" },
      { index: 99, name: "CLS 250" },
      { index: 100, name: "CLS 250 Shooting Brake" },
      { index: 101, name: "CLS 280" },
      { index: 102, name: "CLS 300" },
      { index: 103, name: "CLS 320" },
      { index: 104, name: "CLS 350" },
      { index: 105, name: "CLS 350 Shooting Brake" },
      { index: 106, name: "CLS 400" },
      { index: 107, name: "CLS 400 Shooting Brake" },
      { index: 108, name: "CLS 450" },
      { index: 109, name: "CLS 500" },
      { index: 110, name: "CLS 500 Shooting Brake" },
      { index: 111, name: "CLS 53 AMG" },
      { index: 112, name: "CLS 55 AMG" },
      { index: 113, name: "CLS 63 AMG" },
      { index: 114, name: "CLS 63 AMG Shooting Brake" },
      { index: 115, name: "CLS Shooting Brake" },
      { index: 116, name: "E 200" },
      { index: 117, name: "E 220" },
      { index: 118, name: "E 230" },
      { index: 119, name: "E 240" },
      { index: 120, name: "E 250" },
      { index: 121, name: "E 260" },
      { index: 122, name: "E 270" },
      { index: 123, name: "E 280" },
      { index: 124, name: "E 290" },
      { index: 125, name: "E 300" },
      { index: 126, name: "E 320" },
      { index: 127, name: "E 350" },
      { index: 128, name: "E 36 AMG" },
      { index: 129, name: "E 400" },
      { index: 130, name: "E 420" },
      { index: 131, name: "E 43 AMG" },
      { index: 132, name: "E 430" },
      { index: 133, name: "E 50" },
      { index: 134, name: "E 500" },
      { index: 135, name: "E 53 AMG" },
      { index: 136, name: "E 55 AMG" },
      { index: 137, name: "E 60 AMG" },
      { index: 138, name: "E 63 AMG" },
      { index: 139, name: "EQA" },
      { index: 140, name: "EQB" },
      { index: 141, name: "EQC" },
      { index: 142, name: "EQE" },
      { index: 143, name: "EQS" },
      { index: 144, name: "EQT" },
      { index: 145, name: "EQV" },
      { index: 146, name: "G 230" },
      { index: 147, name: "G 240" },
      { index: 148, name: "G 250" },
      { index: 149, name: "G 270" },
      { index: 150, name: "G 280" },
      { index: 151, name: "G 290" },
      { index: 152, name: "G 300" },
      { index: 153, name: "G 320" },
      { index: 154, name: "G 350" },
      { index: 155, name: "G 400" },
      { index: 156, name: "G 500" },
      { index: 157, name: "G 55 AMG" },
      { index: 158, name: "G 63 AMG" },
      { index: 159, name: "G 65 AMG" },
      { index: 160, name: "GLA 180" },
      { index: 161, name: "GLA 200" },
      { index: 162, name: "GLA 220" },
      { index: 163, name: "GLA 250" },
      { index: 164, name: "GLA 35 AMG" },
      { index: 165, name: "GLA 45 AMG" },
      { index: 166, name: "GLB 180" },
      { index: 167, name: "GLB 200" },
      { index: 168, name: "GLB 220" },
      { index: 169, name: "GLB 250" },
      { index: 170, name: "GLB 35 AMG" },
      { index: 171, name: "GLC 200" },
      { index: 172, name: "GLC 220" },
      { index: 173, name: "GLC 250" },
      { index: 174, name: "GLC 300" },
      { index: 175, name: "GLC 350" },
      { index: 176, name: "GLC 350 Coupe" },
      { index: 177, name: "GLC 400" },
      { index: 178, name: "GLC 43 AMG" },
      { index: 179, name: "GLC 63 AMG" },
      { index: 180, name: "GLC AMG" },
      { index: 181, name: "GLE 250" },
      { index: 182, name: "GLE 300" },
      { index: 183, name: "GLE 350" },
      { index: 184, name: "GLE 350 coupe" },
      { index: 185, name: "GLE 400" },
      { index: 186, name: "GLE 400 Coupe" },
      { index: 187, name: "GLE 43 AMG" },
      { index: 188, name: "GLE 450" },
      { index: 189, name: "GLE 450 Coupe" },
      { index: 190, name: "GLE 500" },
      { index: 191, name: "GLE 53 AMG" },
      { index: 192, name: "GLE 580" },
      { index: 193, name: "GLE 63 AMG" },
      { index: 194, name: "GLK 200" },
      { index: 195, name: "GLK 220" },
      { index: 196, name: "GLK 250" },
      { index: 197, name: "GLK 280" },
      { index: 198, name: "GLK 300" },
      { index: 199, name: "GLK 320" },
      { index: 200, name: "GLK 350" },
      { index: 201, name: "GL 320" },
      { index: 202, name: "GL 350" },
      { index: 203, name: "GL 400" },
      { index: 204, name: "GL 420" },
      { index: 205, name: "GL 450" },
      { index: 206, name: "GL 500" },
      { index: 207, name: "GL 55 AMG" },
      { index: 208, name: "GL 63 AMG" },
      { index: 209, name: "GLS" },
      { index: 210, name: "GLS 350" },
      { index: 211, name: "GLS 400" },
      { index: 212, name: "GLS 450" },
      { index: 213, name: "GLS 500" },
      { index: 214, name: "GLS 580" },
      { index: 215, name: "GLS 600" },
      { index: 216, name: "GLS 63" },
      { index: 217, name: "AMG GT" },
      { index: 218, name: "AMG GT C" },
      { index: 219, name: "AMG GT R" },
      { index: 220, name: "AMG GT S" },
      { index: 221, name: "MB 100" },
      { index: 222, name: "ML 230" },
      { index: 223, name: "ML 250" },
      { index: 224, name: "ML 270" },
      { index: 225, name: "ML 280" },
      { index: 226, name: "ML 300" },
      { index: 227, name: "ML 320" },
      { index: 228, name: "ML 350" },
      { index: 229, name: "ML 400" },
      { index: 230, name: "ML 420" },
      { index: 231, name: "ML 430" },
      { index: 232, name: "ML 450" },
      { index: 233, name: "ML 500" },
      { index: 234, name: "ML 55 AMG" },
      { index: 235, name: "ML 63 AMG" },
      { index: 236, name: "R 280" },
      { index: 237, name: "R 300" },
      { index: 238, name: "R 320" },
      { index: 239, name: "R 350" },
      { index: 240, name: "R 500" },
      { index: 241, name: "R 63 AMG" },
      { index: 242, name: "S 250" },
      { index: 243, name: "S 260" },
      { index: 244, name: "S 280" },
      { index: 245, name: "S 300" },
      { index: 246, name: "S 320" },
      { index: 247, name: "S 350" },
      { index: 248, name: "S 400" },
      { index: 249, name: "S 420" },
      { index: 250, name: "S 430" },
      { index: 251, name: "S 450" },
      { index: 252, name: "S 500" },
      { index: 253, name: "S 55" },
      { index: 254, name: "S 550" },
      { index: 255, name: "S 560" },
      { index: 256, name: "S 580" },
      { index: 257, name: "S 600" },
      { index: 258, name: "S 63 AMG" },
      { index: 259, name: "S 65 AMG" },
      { index: 260, name: "S 650" },
      { index: 261, name: "S 680" },
      { index: 262, name: "SLC 180" },
      { index: 263, name: "SLC 200" },
      { index: 264, name: "SLC 250" },
      { index: 265, name: "SLC 280" },
      { index: 266, name: "SLC 300" },
      { index: 267, name: "SLC 43 AMG" },
      { index: 268, name: "SLC 450" },
      { index: 269, name: "SLK 200" },
      { index: 270, name: "SLK 230" },
      { index: 271, name: "SLK 250" },
      { index: 272, name: "SLK 280" },
      { index: 273, name: "SLK 300" },
      { index: 274, name: "SLK 32 AMG" },
      { index: 275, name: "SLK 320" },
      { index: 276, name: "SLK 350" },
      { index: 277, name: "SLK 55 AMG" },
      { index: 278, name: "SL 280" },
      { index: 279, name: "SL 300" },
      { index: 280, name: "SL 320" },
      { index: 281, name: "SL 350" },
      { index: 282, name: "SL 380" },
      { index: 283, name: "SL 400" },
      { index: 284, name: "SL 420" },
      { index: 285, name: "SL 43 AMG" },
      { index: 286, name: "SL 450" },
      { index: 287, name: "SL 500" },
      { index: 288, name: "SL 55 AMG" },
      { index: 289, name: "SL 560" },
      { index: 290, name: "SL 60 AMG" },
      { index: 291, name: "SL 600" },
      { index: 292, name: "SL 63 AMG" },
      { index: 293, name: "SL 63 AMG S E Performance" },
      { index: 294, name: "SL 65 AMG" },
      { index: 295, name: "SL 70 AMG" },
      { index: 296, name: "SL 73 AMG" },
      { index: 297, name: "SLR" },
      { index: 298, name: "SLS AMG" },
      { index: 299, name: "Sprinter" },
      { index: 300, name: "T-Klasse" },
      { index: 301, name: "V 200" },
      { index: 302, name: "V 220" },
      { index: 303, name: "V 230" },
      { index: 304, name: "V 250" },
      { index: 305, name: "V 280" },
      { index: 306, name: "V 300" },
      { index: 307, name: "Vaneo" },
      { index: 308, name: "Vario" },
      { index: 309, name: "Viano" },
      { index: 310, name: "Vito" },
      { index: 311, name: "X 200" },
      { index: 312, name: "X 220" },
      { index: 313, name: "X 250" },
      { index: 314, name: "X 350" },
      { index: 315, name: "190" },
      { index: 316, name: "200" },
      { index: 317, name: "220" },
      { index: 318, name: "230" },
      { index: 319, name: "240" },
      { index: 320, name: "250" },
      { index: 321, name: "260" },
      { index: 322, name: "270" },
      { index: 323, name: "280" },
      { index: 324, name: "290" },
      { index: 325, name: "300" },
      { index: 326, name: "320" },
      { index: 327, name: "350" },
      { index: 328, name: "380" },
      { index: 329, name: "400" },
      { index: 330, name: "416" },
      { index: 331, name: "420" },
      { index: 332, name: "450" },
      { index: 333, name: "500" },
      { index: 334, name: "560" },
      { index: 335, name: "600" },
      { index: 336, name: "Sonstige" },
    ],
  },
  {
    index: 13,
    name: "Mitsubishi",
    subcategories: [
      { index: 1, name: "3000 GT" },
      { index: 2, name: "ASX" },
      { index: 3, name: "Canter" },
      { index: 4, name: "Carisma" },
      { index: 5, name: "Colt" },
      { index: 6, name: "Cordia" },
      { index: 7, name: "Cosmos" },
      { index: 8, name: "Diamante" },
      { index: 9, name: "Eclipse" },
      { index: 10, name: "Eclipse Cross" },
      { index: 11, name: "Endeavor" },
      { index: 12, name: "Galant" },
      { index: 13, name: "Galloper" },
      { index: 14, name: "Grandis" },
      { index: 15, name: "i-MiEV" },
      { index: 16, name: "L200" },
      { index: 17, name: "L300" },
      { index: 18, name: "L400" },
      { index: 19, name: "Lancer" },
      { index: 20, name: "Mirage" },
      { index: 21, name: "Montero" },
      { index: 22, name: "Outlander" },
      { index: 23, name: "Pajero" },
      { index: 24, name: "Pajero Pinin" },
      { index: 25, name: "Pick-up" },
      { index: 26, name: "Plug-in Hybrid Outlander" },
      { index: 27, name: "Santamo" },
      { index: 28, name: "Sapporo" },
      { index: 29, name: "Shogun" },
      { index: 30, name: "Sigma" },
      { index: 31, name: "Space Gear" },
      { index: 32, name: "Space Runner" },
      { index: 33, name: "Space Star" },
      { index: 34, name: "Space Wagon" },
      { index: 35, name: "Starion" },
      { index: 36, name: "Tredia" },
      { index: 37, name: "Sonstige" },
    ],
  },
  {
    index: 14,
    name: "Nissan",
    subcategories: [
      { index: 1, name: "100 NX" },
      { index: 2, name: "200 SX" },
      { index: 3, name: "240 SX" },
      { index: 4, name: "280 ZX" },
      { index: 5, name: "300 ZX" },
      { index: 6, name: "350Z" },
      { index: 7, name: "370Z" },
      { index: 8, name: "Almera" },
      { index: 9, name: "Almera Tino" },
      { index: 10, name: "Altima" },
      { index: 11, name: "Ariya" },
      { index: 12, name: "Armada" },
      { index: 13, name: "Bluebird" },
      { index: 14, name: "Cabstar" },
      { index: 15, name: "Cargo" },
      { index: 16, name: "Cherry" },
      { index: 17, name: "Cube" },
      { index: 18, name: "e-NV200" },
      { index: 19, name: "Evalia" },
      { index: 20, name: "Frontier" },
      { index: 21, name: "GT-R" },
      { index: 22, name: "Interstar" },
      { index: 23, name: "Juke" },
      { index: 24, name: "King Cab" },
      { index: 25, name: "Kubistar" },
      { index: 26, name: "Laurel" },
      { index: 27, name: "Leaf" },
      { index: 28, name: "Maxima" },
      { index: 29, name: "Micra" },
      { index: 30, name: "Murano" },
      { index: 31, name: "Navara" },
      { index: 32, name: "Note" },
      { index: 33, name: "NP 300" },
      { index: 34, name: "NP 300" },
      { index: 35, name: "NV200" },
      { index: 36, name: "NV250" },
      { index: 37, name: "NV300" },
      { index: 38, name: "NV400" },
      { index: 39, name: "Pathfinder" },
      { index: 40, name: "Patrol" },
      { index: 41, name: "PickUp" },
      { index: 42, name: "Pixo" },
      { index: 43, name: "Prairie" },
      { index: 44, name: "Primastar" },
      { index: 45, name: "Primera" },
      { index: 46, name: "Pulsar" },
      { index: 47, name: "Rogue" },
      { index: 48, name: "Qashqai" },
      { index: 49, name: "Qashqai+2" },
      { index: 50, name: "Quest" },
      { index: 51, name: "Sentra" },
      { index: 52, name: "Serena" },
      { index: 53, name: "Silvia" },
      { index: 54, name: "Skyline" },
      { index: 55, name: "Sunny" },
      { index: 56, name: "Terrano" },
      { index: 57, name: "Tiida" },
      { index: 58, name: "Titan" },
      { index: 59, name: "Townstar" },
      { index: 60, name: "Trade" },
      { index: 61, name: "Urvan" },
      { index: 62, name: "Vanette" },
      { index: 63, name: "Xterra" },
      { index: 64, name: "X-Trail" },
      { index: 65, name: "Sonstige" },
    ],
  },
  {
    index: 15,
    name: "Opel",
    subcategories: [
      { index: 1, name: "Adam" },
      { index: 2, name: "Agila" },
      { index: 3, name: "Ampera" },
      { index: 4, name: "Ampera-e" },
      { index: 5, name: "Antara" },
      { index: 6, name: "Arena" },
      { index: 7, name: "Ascona" },
      { index: 8, name: "Astra" },
      { index: 9, name: "Astra Classic" },
      { index: 10, name: "Calibra" },
      { index: 11, name: "Campo" },
      { index: 12, name: "Cascada" },
      { index: 13, name: "Cavalier" },
      { index: 14, name: "Combo" },
      { index: 15, name: "Combo Life" },
      { index: 16, name: "Commodore" },
      { index: 17, name: "Corsa" },
      { index: 18, name: "Crossland X" },
      { index: 19, name: "Diplomat" },
      { index: 20, name: "Frontera" },
      { index: 21, name: "Grandland X" },
      { index: 22, name: "GT" },
      { index: 23, name: "Insignia" },
      { index: 24, name: "Insignia CT" },
      { index: 25, name: "Kadett" },
      { index: 26, name: "Karl" },
      { index: 27, name: "Manta" },
      { index: 28, name: "Meriva" },
      { index: 29, name: "Mokka" },
      { index: 30, name: "Mokka X" },
      { index: 31, name: "Monterey" },
      { index: 32, name: "Monza" },
      { index: 33, name: "Movano" },
      { index: 34, name: "Nova" },
      { index: 35, name: "Omega" },
      { index: 36, name: "Pick Up Sportscap" },
      { index: 37, name: "Rekord" },
      { index: 38, name: "Rocks-e" },
      { index: 39, name: "Senator" },
      { index: 40, name: "Signum" },
      { index: 41, name: "Sintra" },
      { index: 42, name: "Speedster" },
      { index: 43, name: "Tigra" },
      { index: 44, name: "Vectra" },
      { index: 45, name: "Vivaro" },
      { index: 46, name: "Zafira" },
      { index: 47, name: "Zafira Life" },
      { index: 48, name: "Zafira Tourer" },
      { index: 49, name: "Sonstige" },
    ],
  },
  {
    index: 16,
    name: "Peugeot",
    subcategories: [
      { index: 1, name: "104" },
      { index: 2, name: "106" },
      { index: 3, name: "107" },
      { index: 4, name: "108" },
      { index: 5, name: "203" },
      { index: 6, name: "204" },
      { index: 7, name: "205" },
      { index: 8, name: "206" },
      { index: 9, name: "207" },
      { index: 10, name: "208" },
      { index: 11, name: "301" },
      { index: 12, name: "304" },
      { index: 13, name: "305" },
      { index: 14, name: "306" },
      { index: 15, name: "307" },
      { index: 16, name: "308" },
      { index: 17, name: "309" },
      { index: 18, name: "404" },
      { index: 19, name: "405" },
      { index: 20, name: "406" },
      { index: 21, name: "407" },
      { index: 22, name: "408" },
      { index: 23, name: "504" },
      { index: 24, name: "505" },
      { index: 25, name: "508" },
      { index: 26, name: "604" },
      { index: 27, name: "605" },
      { index: 28, name: "607" },
      { index: 29, name: "806" },
      { index: 30, name: "807" },
      { index: 31, name: "1007" },
      { index: 32, name: "2008" },
      { index: 33, name: "3008" },
      { index: 34, name: "4007" },
      { index: 35, name: "4008" },
      { index: 36, name: "5008" },
      { index: 37, name: "Bipper" },
      { index: 38, name: "Bipper Tepee" },
      { index: 39, name: "Boxer" },
      { index: 40, name: "e-Expert Combi" },
      { index: 41, name: "e-Rifter" },
      { index: 42, name: "e-Traveller" },
      { index: 43, name: "e-208" },
      { index: 44, name: "e-2008" },
      { index: 45, name: "e-5008" },
      { index: 46, name: "Expert" },
      { index: 47, name: "Expert Tepee" },
      { index: 48, name: "iOn" },
      { index: 49, name: "J5" },
      { index: 50, name: "Partner" },
      { index: 51, name: "Partner Tepee" },
      { index: 52, name: "RCZ" },
      { index: 53, name: "Rifter" },
      { index: 54, name: "TePee" },
      { index: 55, name: "Traveller" },
      { index: 56, name: "Sonstige" },
    ],
  },
  {
    index: 17,
    name: "Renault",
    subcategories: [
      { index: 1, name: "Alaskan" },
      { index: 2, name: "Alpine A110" },
      { index: 3, name: "Alpine A310" },
      { index: 4, name: "Alpine V6" },
      { index: 5, name: "Arkana" },
      { index: 6, name: "Austral E-Tech" },
      { index: 7, name: "Avantime" },
      { index: 8, name: "Captur" },
      { index: 9, name: "Clio" },
      { index: 10, name: "Clio E-Tech" },
      { index: 11, name: "Coupe" },
      { index: 12, name: "Espace" },
      { index: 13, name: "Espace E-Tech" },
      { index: 14, name: "Express" },
      { index: 15, name: "Express Van" },
      { index: 16, name: "Fluence" },
      { index: 17, name: "Fuego" },
      { index: 18, name: "Grand Espace" },
      { index: 19, name: "Grand Modus" },
      { index: 20, name: "Grand Scenic" },
      { index: 21, name: "Kadjar" },
      { index: 22, name: "Kangoo" },
      { index: 23, name: "Koleos" },
      { index: 24, name: "Laguna" },
      { index: 25, name: "Latitude" },
      { index: 26, name: "Mascott" },
      { index: 27, name: "Master" },
      { index: 28, name: "Megane" },
      { index: 29, name: "Megane Conquest" },
      { index: 30, name: "Megane GrandCoupe" },
      { index: 31, name: "Megane Grandtour" },
      { index: 32, name: "Modus" },
      { index: 33, name: "P 1400" },
      { index: 34, name: "R 11" },
      { index: 35, name: "R 14" },
      { index: 36, name: "R 18" },
      { index: 37, name: "R 19" },
      { index: 38, name: "R 20" },
      { index: 39, name: "R 21" },
      { index: 40, name: "R 25" },
      { index: 41, name: "R 30" },
      { index: 42, name: "R 4" },
      { index: 43, name: "R 5" },
      { index: 44, name: "R 6" },
      { index: 45, name: "R 9" },
      { index: 46, name: "Rafale E-Tech" },
      { index: 47, name: "Rapid" },
      { index: 48, name: "Safrane" },
      { index: 49, name: "Scenic" },
      { index: 50, name: "Spider" },
      { index: 51, name: "Talisman" },
      { index: 52, name: "Thalia" },
      { index: 53, name: "Trafic" },
      { index: 54, name: "Twingo" },
      { index: 55, name: "Twizy" },
      { index: 56, name: "Vel Satis" },
      { index: 57, name: "Wind" },
      { index: 58, name: "ZOE" },
      { index: 59, name: "Sonstige" },
    ],
  },
  {
    index: 18,
    name: "Seat",
    subcategories: [
      { index: 1, name: "Alhambra" },
      { index: 2, name: "Altea" },
      { index: 3, name: "Altea XL" },
      { index: 4, name: "Arona" },
      { index: 5, name: "Arosa" },
      { index: 6, name: "Ateca" },
      { index: 7, name: "Cordoba" },
      { index: 8, name: "Exeo" },
      { index: 9, name: "Ibiza" },
      { index: 10, name: "Inca" },
      { index: 11, name: "Leon" },
      { index: 12, name: "Malaga" },
      { index: 13, name: "Marbella" },
      { index: 14, name: "Mii" },
      { index: 15, name: "Tarraco" },
      { index: 16, name: "Tarraco e-HYBRID" },
      { index: 17, name: "Terra" },
      { index: 18, name: "Toledo" },
      { index: 19, name: "Sonstige" },
    ],
  },
  {
    index: 19,
    name: "Škoda",
    subcategories: [
      { index: 1, name: "105" },
      { index: 2, name: "120" },
      { index: 3, name: "130" },
      { index: 4, name: "135" },
      { index: 5, name: "Citigo" },
      { index: 6, name: "Enyaq" },
      { index: 7, name: "Enyaq Coupé" },
      { index: 8, name: "Fabia" },
      { index: 9, name: "Fabia RS" },
      { index: 10, name: "Favorit" },
      { index: 11, name: "Felicia" },
      { index: 12, name: "Forman" },
      { index: 13, name: "Kamiq" },
      { index: 14, name: "Karoq" },
      { index: 15, name: "Kodiaq" },
      { index: 16, name: "Octavia" },
      { index: 17, name: "Octavia RS" },
      { index: 18, name: "Octavia Scout" },
      { index: 19, name: "Pick-up" },
      { index: 20, name: "Praktik" },
      { index: 21, name: "Rapid" },
      { index: 22, name: "Roomster" },
      { index: 23, name: "Scala" },
      { index: 24, name: "Spaceback" },
      { index: 25, name: "Superb" },
      { index: 26, name: "Yeti" },
      { index: 27, name: "Sonstige" },
    ],
  },
  {
    index: 20,
    name: "Suzuki",
    subcategories: [
      { index: 1, name: "Across" },
      { index: 2, name: "Alto" },
      { index: 3, name: "Baleno" },
      { index: 4, name: "Cappuccino" },
      { index: 5, name: "Carry" },
      { index: 6, name: "Celerio" },
      { index: 7, name: "Grand Vitara" },
      { index: 8, name: "Ignis" },
      { index: 9, name: "iK-2" },
      { index: 10, name: "Jimny" },
      { index: 11, name: "Kizashi" },
      { index: 12, name: "Liana" },
      { index: 13, name: "LJ" },
      { index: 14, name: "SJ Samurai" },
      { index: 15, name: "Splash" },
      { index: 16, name: "Super-Carry" },
      { index: 17, name: "Swace" },
      { index: 18, name: "Swift" },
      { index: 19, name: "SX4" },
      { index: 20, name: "SX4 S-Cross" },
      { index: 21, name: "Vitara" },
      { index: 22, name: "Wagon R+" },
      { index: 23, name: "X-90" },
      { index: 24, name: "Sonstige" },
    ],
  },
  {
    index: 21,
    name: "Toyota",
    subcategories: [
      { index: 1, name: "4-Runner" },
      { index: 2, name: "Alphard" },
      { index: 3, name: "Auris" },
      { index: 4, name: "Auris Touring Sports" },
      { index: 5, name: "Avalon" },
      { index: 6, name: "Avensis" },
      { index: 7, name: "Avensis Verso" },
      { index: 8, name: "Aygo" },
      { index: 9, name: "Aygo X" },
      { index: 10, name: "bZ4x Electric" },
      { index: 11, name: "Camry" },
      { index: 12, name: "Carina" },
      { index: 13, name: "Celica" },
      { index: 14, name: "C-HR" },
      { index: 15, name: "Corolla" },
      { index: 16, name: "Corolla Cross" },
      { index: 17, name: "Corolla Verso" },
      { index: 18, name: "Cressida" },
      { index: 19, name: "Crown" },
      { index: 20, name: "Dyna" },
      { index: 21, name: "FCV" },
      { index: 22, name: "FJ" },
      { index: 23, name: "Fortuner" },
      { index: 24, name: "GT86" },
      { index: 25, name: "Hiace" },
      { index: 26, name: "Highlander" },
      { index: 27, name: "Hilux" },
      { index: 28, name: "IQ" },
      { index: 29, name: "Land Cruiser" },
      { index: 30, name: "Lite-Ace" },
      { index: 31, name: "Matrix" },
      { index: 32, name: "Mirai" },
      { index: 33, name: "MR 2" },
      { index: 34, name: "Paseo" },
      { index: 35, name: "Picnic" },
      { index: 36, name: "Previa" },
      { index: 37, name: "Prius" },
      { index: 38, name: "Prius+" },
      { index: 39, name: "Proace" },
      { index: 40, name: "Proace City" },
      { index: 41, name: "Proace City Verso" },
      { index: 42, name: "Proace Verso" },
      { index: 43, name: "RAV 4" },
      { index: 44, name: "Sequoia" },
      { index: 45, name: "Sienna" },
      { index: 46, name: "Starlet" },
      { index: 47, name: "Supra" },
      { index: 48, name: "Tacoma" },
      { index: 49, name: "Tercel" },
      { index: 50, name: "Tundra" },
      { index: 51, name: "Urban Cruiser" },
      { index: 52, name: "Verso" },
      { index: 53, name: "Verso-S" },
      { index: 54, name: "Yaris" },
      { index: 55, name: "Yaris GR" },
      { index: 56, name: "Yaris Cross" },
      { index: 57, name: "Sonstige" },
    ],
  },
  {
    index: 22,
    name: "Volkswagen",
    subcategories: [
      { index: 1, name: "181" },
      { index: 2, name: "Amarok" },
      { index: 3, name: "Arteon" },
      { index: 4, name: "Atlas" },
      { index: 5, name: "Bora" },
      { index: 6, name: "Buba / Beetle" },
      { index: 7, name: "Buggy" },
      { index: 8, name: "Caddy" },
      { index: 9, name: "Corrado" },
      { index: 10, name: "Crafter" },
      { index: 11, name: "Eos" },
      { index: 12, name: "Fox" },
      { index: 13, name: "e-Golf" },
      { index: 14, name: "Golf" },
      { index: 15, name: "Golf Alltrack" },
      { index: 16, name: "Golf GTE" },
      { index: 17, name: "Golf Plus" },
      { index: 18, name: "Golf Sportsvan" },
      { index: 19, name: "ID.3" },
      { index: 20, name: "ID.3 GTX" },
      { index: 21, name: "ID.4" },
      { index: 22, name: "ID.5" },
      { index: 23, name: "ID.6" },
      { index: 24, name: "ID.7" },
      { index: 25, name: "ID.7 GTX Tourer" },
      { index: 26, name: "ID.Buzz" },
      { index: 27, name: "ID.Buzz Cargo" },
      { index: 28, name: "Iltis" },
      { index: 29, name: "Jetta" },
      { index: 30, name: "Karmann Ghia" },
      { index: 31, name: "LT" },
      { index: 32, name: "Lupo" },
      { index: 33, name: "Passat" },
      { index: 34, name: "Passat Alltrack" },
      { index: 35, name: "Passat CC" },
      { index: 36, name: "Phaeton" },
      { index: 37, name: "Polo" },
      { index: 38, name: "Polo Cross" },
      { index: 39, name: "Polo Plus" },
      { index: 40, name: "Routan" },
      { index: 41, name: "Santana" },
      { index: 42, name: "Scirocco" },
      { index: 43, name: "Sharan" },
      { index: 44, name: "T1" },
      { index: 45, name: "T2" },
      { index: 46, name: "T3 Caravelle" },
      { index: 47, name: "T3 Sonstige" },
      { index: 48, name: "T3 Kombi" },
      { index: 49, name: "T3 Multivan" },
      { index: 50, name: "T4 Caravelle" },
      { index: 51, name: "T4 Sonstige" },
      { index: 52, name: "T4 Kombi" },
      { index: 53, name: "T4 Multivan" },
      { index: 54, name: "T5 Caravelle" },
      { index: 55, name: "T5 Sonstige" },
      { index: 56, name: "T5 Kombi" },
      { index: 57, name: "T5 Multivan" },
      { index: 58, name: "T5 Shuttle" },
      { index: 59, name: "T6 Caravelle" },
      { index: 60, name: "T6 Sonstige" },
      { index: 61, name: "T6 Kombi" },
      { index: 62, name: "T6 Multivan" },
      { index: 63, name: "T6 Shuttle" },
      { index: 64, name: "T7 Multivan" },
      { index: 65, name: "Taigo" },
      { index: 66, name: "Taro" },
      { index: 67, name: "T-Cross" },
      { index: 68, name: "Tiguan" },
      { index: 69, name: "Tiguan Allspace" },
      { index: 70, name: "Touareg" },
      { index: 71, name: "Touran" },
      { index: 72, name: "T-Roc" },
      { index: 73, name: "T-Roc Cabriolet" },
      { index: 74, name: "Up!" },
      { index: 75, name: "Vento" },
      { index: 76, name: "XL1" },
      { index: 77, name: "Sonstige" },
    ],
  },
  {
    index: 23,
    name: "Volvo",
    subcategories: [
      { index: 1, name: "240" },
      { index: 2, name: "244" },
      { index: 3, name: "245" },
      { index: 4, name: "262" },
      { index: 5, name: "264" },
      { index: 6, name: "340" },
      { index: 7, name: "360" },
      { index: 8, name: "440" },
      { index: 9, name: "460" },
      { index: 10, name: "480" },
      { index: 11, name: "740" },
      { index: 12, name: "744" },
      { index: 13, name: "745" },
      { index: 14, name: "760" },
      { index: 15, name: "780" },
      { index: 16, name: "850" },
      { index: 17, name: "855" },
      { index: 18, name: "940" },
      { index: 19, name: "944" },
      { index: 20, name: "945" },
      { index: 21, name: "960" },
      { index: 22, name: "965" },
      { index: 23, name: "Amazon" },
      { index: 24, name: "C30" },
      { index: 25, name: "C40" },
      { index: 26, name: "C70" },
      { index: 27, name: "EX30" },
      { index: 28, name: "EX90" },
      { index: 29, name: "Polar" },
      { index: 30, name: "Polestar 3" },
      { index: 31, name: "S40" },
      { index: 32, name: "S60" },
      { index: 33, name: "S60 Cross Country" },
      { index: 34, name: "S70" },
      { index: 35, name: "S80" },
      { index: 36, name: "S90" },
      { index: 37, name: "V40" },
      { index: 38, name: "V40 Cross Country" },
      { index: 39, name: "V50" },
      { index: 40, name: "V60" },
      { index: 41, name: "V60 Cross Country" },
      { index: 42, name: "V70" },
      { index: 43, name: "V90" },
      { index: 44, name: "V90 Cross Country" },
      { index: 45, name: "XC 60" },
      { index: 46, name: "XC 70" },
      { index: 47, name: "XC 90" },
      { index: 48, name: "XC40" },
      { index: 49, name: "Sonstige" },
    ],
  },
  {
    index: 24,
    name: "Acura",
    subcategories: [
      { index: 1, name: "MDX" },
      { index: 2, name: "NSX" },
      { index: 3, name: "RL" },
      { index: 4, name: "RSX" },
      { index: 5, name: "TL" },
      { index: 6, name: "TSX" },
      { index: 7, name: "ZDX" },
      { index: 8, name: "Sonstige" },
    ],
  },
  {
    index: 25,
    name: "Aixam",
    subcategories: [
      { index: 1, name: "City" },
      { index: 2, name: "Crossline" },
      { index: 3, name: "Roadline" },
      { index: 4, name: "Scouty R" },
      { index: 5, name: "Sonstige" },
    ],
  },
  {
    index: 26,
    name: "Alpina",
    subcategories: [
      { index: 1, name: "B10" },
      { index: 2, name: "B12" },
      { index: 3, name: "B3" },
      { index: 4, name: "B4" },
      { index: 5, name: "B5" },
      { index: 6, name: "B6" },
      { index: 7, name: "B7" },
      { index: 8, name: "B8" },
      { index: 9, name: "D10" },
      { index: 10, name: "D3" },
      { index: 11, name: "D4" },
      { index: 12, name: "D5" },
      { index: 13, name: "Roadster S" },
      { index: 14, name: "XB7" },
      { index: 15, name: "XD3" },
      { index: 16, name: "XD4" },
      { index: 17, name: "Sonstige" },
    ],
  },
  {
    index: 27,
    name: "Asia Motors",
    subcategories: [
      { index: 1, name: "Rocsta" },
      { index: 2, name: "Sonstige" },
    ],
  },
  {
    index: 28,
    name: "Aston Martin",
    subcategories: [
      { index: 1, name: "AR1" },
      { index: 2, name: "Cygnet" },
      { index: 3, name: "DB" },
      { index: 4, name: "DB11" },
      { index: 5, name: "DB7" },
      { index: 6, name: "DB9" },
      { index: 7, name: "DBS" },
      { index: 8, name: "DBX" },
      { index: 9, name: "Lagonda" },
      { index: 10, name: "Rapide" },
      { index: 11, name: "V12 Vantage" },
      { index: 12, name: "V8" },
      { index: 13, name: "V8 Vantage" },
      { index: 14, name: "Vanquish" },
      { index: 15, name: "Vantage" },
      { index: 16, name: "Virage" },
      { index: 17, name: "Volante" },
      { index: 18, name: "Sonstige" },
    ],
  },
  {
    index: 29,
    name: "Austin",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 30,
    name: "Austin Healey",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 31,
    name: "BAIC",
    subcategories: [
      { index: 1, name: "ALL NEW X7" },
      { index: 2, name: "BJ20" },
      { index: 3, name: "BJ40 PLUS" },
      { index: 4, name: "BJ40 SE" },
      { index: 5, name: "BJ60" },
      { index: 6, name: "BJ80" },
      { index: 7, name: "F40" },
      { index: 8, name: "Senova X35" },
      { index: 9, name: "Senova X55" },
      { index: 10, name: "Senova X65" },
      { index: 11, name: "X55" },
      { index: 12, name: "X55 II" },
      { index: 13, name: "X35" },
      { index: 14, name: "U5 PLUS" },
      { index: 15, name: "EU5" },
      { index: 16, name: "Senova D20" },
      { index: 17, name: "Senova X25" },
    ],
  },
  {
    index: 32,
    name: "Bentley",
    subcategories: [
      { index: 1, name: "Arnage" },
      { index: 2, name: "Azure" },
      { index: 3, name: "Bentayga" },
      { index: 4, name: "Brooklands" },
      { index: 5, name: "Continental" },
      { index: 6, name: "Continental GT" },
      { index: 7, name: "Continental GTC" },
      { index: 8, name: "Continental Supersports" },
      { index: 9, name: "Eight" },
      { index: 10, name: "Flying Spur" },
      { index: 11, name: "Mulsanne" },
      { index: 12, name: "Turbo R" },
      { index: 13, name: "Turbo RT" },
      { index: 14, name: "Turbo S" },
      { index: 15, name: "Sonstige" },
    ],
  },
  {
    index: 33,
    name: "Borgward",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 34,
    name: "Brilliance",
    subcategories: [
      { index: 1, name: "BC3" },
      { index: 2, name: "BS2" },
      { index: 3, name: "BS4" },
      { index: 4, name: "BS6" },
      { index: 5, name: "Sonstige" },
    ],
  },
  {
    index: 35,
    name: "Bugatti",
    subcategories: [
      { index: 1, name: "Chiron" },
      { index: 2, name: "EB 110" },
      { index: 3, name: "Veyron" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 36,
    name: "Buick",
    subcategories: [
      { index: 1, name: "Century" },
      { index: 2, name: "Electra" },
      { index: 3, name: "Enclave" },
      { index: 4, name: "La Crosse" },
      { index: 5, name: "Le Sabre" },
      { index: 6, name: "Park Avenue" },
      { index: 7, name: "Regal" },
      { index: 8, name: "Riviera" },
      { index: 9, name: "Roadmaster" },
      { index: 10, name: "Skylark" },
      { index: 11, name: "Sonstige" },
    ],
  },
  {
    index: 37,
    name: "BYD",
    subcategories: [
      { index: 1, name: "BYD ATTO 3" },
      { index: 2, name: "BYD DOLPHIN" },
      { index: 3, name: "BYD HAN" },
      { index: 4, name: "BYD SEAL" },
      { index: 5, name: "BYD TANG" },
      { index: 6, name: "Sonstige" },
    ],
  },
  {
    index: 38,
    name: "Cadillac",
    subcategories: [
      { index: 1, name: "Allante" },
      { index: 2, name: "ATS" },
      { index: 3, name: "BLS" },
      { index: 4, name: "Celestiq" },
      { index: 5, name: "CT6" },
      { index: 6, name: "CTS" },
      { index: 7, name: "Deville" },
      { index: 8, name: "Eldorado" },
      { index: 9, name: "Escalade" },
      { index: 10, name: "Fleetwood" },
      { index: 11, name: "Seville" },
      { index: 12, name: "SRX" },
      { index: 13, name: "STS" },
      { index: 14, name: "Sviante" },
      { index: 15, name: "XLR" },
      { index: 16, name: "XT4" },
      { index: 17, name: "XT5" },
      { index: 18, name: "XT6" },
      { index: 19, name: "Sonstige" },
    ],
  },
  {
    index: 39,
    name: "Caterham",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 40,
    name: "Chery",
    subcategories: [
      { index: 1, name: "Arrizo 6" },
      { index: 2, name: "Arrizo 8" },
      { index: 3, name: "FX" },
      { index: 4, name: "Tiggo 2 pro" },
      { index: 5, name: "Tiggo 4 pro" },
      { index: 6, name: "Tiggo 7" },
      { index: 7, name: "Tiggo 7 Pro" },
      { index: 8, name: "Tiggo 7 pro max" },
      { index: 9, name: "Tiggo 8" },
      { index: 10, name: "Tiggo 8 Pro" },
      { index: 11, name: "Tiggo 8 pro e+" },
    ],
  },
  {
    index: 41,
    name: "Chevrolet",
    subcategories: [
      { index: 1, name: "2500" },
      { index: 2, name: "Alero" },
      { index: 3, name: "Astro" },
      { index: 4, name: "Avalanche" },
      { index: 5, name: "Aveo" },
      { index: 6, name: "Beretta" },
      { index: 7, name: "Blazer" },
      { index: 8, name: "Blazer EV" },
      { index: 9, name: "C1500" },
      { index: 10, name: "Camaro" },
      { index: 11, name: "Caprice" },
      { index: 12, name: "Captiva" },
      { index: 13, name: "Cavalier" },
      { index: 14, name: "Chevelle" },
      { index: 15, name: "Chevy Van" },
      { index: 16, name: "Citation" },
      { index: 17, name: "Colorado" },
      { index: 18, name: "Corsica" },
      { index: 19, name: "Corvette" },
      { index: 20, name: "Cruze" },
      { index: 21, name: "El Camino" },
      { index: 22, name: "Epica" },
      { index: 23, name: "Evanda" },
      { index: 24, name: "Express" },
      { index: 25, name: "G" },
      { index: 26, name: "HHR" },
      { index: 27, name: "Impala" },
      { index: 28, name: "K1500" },
      { index: 29, name: "K30" },
      { index: 30, name: "Kalos" },
      { index: 31, name: "Lacetti" },
      { index: 32, name: "Lumina" },
      { index: 33, name: "Malibu" },
      { index: 34, name: "Matiz" },
      { index: 35, name: "Monte Carlo" },
      { index: 36, name: "Niva" },
      { index: 37, name: "Nubira" },
      { index: 38, name: "Orlando" },
      { index: 39, name: "Rezzo" },
      { index: 40, name: "S-10" },
      { index: 41, name: "Silverado" },
      { index: 42, name: "Spark" },
      { index: 43, name: "SSR" },
      { index: 44, name: "Suburban" },
      { index: 45, name: "Tacuma" },
      { index: 46, name: "Tahoe" },
      { index: 47, name: "Trailblazer" },
      { index: 48, name: "Trans Sport" },
      { index: 49, name: "Traverse" },
      { index: 50, name: "Trax" },
      { index: 51, name: "Venture" },
      { index: 52, name: "Volt" },
      { index: 53, name: "Sonstige" },
    ],
  },
  {
    index: 42,
    name: "Chrysler",
    subcategories: [
      { index: 1, name: "200" },
      { index: 2, name: "300 M" },
      { index: 3, name: "300C" },
      { index: 4, name: "Aspen" },
      { index: 5, name: "Crossfire" },
      { index: 6, name: "Daytona" },
      { index: 7, name: "ES" },
      { index: 8, name: "Grand Voyager" },
      { index: 9, name: "GS" },
      { index: 10, name: "GTS" },
      { index: 11, name: "Imperial" },
      { index: 12, name: "Le Baron" },
      { index: 13, name: "Neon" },
      { index: 14, name: "New Yorker" },
      { index: 15, name: "Pacifica" },
      { index: 16, name: "Prowler" },
      { index: 17, name: "PT Cruiser" },
      { index: 18, name: "Saratoga" },
      { index: 19, name: "Sebring" },
      { index: 20, name: "Stratus" },
      { index: 21, name: "Valiant" },
      { index: 22, name: "Viper" },
      { index: 23, name: "Vision" },
      { index: 24, name: "Voyager" },
      { index: 25, name: "Sonstige" },
    ],
  },
  {
    index: 43,
    name: "Cobra",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 44,
    name: "Corvette",
    subcategories: [
      { index: 1, name: "C1" },
      { index: 2, name: "C2" },
      { index: 3, name: "C3" },
      { index: 4, name: "C4" },
      { index: 5, name: "C5" },
      { index: 6, name: "C6" },
      { index: 7, name: "C7" },
      { index: 8, name: "C8" },
      { index: 9, name: "Z06" },
      { index: 10, name: "ZR 1" },
      { index: 11, name: "Sonstige" },
    ],
  },
  {
    index: 45,
    name: "Dacia",
    subcategories: [
      { index: 1, name: "Dokker" },
      { index: 2, name: "Duster" },
      { index: 3, name: "Jogger" },
      { index: 4, name: "Lodgy" },
      { index: 5, name: "Logan" },
      { index: 6, name: "Logan Pick-Up" },
      { index: 7, name: "Pick Up" },
      { index: 8, name: "Sandero" },
      { index: 9, name: "Spring" },
      { index: 10, name: "Sonstige" },
    ],
  },
  {
    index: 46,
    name: "Daewoo",
    subcategories: [
      { index: 1, name: "Espero" },
      { index: 2, name: "Evanda" },
      { index: 3, name: "Kalos" },
      { index: 4, name: "Korando" },
      { index: 5, name: "Lacetti" },
      { index: 6, name: "Lanos" },
      { index: 7, name: "Leganza" },
      { index: 8, name: "Matiz" },
      { index: 9, name: "Musso" },
      { index: 10, name: "Nexia" },
      { index: 11, name: "Nubira" },
      { index: 12, name: "Racer" },
      { index: 13, name: "Rezzo" },
      { index: 14, name: "Tacuma" },
      { index: 15, name: "Tico" },
      { index: 16, name: "Sonstige" },
    ],
  },
  {
    index: 47,
    name: "Daihatsu",
    subcategories: [
      { index: 1, name: "Applause" },
      { index: 2, name: "Charade" },
      { index: 3, name: "Charmant" },
      { index: 4, name: "Copen" },
      { index: 5, name: "Cuore" },
      { index: 6, name: "Feroza/Sportrak" },
      { index: 7, name: "Freeclimber" },
      { index: 8, name: "Gran Move" },
      { index: 9, name: "Hijet" },
      { index: 10, name: "MATERIA" },
      { index: 11, name: "Move" },
      { index: 12, name: "Rocky/Fourtrak" },
      { index: 13, name: "Rocky Taft F20" },
      { index: 14, name: "Sirion" },
      { index: 15, name: "Terios" },
      { index: 16, name: "TREVIS" },
      { index: 17, name: "YRV" },
      { index: 18, name: "Sonstige" },
    ],
  },
  {
    index: 48,
    name: "DeTomaso",
    subcategories: [
      { index: 1, name: "Guarà" },
      { index: 2, name: "Pantera" },
      { index: 3, name: "Sonstige" },
    ],
  },
  {
    index: 49,
    name: "Dodge",
    subcategories: [
      { index: 1, name: "Avenger" },
      { index: 2, name: "Caliber" },
      { index: 3, name: "Challenger" },
      { index: 4, name: "Challenger (Svi)" },
      { index: 5, name: "Charger" },
      { index: 6, name: "Coronet" },
      { index: 7, name: "Dakota" },
      { index: 8, name: "Dart" },
      { index: 9, name: "Demon" },
      { index: 10, name: "Durango" },
      { index: 11, name: "Grand Caravan" },
      { index: 12, name: "Hornet" },
      { index: 13, name: "Journey" },
      { index: 14, name: "Magnum" },
      { index: 15, name: "Neon" },
      { index: 16, name: "Nitro" },
      { index: 17, name: "RAM" },
      { index: 18, name: "Stealth" },
      { index: 19, name: "Viper" },
      { index: 20, name: "Sonstige" },
    ],
  },
  {
    index: 50,
    name: "Drugi",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 51,
    name: "DS automobiles",
    subcategories: [
      { index: 1, name: "DS3" },
      { index: 2, name: "DS3 Crossback" },
      { index: 3, name: "DS4" },
      { index: 4, name: "DS4 Crossback" },
      { index: 5, name: "DS5" },
      { index: 6, name: "DS7 Crossback" },
      { index: 7, name: "DS9" },
    ],
  },
  {
    index: 52,
    name: "Ferrari",
    subcategories: [
      { index: 1, name: "208" },
      { index: 2, name: "246" },
      { index: 3, name: "250" },
      { index: 4, name: "275" },
      { index: 5, name: "288" },
      { index: 6, name: "308" },
      { index: 7, name: "328" },
      { index: 8, name: "330" },
      { index: 9, name: "348" },
      { index: 10, name: "360" },
      { index: 11, name: "365" },
      { index: 12, name: "400" },
      { index: 13, name: "412" },
      { index: 14, name: "456" },
      { index: 15, name: "458" },
      { index: 16, name: "488" },
      { index: 17, name: "512" },
      { index: 18, name: "550" },
      { index: 19, name: "575" },
      { index: 20, name: "612" },
      { index: 21, name: "750" },
      { index: 22, name: "812" },
      { index: 23, name: "488 GTB" },
      { index: 24, name: "488 Pista" },
      { index: 25, name: "488 Spider" },
      { index: 26, name: "599 GTB" },
      { index: 27, name: "599 GTO" },
      { index: 28, name: "599 SA Aperta" },
      { index: 29, name: "California" },
      { index: 30, name: "Daytona" },
      { index: 31, name: "Dino GT4" },
      { index: 32, name: "Enzo Ferrari" },
      { index: 33, name: "F12" },
      { index: 34, name: "F355" },
      { index: 35, name: "F40" },
      { index: 36, name: "F430" },
      { index: 37, name: "F50" },
      { index: 38, name: "F8" },
      { index: 39, name: "FF" },
      { index: 40, name: "GTC4Lusso" },
      { index: 41, name: "LaFerrari" },
      { index: 42, name: "Mondial" },
      { index: 43, name: "Portofino" },
      { index: 44, name: "Purosangue" },
      { index: 45, name: "Roma" },
      { index: 46, name: "SF90" },
      { index: 47, name: "Superamerica" },
      { index: 48, name: "Testarossa" },
      { index: 49, name: "Sonstige" },
    ],
  },
  {
    index: 53,
    name: "Geely",
    subcategories: [
      { index: 1, name: "Geely Binyue" },
      { index: 2, name: "Geely Boyue" },
      { index: 3, name: "Geely Haoyue L" },
      { index: 4, name: "Geely Xingrui" },
      { index: 5, name: "Geely Xingyue" },
      { index: 6, name: "Geely Xingyue L" },
      { index: 7, name: "Sonstige" },
    ],
  },
  {
    index: 54,
    name: "GMC",
    subcategories: [
      { index: 1, name: "Acadia" },
      { index: 2, name: "Envoy" },
      { index: 3, name: "Safari" },
      { index: 4, name: "Savana" },
      { index: 5, name: "Sierra" },
      { index: 6, name: "Sonoma" },
      { index: 7, name: "Suburban" },
      { index: 8, name: "Syclone" },
      { index: 9, name: "Terrain" },
      { index: 10, name: "Typhoon" },
      { index: 11, name: "Vandura" },
      { index: 12, name: "Yukon" },
      { index: 13, name: "Sonstige" },
    ],
  },
  {
    index: 55,
    name: "Gonow",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 56,
    name: "Great Wall",
    subcategories: [{ index: 1, name: "Hover" }],
  },
  {
    index: 57,
    name: "Holden",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 58,
    name: "Honda",
    subcategories: [
      { index: 1, name: "Accord" },
      { index: 2, name: "Aerodeck" },
      { index: 3, name: "City" },
      { index: 4, name: "Civic" },
      { index: 5, name: "Clarity" },
      { index: 6, name: "Concerto" },
      { index: 7, name: "CR-V" },
      { index: 8, name: "CRX" },
      { index: 9, name: "CR-Z" },
      { index: 10, name: "e" },
      { index: 11, name: "Element" },
      { index: 12, name: "FR-V" },
      { index: 13, name: "HR-V" },
      { index: 14, name: "Insight" },
      { index: 15, name: "Integra" },
      { index: 16, name: "Jazz" },
      { index: 17, name: "Legend" },
      { index: 18, name: "Logo" },
      { index: 19, name: "NSX" },
      { index: 20, name: "Odyssey" },
      { index: 21, name: "Pilot" },
      { index: 22, name: "Prelude" },
      { index: 23, name: "Prologue" },
      { index: 24, name: "Ridgeline" },
      { index: 25, name: "S2000" },
      { index: 26, name: "Shuttle" },
      { index: 27, name: "Stream" },
      { index: 28, name: "Sonstige" },
    ],
  },
  {
    index: 59,
    name: "Hummer",
    subcategories: [
      { index: 1, name: "H1" },
      { index: 2, name: "H2" },
      { index: 3, name: "H3" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 60,
    name: "Infiniti",
    subcategories: [
      { index: 1, name: "EX30" },
      { index: 2, name: "EX35" },
      { index: 3, name: "EX37" },
      { index: 4, name: "FX" },
      { index: 5, name: "G35" },
      { index: 6, name: "G37" },
      { index: 7, name: "M30" },
      { index: 8, name: "M35" },
      { index: 9, name: "M37" },
      { index: 10, name: "Q30" },
      { index: 11, name: "Q45" },
      { index: 12, name: "Q50" },
      { index: 13, name: "Q60" },
      { index: 14, name: "Q70" },
      { index: 15, name: "QX30" },
      { index: 16, name: "QX50" },
      { index: 17, name: "QX56" },
      { index: 18, name: "QX60" },
      { index: 19, name: "QX70" },
      { index: 20, name: "QX80" },
      { index: 21, name: "Sonstige" },
    ],
  },
  {
    index: 61,
    name: "Isuzu",
    subcategories: [
      { index: 1, name: "Campo" },
      { index: 2, name: "D-Max" },
      { index: 3, name: "Gemini" },
      { index: 4, name: "Midi" },
      { index: 5, name: "PICK UP" },
      { index: 6, name: "Rodeo" },
      { index: 7, name: "Trooper" },
      { index: 8, name: "Sonstige" },
    ],
  },
  {
    index: 62,
    name: "Jaguar",
    subcategories: [
      { index: 1, name: "Daimler" },
      { index: 2, name: "E-Pace" },
      { index: 3, name: "E-Type" },
      { index: 4, name: "F-Pace" },
      { index: 5, name: "F-Type" },
      { index: 6, name: "I-Pace" },
      { index: 7, name: "MK II" },
      { index: 8, name: "S-Type" },
      { index: 9, name: "XE" },
      { index: 10, name: "XF" },
      { index: 11, name: "XJ" },
      { index: 12, name: "XJ12" },
      { index: 13, name: "XJ40" },
      { index: 14, name: "XJ6" },
      { index: 15, name: "XJ8" },
      { index: 16, name: "XJR" },
      { index: 17, name: "XJS" },
      { index: 18, name: "XJSC" },
      { index: 19, name: "XK" },
      { index: 20, name: "XK8" },
      { index: 21, name: "XKR" },
      { index: 22, name: "X-Type" },
      { index: 23, name: "Sonstige" },
    ],
  },
  {
    index: 63,
    name: "Königsegg",
    subcategories: [
      { index: 1, name: "Agera" },
      { index: 2, name: "CCR" },
      { index: 3, name: "CCXR" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 64,
    name: "Lada",
    subcategories: [
      { index: 1, name: "110" },
      { index: 2, name: "111" },
      { index: 3, name: "112" },
      { index: 4, name: "1200" },
      { index: 5, name: "2107" },
      { index: 6, name: "2110" },
      { index: 7, name: "2111" },
      { index: 8, name: "2112" },
      { index: 9, name: "Aleko" },
      { index: 10, name: "Forma" },
      { index: 11, name: "Granta" },
      { index: 12, name: "Kalina" },
      { index: 13, name: "Niva" },
      { index: 14, name: "Nova" },
      { index: 15, name: "Priora" },
      { index: 16, name: "Samara" },
      { index: 17, name: "Taiga" },
      { index: 18, name: "Urban" },
      { index: 19, name: "Sonstige" },
    ],
  },
  {
    index: 65,
    name: "Lamborghini",
    subcategories: [
      { index: 1, name: "Aventador" },
      { index: 2, name: "Countach" },
      { index: 3, name: "Diablo" },
      { index: 4, name: "Espada" },
      { index: 5, name: "Gallardo" },
      { index: 6, name: "Huracán" },
      { index: 7, name: "Huracan Sterrato" },
      { index: 8, name: "Jalpa" },
      { index: 9, name: "LM" },
      { index: 10, name: "Miura" },
      { index: 11, name: "Murciélago" },
      { index: 12, name: "Urraco" },
      { index: 13, name: "Urus" },
      { index: 14, name: "Sonstige" },
    ],
  },
  {
    index: 66,
    name: "Lancia",
    subcategories: [
      { index: 1, name: "Beta" },
      { index: 2, name: "Dedra" },
      { index: 3, name: "Delta" },
      { index: 4, name: "Flaminia" },
      { index: 5, name: "Flavia" },
      { index: 6, name: "Fulvia" },
      { index: 7, name: "Gamma" },
      { index: 8, name: "Kappa" },
      { index: 9, name: "Lybra" },
      { index: 10, name: "MUSA" },
      { index: 11, name: "Phedra" },
      { index: 12, name: "Prisma" },
      { index: 13, name: "Stratos" },
      { index: 14, name: "Thema" },
      { index: 15, name: "Thesis" },
      { index: 16, name: "Voyager" },
      { index: 17, name: "Ypsilon" },
      { index: 18, name: "Zeta" },
      { index: 19, name: "Sonstige" },
    ],
  },
  {
    index: 67,
    name: "Land Rover",
    subcategories: [
      { index: 1, name: "Defender" },
      { index: 2, name: "Discovery" },
      { index: 3, name: "Discovery Sport" },
      { index: 4, name: "Freelander" },
      { index: 5, name: "Range Rover" },
      { index: 6, name: "Range Rover Evoque" },
      { index: 7, name: "Range Rover Sport" },
      { index: 8, name: "Range Rover Velar" },
      { index: 9, name: "Serie I" },
      { index: 10, name: "Serie II" },
      { index: 11, name: "Serie III" },
      { index: 12, name: "Sonstige" },
    ],
  },
  {
    index: 68,
    name: "Landwind",
    subcategories: [
      { index: 1, name: "CV-9" },
      { index: 2, name: "S" },
      { index: 3, name: "SC2" },
      { index: 4, name: "SC4" },
      { index: 5, name: "Sonstige" },
    ],
  },
  {
    index: 69,
    name: "Lexus",
    subcategories: [
      { index: 1, name: "CT 200h" },
      { index: 2, name: "ES 300" },
      { index: 3, name: "ES 330" },
      { index: 4, name: "ES 350" },
      { index: 5, name: "GS 250" },
      { index: 6, name: "GS 300" },
      { index: 7, name: "GS 350" },
      { index: 8, name: "GS 430" },
      { index: 9, name: "GS 450" },
      { index: 10, name: "GS 460" },
      { index: 11, name: "GS F" },
      { index: 12, name: "GX 460" },
      { index: 13, name: "GX 470" },
      { index: 14, name: "IS 200" },
      { index: 15, name: "IS 220" },
      { index: 16, name: "IS 250" },
      { index: 17, name: "IS 300" },
      { index: 18, name: "IS 350" },
      { index: 19, name: "IS-F" },
      { index: 20, name: "LC 500" },
      { index: 21, name: "LC 500h" },
      { index: 22, name: "LFA" },
      { index: 23, name: "LS 400" },
      { index: 24, name: "LS 430" },
      { index: 25, name: "LS 460" },
      { index: 26, name: "LS 500" },
      { index: 27, name: "LS 600" },
      { index: 28, name: "LX 450" },
      { index: 29, name: "LX 470" },
      { index: 30, name: "LX 570" },
      { index: 31, name: "NX 200" },
      { index: 32, name: "NX 300" },
      { index: 33, name: "NX 350" },
      { index: 34, name: "NX 450" },
      { index: 35, name: "RC 200" },
      { index: 36, name: "RC 300" },
      { index: 37, name: "RC 350" },
      { index: 38, name: "RC F" },
      { index: 39, name: "RX 200" },
      { index: 40, name: "RX 300" },
      { index: 41, name: "RX 330" },
      { index: 42, name: "RX 350" },
      { index: 43, name: "RX 400" },
      { index: 44, name: "RX 450" },
      { index: 45, name: "RX 450h" },
      { index: 46, name: "RZ 450e" },
      { index: 47, name: "SC 400" },
      { index: 48, name: "SC 430" },
      { index: 49, name: "UX" },
      { index: 50, name: "Sonstige" },
    ],
  },
  {
    index: 70,
    name: "Ligier",
    subcategories: [
      { index: 1, name: "Ambra" },
      { index: 2, name: "Be Sun" },
      { index: 3, name: "JS 50" },
      { index: 4, name: "JS 50 L" },
      { index: 5, name: "JS 60" },
      { index: 6, name: "JS RC" },
      { index: 7, name: "Nova" },
      { index: 8, name: "Optima" },
      { index: 9, name: "X - Too" },
      { index: 10, name: "Sonstige" },
    ],
  },
  {
    index: 71,
    name: "Lincoln",
    subcategories: [
      { index: 1, name: "Aviator" },
      { index: 2, name: "Continental" },
      { index: 3, name: "LS" },
      { index: 4, name: "Mark" },
      { index: 5, name: "Navigator" },
      { index: 6, name: "Town Car" },
      { index: 7, name: "Sonstige" },
    ],
  },
  {
    index: 72,
    name: "Lotus",
    subcategories: [
      { index: 1, name: "340 R" },
      { index: 2, name: "Cortina" },
      { index: 3, name: "Elan" },
      { index: 4, name: "Elise" },
      { index: 5, name: "Elite" },
      { index: 6, name: "Emira" },
      { index: 7, name: "Esprit" },
      { index: 8, name: "Europa" },
      { index: 9, name: "Evora" },
      { index: 10, name: "Excel" },
      { index: 11, name: "Exige" },
      { index: 12, name: "Super Seven" },
      { index: 13, name: "Sonstige" },
    ],
  },
  {
    index: 73,
    name: "Lynk & Co",
    subcategories: [
      { index: 1, name: "Lynk & Co 01" },
      { index: 2, name: "Lynk & Co 02" },
      { index: 3, name: "Lynk & Co 03" },
      { index: 4, name: "Lynk & Co 04" },
      { index: 5, name: "Lynk & Co 05" },
      { index: 6, name: "Lynk & Co 06" },
      { index: 7, name: "Lynk & Co 07" },
      { index: 8, name: "Lynk & Co 08" },
      { index: 9, name: "Lynk & Co 09" },
    ],
  },
  {
    index: 74,
    name: "Mahindra",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 75,
    name: "Maserati",
    subcategories: [
      { index: 1, name: "222" },
      { index: 2, name: "224" },
      { index: 3, name: "228" },
      { index: 4, name: "418" },
      { index: 5, name: "420" },
      { index: 6, name: "422" },
      { index: 7, name: "424" },
      { index: 8, name: "430" },
      { index: 9, name: "3200" },
      { index: 10, name: "4200" },
      { index: 11, name: "Biturbo" },
      { index: 12, name: "Ghibli" },
      { index: 13, name: "GranCabrio" },
      { index: 14, name: "Gransport" },
      { index: 15, name: "Granturismo" },
      { index: 16, name: "Grecale" },
      { index: 17, name: "Indy" },
      { index: 18, name: "Karif" },
      { index: 19, name: "Levante" },
      { index: 20, name: "MC12" },
      { index: 21, name: "MC20" },
      { index: 22, name: "Merak" },
      { index: 23, name: "Quattroporte" },
      { index: 24, name: "Shamal" },
      { index: 25, name: "Spyder" },
      { index: 26, name: "Sonstige" },
    ],
  },
  {
    index: 76,
    name: "Maybach",
    subcategories: [
      { index: 1, name: "57" },
      { index: 2, name: "62" },
      { index: 3, name: "Pullman" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 77,
    name: "MG",
    subcategories: [
      { index: 1, name: "Comet EV" },
      { index: 2, name: "EHS" },
      { index: 3, name: "HS" },
      { index: 4, name: "Marvel R" },
      { index: 5, name: "MG4" },
      { index: 6, name: "MG5" },
      { index: 7, name: "MG7" },
      { index: 8, name: "MGA" },
      { index: 9, name: "MGB" },
      { index: 10, name: "MGF" },
      { index: 11, name: "Midget" },
      { index: 12, name: "Montego" },
      { index: 13, name: "TD" },
      { index: 14, name: "TF" },
      { index: 15, name: "ZR" },
      { index: 16, name: "ZS" },
      { index: 17, name: "ZT" },
      { index: 18, name: "Sonstige" },
    ],
  },
  {
    index: 78,
    name: "MINI",
    subcategories: [
      { index: 1, name: "Cooper Cabrio" },
      { index: 2, name: "Cooper D Cabrio" },
      { index: 3, name: "Cooper S Cabrio" },
      { index: 4, name: "Cooper SD Cabrio" },
      { index: 5, name: "John Cooper Works Cabrio" },
      { index: 6, name: "One Cabrio" },
      { index: 7, name: "Cooper Clubman" },
      { index: 8, name: "Cooper D Clubman" },
      { index: 9, name: "Cooper S Clubman" },
      { index: 10, name: "Cooper SD Clubman" },
      { index: 11, name: "John Cooper Works Clubman" },
      { index: 12, name: "One Clubman" },
      { index: 13, name: "One D Clubman" },
      { index: 14, name: "Clubvan" },
      { index: 15, name: "Cooper Countryman" },
      { index: 16, name: "Cooper D Countryman" },
      { index: 17, name: "Cooper S Countryman" },
      { index: 18, name: "Cooper SD Countryman" },
      { index: 19, name: "Cooper SE Countryman" },
      { index: 20, name: "John Cooper Works Countryman" },
      { index: 21, name: "One Countryman" },
      { index: 22, name: "One D Countryman" },
      { index: 23, name: "Cooper Coupé" },
      { index: 24, name: "Cooper S Coupé" },
      { index: 25, name: "Cooper SD Coupé" },
      { index: 26, name: "John Cooper Works Coupé" },
      { index: 27, name: "1000" },
      { index: 28, name: "1300" },
      { index: 29, name: "Cooper" },
      { index: 30, name: "Cooper D" },
      { index: 31, name: "Cooper S" },
      { index: 32, name: "Cooper SD" },
      { index: 33, name: "Cooper SE" },
      { index: 34, name: "John Cooper Works" },
      { index: 35, name: "ONE" },
      { index: 36, name: "One D" },
      { index: 37, name: "One First" },
      { index: 38, name: "Cooper D Paceman" },
      { index: 39, name: "Cooper Paceman" },
      { index: 40, name: "Cooper S Paceman" },
      { index: 41, name: "Cooper SD Paceman" },
      { index: 42, name: "John Cooper Works Paceman" },
      { index: 43, name: "Cooper Roadster" },
      { index: 44, name: "Cooper S Roadster" },
      { index: 45, name: "Cooper SD Roadster" },
      { index: 46, name: "John Cooper Works Roadster" },
      { index: 47, name: "Sonstige" },
    ],
  },
  {
    index: 79,
    name: "Morgan",
    subcategories: [
      { index: 1, name: "4/4" },
      { index: 2, name: "3 Wheeler" },
      { index: 3, name: "Aero 8" },
      { index: 4, name: "Plus 4" },
      { index: 5, name: "Plus 6" },
      { index: 6, name: "Plus 8" },
      { index: 7, name: "Roadster" },
      { index: 8, name: "Sonstige" },
    ],
  },
  {
    index: 80,
    name: "NIO",
    subcategories: [
      { index: 1, name: "EL 7" },
      { index: 2, name: "ET 5" },
      { index: 3, name: "ET 7" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 81,
    name: "NSU",
    subcategories: [
      { index: 1, name: "NSU 1000 TT" },
      { index: 2, name: "NSU 1200" },
      { index: 3, name: "NSU K70" },
      { index: 4, name: "NSU Ro 80" },
      { index: 5, name: "NSU Spider" },
      { index: 6, name: "NSU Typ 110" },
      { index: 7, name: "Prinz" },
      { index: 8, name: "Prinz 1000" },
      { index: 9, name: "Prinz 4" },
      { index: 10, name: "Sport Prinz" },
      { index: 11, name: "Sonstige" },
    ],
  },
  {
    index: 82,
    name: "Oldsmobile",
    subcategories: [
      { index: 1, name: "Bravada" },
      { index: 2, name: "Custom Cruiser" },
      { index: 3, name: "Cutlass" },
      { index: 4, name: "Delta 88" },
      { index: 5, name: "Silhouette" },
      { index: 6, name: "Supreme" },
      { index: 7, name: "Toronado" },
      { index: 8, name: "Sonstige" },
    ],
  },
  {
    index: 83,
    name: "Piaggio",
    subcategories: [
      { index: 1, name: "APE" },
      { index: 2, name: "APE TM" },
      { index: 3, name: "Porter" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 84,
    name: "Plymouth",
    subcategories: [
      { index: 1, name: "Prowler" },
      { index: 2, name: "Sonstige" },
    ],
  },
  {
    index: 85,
    name: "Polestar",
    subcategories: [
      { index: 1, name: "Polestar 2" },
      { index: 2, name: "Polestar 3" },
      { index: 3, name: "Polestar 4" },
      { index: 4, name: "Polestar 5" },
      { index: 5, name: "Polestar 6" },
    ],
  },
  {
    index: 86,
    name: "Pontiac",
    subcategories: [
      { index: 1, name: "6000" },
      { index: 2, name: "Bonneville" },
      { index: 3, name: "Fiero" },
      { index: 4, name: "Firebird" },
      { index: 5, name: "G6" },
      { index: 6, name: "Grand-Am" },
      { index: 7, name: "Grand-Prix" },
      { index: 8, name: "GTO" },
      { index: 9, name: "Montana" },
      { index: 10, name: "Solstice" },
      { index: 11, name: "Sunbird" },
      { index: 12, name: "Sunfire" },
      { index: 13, name: "Targa" },
      { index: 14, name: "Trans Am" },
      { index: 15, name: "Trans Sport" },
      { index: 16, name: "Vibe" },
      { index: 17, name: "Sonstige" },
    ],
  },
  {
    index: 87,
    name: "Porsche",
    subcategories: [
      { index: 1, name: "356" },
      { index: 2, name: "912" },
      { index: 3, name: "914" },
      { index: 4, name: "918" },
      { index: 5, name: "924" },
      { index: 6, name: "928" },
      { index: 7, name: "944" },
      { index: 8, name: "959" },
      { index: 9, name: "962" },
      { index: 10, name: "968" },
      { index: 11, name: "911" },
      { index: 12, name: "930" },
      { index: 13, name: "964" },
      { index: 14, name: "991" },
      { index: 15, name: "992" },
      { index: 16, name: "993" },
      { index: 17, name: "996" },
      { index: 18, name: "997" },
      { index: 19, name: "Boxster" },
      { index: 20, name: "Carrera GT" },
      { index: 21, name: "Cayenne" },
      { index: 22, name: "Cayenne Coupe" },
      { index: 23, name: "Cayman" },
      { index: 24, name: "Macan" },
      { index: 25, name: "Panamera" },
      { index: 26, name: "Panamera Sport Turismo" },
      { index: 27, name: "Taycan" },
      { index: 28, name: "Taycan Cross Turismo" },
      { index: 29, name: "Sonstige" },
      { index: 30, name: "Taycan Turbo GT" },
    ],
  },
  {
    index: 88,
    name: "Proton",
    subcategories: [
      { index: 1, name: "300 Serie" },
      { index: 2, name: "400 Serie" },
      { index: 3, name: "Sonstige" },
    ],
  },
  {
    index: 89,
    name: "Rolls Royce",
    subcategories: [
      { index: 1, name: "Corniche" },
      { index: 2, name: "Cullinan" },
      { index: 3, name: "Dawn" },
      { index: 4, name: "Flying Spur" },
      { index: 5, name: "Ghost" },
      { index: 6, name: "Park Ward" },
      { index: 7, name: "Phantom" },
      { index: 8, name: "Silver Cloud" },
      { index: 9, name: "Silver Dawn" },
      { index: 10, name: "Silver Seraph" },
      { index: 11, name: "Silver Shadow" },
      { index: 12, name: "Silver Spirit" },
      { index: 13, name: "Silver Spur" },
      { index: 14, name: "Wraith" },
      { index: 15, name: "Sonstige" },
    ],
  },
  {
    index: 90,
    name: "Rover",
    subcategories: [
      { index: 1, name: "25" },
      { index: 2, name: "45" },
      { index: 3, name: "75" },
      { index: 4, name: "100" },
      { index: 5, name: "111" },
      { index: 6, name: "114" },
      { index: 7, name: "115" },
      { index: 8, name: "200" },
      { index: 9, name: "213" },
      { index: 10, name: "214" },
      { index: 11, name: "216" },
      { index: 12, name: "218" },
      { index: 13, name: "220" },
      { index: 14, name: "400" },
      { index: 15, name: "414" },
      { index: 16, name: "416" },
      { index: 17, name: "418" },
      { index: 18, name: "420" },
      { index: 19, name: "600" },
      { index: 20, name: "618" },
      { index: 21, name: "620" },
      { index: 22, name: "623" },
      { index: 23, name: "800" },
      { index: 24, name: "820" },
      { index: 25, name: "825" },
      { index: 26, name: "827" },
      { index: 27, name: "City Rover" },
      { index: 28, name: "Metro" },
      { index: 29, name: "Montego" },
      { index: 30, name: "SD" },
      { index: 31, name: "Streetwise" },
      { index: 32, name: "Sonstige" },
    ],
  },
  {
    index: 91,
    name: "Saab",
    subcategories: [
      { index: 1, name: "90" },
      { index: 2, name: "96" },
      { index: 3, name: "99" },
      { index: 4, name: "900" },
      { index: 5, name: "9000" },
      { index: 6, name: "9-3" },
      { index: 7, name: "9-5" },
      { index: 8, name: "9-4X" },
      { index: 9, name: "9-7X" },
      { index: 10, name: "Sonstige" },
    ],
  },
  {
    index: 92,
    name: "Samand",
    subcategories: [{ index: 1, name: "X7" }],
  },
  {
    index: 93,
    name: "Santana",
    subcategories: [{ index: 1, name: "Sonstige" }],
  },
  {
    index: 94,
    name: "Seres",
    subcategories: [
      { index: 1, name: "Seres 3" },
      { index: 2, name: "Seres 5" },
      { index: 3, name: "Seres 7" },
      { index: 4, name: "Seres E1" },
    ],
  },
  {
    index: 95,
    name: "Smart",
    subcategories: [
      { index: 1, name: "#1" },
      { index: 2, name: "Crossblade" },
      { index: 3, name: "ForFour" },
      { index: 4, name: "ForTwo" },
      { index: 5, name: "Roadster" },
      { index: 6, name: "Sonstige" },
    ],
  },
  {
    index: 96,
    name: "Spyker",
    subcategories: [
      { index: 1, name: "C8" },
      { index: 2, name: "C8 AILERON" },
      { index: 3, name: "C8 DOUBLE 12 S" },
      { index: 4, name: "C8 LAVIOLETTE SWB" },
      { index: 5, name: "C8 SPYDER SWB" },
      { index: 6, name: "Sonstige" },
    ],
  },
  {
    index: 97,
    name: "Ssangyong",
    subcategories: [
      { index: 1, name: "Actyon" },
      { index: 2, name: "Family" },
      { index: 3, name: "Korando" },
      { index: 4, name: "Kyron" },
      { index: 5, name: "Musso" },
      { index: 6, name: "Rexton" },
      { index: 7, name: "Rodius" },
      { index: 8, name: "Tivoli" },
      { index: 9, name: "Torres" },
      { index: 10, name: "XLV" },
      { index: 11, name: "Sonstige" },
    ],
  },
  {
    index: 98,
    name: "Subaru",
    subcategories: [
      { index: 1, name: "B9 Tribeca" },
      { index: 2, name: "Baja" },
      { index: 3, name: "BRZ" },
      { index: 4, name: "BRZ tS" },
      { index: 5, name: "Forester" },
      { index: 6, name: "Impreza" },
      { index: 7, name: "Justy" },
      { index: 8, name: "Legacy" },
      { index: 9, name: "Levorg" },
      { index: 10, name: "Libero" },
      { index: 11, name: "Outback" },
      { index: 12, name: "Solterra" },
      { index: 13, name: "SVX" },
      { index: 14, name: "Trezia" },
      { index: 15, name: "Tribeca" },
      { index: 16, name: "Vivio" },
      { index: 17, name: "WRX STI" },
      { index: 18, name: "WRX TR" },
      { index: 19, name: "XT" },
      { index: 20, name: "XV" },
      { index: 21, name: "Sonstige" },
    ],
  },
  {
    index: 99,
    name: "Talbot",
    subcategories: [
      { index: 1, name: "Horizon" },
      { index: 2, name: "Samba" },
      { index: 3, name: "Sonstige" },
    ],
  },
  {
    index: 100,
    name: "Tata",
    subcategories: [
      { index: 1, name: "Indica" },
      { index: 2, name: "Indigo" },
      { index: 3, name: "Nano" },
      { index: 4, name: "Safari" },
      { index: 5, name: "Sumo" },
      { index: 6, name: "Telcoline" },
      { index: 7, name: "Telcosport" },
      { index: 8, name: "Xenon" },
      { index: 9, name: "Sonstige" },
    ],
  },
  {
    index: 101,
    name: "Tesla",
    subcategories: [
      { index: 1, name: "Cybertruck" },
      { index: 2, name: "Model 3" },
      { index: 3, name: "Model S" },
      { index: 4, name: "Model X" },
      { index: 5, name: "Model Y" },
      { index: 6, name: "Roadster" },
    ],
  },
  {
    index: 102,
    name: "Trabant",
    subcategories: [
      { index: 1, name: "601" },
      { index: 2, name: "Sonstige" },
    ],
  },
  {
    index: 103,
    name: "Triumph",
    subcategories: [
      { index: 1, name: "Dolomite" },
      { index: 2, name: "Moss" },
      { index: 3, name: "Spitfire" },
      { index: 4, name: "TR3" },
      { index: 5, name: "TR4" },
      { index: 6, name: "TR5" },
      { index: 7, name: "TR6" },
      { index: 8, name: "TR7" },
      { index: 9, name: "TR8" },
      { index: 10, name: "Sonstige" },
    ],
  },
  {
    index: 104,
    name: "TVR",
    subcategories: [
      { index: 1, name: "Chimaera" },
      { index: 2, name: "Griffith" },
      { index: 3, name: "Tuscan" },
      { index: 4, name: "Sonstige" },
    ],
  },
  {
    index: 105,
    name: "Wartburg",
    subcategories: [
      { index: 1, name: "311" },
      { index: 2, name: "353" },
      { index: 3, name: "Sonstige" },
    ],
  },
  {
    index: 106,
    name: "WEY",
    subcategories: [
      { index: 1, name: "Coffee 01" },
      { index: 2, name: "Coffee 02" },
    ],
  },
  {
    index: 107,
    name: "Wiesmann",
    subcategories: [
      { index: 1, name: "MF 25" },
      { index: 2, name: "MF 28" },
      { index: 3, name: "MF 3" },
      { index: 4, name: "MF 30" },
      { index: 5, name: "MF 35" },
      { index: 6, name: "MF 4" },
      { index: 7, name: "MF 5" },
      { index: 8, name: "Sonstige" },
    ],
  },
  {
    index: 108,
    name: "XEV",
    subcategories: [
      { index: 1, name: "Kitty" },
      { index: 2, name: "Yoyo" },
    ],
  },
  {
    index: 109,
    name: "XPeng",
    subcategories: [
      { index: 1, name: "G3i" },
      { index: 2, name: "G9" },
      { index: 3, name: "P5" },
      { index: 4, name: "P7" },
    ],
  },
  {
    index: 110,
    name: "Zastava",
    subcategories: [
      { index: 1, name: "7" },
      { index: 2, name: "Campagnola" },
      { index: 3, name: "Poli" },
      { index: 4, name: "Yugo Florida" },
      { index: 5, name: "Yugo Koral" },
      { index: 6, name: "Zastava 101" },
      { index: 7, name: "Zastava 128" },
      { index: 8, name: "Zastava 1300 / 1500" },
      { index: 9, name: "Zastava 750 / 850" },
    ],
  },
  {
    index: 111,
    name: "Zeekr",
    subcategories: [
      { index: 1, name: "Zeekr 001" },
      { index: 2, name: "Zeekr 007" },
      { index: 3, name: "Zeekr 009" },
      { index: 4, name: "Zeekr X" },
    ],
  },
];

export class Cars {
  cars;

  constructor(c) {
    this.cars = c;
  }

  getCompany(i) {
    return this.cars.filter((c) => c.index === +i)[0];
  }
  getModels(i) {
    return this.cars.filter((c) => c.index === +i)[0].subcategories;
  }
  getModel(i, mi) {
    const models = this.cars.filter((c) => c.index === +i)[0].subcategories;
    return models.length > 0 ? models.filter((m) => m.index === +mi)[0] : null;
  }
}

export default cars;
