import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//STORE
import { userArticleActions } from "../store/redux-store";
import queryStringCreator from "../helper/query-str-creator";
import { getMyArticles } from "../store/reducers/user-article-slice";

let init = true;

const useQueryStrUserArticle = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const {
    currentArticlePage,
    fetchArticles,
    searchFilters,
    refreshArticles,
    search,
    sort,
    articlesPerPage,
    // toFirstPage,
    currentCategory,
    currentSubCategory,
    queryStr,
  } = useSelector((state) => state.userArticle);
  const [showLoading, setShowLoading] = useState(false);
  const [triggerFirstPageFetch, setTriggerFirstPageFetch] = useState(false);

  const hideLoading = () => {
    setShowLoading(false);
  };

  //CREATE QUERY STRING WITH CURRENT PAGE OF 1
  const queryStrCreator = useCallback(
    (currPage = 1) => {
      return queryStringCreator(
        currPage,
        searchFilters,
        currentCategory,
        search,
        sort,
        articlesPerPage,
        currentSubCategory
      );
    },
    [
      currentCategory,
      search,
      sort,
      searchFilters,
      articlesPerPage,
      currentSubCategory,
    ]
  );

  //FIRST FETCH
  useEffect(() => {
    if (!user._id && token) return;
    if (init) {
      dispatch(
        getMyArticles(
          { queryString: `?page=1&status=1&sort=-createdAt&limit=30` },
          token,
          hideLoading
        )
      );
      init = false;
    }
  }, [dispatch, token, user]);

  //FETCH WHEN VALUE FETCH IS TRUE
  useEffect(() => {
    if (fetchArticles && user._id && token) {
      const queryString = queryStrCreator(currentArticlePage);
      dispatch(userArticleActions.setQueryStr({ value: queryString }));
      dispatch(getMyArticles({ queryString: queryString }, token, hideLoading));
      dispatch(userArticleActions.setFetchArticles({ value: false }));
    }
  }, [
    dispatch,
    queryStr,
    token,
    user._id,
    fetchArticles,
    currentArticlePage,
    queryStrCreator,
  ]);

  // FETCH FIRST PAGE
  const fetchArticlesWithFirstPage = useCallback(() => {
    const queryString = queryStrCreator();
    dispatch(userArticleActions.setQueryStr({ value: queryString }));
    dispatch(getMyArticles({ queryString }, token, hideLoading));
  }, [dispatch, queryStrCreator, token]);

  //FETCH WHEN CATEGORY CHANGE
  useEffect(() => {
    if (!init) {
      setTriggerFirstPageFetch(true);
      // console.log(currentCategory, currentSubCategory, sort);
    }
  }, [currentCategory, currentSubCategory, sort]);

  //FETCH WHEN SUBCATEGORY CHANGE
  // useEffect(() => {
  //   if (!init) {
  //     setTriggerFirstPageFetch(true);
  //   }
  // }, [currentSubCategory]);

  //FETCH WHEN SORT CHANGE
  // useEffect(() => {
  //   if (!init) {
  //     setTriggerFirstPageFetch(true);
  //   }
  // }, [sort]);

  //FETCH ARTICLES AFTER TRIGGERFIRSTPAGEFETCH IS TRUE
  useEffect(() => {
    if (triggerFirstPageFetch) {
      fetchArticlesWithFirstPage();
      setTriggerFirstPageFetch(false);
    }
  }, [triggerFirstPageFetch, fetchArticlesWithFirstPage]);

  //FETCH AFTER REFRESHARTICLES IS TRUE
  useEffect(() => {
    if (!user._id && !token) return;
    if (refreshArticles) {
      dispatch(userArticleActions.setRefreshArticles({ value: false }));
      dispatch(getMyArticles({ queryString: queryStr }, token, hideLoading));
    }
  }, [dispatch, refreshArticles, queryStr, token, user]);

  return {
    queryStrCreator,
    showLoading,
    setShowLoading,
  };
};

export default useQueryStrUserArticle;
