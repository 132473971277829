export const formatDateDifference = (createdAt, translations) => {
  const now = new Date();
  const createdAtDate = new Date(createdAt);
  const secondsDiff = Math.floor((now - createdAtDate) / 1000);
  const minutesDiff = Math.floor(secondsDiff / 60);
  const hoursDiff = Math.floor(minutesDiff / 60);
  const daysDiff = Math.floor(hoursDiff / 24);
  const yearsDiff = Math.floor(daysDiff / 365);

  if (secondsDiff < 60) {
    return `${translations.msg_date_seconds_ago || "vor"} ${secondsDiff} ${
      translations.msg_date_seconds || "Sekunden"
    }`;
  } else if (minutesDiff < 60) {
    return `${translations.msg_date_minutes_ago || "vor"} ${minutesDiff} ${
      translations.msg_date_minutes || "Minuten"
    }`;
  } else if (hoursDiff < 24) {
    return `${translations.msg_date_hours_ago || "vor"} ${hoursDiff} ${
      translations.msg_date_hours || "Stunden"
    }`;
  } else if (daysDiff < 365) {
    return `${translations.msg_date_days_ago || "vor"} ${daysDiff} ${
      translations.msg_date_days || "Tagen"
    }`;
  } else {
    return `${translations.msg_date_years_ago || "vor"} ${yearsDiff} ${
      translations.msg_date_years || "Jahren"
    }`;
  }
};

export const formatDate = (inputDate, translations) => {
  const date = new Date(inputDate);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
  const isYesterday =
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear();

  const time = date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  if (isToday) {
    return `${translations.msg_date_today || "Heute"} ${time}`;
  } else if (isYesterday) {
    return `${translations.msg_date_yesterday || "Gestern"} ${time}`;
  } else {
    return date.toLocaleDateString("de-DE");
  }
};
