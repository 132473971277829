import React from "react";

import classes from "./FetchedSingleImage.module.css";

import { MdOutlineStar, MdDelete } from "react-icons/md";

// import URL from '../../helper/'
const URL = process.env.REACT_APP_IMAGE_URL;
const SUFIX = process.env.REACT_APP_IMAGE_SUFFIX_LARGE;

const FetchedSingleImage = (props) => {
  console.log(props.mainImage);
  return (
    <div className={classes.container}>
      <img
        alt={props.data.name}
        src={`${URL}/articles${SUFIX}/${props.data.imageURL}`}
      />
      <div className={classes.btns}>
        <button id={props.id} onClick={props.onSetMainImageClick}>
          <MdOutlineStar
            style={
              props.data.imageURL === props.mainImage
                ? { color: `var(--main-image)` }
                : {}
            }
            // style={
            //   props.data.imageURL
            //     ? props.data.imageURL.split("_")[1] === "m" && {
            //         color: "var(--main-image)",
            //       }
            //     : props.data.imageURL
            // }
            className={classes.icon}
          />
        </button>
        <button onClick={props.onDeleteClick} id={props.id}>
          <MdDelete className={classes.icon} id={props.id} />
        </button>
      </div>
    </div>
  );
};

export default FetchedSingleImage;
