import React from "react";

import classes from "./PrivacyPolicySection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const PrivacyPolicySection = (props) => {
  const { translations } = useTranslation();

  return (
    <section
      id={"privacy-policy"}
      className={classes.container}
      style={{ backgroundColor: "var(--white)" }}
    >
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_privacy_policy || "Privacy Policy"}</h3>
        <div className={classes["body"]}>
          <h5>
            {translations.privacy_policy?.subtitle_1 || "Datenschutzerklärung"}
          </h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_1_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_1_text_2 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_1_text_3 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_1_text_4 || ""}
          </p>

          <h5>
            {translations.privacy_policy?.subtitle_2 || "Überlassene Daten:"}
          </h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_2_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_2_text_2 || ""}
          </p>

          <h5>{translations.privacy_policy?.subtitle_3 || "Erhobene Daten"}</h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_3_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_3_text_2 || ""}
          </p>

          <h5>{translations.privacy_policy?.subtitle_4 || "Marketing"}</h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_4_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_4_text_2 || ""}
          </p>

          <h5>
            {translations.privacy_policy?.subtitle_5 || "Produktentwicklung"}
          </h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_5_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_5_text_2 || ""}
          </p>

          <h5>
            {translations.privacy_policy?.subtitle_6 || "Datenweitergabe"}
          </h5>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_6_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.privacy_policy?.st_6_text_2 || ""}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicySection;
