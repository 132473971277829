import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";

//SOCKET
import { socket } from "../helper/socket-connection";
import socketCon from "../helper/socket-connection";

//STORE
import { getMyData } from "../store/reducers/auth-store";
import { chatActions } from "../store/redux-store";

const useSocket = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  //
  const [refreshChat, setRefreshChat] = useState(false);
  const [chat, setChat] = useState(null);
  const [sck, setSck] = useState(null);
  const [canConnect, setCanConnect] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && token !== "undefined") {
      dispatch(getMyData(JSON.parse(token)));
      setCanConnect(true);
    }
  }, [dispatch]);

  //SOCKET
  useEffect(() => {
    if (canConnect && isAuthenticated && user._id) {
      socketCon({ _id: user._id });
      setSck(socket);
      setCanConnect(false);
    }
  }, [canConnect, user._id, isAuthenticated]);

  //RECEIVING MESSAGE
  useEffect(() => {
    if (sck) setSck(socket);
    sck?.on("message receive", (data) => {
      setChat(data.chat);

      if (
        data.chat.messages[data.chat.messages.length - 1].recipient === user._id
      ) {
        dispatch(chatActions.setNewMessageReceived({ value: true }));
      }
    });
  }, [sck, user._id, dispatch]);

  //CHAT ENDED
  useEffect(() => {
    if (sck) setSck(socket);
    sck?.on("chat ended", (data) => {
      if (location.pathname.split("/")[1] === "messages") {
        setRefreshChat(true);
      }
    });
  }, [sck, dispatch, location.pathname]);

  //CAHT ADD
  useEffect(() => {
    if (!chat) return;

    if (chat && chat._id) {
      dispatch(chatActions.setUpdateChat({ data: chat, userId: user._id }));
    }
  }, [dispatch, chat, user._id]);

  //USER TYPING TO YOU
  // useEffect(() => {
  //   if (sck) setSck(socket);
  //   sck?.on("typing", (data) => {
  //     dispatch(
  //       chatActions.setCurrentTyping({
  //         userId: data.userId,
  //         articleId: data.articleId,
  //       })
  //     );
  //   });
  // }, [dispatch, sck]);

  // useEffect(() => {
  //   if (sck) setSck(socket);
  //   sck?.on("stopped typing", (data) => {
  //     dispatch(
  //       chatActions.removeCurrentTyping({
  //         userId: data.userId,
  //         articleId: data.articleId,
  //       })
  //     );
  //   });
  // }, [dispatch, sck]);

  return { refreshChat, setRefreshChat, sck, setCanConnect };
};

export default useSocket;
