import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let URL;

URL = process.env.REACT_APP_URL;

const initialReportState = {
  errorReport: false,
  messageReport: false,
  articlesReports: {
    reports: [],
    totalNumOfReports: 0,
    currentPage: 1,
  },
  usersReports: {
    reports: [],
    totalNumOfReports: 0,
    currentPage: 1,
  },
  chatsReports: {
    reports: [],
    totalNumOfReports: 0,
    currentPage: 1,
  },
};

const reportSlice = createSlice({
  name: "report",
  initialState: initialReportState,
  reducers: {
    setArticlesReports(state, action) {
      const newArticlesReports = { ...state.articlesReports };
      if (action.payload.data.reports) {
        newArticlesReports.reports = action.payload.data.reports.data;
      }

      if (action.payload.data.totalNumOfReports) {
        newArticlesReports.totalNumOfReports =
          action.payload.data.totalNumOfReports;
      }

      if (action.payload.data.currentPage) {
        newArticlesReports.currentPage = action.payload.data.currentPage;
      }

      state.articlesReports = newArticlesReports;
    },
    setUsersReports(state, action) {
      const newUsersReports = { ...state.usersReports };
      if (action.payload.data.reports) {
        newUsersReports.reports = action.payload.data.reports.data;
      }

      if (action.payload.data.totalNumOfReports) {
        newUsersReports.totalNumOfReports =
          action.payload.data.totalNumOfReports;
      }

      if (action.payload.data.currentPage) {
        newUsersReports.currentPage = action.payload.data.currentPage;
      }

      state.usersReports = newUsersReports;
    },
    setChatsReports(state, action) {
      const newChatsReports = { ...state.chatsReports };
      if (action.payload.data.reports) {
        newChatsReports.reports = action.payload.data.reports.data;
      }

      if (action.payload.data.totalNumOfReports) {
        newChatsReports.totalNumOfReports =
          action.payload.data.totalNumOfReports;
      }

      if (action.payload.data.currentPage) {
        newChatsReports.currentPage = action.payload.data.currentPage;
      }

      state.chatsReports = newChatsReports;
    },
    setMessageReport(state, action) {
      state.messageReport = action.payload.value;
    },
    setErrorReport(state, action) {
      state.errorReport = action.payload.value;
    },
    updateReportList(state, action) {
      let type = action.payload.data.reportType;
      let copyOfReport;

      if (type === "article")
        copyOfReport = {
          currentPage: state.articlesReports.currentPage,
          reports: [...state.articlesReports.reports],
          totalNumOfReports: state.articlesReports.totalNumOfReports,
        };
      if (type === "user")
        copyOfReport = {
          currentPage: state.usersReports.currentPage,
          reports: [...state.usersReports.reports],
          totalNumOfReports: state.usersReports.totalNumOfReports,
        };
      if (type === "chat")
        copyOfReport = {
          currentPage: state.chatsReports.currentPage,
          reports: [...state.chatsReports.reports],
          totalNumOfReports: state.chatsReports.totalNumOfReports,
        };

      let _reportsList = [];
      copyOfReport.reports.forEach((r) => {
        r._id === action.payload.data._id
          ? _reportsList.push(action.payload.data)
          : _reportsList.push(r);
      });

      copyOfReport.reports = _reportsList;

      if (type === "article") state.articlesReports = copyOfReport;
      if (type === "user") state.usersReports = copyOfReport;
      if (type === "chat") state.chatsReports = copyOfReport;
    },
  },
});

export const getReports = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/reports${userInputs.queryString}`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        const data = {
          reports: response.data.data.reports,
          totalNumOfReports: response.data.data.totalAmountOfReports,
        };
        if (userInputs.reportType === "article") {
          dispatch(reportSlice.actions.setArticlesReports({ data: data }));
        }
        if (userInputs.reportType === "user") {
          dispatch(reportSlice.actions.setUsersReports({ data: data }));
        }
        if (userInputs.reportType === "chat") {
          dispatch(reportSlice.actions.setChatsReports({ data: data }));
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          reportSlice.actions.setErrorReport({
            value: err.response.data.message,
          })
        );
      }
    }
  };
};

export const reportArticle = (userInputs, token, showReportModal) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "POST",
        url: `${URL}/reports/create`,
        data: userInputs,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          reportSlice.actions.setMessageReport({
            value: `You reported ${userInputs.reportType} (${
              userInputs.articleId || userInputs.userId || userInputs.chatId
            }) successfully.`,
          })
        );
        showReportModal(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          reportSlice.actions.setErrorReport({
            value: err.response.data.message,
          })
        );
      }
    }
  };
};

export const approveReport = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/reports/approve/${userInputs.reportId}`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        //SPASI IZMJENE REPORTOVA
        dispatch(
          reportSlice.actions.updateReportList({ data: response.data.data })
        );
        //POSTAVITI DA SE REFRESHAJU ARTIKLI MOZDA!!!
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          reportSlice.actions.setErrorReport({
            value: err.response.data.message,
          })
        );
      }
    }
  };
};

export const rejectReport = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/reports/reject/${userInputs.reportId}`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        //SPASI IZMJENE REPORTOVA
        dispatch(
          reportSlice.actions.updateReportList({ data: response.data.data })
        );
        //POSTAVITI DA SE REFRESHAJU ARTIKLI MOZDA!!!
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          reportSlice.actions.setErrorReport({
            value: err.response.data.message,
          })
        );
      }
    }
  };
};

export default reportSlice;
