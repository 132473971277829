import React, { Fragment } from "react";

import classes from "./AditionalDetails.module.css";

import { useTranslation } from "../../TranslationContext";

import { MdCheckBox } from "react-icons/md";

const SingleDetail = (props) => {
  const cbFields = ["garage", "elevator", "balcony"];
  return (
    <Fragment>
      {props.data.value ? (
        <div className={classes["single__detail-container"]}>
          <p className={classes["single__detail-key"]}>{props.data.key}</p>
          {/* <div className={classes["single__detail-divider"]} /> */}
          {cbFields.includes(props.data.id) ? (
            <div className={classes["single__detail-icon"]}>
              <MdCheckBox />
            </div>
          ) : (
            <p className={classes["single__detail-value"]}>
              {props.data.value}
              {props.data.id === "cc" && <span> (cc)</span>}
              {props.data.id === "kw" && (
                <span> (kw) / {Math.floor(props.data.value * 1.34)} (hp)</span>
              )}
              {props.data.id === "miles" && <span> (km)</span>}
              {props.data.id === "area" && <span> (m2)</span>}
            </p>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

const AditionalDetails = (props) => {
  const { translations } = useTranslation();
  const keys = Object.keys(props.data);
  const _detailsRenderer = keys.map((k) => (
    <SingleDetail
      key={k}
      data={{
        id: k,
        key: translations[`msg_aditional_details_${k}`],
        value: props.data[k],
      }}
    />
  ));
  return (
    <div className={classes.container}>
      <h5>{translations.msg_articledetails_other || "Other"}:</h5>
      <div className={classes["details-list"]}>{_detailsRenderer}</div>
    </div>
  );
};

export default AditionalDetails;
