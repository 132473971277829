import { useEffect, useState } from "react";

import { useTranslation } from "../TranslationContext";

import {
  engine as engineList,
  transmition as transmitionList,
  getYears,
  miles as milesList,
  doors as doorsList,
  colors as colorsList,
} from "../utils/vehicle-filters";

const _getYears = getYears();

const useVehicleTranslations = () => {
  const { translations } = useTranslation();
  const [listOfYears, setListOfYears] = useState([]);
  const [miles, setMiles] = useState([]);
  const [engine, setEngine] = useState([]);
  const [transmition, setTransmition] = useState([]);
  const [engineForm, setEngineForm] = useState([]);
  const [transmitionForm, setTransmitionForm] = useState([]);
  const [doors, setDoors] = useState([]);
  const [doorsForm, setDoorsForm] = useState([]);
  const [carColor, setCarColor] = useState([]);
  const [carColorForm, setCarColorForm] = useState([]);

  useEffect(() => {
    // ENGINES
    let _translatedENGINES = [];
    _translatedENGINES.push({ value: 0, label: translations["all"] });
    engineList.forEach((e) =>
      _translatedENGINES.push({
        value: e.value,
        label: translations[`v_engine_${e.value}`],
      })
    );
    setEngine(_translatedENGINES);

    let _translatedENGINESFORM = [];
    engineList.forEach((e) =>
      _translatedENGINESFORM.push({
        value: e.value,
        label: translations[`v_engine_${e.value}`],
      })
    );
    setEngineForm(_translatedENGINESFORM);

    // MILES
    let _translatedMILES = [];
    _translatedMILES.push({ value: 0, label: translations["all"] });
    milesList.forEach((e) =>
      _translatedMILES.push({ value: e.value, label: e.label })
    );
    setMiles(_translatedMILES);

    // YEARS
    let _translatedYEARS = [];
    _translatedYEARS.push({ value: 0, label: translations["all"] });
    _getYears.forEach((y, i) =>
      _translatedYEARS.push({ value: i + 1, label: y })
    );
    setListOfYears(_translatedYEARS);

    // DOORS
    let _translatedDOORS = [];
    _translatedDOORS.push({ value: 0, label: translations["all"] });
    doorsList.forEach((d) =>
      _translatedDOORS.push({ value: d.value, label: d.label })
    );
    setDoors(_translatedDOORS);

    let _translatedDOORSFORM = [];
    doorsList.forEach((d) =>
      _translatedDOORSFORM.push({ value: d.value, label: d.label })
    );
    setDoorsForm(_translatedDOORSFORM);

    // DOORS
    let _translatedCOLOR = [];
    _translatedCOLOR.push({ value: 0, label: translations["all"] });
    colorsList.forEach((c) =>
      _translatedCOLOR.push({
        value: c.value,
        label: translations[`v_color_${c.value}`],
      })
    );
    setCarColor(_translatedCOLOR);

    let _translatedCOLORFORM = [];
    colorsList.forEach((c) =>
      _translatedCOLORFORM.push({
        value: c.value,
        label: translations[`v_color_${c.value}`],
      })
    );
    setCarColorForm(_translatedCOLORFORM);

    // TRANSMITIONS
    let _translatedTRANSMITIONS = [];
    _translatedTRANSMITIONS.push({ value: 0, label: translations["all"] });
    transmitionList.forEach((t) =>
      _translatedTRANSMITIONS.push({
        value: t.value,
        label: translations[`v_transmition_${t.value}`],
      })
    );
    setTransmition(_translatedTRANSMITIONS);

    let _translatedTRANSMITIONSFORM = [];
    transmitionList.forEach((t) =>
      _translatedTRANSMITIONSFORM.push({
        value: t.value,
        label: translations[`v_transmition_${t.value}`],
      })
    );
    setTransmitionForm(_translatedTRANSMITIONSFORM);
  }, [translations]);

  return {
    listOfYears,
    miles,
    engine,
    transmition,
    engineForm,
    transmitionForm,
    doors,
    doorsForm,
    carColor,
    carColorForm,
  };
};

export default useVehicleTranslations;
