import React from "react";

import classes from "./Error.module.css";

const Error = (props) => {
  return (
    props.data.error && (
      <div className={classes.container}>{props.data.error}</div>
    )
  );
};

export default Error;
