import React from "react";

import classes from "./EndChat.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const EndChat = (props) => {
  const { translations } = useTranslation();

  return (
    <div className={classes["end-chat"]}>
      <h4>{translations.end_chat_title || "End Chat"}</h4>
      <p>{translations.end_chat_question || "Do you want to end this chat?"}</p>
      <div className={classes["end-chat__btns"]}>
        <p onClick={props.onClick}>{translations.end_chat_end || "End"}</p>
        <p onClick={props.cancel}>{translations.end_chat_cancel || "Cancel"}</p>
      </div>
    </div>
  );
};

export default EndChat;
