import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./ArticlesContainer.module.css";

//STORE
import { getReports } from "../../store/reducers/report-slice";
import { reportActions } from "../../store/redux-store";

//COMPONENTS
import SingleReport from "./single-report/SingleReport";
import Pagination from "../../UI/paggination/Pagination";

const NUM_OF_REPORTS_PER_PAGE = 10;

const ArticlesContainer = (props) => {
  const dispatch = useDispatch();
  const { articlesReports } = useSelector((state) => state.report);
  const { token, isAuthenticated } = useSelector((state) => state.auth);
  const [_reportsRenderer, _setReportsRenderer] = useState([]);

  useEffect(() => {
    if ((token, isAuthenticated)) {
      dispatch(
        getReports(
          {
            queryString: `?reportType=article&page=${articlesReports.currentPage}&limit=${NUM_OF_REPORTS_PER_PAGE}`,
            reportType: "article",
          },
          token
        )
      );
    }
  }, [dispatch, articlesReports.currentPage, isAuthenticated, token]);

  useEffect(() => {
    const _reportsList = articlesReports.reports.map((r) => {
      return <SingleReport data={r} key={r._id} />;
    });
    _setReportsRenderer(_reportsList);
  }, [articlesReports.reports]);

  const firstPageHandler = (e) => {
    e.preventDefault();
    dispatch(reportActions.setArticlesReports({ data: { currentPage: 1 } }));
  };

  const lastPageHandler = (e) => {
    e.preventDefault();
    dispatch(
      reportActions.setArticlesReports({
        data: {
          currentPage: Math.ceil(
            articlesReports.totalNumOfReports / NUM_OF_REPORTS_PER_PAGE
          ),
        },
      })
    );
  };

  const pageHandler = (e) => {
    e.preventDefault();
    dispatch(
      reportActions.setArticlesReports({ data: { currentPage: e.target.id } })
    );
  };

  return (
    <div className={classes.container}>
      <h4>Article reports</h4>
      <p>{`There is currently ${articlesReports.totalNumOfReports} reports.`}</p>
      <div className={classes.reports}>{_reportsRenderer}</div>
      <Pagination
        style={{ alignSelf: "center" }}
        currentPage={articlesReports.currentPage}
        numOfPages={Math.ceil(
          articlesReports.totalNumOfReports / NUM_OF_REPORTS_PER_PAGE
        )}
        firstPageHandler={firstPageHandler}
        lastPageHandler={lastPageHandler}
        pageHandler={pageHandler}
      />
    </div>
  );
};

export default ArticlesContainer;
