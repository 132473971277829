import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../TranslationContext";

import classes from "./UserCard.module.css";
import { Link } from "react-router-dom";

import { formatLastOnline } from "../../helper/create-date-user-online";

import { MdOutlineOutlinedFlag } from "react-icons/md";

const IMAGE_URL_PATH = process.env.REACT_APP_IMAGE_URL;

const UserCard = (props) => {
  const navigate = useNavigate();
  const { translations } = useTranslation();
  const { article } = useSelector((state) => state.article);
  const { user, token, isAuthenticated } = useSelector((user) => user.auth);
  const [userIsOnline, setUserIsOnline] = useState(false);
  const [userActiveText, setUserActiveText] = useState("");

  const showPhoneHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token) navigate("/auth/login");
    if (article.creator.phoneVisible) {
      props.show.phoneModal();
    }
  };

  const showMessageHandler = (e) => {
    e.preventDefault();
    if (!isAuthenticated || !token) navigate("/auth/login");
    if (user._id === article.creator?._id) return;
    props.show.messageModal();
  };

  useEffect(() => {
    setUserIsOnline(article.creator?.isOnline);
  }, [article]);

  useEffect(() => {
    if (!article.creator) return;
    if (userIsOnline) {
      setUserActiveText(
        translations.msg_usercard_user_online || "User is online."
      );
    }

    if (!userIsOnline && article.creator?.lastOnline) {
      const { value, unit } = formatLastOnline(
        new Date(article.creator?.lastOnline).getTime()
      );
      const timeUnitTranslation =
        translations[`msg_time_${unit}_ago`] || `${unit} ago`;
      setUserActiveText(
        `${
          translations.msg_usercard_user_was_online || "User was online"
        } ${value} ${timeUnitTranslation}.`
      );
    } else if (!userIsOnline && !article.creator?.lastOnline) {
      setUserActiveText("");
    }
  }, [userIsOnline, article, translations]);

  const reportPlayerHandler = (e) => {
    e.preventDefault();
    if (user._id !== props.data?._id) {
      props.show.reportUserModal();
    }
  };

  return (
    <Fragment>
      {props.data !== null ? (
        <div className={classes.container}>
          {user._id !== props.data?._id && (
            <div className={classes.options}>
              <div className={classes.button} onClick={reportPlayerHandler}>
                <MdOutlineOutlinedFlag
                  style={{ color: "var(--flag-article)" }}
                />
                <p className={classes["report-text"]}>
                  {translations.report_report_user || "Report User"}
                </p>
              </div>
            </div>
          )}
          <div className={classes.header}>
            <img
              src={`${IMAGE_URL_PATH}/users/${props.data?.image}`}
              alt={props.data?.image}
              className={classes.img}
            />
            <div className={classes.details}>
              <Link to={`/user/${props.data._id}`} className={classes.username}>
                {props.data?.username || "Unknown"}
              </Link>
              <p>{userActiveText || "User does not exit anymore!"}</p>
            </div>
          </div>
          {props.data?.isCompany && props.data?.website && (
            <a
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={
                props.data?.website.includes("http")
                  ? `${props.data?.website}`
                  : `https://${props.data?.website}`
              }
              className={classes.link}
            >
              <span>{translations.msg_usercard_website || "Website"}: </span>
              {props.data?.website}
            </a>
          )}
          <div className={classes.contact}>
            <div
              onClick={showPhoneHandler}
              className={`${classes.btn} ${
                !props.data?.phoneVisible && classes.inactive
              }`}
              style={{
                borderTopLeftRadius: "0.6rem",
                borderBottomLeftRadius: "0.6rem",
              }}
            >
              {translations.msg_usercard_phone || "Phone"}
            </div>
            <div
              onClick={showMessageHandler}
              className={classes.btn}
              style={{
                borderTopRightRadius: "0.6rem",
                borderBottomRightRadius: "0.6rem",
              }}
            >
              {translations.msg_usercard_message || "Message"}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.header}>
            {/* <img
              src={`${IMAGE_URL_PATH}/users/default.jpeg`}
              alt={props.data?.image}
              className={classes.img}
            /> */}
            <div className={classes.details}>
              <p
                style={{
                  fontSize: "1.6rem",
                }}
              >
                {"User does not exit anymore!"}
              </p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UserCard;
