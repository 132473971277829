import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";

import classes from "./HeaderTandM.module.css";

import { MdOutlineMenu, MdClear, MdLogout } from "react-icons/md";
import { FiPlus, FiMinus } from "react-icons/fi";
import { IoMdChatboxes } from "react-icons/io";

//STORE
import { logout } from "../../../store/reducers/auth-store";

//COMPONENTS
import Logo from "../Logo";
import SingleNav from "./SingleNav";
import LoginBtn from "./LoginBtn";
import FlagIcon from "./FlagIcon";

//CONTEXTS
import { useTranslation } from "../../../TranslationContext";

const HeaderTandM = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, isAuthenticated, hasUnreadMessages } = useSelector(
    (state) => state.auth
  );
  const { newMessageReceived } = useSelector((state) => state.chat);
  const [expandMenu, setExpandMenu] = useState(false);
  const [expandProfile, setExpandProfile] = useState(false);
  const { language, setLanguage, translations } = useTranslation();

  const expandMenuHandler = (e) => {
    e.preventDefault();
    setExpandMenu(true);
  };

  const colapseMenuHandler = (e) => {
    e.preventDefault();
    setExpandMenu(false);
    setExpandProfile(false);
  };

  const toggleExpandProfileHandler = (e) => {
    e.preventDefault();
    setExpandProfile(!expandProfile);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    props.socket.disconnect();
    setExpandMenu(false);
    setExpandProfile(false);
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  return (
    <div className={classes.container}>
      {expandMenu && (
        <div className={classes.opacity} onClick={colapseMenuHandler} />
      )}
      <Logo onClick={props.onClickLogo} />
      <div className={`${classes.navs}`}>
        <Link
          to={isAuthenticated && user._id ? "/messages" : "/auth/login"}
          className={classes["menu-button"]}
        >
          <IoMdChatboxes className={classes.icon} />
          {(newMessageReceived || hasUnreadMessages) && (
            <div className={classes.msg} />
          )}
        </Link>
        <div className={classes["menu-button"]} onClick={expandMenuHandler}>
          <MdOutlineMenu className={classes.icon} />
        </div>
      </div>
      {expandMenu && (
        <div className={`${classes["nav__links"]}`}>
          <div className={classes["close-box"]}>
            <div
              className={classes["menu-button"]}
              onClick={colapseMenuHandler}
            >
              <MdClear className={classes.icon} />
            </div>
          </div>
          <SingleNav
            expandMenu={setExpandMenu}
            expandProfile={setExpandProfile}
            text={translations.msg_header_terms_of_use || "Terms of Use"}
            to={"/terms-of-use#about-us"}
          />
          <SingleNav
            expandMenu={setExpandMenu}
            expandProfile={setExpandProfile}
            text={translations.msg_header_create_ad || "Inserat aufgeben"}
            to={
              isAuthenticated && user._id
                ? "/articles/create-article"
                : "/auth/login"
            }
          />
          <SingleNav
            expandMenu={setExpandMenu}
            expandProfile={setExpandProfile}
            text={translations.msg_header_messages || "Messages"}
            to={isAuthenticated && user._id ? "/messages" : "/auth/login"}
          />
          <SingleNav
            expandMenu={setExpandMenu}
            expandProfile={setExpandProfile}
            text={translations.msg_header_my_ads || "Meine Inserate"}
            to={
              isAuthenticated && user._id
                ? "/userArticles/active"
                : "/auth/login"
            }
          />
          <SingleNav
            expandMenu={setExpandMenu}
            expandProfile={setExpandProfile}
            text={`${
              translations.msg_userdropdown_my_profile || "Mein Profil"
            }`}
            to={isAuthenticated && user._id ? "/profile" : "/auth/login"}
          />
          {user.role === "admin" && (
            <SingleNav
              expandMenu={setExpandMenu}
              expandProfile={setExpandProfile}
              text={`${
                translations.msg_userdropdown_admin_panel || "Admin Panel"
              }`}
              to={
                isAuthenticated && user._id && user.role === "admin"
                  ? "/admin/general"
                  : "/auth/login"
              }
            />
          )}
          {isAuthenticated && user._id && (
            <div
              className={`${classes["single__nav"]} ${classes.logout}`}
              onClick={handleLogout}
            >
              <MdLogout
                className={`${classes["logout-icon"]} ${classes["menu-icons"]}`}
              />
              <p>{translations.msg_userdropdown_logout || "Log out"}</p>
            </div>
          )}
          {!isAuthenticated && (
            <LoginBtn
              expandMenu={setExpandMenu}
              expandProfile={setExpandProfile}
              text={`${translations.msg_header_login || "Login / Signup"}`}
              to={"/auth/login"}
            />
          )}
          <div
            className={`${classes["single__nav"]} ${classes["single__nav-last"]}`}
          >
            <p>{"Languages:"}</p>
            <div className={classes["languages"]}>
              <FlagIcon
                colors={{
                  first: "#231f20",
                  second: "#e32119",
                  third: "#ffd300",
                }}
                lang="de"
                onClick={() => {
                  setLanguage("de");
                }}
                style={{ flexDirection: "column" }}
              />
              <FlagIcon
                colors={{
                  first: "#002654",
                  second: "#ffffff",
                  third: "#ed2939",
                }}
                lang="fr"
                onClick={() => {
                  setLanguage("fr");
                }}
                style={{ flexDirection: "row" }}
              />
              <FlagIcon
                colors={{
                  first: "#008c45",
                  second: "#ffffff",
                  third: "#cd212a",
                }}
                lang="it"
                onClick={() => {
                  setLanguage("it");
                }}
                style={{ flexDirection: "row" }}
              />
              <FlagIcon
                colors={{
                  first: "blue",
                  second: "blue",
                  third: "yellow",
                }}
                lang="ba"
                onClick={() => {
                  setLanguage("ba");
                }}
                style={{ flexDirection: "column" }}
              />
              <FlagIcon
                colors={{
                  first: "blue",
                  second: "blue",
                  third: "red",
                }}
                lang="en"
                onClick={() => {
                  setLanguage("en");
                }}
                style={{ flexDirection: "column" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderTandM;
