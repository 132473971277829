import React from "react";

import classes from "./Message.module.css";

const Message = (props) => {
  return (
    props.data.message && (
      <div
        className={classes.container}
        // style={props.data.error ? { top: "20%" } : { top: "10%" }}
      >
        {props.data.message}
      </div>
    )
  );
};

export default Message;
