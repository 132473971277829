import React from "react";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./SecondForm.module.css";

// COMPONENTS
import SelectInput from "../../UI/SelectInput";
import NumberInput from "../../UI/NumberInput";
import CheckboxInput from "../../UI/CheckboxInput";

const SecondForm = (props) => {
  const { translations } = useTranslation(); // Use translations
  const {
    _listOfCities,
    cityRef,
    zipInput,
    priceInput,
    stateInput,
    pickedCityHandler,
    zipHandler,
    priceHandler,
    changeStateHandler,
    checkZipValidation,
    checkPriceValidation,
    zipError,
    priceError,
  } = props.data;
  
  return (
    <div className={classes.container}>
      <h3>{translations.msg_secondform_address_price || "Address / Price"}</h3>
      <SelectInput
        data={{
          name: translations.msg_secondform_city || "City",
          options: _listOfCities,
          isSearchable: true,
          ref: cityRef,
          defaultValue: _listOfCities[0],
        }}
        onChange={pickedCityHandler}
      />
      <NumberInput
        data={{
          name: translations.msg_secondform_zip || "Zip",
          error: zipError,
          value: zipInput,
        }}
        onChange={zipHandler}
        onKeyDown={props.onKeyDown}
        onBlur={checkZipValidation}
      />
      <NumberInput
        data={{
          name: translations.msg_secondform_price || "Price",
          error: priceError,
          value: priceInput,
        }}
        onChange={priceHandler}
        onKeyDown={props.onKeyDown}
        onBlur={checkPriceValidation}
      />
      <div className={classes.checkbox}>
        <p>{translations.msg_secondform_article_status || "Article status"}</p>
        {/* <CheckboxInput
          data={{
            name: "state",
            value: stateInput,
            description: stateInput
              ? translations.msg_secondform_article_new || "Article is new."
              : translations.msg_secondform_article_used || "Article is used.",
          }}
          onClick={changeStateHandler}
        /> */}
        <div
          className={classes["article__status"]}
          onClick={changeStateHandler}
        >
          <div
            className={classes["article__status-slider"]}
            style={{
              transform: `${
                stateInput ? "translateX(-50%)" : "translateX(50%)"
              }`,
            }}
          />
          <p
            className={classes["article__status-option"]}
            style={{
              color: `var(--${stateInput ? "white" : "text-color"}) !important`,
            }}
          >
            {translations.msg_secondform_article_new || "New"}
          </p>
          <p
            className={classes["article__status-option"]}
            style={{
              color: `var(--${
                !stateInput ? "white" : "text-color"
              }) !important`,
            }}
          >
            {translations.msg_secondform_article_used || "Used"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecondForm;
