import React from "react";
import { Link } from "react-router-dom";

import classes from "./RedirectToLogin.module.css";

const RedirectToLogin = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h4>{"OVDJE STAVI PRIJEVOD - Login to check you messages!"}</h4>
        <Link className={classes.btn} to={"/auth/login"}>
          <p>{"I OVDJE PREVOD - Go To Login"}</p>
        </Link>
      </div>
    </div>
  );
};

export default RedirectToLogin;
