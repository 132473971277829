import React from "react";
import { useDispatch } from "react-redux";
import classes from "./FilterButtons.module.css";

//STORE
// import { articleActions } from "../../../../store/redux-store";

const TestGetArticles = (props) => {
  // const dispatch = useDispatch();

  // const resetFiltersHandler = (e) => {
  //   e.preventDefault();

  //   dispatch(articleActions.resetSearchFilters());
  // };

  return (
    <div className={classes["buttons-container"]}>
      {/* <button
        className={classes["button"]}
        type="button"
        onClick={resetFiltersHandler}
      >
        Clear Filters
      </button> */}
      <button className={classes["button"]} type="submit">
        Suche
      </button>
    </div>
  );
};

export default TestGetArticles;
