import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./ProfileHeader.module.css";

//COMPONENTS
import HeaderStats from "./HeaderStats";

import { RiImageAddFill } from "react-icons/ri";

const imageURL_PATH = `${process.env.REACT_APP_USER_LOGOS}`;

const ProfileHeader = (props) => {
  const { user } = useSelector((state) => state.auth);
  const [image, setImage] = useState("");
  const [showImageInput, setShowImageInput] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(false);

  useEffect(() => {
    if (user.image) {
      setImage(user.image);
    }
  }, [user.image]);

  const showAddImageInputHandler = (e) => {
    setShowImageInput(true);
  };
  const hideAddImageInputHandler = (e) => {
    setShowImageInput(false);
  };

  //CHANGE IMAGE HANDLER
  useEffect(() => {
    if (props.data.image.get) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPhotoPreview(fileReader.result);
      };
      fileReader.readAsDataURL(props.data.image.get);
    }
  }, [props.data.image.get]);

  const uploadImageHandler = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      props.data.image.set(e.target.files[0]);
      return;
    }
  };

  return (
    <div className={classes.container}>
      {/* <h4>{user.username}</h4> */}
      <div className={classes.body}>
        <div
          className={classes["image-container"]}
          onMouseOver={showAddImageInputHandler}
          onMouseLeave={hideAddImageInputHandler}
        >
          {photoPreview ? (
            <img src={photoPreview} alt={user.username} />
          ) : (
            <img
              src={`${imageURL_PATH}/${image}`}
              alt={`${props.data.username.get}`}
            />
          )}
          {showImageInput && (
            <div className={classes["image-overlay"]}>
              <label htmlFor="image">
                <RiImageAddFill />
                <p>Add New Image</p>
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept=".jpg,.jpeg,.png"
                onChange={uploadImageHandler}
              />
            </div>
          )}
        </div>
        <HeaderStats data={props.data} />
      </div>
    </div>
  );
};

export default ProfileHeader;
