import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "../TranslationContext"; // Import useTranslation

import {
  createNewArticle,
  updateArticle,
} from "../store/reducers/article-slice";

// STORE
import { articleActions } from "../store/redux-store";

// UTILS
import cities from "../utils/cities";
import categories from "../utils/categories";
import cars from "../utils/cars";
import { engine, transmition, getYears } from "../utils/vehicle-filters";

// COMPONENTS
import { SingleImage } from "../components/article-form/ImagesUpload";
import FetchedSingleImage from "../components/article-form/FetchedSingleImage";

// CUSTOM HOOKS
import useValidator from "./useValidator";

const useArticleForm = () => {
  const validate = useValidator();
  const { translations } = useTranslation(); // Use translations
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const { article } = useSelector((state) => state.article);

  const [formType, setFormType] = useState(null);
  const [canSetSelectFields, setCanSetSelectFields] = useState(false);

  // REF
  const categoryRef = useRef(null);
  const subCategoryRef = useRef(null);
  const cityRef = useRef(null);
  const carcompanyRef = useRef(null);
  const carmodelRef = useRef(null);
  const engineRef = useRef(null);
  const transmitionRef = useRef(null);
  const yearRef = useRef(null);
  const doorsRef = useRef(null);
  const carColorRef = useRef(null);

  const [category, setCategory] = useState({
    value: categories[1].index,
    label: categories[1].name,
  });
  const [subCategory, setSubCategory] = useState({
    value: 0,
    label: translations.msg_none || "None",
  });
  const [subCategories, setSubCategories] = useState([
    { value: 0, label: translations.msg_none || "None" },
  ]);
  const [titleInput, setTitleInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [cityInput, setCityInput] = useState({
    value: cities[0],
    label: cities[0],
  });
  const [zipInput, setZipInput] = useState("");
  const [priceInput, setPriceInput] = useState("");
  const [stateInput, setStateInput] = useState(false);
  // ERRORS
  const [titleError, setTitleError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [zipError, setZipError] = useState(null);
  const [priceError, setPriceError] = useState(null);

  // const numberValidation = ``

  // LISTS FOR SELECT
  let _categoriesRenderer = [];
  categories.forEach((c) => {
    if (c.name !== "Alle") {
      _categoriesRenderer.push({ value: c.index, label: c.name });
    }
  });

  const _listOfCities = cities.map((city, i) => {
    return { value: city, label: city };
  });

  const _carCompanies = [];
  cars.forEach((c) => {
    if (c.name === "Alle") return;
    _carCompanies.push({ value: c.index, label: c.name });
  });

  const _engineList = [];
  engine.forEach(
    (e) => e !== "Alle" && _engineList.push({ value: e, label: e })
  );

  const _transmitionList = [];
  transmition.forEach(
    (t) => t !== "Alle" && _transmitionList.push({ value: t, label: t })
  );

  const _yearsList = [];
  const yearsGenerated = getYears();
  yearsGenerated.forEach((y, i) => _yearsList.push({ value: y, label: y }));

  /////////////////////////////////////////////////////////////////////////////
  // CATEGORY AND SUBCATEGORIES LOGIC
  /////////////////////////////////////////////////////////////////////////////
  const getCategory = useCallback(() => {
    if (!category?.value) return;
    return categories?.find((c) => c.index === category.value);
  }, [category?.value]);

  const setCategoryHandler = (value) => {
    if (!value) return;
    setCategory(value);
  };

  const setSubCategoryHandler = (value) => {
    if (!value) return;
    setSubCategory(value);
  };

  useEffect(() => {
    if (!translations) return;
    let subCatList = [];
    let currCat = getCategory();
    if (!subCategoryRef.current) return;

    if (currCat && currCat.subcategories.length > 0) {
      currCat.subcategories.length > 0 &&
        currCat.subcategories.forEach((sc) => {
          // subCatList.push({ value: sc.index, label: sc.name });
          subCatList.push({
            value: sc.index,
            label: translations[`subcat_${currCat.index}_${sc.index}`],
          });
        });
    }

    if (currCat && currCat.subcategories.length === 0) {
      subCatList.push({ value: 0, label: "" });
    }

    setSubCategories(subCatList);
    subCategoryRef.current.setValue(subCatList[0]);
  }, [category, getCategory, translations]);

  //////////////////////////////////////////////////////////////////////
  // TITLE // //////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const TITLE_LIMIT_MIN = 3;
  const TITLE_LIMIT_MAX = 90;
  const titleHandler = (e) => {
    e.preventDefault();
    setTitleInput(e.target.value);
  };

  const checkTitleValidation = (e) => {
    e.preventDefault();
    validate.textValidate(titleInput, setTitleError, "Title", {
      min: TITLE_LIMIT_MIN,
      max: TITLE_LIMIT_MAX,
    });
  };

  //////////////////////////////////////////////////////////////////////
  // DESCRIPTION ///////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const DESCRIPTION_LIMIT_MAX = 5000;
  const descriptionHandler = (e) => {
    e.preventDefault();

    if (descriptionInput.length < DESCRIPTION_LIMIT_MAX) {
      setDescriptionInput(e.target.value);
    }
    if (
      descriptionInput.length >= DESCRIPTION_LIMIT_MAX &&
      e.target.value.length < descriptionInput.length
    ) {
      setDescriptionInput(e.target.value);
    }
  };

  const checkDescriptionValidation = (e) => {
    e.preventDefault();
    validate.textValidate(
      descriptionInput,
      setDescriptionError,
      "Description",
      {
        max: DESCRIPTION_LIMIT_MAX,
      }
    );
  };
  //////////////////////////////////////////////////////////////////////
  // CITY //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const pickedCityHandler = (value) => {
    setCityInput(value);
  };

  //////////////////////////////////////////////////////////////////////
  // ZIP ///////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const zipHandler = (e) => {
    e.preventDefault();
    let zip;

    if (`${e.target.value}`.includes(".")) {
      let splited = `${e.target.value}`.split(".");
      let joined = Number(splited.join(""));
      zip = joined;
    } else {
      zip = e.target.value;
    }

    if (`${zip}`.includes("-")) {
      let splited = `${zip}`.split("-");
      let joined = Number(splited.join(""));
      zip = joined;
    }

    setZipInput(zip);
  };

  const checkZipValidation = (e) => {
    validate.numberValidate(
      zipInput,
      setZipError,
      translations.msg_real_estate_zip,
      {
        min: 1000,
        max: 9999,
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // PRICE /////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  const priceHandler = (e) => {
    e.preventDefault();
    let price;

    if (`${e.target.value}`.includes(".")) {
      let splited = `${e.target.value}`.split(".");
      let joined = Number(splited.join(""));
      price = joined;
    } else {
      price = e.target.value;
    }

    if (`${price}`.includes("-")) {
      let splited = `${price}`.split("-");
      let joined = Number(splited.join(""));
      price = joined;
    }

    setPriceInput(price);
  };

  const checkPriceValidation = (e) => {
    validate.numberValidate(
      priceInput,
      setPriceError,
      translations.msg_real_estate_price
    );
  };

  /////////////////////////////
  // STATE ////////////////////
  /////////////////////////////

  const changeStateHandler = (e) => {
    e.preventDefault();
    setStateInput(!stateInput);
  };

  /////////////////////////////
  // IMAGES ///////////////////
  /////////////////////////////

  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState("");

  const [imagesForUpload, setImagesForUpload] = useState([]);
  const [imagesPreviewURL, setImagesPreviewURL] = useState([]);
  const [imagesNames, setImagesNames] = useState([]);
  const [imagesPREV, setImagesPREV] = useState([]);

  useEffect(() => {
    setImagesPREV([]);

    imagesPreviewURL.forEach((img, i) => {
      setImagesPREV((oldState) => [
        ...oldState,
        <SingleImage
          mainImage={mainImage}
          setMainImage={setMainImage}
          imagesForUpload={imagesForUpload}
          setImagesForUpload={setImagesForUpload}
          imagesPreviewURL={imagesPreviewURL}
          setImagesPreviewURL={setImagesPreviewURL}
          imagesNames={imagesNames}
          setImagesNames={setImagesNames}
          key={i}
          id={i}
          name={img.name}
          src={img.img}
        />,
      ]);
    });
  }, [imagesPreviewURL, imagesForUpload, imagesNames, mainImage]);

  useEffect(() => {
    setImages(imagesForUpload);
  }, [imagesForUpload]);

  // VEHICLE /////////////////////////////////////////////////////
  // VEHICLE /////////////////////////////////////////////////////
  // VEHICLE /////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // CC //////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  const [ccInput, setCcInput] = useState("");
  const [kwInput, setKwInput] = useState("");
  const [carcompany, setCarcompany] = useState(_carCompanies[0]);
  const [carmodelsList, setCarmodelsList] = useState([]);
  const [carmodel, setCarmodel] = useState(null);
  const [engineInput, setEngineInput] = useState({ value: 1, label: "" });
  const [transmitionInput, setTransmitionInput] = useState({
    value: 1,
    label: "",
  });
  const [yearInput, setYearInput] = useState(_yearsList[0]);
  const [milesInput, setMilesInput] = useState("");
  const [carDoorsInput, setCarDoorsInput] = useState({ value: 1, label: "" });
  const [carColorInput, setCarColorInput] = useState({ value: 1, label: "" });
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedImgsRenderer, setFetchedImgsRenderer] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  // ERROR
  const [ccError, setCcError] = useState(null);
  const [kwError, setKwError] = useState(null);
  const [milesError, setMilesError] = useState(null);

  const changeCcHandler = (e) => {
    e.preventDefault();
    setCcInput(e.target.value);
  };

  const checkCcValidation = (e) => {
    validate.numberValidate(
      e.target.value,
      setCcError,
      translations.msg_real_estate_cc
    );
  };

  ////////////////////////////////////////////////////////////////
  // KW //////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  const changeKwHandler = (e) => {
    e.preventDefault();
    setKwInput(e.target.value);
  };

  const checkKwValidation = (e) => {
    validate.numberValidate(
      e.target.value,
      setKwError,
      translations.msg_real_estate_kw
    );
  };

  ////////////////////////////////////////////////////////////////
  // CARCOMPANIES AND CAR MODELS /////////////////////////////////
  ////////////////////////////////////////////////////////////////

  const changeCarcompanyHandler = (value) => {
    setCarcompany(value);
  };

  const changecarModelHandler = (value) => {
    setCarmodel(value);
  };
  // console.log(carmodel);
  const changeEngineHandler = (value) => {
    setEngineInput(value);
  };

  const changeTransmitionHandler = (value) => {
    setTransmitionInput(value);
  };

  const changeYearHandler = (value) => {
    setYearInput(value);
  };

  const getCarcompany = useCallback(() => {
    return cars.find((c) => c.index === carcompany.value);
  }, [carcompany]);

  useEffect(() => {
    if (category.value !== 6) return;
    const _filteredCarCompany = getCarcompany();

    let _carModelsList = [];
    _filteredCarCompany?.subcategories &&
      _filteredCarCompany.subcategories.forEach((cm) => {
        _carModelsList.push({ value: cm.index, label: cm.name });
      });

    if (!carmodelRef.current) return;
    setCarmodelsList(_carModelsList);
    let _carModel = carmodel ? carmodel : { value: 0, label: "" };
    _carModelsList.length > 0
      ? carmodelRef.current.setValue(_carModel)
      : // ? carmodelRef.current.setValue(_carModelsList[0])
        carmodelRef.current.setValue({ label: null, value: null });
  }, [carcompany, getCarcompany, category, carmodel]);

  ////////////////////////////////////////////////
  // MILES ///////////////////////////////////////
  ////////////////////////////////////////////////

  const changeMilesHandler = (e) => {
    setMilesInput(e.target.value);
  };

  const checkMilesValidation = (e) => {
    validate.numberValidate(
      e.target.value,
      setMilesError,
      translations.msg_real_estate_miles
    );
  };

  ////////////////////////////////////////////////
  // DOORS ///////////////////////////////////////
  ////////////////////////////////////////////////

  const changeDoorsHandler = (value) => {
    setCarDoorsInput(value);
  };

  ////////////////////////////////////////////////
  // COLOR ///////////////////////////////////////
  ////////////////////////////////////////////////

  const changeCarColorHandler = (value) => {
    setCarColorInput(value);
  };

  // REALESTATEFIELDS
  const REStatusRef = useRef(null);
  const [REStatusInput, setREStatusInput] = useState({ value: 1, label: "" });
  const [roomsInput, setRoomsInput] = useState("");
  const [areaInput, setAreaInput] = useState("");
  const [elevatorInput, setElevatorInput] = useState(false);
  const [garageInput, setGarageInput] = useState(false);
  const [balconyInput, setBalconyInput] = useState(false);

  // ERRORS
  const [roomsError, setRoomsError] = useState(null);
  const [areaError, setAreaError] = useState(null);

  // REAL ESTATE STATUS
  const changeREStatusHandler = (value) => {
    setREStatusInput(value);
  };

  // ROOMS
  const changeRoomsHandler = (e) => {
    e.preventDefault();
    setRoomsInput(e.target.value);
  };

  const checkRoomsValidation = (e) => {
    e.preventDefault();
    validate.numberValidate(
      roomsInput,
      setRoomsError,
      translations.msg_real_estate_rooms || "Rooms"
    );
  };

  // AREA
  const changeAreaHandler = (e) => {
    e.preventDefault();
    setAreaInput(e.target.value);
  };

  const checkAreaValidation = (e) => {
    e.preventDefault();
    validate.numberValidate(
      areaInput,
      setAreaError,
      translations.msg_real_estate_area || "Area"
    );
  };

  // ELEVATOR
  const changeElevatorHandler = (e) => {
    e.preventDefault();
    setElevatorInput(!elevatorInput);
  };

  // GARAGE
  const changeGarageHandler = (e) => {
    e.preventDefault();
    setGarageInput(!garageInput);
  };

  // BALCONY
  const changeBalconyHandler = (e) => {
    e.preventDefault();
    setBalconyInput(!balconyInput);
  };

  ////////////////////////////////////////////////
  // IMAGES //////////////////////////////////////
  ////////////////////////////////////////////////

  const deleteFetchedImageHandler = (e) => {
    e.preventDefault();
    let imageName = e.target.id;
    if (!imageName) {
      imageName = e.target.closest("button").id;
    }

    let filteredImages = [];
    fetchedImages.forEach((i) => {
      if (i === imageName) return;
      filteredImages.push(i);
    });

    if (imageName === mainImage) {
      setMainImage("");
    }

    setFetchedImages(filteredImages);
    let _deletedImages = [...deletedImages];
    _deletedImages.push(imageName);
    setDeletedImages(_deletedImages);
  };

  const setFetchedMainImageHandler = useCallback(
    (e) => {
      e.preventDefault();
      let imageName = e.target.id;
      if (!imageName) {
        imageName = e.target.closest("button").id;
      }
      console.log(imageName);
      if (mainImage === imageName) {
        setMainImage("");
      } else {
        setMainImage(imageName);
      }
    },
    [mainImage]
  );

  // CREATE FETCHED IMAGES RENDERER WHEN FETCHED IMAGES LIST CHANGE
  useEffect(() => {
    let _imgList = [];
    fetchedImages.forEach((img, i) => {
      _imgList.push(
        <FetchedSingleImage
          data={{ imageURL: img }}
          id={img}
          key={i}
          mainImage={mainImage}
          setMainImage={setMainImage}
          onDeleteClick={deleteFetchedImageHandler}
          onSetMainImageClick={setFetchedMainImageHandler}
        />
      );
    });
    setFetchedImgsRenderer(_imgList);
  }, [fetchedImages, mainImage, setFetchedMainImageHandler]);

  // SET VALUES IF FORMTYPE IS 'edit-article'
  useEffect(() => {
    if (!translations) return;
    if (formType === "edit-article" && !setCanSetSelectFields) return;
    if (formType === "edit-article" && article) {
      // SET CATEGORY & SUBCATEGORY
      const _articleCat = categories.find((c) => c.index === article.category);
      setCategory({ value: _articleCat.index, label: _articleCat.name });
      categoryRef.current.setValue({
        value: _articleCat.index,
        label: _articleCat.name,
      });

      const _articleSubCat =
        _articleCat.subcategories.length > 0
          ? _articleCat.subcategories.find(
              (sc) => sc.index === article.subCategory
            )
          : [];

      const subCatValue =
        _articleSubCat?.length > 0
          ? {
              value: _articleSubCat.index,
              label:
                translations[
                  `subcat_${_articleCat.index}_${_articleCat.index}`
                ],
              // label: _articleSubCat.name,
            }
          : { value: null, label: null };
      setSubCategory(subCatValue);
      subCategoryRef.current.setValue(subCatValue);

      // SET OTHER FIELDS
      setTitleInput(article.title);
      setDescriptionInput(article.description);
      setCityInput({ value: article.city, label: article.city });
      cityRef.current.setValue({ value: article.city, label: article.city });
      setZipInput(article.zip);
      setPriceInput(article.price);
      setStateInput(article.itemCondition === "new" ? true : false);
      setMainImage(article.mainImage);

      // SET VEHICLE FIELDS
      if (article.category === 6) {
        // SET CAR COMPANY AND CAR MODEL
        const _articleCarCompany = cars.find(
          (c) => c.index === article.carcompany
        );

        const currCarCompanyValue = {
          value: _articleCarCompany.index,
          label: _articleCarCompany.name,
        };
        setCarcompany(currCarCompanyValue);

        const _carModelsList = [];
        _articleCarCompany.subcategories.length > 0 &&
          _articleCarCompany.subcategories.forEach((sc) =>
            _carModelsList.push({ value: sc.index, label: sc.name })
          );
        setCarmodelsList(_carModelsList);
        // console.log(_carModelsList, article.carmodel);
        const currCarModelValue =
          _articleCarCompany.subcategories.length > 0
            ? _carModelsList.find((m) => m.value === article.carmodel)
            : { value: null, label: null };
        setCarmodel(currCarModelValue);
        // console.log(currCarModelValue);

        // SET OTHER VEHICLE FIELDS
        setMilesInput(article.miles);
        setCcInput(article.cc);
        setKwInput(article.kw);
        const engyneValue = {
          value: article.engynetype,
          label: article.engynetype,
        };
        setEngineInput(engyneValue);

        const transmitionValue = {
          value: article.transmition,
          label: article.transmition,
        };
        setTransmitionInput(transmitionValue);

        const yearValue = {
          value: article.year,
          label: article.year,
        };
        setYearInput(yearValue);

        const doorsValue = {
          value: article.car_doors,
          label: article.car_doors,
        };
        setCarDoorsInput(doorsValue);

        const colorValue = {
          value: article.car_color,
          label: article.car_color,
        };
        setCarColorInput(colorValue);
      }

      // FOR REAL ESTATE
      if (article.category === 11) {
        setREStatusInput({
          value: article.realestatestatus,
          label: "",
        });
        setRoomsInput(article.rooms || "");
        setAreaInput(article.area || "");
        setElevatorInput(article.elevator);
        setBalconyInput(article.balcony);
        setGarageInput(article.garage);
      }

      // FETCHED IMAGES RENDERER
      setFetchedImages([...article.images]);
    }
  }, [article, formType, translations]);

  // FUNCTIONS
  const getInputsObject = useCallback(() => {
    const articleFields = {};
    articleFields.category = category.value;
    articleFields.subCategory = subCategory.value;
    articleFields.title = titleInput;
    articleFields.description = descriptionInput;
    articleFields.city = cityInput?.value;
    articleFields.zip = zipInput;
    articleFields.price = priceInput;
    articleFields.itemCondition = stateInput ? "new" : "used";

    if (articleFields.category === 6) {
      articleFields.carcompany = carcompany.value;
      articleFields.carmodel = carmodel.value;
      articleFields.engynetype = engineInput.value;
      articleFields.transmition = transmitionInput.value;
      articleFields.year = yearInput.value;
      articleFields.miles = milesInput;
      articleFields.cc = ccInput;
      articleFields.kw = kwInput;
      articleFields.car_doors = carDoorsInput.value;
      articleFields.car_color = carColorInput.value;
    }

    if (articleFields.category === 11) {
      articleFields.realEstateStatus = REStatusInput.value;
      articleFields.rooms = roomsInput;
      articleFields.area = areaInput;
      articleFields.elevator = elevatorInput;
      articleFields.garage = garageInput;
      articleFields.balcony = balconyInput;
    }
    return articleFields;
  }, [
    titleInput,
    descriptionInput,
    cityInput?.value,
    zipInput,
    priceInput,
    stateInput,
    category?.value,
    subCategory?.value,
    carcompany?.value,
    carmodel?.value,
    ccInput,
    engineInput?.value,
    kwInput,
    milesInput,
    transmitionInput?.value,
    yearInput?.value,
    carDoorsInput?.value,
    carColorInput?.value,
    REStatusInput?.value,
    areaInput,
    roomsInput,
    balconyInput,
    elevatorInput,
    garageInput,
  ]);

  const createArticleHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (user.status === 2) return;

      const formData = new FormData();
      // TODO DODATI I MAIN IMAGE DA BACKEND POSTAVI ODABRANU MAIN IMG A NE PRVU U IMAGES ARRAY
      if (images) {
        images.forEach((img) => formData.append("images", img));
      }

      if (mainImage) {
        formData.append("mainImage", mainImage);
      }

      let userInputs = getInputsObject();

      Object.keys(userInputs).forEach((key) => {
        formData.append(`${key}`, userInputs[key]);
      });

      dispatch(createNewArticle(formData, token, navigate));
      dispatch(articleActions.setUpdateAllArticles({ value: true }));
    },
    [dispatch, navigate, getInputsObject, images, token, user.status, mainImage]
  );

  const updateArticleHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (user.status === 2) return;

      const formData = new FormData();

      let userInputs = getInputsObject();

      if (mainImage) {
        formData.append("mainImage", mainImage);
      }

      if (images.length > 0) {
        images.forEach((img) => formData.append("images", img));
      }

      if (fetchedImages.length > 0) {
        fetchedImages.forEach((img) => formData.append("oldImages", img));
      }

      if (deletedImages.length > 0) {
        deletedImages.forEach((img) => formData.append("deletedImages", img));
      }

      Object.keys(userInputs).forEach((key) => {
        formData.append(`${key}`, userInputs[key]);
      });

      // UPDATE ARTICLE
      dispatch(
        updateArticle(
          { articleId: article._id, data: formData },
          token,
          navigate
        )
      );
      dispatch(articleActions.setUpdateAllArticles({ value: true }));
    },
    [
      fetchedImages,
      getInputsObject,
      mainImage,
      images,
      deletedImages,
      article?._id,
      dispatch,
      navigate,
      token,
      user.status,
    ]
  );

  const setFormTypeHandler = (type) => {
    setFormType(type);
  };

  return {
    // LISTS
    _categoriesRenderer,
    _listOfCities,
    _carCompanies,
    carmodelsList,
    _transmitionList,
    _engineList,
    _yearsList,
    // REFS
    categoryRef,
    subCategoryRef,
    carcompanyRef,
    carmodelRef,
    cityRef,
    engineRef,
    transmitionRef,
    yearRef,
    doorsRef,
    carColorRef,
    REStatusRef,
    // VALUES
    category,
    subCategory,
    subCategories,
    titleInput,
    descriptionInput,
    cityInput,
    zipInput,
    priceInput,
    stateInput,
    carcompany,
    carmodel,
    ccInput,
    kwInput,
    milesInput,
    yearInput,
    engineInput,
    transmitionInput,
    carDoorsInput,
    carColorInput,
    fetchedImages,
    fetchedImgsRenderer,
    REStatusInput,
    roomsInput,
    areaInput,
    elevatorInput,
    garageInput,
    balconyInput,
    // SET VALUES
    setCategory,
    setSubCategory,
    setSubCategories,
    setTitleInput,
    setDescriptionInput,
    setCityInput,
    setZipInput,
    setPriceInput,
    setCcInput,
    setKwInput,
    setMilesInput,
    setCarDoorsInput,
    setCarColorInput,
    setCanSetSelectFields,
    // ERRORS
    titleError,
    descriptionError,
    zipError,
    priceError,
    ccError,
    kwError,
    milesError,
    roomsError,
    areaError,
    // HANDLERS
    setCategoryHandler,
    setSubCategoryHandler,
    titleHandler,
    descriptionHandler,
    pickedCityHandler,
    zipHandler,
    priceHandler,
    changeStateHandler,
    changeCcHandler,
    changeKwHandler,
    changeCarcompanyHandler,
    changecarModelHandler,
    changeEngineHandler,
    changeTransmitionHandler,
    changeYearHandler,
    changeMilesHandler,
    changeDoorsHandler,
    changeCarColorHandler,
    changeRoomsHandler,
    changeAreaHandler,
    changeREStatusHandler,
    changeElevatorHandler,
    changeGarageHandler,
    changeBalconyHandler,
    // VALIDATION
    checkTitleValidation,
    checkDescriptionValidation,
    checkZipValidation,
    checkPriceValidation,
    checkCcValidation,
    checkKwValidation,
    checkMilesValidation,
    checkRoomsValidation,
    checkAreaValidation,
    // IMAGES
    imagesForUpload,
    setImagesForUpload,
    imagesPreviewURL,
    setImagesPreviewURL,
    imagesNames,
    setImagesNames,
    imagesPREV,
    setImagesPREV,
    images,
    setImages,
    // FUNCTIONS
    getCategory,
    getInputsObject,
    createArticleHandler,
    updateArticleHandler,
    setFormTypeHandler,
  };
};

export default useArticleForm;
