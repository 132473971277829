const queryStrCreator = (
  currentPage = 1,
  obj,
  category = "",
  search = "",
  sort = "",
  limit = "",
  subCategory = ""
) => {
  let queryStr = `?page=${currentPage}`;

  Object.entries(obj).forEach((e) => {
    const numberFields = [
      "minPrice",
      "maxPrice",
      //VEHICLES
      "minYear",
      "maxMiles",
      "minKw",
      "maxKw",
      "minCc",
      "maxCc",
      //REAL ESTATE
      "minArea",
      "maxArea",
    ];
    const includes = numberFields.includes(e[0]);
    if (!e[1]) return;
    if (includes && (e[1] !== false || e[1] !== 0 || e[1] !== null)) {
      const field = e[0].slice(3, e[0].length).toLowerCase();
      const action = e[0].includes("min") ? "[gte]" : "[lte]";
      queryStr = queryStr + `&${field}${action}=${e[1]}`;
    }

    if (!includes && e[1] !== null) {
      queryStr = queryStr + `&${e[0]}=${e[1]}`;
      // queryStr = queryStr + `&${e[0].toLowerCase()}=${e[1]}`;
    }
  });

  if (`${category}` !== "0") {
    queryStr = queryStr + `&category=${category}`;
  }

  if (`${subCategory}` !== "0") {
    queryStr = queryStr + `&subCategory=${subCategory}`;
  }

  if (search !== "") {
    const encodedSearch = encodeURIComponent(search);
    queryStr = queryStr + `&search=${encodedSearch}`;
  }

  if (sort !== "") {
    queryStr = queryStr + `&sort=${sort}`;
  }

  if (limit !== "") {
    queryStr = queryStr + `&limit=${limit}`;
  }

  return queryStr;
};

export default queryStrCreator;
