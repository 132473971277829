import React from "react";

import classes from "./FlagIcon.module.css";

const FlagIcon = (props) => {
  return (
    <div
      className={classes.container}
      style={props.style}
      onClick={props.onClick}
    >
      <div
        style={{ backgroundColor: `${props.colors.first}` }}
        className={`${classes.color}`}
      />
      <div
        style={{ backgroundColor: `${props.colors.second}` }}
        className={`${classes.color}`}
      />
      <div
        style={{ backgroundColor: `${props.colors.third}` }}
        className={`${classes.color}`}
      />
    </div>
  );
};

export default FlagIcon;
