import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./PromoteOverlay.module.css";

import { _PROMOTIONS } from "../../utils/promotion-packages";

//STORE
import { promoteArticle } from "../../store/reducers/user-article-slice";
import { authActions, userArticleActions } from "../../store/redux-store";

import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

const SinglePackage = (props) => {
  const pickPromotionHandler = (e) => {
    e.preventDefault();
    props.setPickedPromotion(props.promotion);
  };
  return (
    <div className={classes["single-package"]} onClick={pickPromotionHandler}>
      {props.picked === props.promotion ? (
        <RiCheckboxBlankCircleFill />
      ) : (
        <RiCheckboxBlankCircleLine />
      )}
      <p>{`Days: ${_PROMOTIONS[props.promotion].duration}`}</p>
      <p>{`Credit: ${_PROMOTIONS[props.promotion].cost}`}</p>
    </div>
  );
};

const PromoteOverlay = (props) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const [pickedPromotion, setPickedPromotion] = useState(0);

  const promotionsRenderer = _PROMOTIONS.map((p) => {
    return (
      <SinglePackage
        key={p.type}
        promotion={p.type}
        picked={pickedPromotion}
        setPickedPromotion={setPickedPromotion}
      />
    );
  });

  const promoteArticleHandler = (e) => {
    e.preventDefault();

    if (props.data.subscriptionType !== 0) {
      dispatch(
        authActions.setErrorAuth({
          value: "Article is already promoted.",
        })
      );
      return;
    }

    const pickedPromotionCost = _PROMOTIONS[pickedPromotion].cost;

    const userInputs = {
      articleId: props.data._id,
      subscriptionType: pickedPromotion === 0 ? 0 : 1,
      promote: pickedPromotion,
    };

    const onSuccess = () => {
      dispatch(
        authActions.setUserCredit({ value: user.credit - pickedPromotionCost })
      );
      dispatch(userArticleActions.setRefreshArticles({ value: true }));
      props.setShowPromoteOverlay(false);
    };

    if (!user.credit || user.credit < pickedPromotionCost) {
      dispatch(
        authActions.setErrorAuth({
          value: "You dont have enough credit to promote article!",
        })
      );
      return;
    }
    dispatch(promoteArticle(userInputs, token, onSuccess));
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h5>Istakni Artikal</h5>
        <div className={classes["promotion-packages"]}>
          {promotionsRenderer}
        </div>
        <div className={classes.btns}>
          <button
            type="button"
            className={classes.btn}
            onClick={props.hidePromoteOverlayHandler}
          >
            Cancel
          </button>
          <button
            type="button"
            className={classes.btn}
            onClick={promoteArticleHandler}
          >
            Promote Article
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoteOverlay;
