import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classes from "./SingleReport.module.css";

//STORE
import {
  approveReport,
  rejectReport,
} from "../../../store/reducers/report-slice";

//UTILS
import { reports } from "../../../utils/reports";

import { FiAlertTriangle } from "react-icons/fi";

const URL = process.env.REACT_APP_IMAGE_URL;

const SingleReport = (props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const _reports = reports[props.data.reportType];

  let headerStyle = {};
  const headerStl = {
    backgroundColor: "#fff",
    // backgroundColor: "var(--background)",
    borderBottom: "0.1rem solid var(--accent-secondary-color)",
  };
  // const headerStl = { backgroundColor: "var(--main-color)" };

  if (props.data.status === 1) {
    headerStyle = { backgroundColor: "var(--report-pending)" };
  } else if (props.data.status === 2) {
    headerStyle = { backgroundColor: "var(--report-blocked)" };
  } else if (props.data.status === 3) {
    headerStyle = { backgroundColor: "var(--report-rejected)" };
  }

  const setAndShowMessagesHandler = (e) => {
    e.preventDefault();
    props.showMessageHandler();
    props.setMessageListHandler(props.data.chatId.messages);
  };

  const approveReportHandler = (e) => {
    e.preventDefault();
    dispatch(approveReport({ reportId: props.data._id }, token));
    //1-pending, 2-approved, 3-rejected
  };

  const rejectReportHandler = (e) => {
    e.preventDefault();
    dispatch(rejectReport({ reportId: props.data._id }, token));
  };

  return (
    <div className={classes.container}>
      <p
        className={classes.header}
        style={headerStl}
      >{`TICKET: ${props.data._id}`}</p>
      <p
        className={classes.title}
      >{`User ${props.data.reporting.username} (${props.data.reporting._id}) reported ${props.data.reportType}.`}</p>
      {props.data.reportType === "article" && (
        <div className={classes["report-details"]}>
          {props.data.articleId?._id && (
            <img
              className={classes["report-details__image"]}
              src={`${URL}/articles/${props.data.articleId?.mainImage}`}
              alt={props.data.articleId?.title}
            />
          )}
          {props.data.articleId?._id ? (
            <div className={classes["report-details__specs"]}>
              <p>{`ID: ${props.data.articleId?._id}`}</p>
              <p className={classes["report-details__title"]}>
                {`Title: ${props.data.articleId?.title}`}
              </p>
              <Link
                target="_blank"
                to={`/article?articleId=${props.data.articleId?._id}`}
                className={classes["report-details__visit-link"]}
              >
                Go to article
              </Link>
            </div>
          ) : (
            <div className="report-details__specs">
              <p>{"Article is deleted!"}</p>
            </div>
          )}
        </div>
      )}
      {props.data.reportType === "user" && (
        <div className={classes["report-details"]}>
          <img
            className={classes["report-details__image"]}
            src={`${URL}/users/${props.data.userId.image}`}
            alt={props.data.userId.username}
          />
          <div className={classes["report-details__specs"]}>
            <p>{`ID: ${props.data.userId._id}`}</p>
            <p className={classes["report-details__username"]}>
              {`Username: ${props.data.userId.username}`}
            </p>
            <p className={classes["report-details__email"]}>
              {`E-mail: ${props.data.userId.email}`}
            </p>
          </div>
        </div>
      )}
      {props.data.chatId?._id && props.data.reportType === "chat" && (
        <div className={classes["report-details"]}>
          <div className={classes["report-details__specs"]}>
            <p>{`Chat ID: ${props.data.chatId._id}`}</p>
            <p className={classes["report-details__title"]}>
              {`Title: ${props.data.chatId.title}`}
            </p>
            <div
              className={classes["report-details__show-chat"]}
              onClick={setAndShowMessagesHandler}
            >
              Show chat
            </div>
          </div>
        </div>
      )}
      <FiAlertTriangle className={classes.icon} />
      <p className={classes.reason}>{`Reason: ${
        _reports[props.data.reason]
      }`}</p>
      {props.data.reason === 100 && (
        <p
          className={classes.description}
        >{`Description: ${props.data.reasonDescription}`}</p>
      )}
      {props.data.status === 1 && (
        <div className={classes.buttons}>
          <button
            type="button"
            className={classes.block}
            onClick={approveReportHandler}
          >
            Block
          </button>
          <button
            type="button"
            className={classes.reject}
            onClick={rejectReportHandler}
          >
            Reject
          </button>
        </div>
      )}
      {props.data.status === 2 && (
        <div className={classes.status} style={headerStyle}>
          BLOCKED
        </div>
      )}
      {props.data.status === 3 && (
        <div className={classes.status} style={headerStyle}>
          REJECTED
        </div>
      )}
    </div>
  );
};

export default SingleReport;
