import { useState, useRef, useEffect } from "react";
import { useTranslation } from "../../../../TranslationContext"; // Import useTranslation

import classes from "./VehiclesFilters.module.css";

import {
  getYears,
  carCompanies,
  miles,
  engine,
  transmition,
} from "../../../../utils/vehicle-filters";
import cars from "../../../../utils/cars";

//COMPONENTS
import SelectInput from "../../../../UI/SelectInput";
import NumberInput from "../../../../UI/NumberInput";

//CUSTOM HOOKS
import useVehicleTranslations from "../../../../custom-hooks/useVehicleTranslations";

// const _listOfYears = getYears().map((y) => {
//   return { value: y, label: y };
// });

const _carCompanies = cars.map((c) => {
  return { value: c.index, label: c.name };
});

// const _miles = miles.map((m) => {
//   return { value: m, label: m };
// });

// const _engine = engine.map((e) => {
//   return { value: e, label: e };
// });

// const _transmition = transmition.map((t) => {
//   return { value: t, label: t };
// });

const VehiclesFilters = (props) => {
  const { translations } = useTranslation(); // Use translations
  const carCompanyRef = useRef();
  const carModelRef = useRef();
  const yearRef = useRef();
  const milesRef = useRef();
  const engineRef = useRef();
  const transmitionRef = useRef();
  const colorRef = useRef();
  const doorsRef = useRef();
  const [carModelList, setCarModelList] = useState([]);
  const VT = useVehicleTranslations();
  // const [_listOfYears, _setListOfYears] = useState([]);
  // const [_carCompanies, _setCarCompanies] = useState([]);
  // const [_miles, _setMiles] = useState([]);
  // const [_engine, _setEngine] = useState([]);
  // const [_transmition, _setTransmition] = useState([]);

  // useEffect(() => {

  // }, [translations]);

  useEffect(() => {
    let pickedCarCompany = _carCompanies.find(
      (c) => c.value === props.data.carCompany.get
    );

    if (
      pickedCarCompany &&
      carCompanyRef.current.getValue().length > 0
      // carCompanyRef.current.getValue()[0]?.value !== pickedCarCompany?.value
    ) {
      carCompanyRef.current.setValue({
        value: pickedCarCompany.value,
        label:
          pickedCarCompany.value === 0
            ? translations.all
            : pickedCarCompany.label,
      });
      let _modelList =
        pickedCarCompany.subcategories?.map((m) => {
          return { value: m.index, label: m.name };
        }) || [];
      _modelList.length > 0 &&
        _modelList.unshift({
          value: 0,
          label: translations.all || "Alle",
        });
      setCarModelList(_modelList);
      carModelRef.current.setValue({
        value: 0,
        label: translations.all || "Alle",
      });
    }
  }, [props.data.carCompany.get, translations]);

  const pickCarCompanyHandler = (value) => {
    props.data.carCompany.set(value.value);
  };

  const pickCarModelHandler = (value) => {
    props.data.carModel.set(value.value);
  };

  useEffect(() => {
    let pickedYear = VT.listOfYears.find(
      (c) => c.value === props.data.year.get
    );

    if (
      VT.listOfYears.length > 0 &&
      yearRef.current.getValue().length > 0
      // yearRef.current.getValue()[0].value !== pickedYear.value
    ) {
      yearRef.current.setValue({
        value: pickedYear.value,
        label: pickedYear.label,
      });
    }
  }, [VT.listOfYears, props.data.year.get]);

  const pickYearHandler = (value) => {
    props.data.year.set(value.value);
  };

  useEffect(() => {
    let pickedMiles = VT.miles.find((c) => c.value === props.data.miles.get);

    if (
      VT.miles.length > 0 &&
      milesRef.current.getValue().length > 0
      // milesRef.current.getValue()[0].value !== pickedMiles.value
    ) {
      milesRef.current.setValue({
        value: pickedMiles.value,
        label: pickedMiles.label,
      });
    }
  }, [props.data.miles, VT.miles]);

  const pickMilesHandler = (value) => {
    props.data.miles.set(value.value);
  };

  useEffect(() => {
    let pickedEngine = VT.engine.find((c) => c.value === props.data.engine.get);

    if (
      VT.engine.length > 0 &&
      engineRef.current.getValue().length > 0
      // engineRef.current.getValue()[0].value !== pickedEngine.value
    ) {
      engineRef.current.setValue({
        value: pickedEngine.value,
        label: pickedEngine.label,
      });
    }
  }, [props.data.engine, VT.engine]);

  const pickEngineHandler = (value) => {
    props.data.engine.set(value.value);
  };

  useEffect(() => {
    let pickedTransmition = VT.transmition.find(
      (c) => c.value === props.data.transmition.get
    );

    if (
      VT.transmition.length > 0 &&
      transmitionRef.current.getValue().length > 0
      // transmitionRef.current.getValue()[0].value !== pickedTransmition.value
    ) {
      transmitionRef.current.setValue({
        value: pickedTransmition.value,
        label: pickedTransmition.label,
      });
    }
  }, [props.data.transmition, VT.transmition]);

  const pickTransmitionHandler = (value) => {
    props.data.transmition.set(value.value);
  };

  // DOORS
  useEffect(() => {
    if (VT.doors.length === 0) return;
    let pickedDoors = VT.doors.find((c) => c.value === props.data.carDoors.get);

    if (doorsRef.current && doorsRef.current.getValue().length > 0) {
      doorsRef.current.setValue({
        value: pickedDoors.value,
        label: pickedDoors.label,
      });
    }
  }, [props.data.carDoors, VT.doors]);

  const pickDoorsHandler = (value) => {
    props.data.carDoors.set(value.value);
  };

  // COLOR
  useEffect(() => {
    if (VT.carColor.length === 0) return;
    let pickedColor = VT.carColor.find(
      (c) => c.value === props.data.carColor.get
    );

    if (colorRef.current && colorRef.current.getValue().length > 0) {
      colorRef.current.setValue({
        value: pickedColor.value,
        label: pickedColor.label,
      });
    }
  }, [props.data.carColor, VT.carColor]);

  const pickColorHandler = (value) => {
    props.data.carColor.set(value.value);
  };

  const minCcInputHandler = (e) => {
    e.preventDefault();
    props.data.minCc.set(e.target.value);
  };
  const maxCcInputHandler = (e) => {
    e.preventDefault();
    props.data.maxCc.set(e.target.value);
  };

  const minKwInputHandler = (e) => {
    e.preventDefault();
    props.data.minKw.set(e.target.value);
  };
  const maxKwInputHandler = (e) => {
    e.preventDefault();
    props.data.maxKw.set(e.target.value);
  };

  return (
    <div className={classes.container}>
      <h3>{translations.msg_vehiclesfilters_title || "Fahrzeuge Filter"}</h3>
      <div className={classes.divider} />
      <div className={classes["vehicles-body"]}>
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_brand || "Marke",
            options: _carCompanies,
            isSearchable: true,
            defaultValue: {
              value: 0,
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: carCompanyRef,
          }}
          onChange={pickCarCompanyHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_model || "Model",
            options: carModelList,
            isSearchable: true,
            isDisabled: carModelList.length > 0 ? false : true,
            defaultValue: {
              value: 0,
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: carModelRef,
          }}
          onChange={pickCarModelHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_engine || "Engine",
            options: VT.engine,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: engineRef,
          }}
          onChange={pickEngineHandler}
        />
        <SelectInput
          data={{
            name:
              translations.msg_vehiclesfilters_year ||
              "Erstzulassung ab / Year",
            options: VT.listOfYears,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: yearRef,
          }}
          onChange={pickYearHandler}
        />
        <SelectInput
          data={{
            name:
              translations.msg_vehiclesfilters_miles || "Kilometer bis / Miles",
            options: VT.miles,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: milesRef,
          }}
          onChange={pickMilesHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_transmition || "Transmition",
            options: VT.transmition,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: transmitionRef,
          }}
          onChange={pickTransmitionHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_doors || "Doors",
            options: VT.doors,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: doorsRef,
          }}
          onChange={pickDoorsHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehiclesfilters_color || "Color",
            options: VT.carColor,
            isSearchable: false,
            defaultValue: {
              value: "Alle",
              label: translations.msg_vehiclesfilters_all || "Alle",
            },
            ref: colorRef,
          }}
          onChange={pickColorHandler}
        />
      </div>
      <div className={classes["divider-second"]} />
      <div className={classes["vehicles-body__two"]}>
        <div className={classes["single-input"]}>
          <h4>{translations.msg_vehiclesfilters_cc || "Habraum (CC)"}</h4>
          <div className={classes.inputs}>
            <NumberInput
              data={{
                name: "",
                value: props.data.minCc.get,
                placeholder: translations.msg_vehiclesfilters_from || "Von",
              }}
              onChange={minCcInputHandler}
              onBlur={() => {}}
            />
            <div className={classes.dash} />
            <NumberInput
              data={{
                name: "",
                value: props.data.maxCc.get,
                placeholder: translations.msg_vehiclesfilters_to || "Bis",
              }}
              onChange={maxCcInputHandler}
              onBlur={() => {}}
            />
          </div>
        </div>
        <div className={classes["single-input"]}>
          <h4>{translations.msg_vehiclesfilters_kw || "Leistung (KW)"}</h4>
          <div className={classes.inputs}>
            <NumberInput
              data={{
                name: "",
                value: props.data.minKw.get,
                placeholder: translations.msg_vehiclesfilters_from || "Von",
              }}
              onChange={minKwInputHandler}
              onBlur={() => {}}
            />
            <div className={classes.dash} />
            <NumberInput
              data={{
                name: "",
                value: props.data.maxKw.get,
                placeholder: translations.msg_vehiclesfilters_to || "Bis",
              }}
              onChange={maxKwInputHandler}
              onBlur={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiclesFilters;
