import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let URL;

URL = process.env.REACT_APP_URL;
console.log(process.env.REACT_APP_URL);

const initialChatState = {
  errorChat: false,
  messageChat: false,
  chats: [
    { _id: "userOne", chats: [] },
    { _id: "userTwo", chats: [] },
  ],
  openedChat: {},
  sendMessageTemplate: {
    text: "",
    recipient: "",
    articleId: "",
    title: "",
  },
  newMessageReceived: false,
  currentTyping: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    setErrorChat(state, action) {
      state.errorChat = action.payload.value;
    },
    setMessageChat(state, action) {
      state.messageChat = action.payload.value;
    },
    setChats(state, action) {
      const chats = [];

      action.payload.data.forEach((c) =>
        c._id === "userOne" ? chats.unshift(c) : chats.push(c)
      );

      if (chats.length === 1 && chats[0]._id === "userOne") {
        chats.push({ _id: "userTwo", chats: [] });
      } else if (chats.length === 1 && chats[0]._id === "userTwo") {
        chats.unshift({ _id: "userOne", chats: [] });
      } else if (chats.length === 0) {
        chats.push({ _id: "userOne", chats: [] });
        chats.push({ _id: "userTwo", chats: [] });
      }

      state.chats = chats;
    },
    setOpenedChat(state, action) {
      state.openedChat = action.payload.data;
    },
    setOpenedChatToEnded(state, action) {
      let _openedChat = { ...state.openedChat };
      _openedChat.ended = action.payload.value;
      state.openedChat = _openedChat;
    },
    setSendMessageTemplate(state, action) {
      const field = Object.keys(action.payload)[0];
      const fields = ["text", "recipient", "articleId", "title"];

      if (!fields.includes(field)) return;
      state.sendMessageTemplate[field] = action.payload[field];
    },
    resetSendMessageTemplate(state) {
      state.sendMessageTemplate.text = "";
    },
    // checkMessageTemplateValidity(state, action) {
    //   const recipient = action.payload.data.recipient === "";
    //   const articleId = action.payload.data.articleId === "";
    //   const text = action.payload.data.text === "";
    //   if (recipient || articleId) {
    //     state.chatError = "Message cannot be sent to a recipient!";
    //   }
    //   if (text) {
    //     state.chatError = "You can't send empty message!";
    //   }
    // },
    // pushMessage(state, action) {
    //   const data = action.payload.data.msg;
    //   const chatId = action.payload.data.chatId;

    //   state.chats.forEach((conv) => {
    //     if (conv._id === chatId) {
    //       conv.messages.push(data);
    //     }
    //   });
    // },
    setNewMessageReceived(state, action) {
      state.newMessageReceived = action.payload.value;
    },
    // pushMsgToCurrentConversation(state, action) {
    //   state.openedChat.messages.push(action.payload.data);
    // },
    setCurrentTyping(state, action) {
      if (
        state.currentTyping.find(
          (chat) =>
            chat.userId === action.payload.userId &&
            chat.articleId === action.payload.articleId
        )
      )
        return;
      state.currentTyping.push({
        userId: action.payload.userId,
        articleId: action.payload.articleId,
      });
    },
    removeCurrentTyping(state, action) {
      let filteredList = [];
      state.currentTyping.forEach((chat) => {
        if (
          !(
            chat.userId === action.payload.userId &&
            chat.articleId === action.payload.articleId
          )
        ) {
          filteredList.push(chat);
        }
      });
      // const filteredList = state.currentTyping.filter(
      //   (chat) => chat.userId !== action.payload.userId
      // );
      state.currentTyping = filteredList;
    },
    setUpdateChat(state, action) {
      let exist = false;
      let firstArray =
        action.payload.data.userOne._id === action.payload.userId;

      const arr = firstArray ? 0 : 1;

      exist = state.chats[arr].chats.some(
        (ch) => ch._id === action.payload.data._id
      );

      let _chats = [];
      state.chats.forEach((ch) => _chats.push({ ...ch }));

      if (exist) {
        let modifiedChats = [];
        // console.log("✌ modifajanje", action.payload.data);
        modifiedChats.push(action.payload.data);
        state.chats[arr].chats.forEach((ch) => {
          if (ch._id !== action.payload.data._id) modifiedChats.push(ch);
        });
        // modifiedChats.unshift({ ...action.payload.data });
        _chats[arr].chats = modifiedChats;
      } else {
        let modifiedChats = [];
        state.chats[arr].chats.forEach((c) => modifiedChats.push(c));
        modifiedChats.unshift(action.payload.data);
        _chats[arr].chats = modifiedChats;
      }
      // console.log(state.chats);
      if (state.openedChat._id === action.payload.data._id) {
        state.openedChat = action.payload.data;
      }
      state.chats = _chats;
      // console.log(state.chats);
    },
    updateSeenMessagesInChat(state, action) {
      let _chats = [];

      let firstObject = {
        _id: "userOne",
        chats: state.chats[0].chats.map((ch) => ({ ...ch })),
      };

      let secondObject = {
        _id: "userTwo",
        chats: state.chats[1].chats.map((ch) => ({ ...ch })),
      };

      _chats.push(firstObject, secondObject);

      let firstArray =
        action.payload.data.userOne._id === action.payload.userId;
      const arrIndex = firstArray ? 0 : 1;

      let modifiedChats = [];
      _chats[arrIndex].chats.map((c) => {
        if (c._id === action.payload.data._id) {
          return modifiedChats.push({ ...action.payload.data });
        } else {
          return modifiedChats.push({ ...c });
        }
      });

      _chats[arrIndex].chats = modifiedChats;

      state.chats = _chats;
    },
  },
});

export const getChats = (token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");
      
      const axiosOptions = {
        method: "GET",
        url: `${URL}/users/messages/getAllMessages`,
        headers: { 
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`, 
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(chatSlice.actions.setChats({ data: response.data.data }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendMessage = (userInputs, token, closeModal = false) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");
      
      const axiosOptions = {
        method: "POST",
        data: userInputs,
        url: `${URL}/users/messages/send`,
        headers: { 
          "Accept-Language": lang,
          Authorization: `Bearer ${token}` 
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(chatSlice.actions.resetSendMessageTemplate());
        if (closeModal) {
          closeModal();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        dispatch(
          chatSlice.actions.setErrorChat({ value: err.response.data.message })
        );
      } else {
        dispatch(
          chatSlice.actions.setErrorChat({ value: "Message is not sent!" })
        );
      }
    }
  };
};

export const seenMessages = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");
      
      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/users/messages/seenMessages/${userInputs.chatId}`,
        headers: { 
          "Accept-Language": lang,
          Authorization: `Bearer ${token}` 
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          chatSlice.actions.updateSeenMessagesInChat({
            data: response.data.data,
            userId: userInputs.user._id,
          })
        );
        // dispatch(chatSlice.actions.setOpenedChat({ data: response.data.data }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const endChat = (userInputs, token, fn) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");
      
      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/users/messages/endChat/${userInputs.chatId}`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          chatSlice.actions.setMessageChat({
            value: "You ended chat successfully.",
          })
        );
        fn();
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default chatSlice;
