import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import classes from "./EditArticle.module.css";

//STORE
import { getArticle } from "../store/reducers/article-slice";

//COMPONENTS
import ArticleFormContainer from "../components/article-form/ArticleFormContainer";
import Footer from "../components/footer/Footer";

const EditArticle = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const articleId = params.articleId;
    dispatch(getArticle({ articleId }));
  }, [dispatch, params.articleId]);

  return (
    <div className={classes.container}>
      <ArticleFormContainer formType="edit-article" />
      <Footer />
    </div>
  );
};

export default EditArticle;
