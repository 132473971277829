import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "../TranslationContext"; // Import useTranslation

//STORE
import {
  updateUserProfile,
  updatePassword,
} from "../store/reducers/auth-store";

// CUSTOM HOOKS
import useValidator from "./useValidator";

const useProfile = () => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const { translations } = useTranslation(); // Use translations

  const [username, setUsername] = useState("");
  const [image, setImage] = useState(false);
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [website, setWebsite] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [receiveEmails, setReceiveEmails] = useState(true);

  // ERRORS
  const [usernameError, setUsernameError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [street1Error, setStreet1Error] = useState(null);
  const [street2Error, setStreet2Error] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [zipError, setZipError] = useState(null);

  const resetStateInputs = useCallback(() => {
    if (user._id && isAuthenticated) {
      setUsername(user.username);
      setStreet1(user.address.street1);
      setStreet2(user.address.street2);
      setCity(user.address.city);
      setZip(user.address.zip);
      setPhone(user.phone || "");
      setPhoneVisible(user.phoneVisible || false);
      setEmailVisible(user.emailVisible || false);
      setIsCompany(user.isCompany);
      setWebsite(user.website);
      setReceiveEmails(user.receiveEmails || false);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (user._id && isAuthenticated) {
      resetStateInputs();
    }
  }, [user, isAuthenticated, resetStateInputs]);

  const updateUserProfileHandler = () => {
    const formData = new FormData();

    if (image) {
      formData.append("image", image);
      formData.append("oldImage", user.image ? user.image : undefined);
    }

    formData.append("username", username);
    formData.append("street1", street1);
    formData.append("street2", street2);
    formData.append("city", city);
    formData.append("zip", zip);
    formData.append("phone", phone);
    formData.append("phoneVisible", phoneVisible);
    formData.append("emailVisible", emailVisible);
    formData.append("isCompany", isCompany);
    formData.append("website", isCompany ? website : "");
    formData.append("receiveEmails", receiveEmails);

    dispatch(updateUserProfile(formData, token, translations));
  };

  // VALIDATORS
  const checkZipValidation = (e) => {
    e.preventDefault();
    validator.numberValidate(zip, setZipError, "Zip", { min: 1000, max: 9999 });
  };

  const updatePasswordHandler = () => {
    dispatch(
      updatePassword(
        { oldPassword, newPassword, confirmNewPassword },
        token,
        translations
      )
    );
  };

  return {
    data: {
      user: user,
      username: { get: username, set: setUsername },
      image: { get: image, set: setImage },
      street1: { get: street1, set: setStreet1 },
      street2: { get: street2, set: setStreet2 },
      city: { get: city, set: setCity },
      zip: {
        get: zip,
        set: setZip,
        validator: checkZipValidation,
        error: zipError,
      },
      phone: { get: phone, set: setPhone },
      phoneVisible: { get: phoneVisible, set: setPhoneVisible },
      emailVisible: { get: emailVisible, set: setEmailVisible },
      isCompany: { get: isCompany, set: setIsCompany },
      website: { get: website, set: setWebsite },
      oldPassword: { get: oldPassword, set: setOldPassword },
      newPassword: { get: newPassword, set: setNewPassword },
      confirmNewPassword: {
        get: confirmNewPassword,
        set: setConfirmNewPassword,
      },
      receiveEmails: { get: receiveEmails, set: setReceiveEmails },
    },
    handlers: {
      updateUserProfileHandler,
      updatePasswordHandler,
      resetStateInputs,
    },
  };
};

export default useProfile;
