import React from "react";

import classes from "./ContactSection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

import config from "../../website-config";

import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";

const ContactCard = (props) => {
  const { translations } = useTranslation();

  return (
    <div className={classes["contact-card"]}>
      <div className={classes.header}>
        <p className={classes["icon-box"]}>{props.icon}</p>
        <h5>{props.title}</h5>
      </div>
      <div className={classes["contact-card__body"]}>
        {props.type === "phone" && (
          <a href={`tel:${props.value}`} className={classes.value}>
            {props.value}
          </a>
        )}
        {props.type === "email" && (
          <a href={`mailto:${props.value}`} className={classes.value}>
            {props.value}
          </a>
        )}
        {props.type !== "phone" && props.type !== "email" && (
          <p className={classes.value}>{props.value}</p>
        )}
      </div>
    </div>
  );
};

const ContactSection = (props) => {
  const { translations } = useTranslation();

  return (
    <section
      id={"contact"}
      className={classes.container}
      style={{ backgroundColor: "var(--background)" }}
    >
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_contact || "Contact"}</h3>
        <div className={classes["body"]}>
          <h5>info@schweizer-gratis-inserate.ch</h5>
          <div className={classes["contact-info"]}>
            <ContactCard
              type="phone"
              title={translations.tos_phone || "Phone"}
              value={`${config.contactPhone}`}
              icon={<MdPhone className={classes.icon} />}
            />
            <ContactCard
              type="email"
              title={translations.tos_email || "Email"}
              value={`${config.contactEmail}`}
              icon={<MdEmail className={classes.icon} />}
            />
            <ContactCard
              type="location"
              title={translations.tos_location || "Location"}
              value={`${config.contactLocation}`}
              icon={<MdLocationOn className={classes.icon} />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
