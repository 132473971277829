import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

//STORE
import { getGeolocation } from "../store/reducers/auth-store";

//CONFIG
import config from "../website-config";

const useCookie = () => {
  const dispatch = useDispatch();
  const { userGeolocation } = useSelector((store) => store.auth);
  const [initialCookie, setInitialCookie] = useState(null);
  const [updateInitCookie, setUpdateInitCookie] = useState(false);
  const [showCookieQuestion, setShowCookieQuestion] = useState(false);

  useEffect(() => {
    const cookieValue = Cookies.get("consent");
    setInitialCookie(cookieValue);
    dispatch(getGeolocation());
  }, [setInitialCookie, dispatch]);

  useEffect(() => {
    if (updateInitCookie) {
      const cookieValue = Cookies.get("consent");
      setInitialCookie(cookieValue);
      setUpdateInitCookie(false);
    }
  }, [updateInitCookie]);

  const getCookieHandler = useCallback((name) => {
    const cookieValue = Cookies.get(name);
    return cookieValue;
  }, []);

  const setCookieHandler = useCallback((name, value, path) => {
    const exp = new Date(
      Date.now() + config.initialCookieExpireDays * 24 * 60 * 60 * 1000
    );
    console.log(exp, new Date(Date.now()));
    Cookies.set(name, JSON.stringify(value), { expires: exp, path });
  }, []);

  const setInitialCookieHandler = useCallback(
    (groups) => {
      const uid = uuidv4();
      const value = {
        timestamp: new Date().toISOString(),
        geolocation: `${userGeolocation.country}/${userGeolocation.region}`,
        version: config.privacyPolicyVersion,
        consentId: uid,
        groups: groups,
      };

      setCookieHandler("consent", value, "/");
      setUpdateInitCookie(true);
    },
    [userGeolocation, setCookieHandler]
  );

  useEffect(() => {
    if (!initialCookie) {
      setShowCookieQuestion(true);
      return;
    }

    if (initialCookie) {
      let invalidCookie = false;
      const cookieParsed = JSON.parse(initialCookie);
      const cookieTimestamp = Date.parse(cookieParsed.timestamp);
      const cookieExpireDays =
        cookieTimestamp + config.initialCookieExpireDays * 24 * 60 * 60 * 1000;
      const privacyVersion = cookieParsed.version;

      if (cookieExpireDays < Date.now()) {
        invalidCookie = true;
      }
      if (privacyVersion !== config.privacyPolicyVersion) {
        invalidCookie = true;
      }

      setShowCookieQuestion(invalidCookie);
    }
  }, [initialCookie, setShowCookieQuestion]);

  return {
    initialCookie,
    showCookieQuestion,
    getCookieHandler,
    setCookieHandler,
    setInitialCookieHandler,
  };
};

export default useCookie;
