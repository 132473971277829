import { Link } from "react-router-dom";
import classes from "./ArticleCard.module.css";
import { formatDateDifference } from "../../../helper/date-time-helper";
import { useTranslation } from "../../../TranslationContext";

import { MdOutlinePhotoCamera } from "react-icons/md";

let IMAGE_URL_PATH;

IMAGE_URL_PATH = process.env.REACT_APP_IMAGE_URL;

const ArticleCard = (props) => {
  const { translations } = useTranslation();
  const dateDifference = formatDateDifference(
    props.data.createdAt,
    translations
  );

  // const hasMainImage = props.data?.mainImage !== "" ? 1 : 0;
  // let numOfImages = props.data?.images?.length + hasMainImage;
  let numOfImages = props.data?.images?.length;

  return (
    <div className={classes.card} id={props.data._id} key={props.data._id}>
      <Link
        to={`/article?articleId=${props.data._id}`}
        className={classes["image-container"]}
      >
        <img
          alt={props.data.title}
          src={
            !props.data?.mainImage && props.data.images.length === 0
              ? `${IMAGE_URL_PATH}/articles/default.jpeg`
              : `${IMAGE_URL_PATH}/articles${process.env.REACT_APP_IMAGE_SUFFIX_SMALL}/${props.data.mainImage}`
            // `${props.data.mainImage}`.includes("http")
            //   ? props.data.mainImage
            //   : `${IMAGE_URL_PATH}/articles${process.env.REACT_APP_IMAGE_SUFFIX_SMALL}/${props.data.mainImage}`
          }
          className={classes.image}
        />
        {numOfImages > 0 && (
          <div className={classes["images-count"]}>
            <MdOutlinePhotoCamera className={classes.icon} />
            <p>{`${numOfImages}`}</p>
          </div>
        )}
        {props.data.subscriptionType === 1 && (
          <p className={classes.promoted}>
            {translations.article_promoted || "Promoted"}
          </p>
        )}
      </Link>
      <div className={classes.details}>
        <div className={classes["locations-and-date"]}>
          <p
            className={classes.location}
          >{`${props.data.city}, ${props.data.zip}`}</p>
          <p className={classes.date}>{dateDifference}</p>
        </div>
        <div className={classes["title-and-price"]}>
          <Link
            to={`/article?articleId=${props.data._id}`}
            className={classes.title}
          >
            {props.data.title}
          </Link>
          {/* <p className={classes.user}>
            {props.data?.creator?.username || "Nema username"}
          </p> */}
          <p className={classes.price}>
            {translations.msg_articlecard_price || "CHF"} {props.data.price}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
