import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import classes from "./TermsOfUse.module.css";

// STORE
import { getNumOfActiveArticles } from "../store/reducers/article-slice";

// COMPONENTS
import Nav from "../components/terms-of-use/Nav";
import AboutUsSection from "../components/terms-of-use/AboutUsSection";
import TermsOfUseSection from "../components/terms-of-use/TermsOfUseSection";
import PrivacyPolicySection from "../components/terms-of-use/PrivacyPolicySection";
import MarketingSection from "../components/terms-of-use/MarketingSection";
import ImprintSection from "../components/terms-of-use/ImprintSection";
import ContactSection from "../components/terms-of-use/ContactSection";
import Footer from "../components/footer/Footer";

const TermsOfUse = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getNumOfActiveArticles());
  }, [dispatch]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          navigate(`#${sectionId}`, { replace: true });
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    sections.forEach((s) => {
      observer.observe(s);
    });

    return () => {
      sections.forEach((s) => {
        observer.unobserve(s);
      });
    };
  }, [navigate]);

  return (
    <div className={classes.container}>
      <Nav />
      <div className={classes.sections}>
        <AboutUsSection />
        <TermsOfUseSection />
        <PrivacyPolicySection />
        <MarketingSection />
        <ImprintSection />
        <ContactSection />
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
