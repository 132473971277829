import React from "react";
import { useTranslation } from "../../TranslationContext";

import classes from "./Search.module.css";
import { FiSearch } from "react-icons/fi";

const Search = (props) => {
  const { translations } = useTranslation();

  const searchInputHandler = (e) => {
    e.preventDefault();
    props.data.search.set(e.target.value);
  };

  return (
    <div className={classes["search-container"]}>
      <input
        placeholder={translations.msg_search_placeholder || "Was suchst du?"}
        className={classes.input}
        value={props.data.search.get}
        onChange={searchInputHandler}
      ></input>
      <button type="submit" className={classes.search}>
        <FiSearch className={classes.icon} />
      </button>
    </div>
  );
};

export default Search;
