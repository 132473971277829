import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./RejectedArticlesContainer.module.css";

//COMPONENTS
import SingleUserArticle from "./SingleUserArticle";

const RejectedArticlesContainer = (props) => {
  const { articles } = useSelector((state) => state.userArticle);
  const [rejectedArticles, setRejectedArticles] = useState([]);
  const [articlesRenderer, setArticlesRenderer] = useState([]);

  useEffect(() => {
    let _rejectedArticles = [];
    articles.forEach((a) => {
      if (a.status === 4) _rejectedArticles.push(a);
    });
    setRejectedArticles(_rejectedArticles);
  }, [articles]);

  useEffect(() => {
    setArticlesRenderer(
      rejectedArticles.map((a) => {
        return <SingleUserArticle key={a._id} data={a} />;
      })
    );
  }, [rejectedArticles]);

  return <div className={classes.container}>{articlesRenderer}</div>;
};

export default RejectedArticlesContainer;
