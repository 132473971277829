import React from "react";

import classes from "./SingleDetail.module.css";

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const SingleDetail = (props) => {
  return (
    <div className={classes.container}>
      <h5>{`${props.data.label}:`}</h5>
      {!props.data.bool && <p>{props.data.value}</p>}
      {props.data.bool && (
        <div className={classes.checkboxes}>
          {props.data.value ? (
            <MdCheckBox className={classes.icon} />
          ) : (
            <MdCheckBoxOutlineBlank className={classes.icon} />
          )}
        </div>
      )}
    </div>
  );
};

export default SingleDetail;
