import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./ChatsList.module.css";

// STORE
import { authActions, chatActions } from "../../store/redux-store";

// COMPONENTS
import SingleChat from "./SingleChat";

const ChatsList = (props) => {
  const { translations } = useTranslation(); // Use translations
  const dispatch = useDispatch();
  const { chats } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.auth);
  const [chatsRenderer, setChatsRenderer] = useState([]);
  const [currMessages, setCurrentMessages] = useState("0");
  const [myMessagesUnread, setMyMessagesUnread] = useState(0);
  const [myArticleMessagesUnread, setMyArticleMessagesUnread] = useState(0);
  // console.log(chats);
  useEffect(() => {
    let _myMessagesUnread = 0;
    if (chats[0] && chats[0].chats.length > 0) {
      chats[0].chats.forEach((c) => {
        c.messages.forEach((m) => {
          if (m.recipient === user._id && m.seen === false) {
            _myMessagesUnread += 1;
          }
        });
      });
    }
    setMyMessagesUnread(_myMessagesUnread);

    let _myArticleMessagesUnread = 0;
    if (chats[1] && chats[1].chats.length > 0) {
      chats[1].chats.forEach((c) => {
        c.messages.forEach((m) => {
          if (m.recipient === user._id && m.seen === false)
            _myArticleMessagesUnread++;
        });
      });
    }
    setMyArticleMessagesUnread(_myArticleMessagesUnread);
    if (_myMessagesUnread === 0 && _myArticleMessagesUnread === 0) {
      dispatch(authActions.setHasUnreadMessages({ value: false }));
    } else {
      dispatch(authActions.setHasUnreadMessages({ value: true }));
    }
  }, [chats, user._id, dispatch]);

  useEffect(() => {
    if (chats.length > 0) {
      setChatsRenderer(
        chats[currMessages].chats.map((chat) => {
          return <SingleChat key={chat?._id} id={chat?._id} data={chat} />;
        })
      );
    }
  }, [chats, currMessages]);

  const changeCurrMessagesHandler = (e) => {
    e.preventDefault();
    setCurrentMessages(e.target.id);
    dispatch(chatActions.setOpenedChat({ data: {} }));
  };
  // console.log(currMessages);
  const style =
    currMessages === "0"
      ? { backgroundColor: "var(--main-color)", color: "#fff" }
      : { backgroundColor: "#fff", color: "var(--text-color)" };
  const style2 =
    currMessages === "1"
      ? { backgroundColor: "var(--main-color)", color: "#fff" }
      : { backgroundColor: "#fff", color: "var(--text-color)" };

  return (
    <div className={`${classes.container}`} style={props.style}>
      <div className={classes.btns}>
        <div
          className={classes.btn}
          style={style}
          id={0}
          onClick={changeCurrMessagesHandler}
        >
          {translations.msg_chatslist_my_inquiries || "Moji upiti"}{" "}
          {myMessagesUnread > 0 && <span>{`${myMessagesUnread}`}</span>}
        </div>
        <div
          className={classes.btn}
          style={style2}
          id={1}
          onClick={changeCurrMessagesHandler}
        >
          {translations.msg_chatslist_my_articles || "Moji artikli"}{" "}
          {myArticleMessagesUnread > 0 && (
            <span>{`${myArticleMessagesUnread}`}</span>
          )}
        </div>
      </div>
      <div className={classes["chat-list"]}>{chatsRenderer}</div>
    </div>
  );
};

export default ChatsList;
