export const formatLastOnline = (lastLogoutTime) => {
  const timeDifference = new Date(Date.now()) - lastLogoutTime;
  const seconds = Math.floor(timeDifference / 1000);

  if (seconds < 3600) {
    let minutes = Math.floor(seconds / 60);
    if (minutes === 0) {
      minutes = 1;
    }
    return { value: minutes, unit: "minutes" };
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return { value: hours, unit: "hours" };
  } else if (seconds < 2592000) {
    const days = Math.floor(seconds / 86400);
    return { value: days, unit: "days" };
  } else if (seconds < 31536000) {
    const months = Math.floor(seconds / 2592000);
    return { value: months, unit: "months" };
  } else {
    const years = Math.floor(seconds / 31536000);
    return { value: years, unit: "years" };
  }
};
