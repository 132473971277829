import { useState } from "react";

import classes from "./CreateArticle.module.css";

//COMPONENTS
import ArticleFormContainer from "../components/article-form/ArticleFormContainer";
import Footer from "../components/footer/Footer";

const CreateArticle = (props) => {
  return (
    <div className={classes.container}>
      <ArticleFormContainer formType="create-article" />
      <Footer />
    </div>
  );
};

export default CreateArticle;
