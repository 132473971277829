import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./ArticleCard.module.css";

//COMPONENTS
// import SingleReport from "../single-report/SingleReport";
import { SingeSpec } from "./UserCard";

import categories, { Categories } from "../../../utils/categories";
import {
  _articleStatus,
  _subscriptionType,
} from "../../../utils/article-lists";
import cars, { Cars } from "../../../utils/cars";

//STORE
import {
  changeArticleStatus,
  changeSubscriptionType,
} from "../../../store/reducers/admin-slice";

let URL = process.env.REACT_APP_IMAGE_URL;
const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_SUFFIX_LARGE;

// const _statusList = ['Active', 'Paused', '']

const ArticleCard = (props) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);
  const cats = new Categories(categories);
  const carCompanies = new Cars(cars);

  let imageName = props.data.mainImage
    ? props.data.mainImage
    : props.data.images[0];
  if (!imageName) imageName = "default.jpeg";

  //CATEGORY
  let category = cats.getCategory(props.data.category);
  let subCategories = cats.getSubcategories(props.data.category);
  let subCategory =
    subCategories.length > 0 ? subCategories[props.data.subCategory] : null;

  //VEHICLE
  let carCompany, carModel;
  if (+props.data.category === 6) {
    carCompany = carCompanies.getCompany(props.data.carcompany);
    carModel = carCompanies.getModel(
      props.data.carcompany,
      props.data.carmodel
    );
  }

  //HANDLERS
  const changeArticleStatusHandler = (status) => {
    if (!isAuthenticated && !user._id) return;
    dispatch(changeArticleStatus({ articleId: props.data._id, status }, token));
  };
  const changeArticleSubscriptionHandler = (subscriptionType) => {
    if (!isAuthenticated && !user._id) return;
    dispatch(
      changeSubscriptionType(
        { articleId: props.data._id, subscriptionType },
        token
      )
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes["image-container"]}>
        <img
          src={`${URL}/articles${IMAGE_PREFIX}/${imageName}`}
          alt={props.data._id}
        />
      </div>
      <div className={classes.details}>
        <SingeSpec data={{ label: "ID", value: props.data._id }} />
        <SingeSpec
          data={{ label: "Creator", value: props.data.creator.username }}
        />
        <h3>General:</h3>
        <SingeSpec
          class={classes["grid-span"]}
          data={{ label: "Title", value: props.data.title }}
        />
        <SingeSpec data={{ label: "Category", value: category.name }} />
        {subCategory?.name && (
          <SingeSpec data={{ label: "SubCategory", value: subCategory.name }} />
        )}
        <SingeSpec
          class={classes["description"]}
          style={{ alignItems: "flex-start", flexDirection: "column" }}
          data={{ label: "Description", value: props.data.description }}
        />
        <h3>Details:</h3>
        <SingeSpec data={{ label: "City", value: props.data.city }} />
        <SingeSpec data={{ label: "Zip", value: props.data.zip }} />
        <SingeSpec data={{ label: "State", value: props.data.itemCondition }} />
        <SingeSpec
          data={{
            label: "Status",
            value: _articleStatus[props.data.status - 1],
          }}
        />
        <SingeSpec
          data={{
            label: "Subscription",
            value: _subscriptionType[props.data.subscriptionType],
          }}
        />
        <SingeSpec
          data={{ label: "Price", value: `CHF ${props.data.price}` }}
        />
      </div>
      {props.data.category === 6 && (
        <div className={classes.details}>
          <h3>Vehicle:</h3>
          <SingeSpec data={{ label: "Marka", value: carCompany.name }} />
          <SingeSpec
            data={{ label: "Model", value: carModel ? carModel.name : "" }}
          />
          <SingeSpec
            data={{ label: "Engine", value: `${props.data.engynetype}` }}
          />
          <SingeSpec
            data={{ label: "Transmition", value: `${props.data.transmition}` }}
          />
          <SingeSpec data={{ label: "Cc", value: `${props.data.cc} (CC)` }} />
          <SingeSpec data={{ label: "KW", value: `${props.data.kw} (KW)` }} />
          <SingeSpec data={{ label: "Year", value: `${props.data.year}` }} />
          <SingeSpec
            data={{ label: "Miles", value: `${props.data.miles} (km)` }}
          />
          <SingeSpec
            data={{ label: "Color", value: `${props.data.car_color}` }}
          />
        </div>
      )}
      {props.data.category === 11 && (
        <div className={classes.details}>
          <h3>Real-Estate:</h3>
          <SingeSpec data={{ label: "Marka", value: carCompany.name }} />
        </div>
      )}
      <div className={classes.btns}>
        {props.data.status === 5 ? (
          <button
            onClick={() => changeArticleStatusHandler(1)}
            className={classes.btn}
            style={
              props.data.status === 5
                ? {}
                : { backgroundColor: "var(--inactive)", cursor: "default" }
            }
          >
            Unblock
          </button>
        ) : (
          <button
            onClick={() => changeArticleStatusHandler(5)}
            className={classes.btn}
            style={
              props.data.status !== 5
                ? {}
                : { backgroundColor: "var(--inactive)", cursor: "default" }
            }
          >
            Block
          </button>
        )}
        {props.data.subscriptionType === 0 ? (
          <button
            className={classes.btn}
            onClick={() => {
              changeArticleSubscriptionHandler(1);
            }}
          >
            Promote
          </button>
        ) : (
          <button
            className={classes.btn}
            onClick={() => {
              changeArticleSubscriptionHandler(0);
            }}
          >
            Unpromote
          </button>
        )}
        <button
          className={classes.btn}
          onClick={() => changeArticleStatusHandler(4)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ArticleCard;
