import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./ActiveArticlesContainer.module.css";

//COMPONENTS
import SingleUserArticle from "./SingleUserArticle";

const ActiveArticlesContainer = (props) => {
  const { articles } = useSelector((state) => state.userArticle);
  const [activeArticles, setActiveArticles] = useState([]);
  const [articlesRenderer, setArticlesRenderer] = useState([]);

  useEffect(() => {
    let _activeArticles = [];
    articles.forEach((a) => {
      if (a.status === 1) _activeArticles.push(a);
    });
    setActiveArticles(_activeArticles);
  }, [articles]);

  useEffect(() => {
    setArticlesRenderer(
      activeArticles.map((a) => {
        return <SingleUserArticle key={a._id} data={a} />;
      })
    );
  }, [activeArticles]);

  return <div className={classes.container}>{articlesRenderer}</div>;
};

export default ActiveArticlesContainer;
