import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL;

URL = process.env.REACT_APP_URL;

const initialArticleState = {
  user: {},
  userArticlesStats: {},
  isAuthenticated: false,
  token: "",
  errorAuth: null,
  messageAuth: null,
  checkEmail: false,
  hasUnreadMessages: false,
  userGeolocation: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialArticleState,
  reducers: {
    setErrorAuth(state, action) {
      state.errorAuth = action.payload.value;
    },
    setMessageAuth(state, action) {
      state.messageAuth = action.payload.value;
    },
    setUser(state, action) {
      state.user = action.payload.data;
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload.value;
    },
    setToken(state, action) {
      state.token = action.payload.token;
      localStorage.setItem("token", JSON.stringify(state.token));
      if (action.payload.expiresIn) {
        localStorage.setItem(
          "token-exp",
          JSON.stringify(action.payload.expiresIn)
        );
      }
    },
    logout(state) {
      state.user = {};
      state.isAuthenticated = false;
      state.token = "";
      localStorage.removeItem("token");
      localStorage.removeItem("token-exp");
    },
    setUserArticlesStats(state, action) {
      const data = action.payload.data;
      const _data = {};

      data.forEach((s) => {
        _data[s._id] = s.count;
      });

      state.userArticlesStats = _data;
    },
    setCheckEmail(state, action) {
      state.checkEmail = action.payload.value;
    },
    setHasUnreadMessages(state, action) {
      state.hasUnreadMessages = action.payload.value;
    },
    setUserCredit(state, action) {
      let updatedUser = { ...state.user };
      updatedUser.credit = action.payload.value;
      state.user = updatedUser;
    },
    setUserGeolocation(state, action) {
      state.userGeolocation = action.payload.data;
    },
  },
});

export const signup = (userInput, navigate, checkEmail, translations) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "POST",
        url: `${URL}/users/signup`,
        data: userInput,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 201) {
        checkEmail(true);
        navigate("/auth/signup/verify-email");
        dispatch(authSlice.actions.setCheckEmail({ value: true }));
        // dispatch(
        //   authSlice.actions.setMessageAuth({
        //     value:
        //       translations.msg_auth_signup_success ||
        //       "Signup successful. Welcome!",
        //   })
        // );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        authSlice.actions.setErrorAuth({ value: err.response.data.message })
      );
    }
  };
};

export const verifyEmail = (userInputs, navigate, checkEmail, translations) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "POST",
        url: `${URL}/users/signup/${userInputs.token}`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 201) {
        dispatch(authSlice.actions.setUser({ data: response.data.data }));
        dispatch(
          authSlice.actions.setToken({
            token: response.data.token,
            expiresIn: response.data.expiresIn,
          })
        );
        dispatch(authSlice.actions.setIsAuthenticated({ value: true }));
        navigate("/");
        dispatch(
          authSlice.actions.setMessageAuth({
            value:
              translations?.msg_auth_signup_success ||
              "Signup successful. Welcome!",
          })
        );
        checkEmail(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.data && err.response.data.message) {
        dispatch(
          authSlice.actions.setErrorAuth({ value: err.response.data.message })
        );
      }
    }
  };
};

export const login = (userInput, navigate, socket, translations) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "POST",
        url: `${URL}/users/signin`,
        data: userInput,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);
      // console.log(response.status);
      if (response.status === 200) {
        console.log(response.data);
        dispatch(
          authSlice.actions.setHasUnreadMessages({
            value: response.data.unseenMessages,
          })
        );
        dispatch(authSlice.actions.setUser({ data: response.data.data }));
        dispatch(
          authSlice.actions.setToken({
            token: response.data.token,
            expiresIn: response.data.expiresIn,
          })
        );
        dispatch(authSlice.actions.setIsAuthenticated({ value: true }));
        navigate("/");
        dispatch(
          authSlice.actions.setMessageAuth({
            value:
              translations.msg_auth_login_success ||
              "You logged in successfully.",
          })
        );
      }
    } catch (err) {
      console.log(err);
      if (!err.response.data) return;
      dispatch(
        authSlice.actions.setErrorAuth({ value: err.response.data.message })
      );
    }
  };
};

export const getMyData = (token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/users/me`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          authSlice.actions.setHasUnreadMessages({
            value: response.data.unseenMessages,
          })
        );
        dispatch(authSlice.actions.setUser({ data: response.data.data }));
        dispatch(authSlice.actions.setIsAuthenticated({ value: true }));
        dispatch(authSlice.actions.setToken({ token: token, expiresIn: null }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUserArticlesStats = (userId) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/users/getArticlesStats/${userId}`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          authSlice.actions.setUserArticlesStats({ data: response.data.data })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUserProfile = (userInputs, token, translations) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/users/me`,
        data: userInputs,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };
      axiosOptions.headers["Content-Type"] = "multipart/form-data";

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(authSlice.actions.setUser({ data: response.data.data }));
        dispatch(
          authSlice.actions.setMessageAuth({
            value:
              translations.msg_auth_profile_update_success ||
              "Your profile has been updated successfully.",
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        authSlice.actions.setMessageAuth({ value: err.response.data.message })
      );
    }
  };
};

export const updatePassword = (userInputs, token, translations) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/users/updatePassword`,
        data: userInputs,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(axiosOptions);
      // console.log(response.data.data);
      if (response.status === 201) {
        dispatch(
          authSlice.actions.setMessageAuth({
            value:
              translations.msg_auth_password_update_success ||
              "Your password is updated successfully.",
          })
        );
        dispatch(
          authSlice.actions.setToken({
            token: response.data.token,
            expiresIn: response.data.expiresIn,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        authSlice.actions.setErrorAuth({ value: err.response.data.message })
      );
    }
  };
};

export const getGeolocation = () => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "GET",
        url: `${URL}/users/geolocation-data`,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          authSlice.actions.setUserGeolocation({
            data: response.data.geolocationData,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const { logout } = authSlice.actions;

export default authSlice;
