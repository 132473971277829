// TranslationContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { loadLanguage } from "./utils/load-languages";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "de"
  );
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    loadLanguage(language)
      .then((trans) => {
        setTranslations(trans);
      })
      .catch((error) => {
        console.error("Error loading translations:", error);
      });
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <TranslationContext.Provider
      value={{ language, setLanguage, translations }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
