import { useCallback } from "react";

import classes from "./InsertNewImages.module.css";

import { LuImagePlus } from "react-icons/lu";

import config from "../../website-config";

const MAX_IMAGES_TO_UPLOAD = config.maxNumOfImagesToUpload;

const InsertNewImages = (props) => {
  const setImages = useCallback(
    (e) => {
      const keys = Object.keys(e.target.files);

      let availableImageSlots = 0;
      // console.log(props.formType);
      if (props.formType === "create-article") {
        availableImageSlots =
          MAX_IMAGES_TO_UPLOAD - props.data.imagesForUpload.length;
      } else if (props.formType === "edit-article") {
        availableImageSlots =
          MAX_IMAGES_TO_UPLOAD -
          (props.data.imagesForUpload.length + props.data.fetchedImages.length);
      }

      if (availableImageSlots <= 0) return;

      keys.forEach((key) => {
        if (availableImageSlots <= 0) return;
        const fileReader = new FileReader();
        let currentFile = e.target.files[key];

        ((file) => {
          if (!props.data.imagesNames.includes(file.name)) {
            props.data.setImagesForUpload((oldState) => [...oldState, file]);
            props.data.setImagesNames((oldState) => [...oldState, file.name]);
            fileReader.onload = () => {
              props.data.setImagesPreviewURL((oldState) => [
                ...oldState,
                { name: file.name, img: fileReader.result },
              ]);
            };
            fileReader.readAsDataURL(file);
            availableImageSlots = availableImageSlots - 1;
          }
        })(currentFile);
      });
    },
    [props]
  );
  // console.log(props.formType, props.data.fetchedImages);
  return (
    <div className={classes.container}>
      <label htmlFor="images">
        <LuImagePlus className={classes["icon"]} />
      </label>
      <input
        className={classes["images-input"]}
        type="file"
        name="images"
        id="images"
        accept=".jpg,.jpeg,.png"
        multiple
        onChange={setImages}
      />
    </div>
  );
};

export default InsertNewImages;
