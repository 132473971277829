import { useEffect, useRef } from "react";

import classes from "./RealEstateFilters.module.css";

//COMPONENTS
import SelectInput from "../../../../UI/SelectInput";
import NumberInput from "../../../../UI/NumberInput";

//CUSTOM HOOKS
import useRealEstateTranslator from "../../../../custom-hooks/useRealEstateTranslator";

// CONTEXT
import { useTranslation } from "../../../../TranslationContext";

import { realEstateStatus } from "../../../../utils/real-estate-filters";

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const _realEstateStatusList = realEstateStatus.map((re) => {
  return { value: re.index, label: re.label };
});

const SingleCheckbox = (props) => {
  return (
    <div
      className={`${props.isActive ? "" : classes.inactive} ${
        classes["single-checkbox"]
      }`}
      onClick={props.onClick}
    >
      <p
        className={`${props.isActive ? "" : classes.inactive} ${
          classes["label"]
        }`}
      >
        {props.data.label}
      </p>
      {props.data.value ? (
        <MdCheckBox
          className={`${props.isActive ? "" : classes.inactive} ${
            classes.icon
          }`}
        />
      ) : (
        <MdCheckBoxOutlineBlank
          className={`${props.isActive ? "" : classes.inactive} ${
            classes.icon
          }`}
        />
      )}
    </div>
  );
};

const RealEstateFilters = (props) => {
  const { translations } = useTranslation();
  const RETHook = useRealEstateTranslator();
  const stateRef = useRef();

  //REAL-ESTATE STATE HANDLERS
  useEffect(() => {
    if (!RETHook.translatedRES || RETHook.translatedRES.length === 0) return;
    let realEstateStatusPicked = RETHook.translatedRES.find(
      (c) => c.value === props.data.realEstateStatus?.get
    );

    stateRef.current.setValue({
      value: realEstateStatusPicked.value,
      label: realEstateStatusPicked.label,
    });
  }, [props.data.realEstateStatus, RETHook.translatedRES]);

  const changeRealEstateStatusHandler = (value) => {
    props.data.realEstateStatus.set(value.value);
  };

  //ROOMS
  const setNumberOfRoomsHandler = (e) => {
    e.preventDefault();
    props.data.rooms.set(e.target.value);
  };

  //AREA
  const setMinAreaHandler = (e) => {
    e.preventDefault();
    props.data.minArea.set(e.target.value);
  };
  const setMaxAreaHandler = (e) => {
    e.preventDefault();
    props.data.maxArea.set(e.target.value);
  };

  // BALCONY
  const setBalconyHandler = (e) => {
    e.preventDefault();
    if (!props.data.REaditionalFilters.get) return;
    props.data.balcony.set(!props.data.balcony.get);
  };
  // ELEVATOR
  const setElevatorHandler = (e) => {
    e.preventDefault();
    if (!props.data.REaditionalFilters.get) return;
    props.data.elevator.set(!props.data.elevator.get);
  };

  // GARAGE
  const setGarageHandler = (e) => {
    e.preventDefault();
    if (!props.data.REaditionalFilters.get) return;
    props.data.garage.set(!props.data.garage.get);
  };

  // TOGGLE REFILTERS
  const toggleREFiltersHandler = (e) => {
    e.preventDefault();
    props.data.REaditionalFilters.set(!props.data.REaditionalFilters.get);
  };

  return (
    <div className={classes.container}>
      <h3>{translations.msg_real_estate_filters || "Immobilien Filter"}</h3>
      <div className={classes.divider} />
      <div className={classes["realestate-body"]}>
        <SelectInput
          data={{
            name: translations.msg_real_estate_status || "Immobilien-Status",
            options: RETHook.translatedRES,
            isSearchable: true,
            defaultValue: { value: 0, label: "All" },
            ref: stateRef,
          }}
          onChange={changeRealEstateStatusHandler}
        />
        <NumberInput
          data={{
            name: translations.msg_real_estate_rooms || "Anzahl der Zimmer",
            value: props.data.rooms.get,
            placeholder: "",
          }}
          onChange={setNumberOfRoomsHandler}
          onBlur={() => {}}
        />
        <div className={classes["area-input"]}>
          <h4>{`${translations.msg_real_estate_area || "Fläche"} (m2)`}</h4>
          <div className={classes.inputs}>
            <NumberInput
              data={{
                name: "",
                value: props.data.minArea.get,
                placeholder: translations.msg_priceinput_from || "Von",
              }}
              onChange={setMinAreaHandler}
              onBlur={() => {}}
            />
            <div className={classes.dash} />
            <NumberInput
              data={{
                name: "",
                value: props.data.maxArea.get,
                placeholder: translations.msg_priceinput_to || "Bis",
              }}
              onChange={setMaxAreaHandler}
              onBlur={() => {}}
            />
          </div>
        </div>
      </div>
      <div className={classes["divider-second"]} />
      <div className={classes["toggle-aditional-filters"]}>
        <p>
          {translations.msg_real_estate_aditional_filters ||
            "Aditional Filters"}
        </p>
        <div
          className={classes["toggle-slider"]}
          onClick={toggleREFiltersHandler}
          style={
            props.data.REaditionalFilters.get
              ? { justifyContent: "flex-end", backgroundColor: "var(--on)" }
              : { justifyContent: "flex-start" }
          }
        >
          <div className={classes["toggle-thumb"]} />
        </div>
      </div>
      {props.data.REaditionalFilters.get && (
        <div className={classes["realestate-body__two"]}>
          <SingleCheckbox
            data={{
              label: translations.msg_real_estate_balcony || "Balcony",
              value: props.data.balcony.get,
            }}
            isActive={props.data.REaditionalFilters.get}
            onClick={setBalconyHandler}
          />
          <SingleCheckbox
            data={{
              label: translations.msg_real_estate_elevator || "Elevator",
              value: props.data.elevator.get,
            }}
            isActive={props.data.REaditionalFilters.get}
            onClick={setElevatorHandler}
          />
          <SingleCheckbox
            data={{
              label: translations.msg_real_estate_garage || "Garage",
              value: props.data.garage.get,
            }}
            isActive={props.data.REaditionalFilters.get}
            onClick={setGarageHandler}
          />
        </div>
      )}
    </div>
  );
};

export default RealEstateFilters;
