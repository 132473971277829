import React from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "./SingleNav.module.css";

const SingleNav = (props) => {
  const navigate = useNavigate();

  const handleRedirect = (e) => {
    e.preventDefault();
    props.expandMenu(false);
    props.expandProfile(false);
    navigate(props.to);
  };

  return (
    <Link
      className={classes["single__nav"]}
      onClick={handleRedirect}
      style={props.style}
    >
      {props.text}
    </Link>
  );
};

export default SingleNav;
