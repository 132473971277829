import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UserDropdown from "./UserDropdown/UserDropdown";
import { useTranslation } from "../../TranslationContext";

import classes from "./Header.module.css";

// STORE
import { articleActions } from "../../store/redux-store";

//COMPONENTS
import HeaderTandM from "./header-tablet-and-mobile/HeaderTandM";
import Logo from "./Logo";
import FlagIcon from "./header-tablet-and-mobile/FlagIcon";

import {
  IoMdChatboxes,
  IoMdPerson,
  IoMdArrowDropdown,
  IoMdListBox,
  IoIosAdd,
} from "react-icons/io";
import { MdLanguage } from "react-icons/md";

const Header = (props) => {
  const { isAuthenticated, user, hasUnreadMessages } = useSelector(
    (state) => state.auth
  );
  const [isUserDropdownExpanded, expandUserDropdown] = useState(false);
  const { newMessageReceived } = useSelector((state) => state.chat);
  const { language, setLanguage, translations } = useTranslation();
  const [showLanguagesDropdown, setShowLanguagesDropdown] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  //USER MENU OFF CLICK CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isUserDropdownExpanded && !event.target.closest(".userMenuItem")) {
        expandUserDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isUserDropdownExpanded]);
  //LANG MENU OFF CLICK CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLanguagesDropdown && !event.target.closest(".languages")) {
        setShowLanguagesDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showLanguagesDropdown]);

  const resetFiltersHandler = (e) => {
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
      dispatch(articleActions.resetSearchFilters());
      dispatch(articleActions.setCurrentCategory({ value: 0 }));
      dispatch(articleActions.setSearch({ value: "" }));
      dispatch(articleActions.setRefreshArticles({ value: true }));
    }
  };

  // const handleLanguageChange = (e) => {
  //   setLanguage(e.target.value);
  //   localStorage.setItem("language", e.target.value);
  // };

  const showLanguageSelectHandler = (e) => {
    e.preventDefault();
    setShowLanguagesDropdown(!showLanguagesDropdown);
  };

  const setLanguageHandler = (lang) => {
    setLanguage(lang);
    setShowLanguagesDropdown(false);
  };

  return (
    <div className={classes.container} id="header" ref={props.refHeader}>
      <div className={classes["nav-container"]}>
        <div className={classes["left-nav"]}>
          <Logo onClick={resetFiltersHandler} />
        </div>
        <div className={classes["links"]}>
          <Link to={"/terms-of-use#about-us"} className={classes.link}>
            <p>{translations.msg_header_terms_of_use || "Terms of Use"}</p>
          </Link>
          <Link
            to={
              isAuthenticated && user._id
                ? "/articles/create-article"
                : "/auth/login"
            }
            className={classes.link}
          >
            <IoIosAdd className={classes.icon} />
            <p>{translations.msg_header_create_ad || "Inserat aufgeben"}</p>
          </Link>
          <Link
            to={isAuthenticated && user._id ? "/messages" : "/auth/login"}
            className={classes.link}
          >
            <IoMdChatboxes className={classes.icon}></IoMdChatboxes>
            {(newMessageReceived || hasUnreadMessages) && (
              <div className={classes.alert} />
            )}
            <p>{translations.msg_header_messages || "Messages"}</p>
          </Link>

          <Link
            to={
              isAuthenticated && user._id
                ? "/userArticles/active"
                : "/auth/login"
            }
            className={classes.link}
          >
            <IoMdListBox className={classes.icon} />
            <p>{translations.msg_header_my_ads || "Meine Inserate"}</p>
          </Link>

          {isAuthenticated ? (
            <div className={`userMenuItem ${classes.userMenuItem}`}>
              <Link
                to="#"
                className={classes.link}
                onClick={(e) => {
                  e.preventDefault();
                  expandUserDropdown(!isUserDropdownExpanded);
                }}
              >
                <IoMdPerson className={classes.icon} />
                <p>{translations.msg_header_profile || "Profile"}</p>
                {/* <p>{user.username}</p> */}
                <IoMdArrowDropdown className={classes["dropdown-icon"]} />
              </Link>

              {isUserDropdownExpanded && (
                <UserDropdown
                  socket={props.socket}
                  isUserDropdownExpanded={isUserDropdownExpanded}
                  expandUserDropdown={expandUserDropdown}
                />
              )}
            </div>
          ) : (
            <Link to="/auth/login" className={classes.link}>
              <p>{translations.msg_header_login || "Login"}</p>
            </Link>
          )}
          {/* <select
            className={classes.languageSelector}
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="de">
              {translations.msg_header_german || "Deutsch"}
            </option>
            <option value="fr">
              {translations.msg_header_french || "Français"}
            </option>
            <option value="it">
              {translations.msg_header_italian || "Italiano"}
            </option>
            <option value="en">
              {translations.msg_header_english || "Englisch"}
            </option>
            <option value="ba">
              {translations.msg_header_bosnian || "Bosnisch"}
            </option>
          </select> */}
          <div className={`languages ${classes.languages}`}>
            <div
              className={classes["languages-btn"]}
              onClick={showLanguageSelectHandler}
            >
              <MdLanguage className={classes.icon} />
              <p className={classes.lang}>{language}</p>
            </div>
            {showLanguagesDropdown && (
              <div className={classes["lang__dropdown"]}>
                <FlagIcon
                  colors={{
                    first: "#231f20",
                    second: "#e32119",
                    third: "#ffd300",
                  }}
                  lang="de"
                  onClick={() => {
                    setLanguageHandler("de");
                  }}
                  style={{ flexDirection: "column" }}
                />
                <FlagIcon
                  colors={{
                    first: "#002654",
                    second: "#ffffff",
                    third: "#ed2939",
                  }}
                  lang="fr"
                  onClick={() => {
                    setLanguageHandler("fr");
                  }}
                  style={{ flexDirection: "row" }}
                />
                <FlagIcon
                  colors={{
                    first: "#008c45",
                    second: "#ffffff",
                    third: "#cd212a",
                  }}
                  lang="it"
                  onClick={() => {
                    setLanguageHandler("it");
                  }}
                  style={{ flexDirection: "row" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <HeaderTandM onClickLogo={resetFiltersHandler} socket={props.socket} />
    </div>
  );
};

export default Header;
