import { useState } from "react";

import { useTranslation } from "../TranslationContext";

const useValidator = () => {
  const { translations } = useTranslation();

  const numberValidate = (value, setError, fieldName, options = {}) => {
    setError(null);
    console.log(`${value}`);
    if (`${value}`.includes("-")) {
      let translatedMsg = translations.msg_error_negative_number;
      let msg = `${fieldName} can't be a negative number!`;
      console.log(translatedMsg, msg);
      setError(translatedMsg || msg);
      return;
    }

    if (!value) {
      let translatedMsg = translations.msg_error_invalid_number;
      let msg = `${fieldName} is invalid number!`;
      setError(translatedMsg || msg);
      return;
    }

    if (options.min && value < options.min) {
      setError(`${fieldName} can't be less than ${options.min}`);
      return;
    }

    if (options.max && value > options.max) {
      setError(`${fieldName} can't be greater than ${options.max}`);
      return;
    }
  };

  // const template =
  //     translations.msg_filterscontainer_found_articles || `{0} articles in {1}`;
  //   const category = props.hook.selectedCategoryOption;

  //   return template.replace(/{(\d)}/g, (match, i) => {
  //     return i == 0 ? numOfArticles : category.label;
  //   });

  const textValidate = (value, setError, fieldName, options = {}) => {
    setError(null);

    if (options.min && value.length < options.min) {
      let template =
        translations.msg_error_min_characters ||
        `{0} must be at least {1} characters.`;
      let msg = template.replace(/{(\d)}/g, (match, i) => {
        return i == 0 ? fieldName : options.min;
      });
      setError(msg);
    }

    if (options.max && value.length > options.max) {
      let template =
        translations.msg_error_max_characters ||
        `{0} can't contain more than {1} characters`;
      let msg = template.replace(/{(\d)}/g, (match, i) => {
        return i == 0 ? fieldName : options.max;
      });
      setError(msg);
    }
  };

  return {
    numberValidate,
    textValidate,
  };
};

export default useValidator;
