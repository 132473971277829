import React from "react";
import { Routes, Route } from "react-router-dom";

import classes from "./AdminPanel.module.css";

//COMPONENTS
import AdminPanelNav from "../components/admin-panel/AdminPanelNav";
import GeneralContainer from "../components/admin-panel/general/GeneralContainer";
import ArticlesContainer from "../components/admin-panel/ArticlesContainer";
import UsersContainer from "../components/admin-panel/UsersContainer";
import ChatsContainer from "../components/admin-panel/ChatsContainer";
import Footer from "../components/footer/Footer";

const AdminPanel = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <AdminPanelNav />
        <Routes>
          <Route path="/general" element={<GeneralContainer />} />
          <Route path="/reports/articles" element={<ArticlesContainer />} />
          <Route path="/reports/users" element={<UsersContainer />} />
          <Route path="/reports/chats" element={<ChatsContainer />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default AdminPanel;
