import React from "react";

import classes from "./TextInput.module.css";

const TextInput = (props) => {
  return (
    <div className={classes.input}>
      {props.data.name && (
        <label htmlFor={props.data.name}>{props.data.name}</label>
      )}
      <input
        id={props.data.name}
        type="text"
        onKeyDown={props.onKeyDown}
        name={props.data.name}
        value={props.data.value}
        style={props.data.error && { borderColor: "red" }}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.data.error && (
        <p className={classes.error}>
          {props.data.error && `* ${props.data.error}`}
        </p>
      )}
    </div>
  );
};

export default TextInput;
