import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//STORE
import {
  alertActions,
  articleActions,
  chatActions,
  userArticleActions,
  authActions,
  reportActions,
  adminActions,
} from "../store/redux-store";
// import { authActions } from "../store/redux-store";

const SHOW_ALERT_MESSAGE = 3;

const useAlert = () => {
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.alert);
  const { errorArticle, messageArticle } = useSelector(
    (state) => state.article
  );
  const { errorChat, messageChat } = useSelector((state) => state.chat);
  const { errorAuth, messageAuth } = useSelector((state) => state.auth);
  const { errorReport, messageReport } = useSelector((state) => state.report);
  const { errorAdmin, messageAdmin } = useSelector((state) => state.admin);
  const { errorUserArticle, messageUserArticle } = useSelector(
    (state) => state.userArticle
  );

  const removeAllErrorAlerts = useCallback(() => {
    dispatch(alertActions.setError({ error: null }));
    dispatch(authActions.setErrorAuth({ value: null }));
    dispatch(articleActions.setErrorArticle({ value: null }));
    dispatch(userArticleActions.setErrorUserArticle({ value: null }));
    dispatch(reportActions.setErrorReport({ value: null }));
    dispatch(adminActions.setErrorAdmin({ value: null }));
  }, [dispatch]);
  const removeAllMessageAlerts = useCallback(() => {
    dispatch(alertActions.setMessage({ message: null }));
    dispatch(authActions.setMessageAuth({ value: null }));
    dispatch(articleActions.setMessageArticle({ value: null }));
    dispatch(userArticleActions.setMessageUserArticle({ value: null }));
    dispatch(reportActions.setMessageReport({ value: null }));
    dispatch(adminActions.setMessageAdmin({ value: null }));
  }, [dispatch]);

  const handleError = useCallback(
    (errAlert) => {
      if (errAlert === null) return;
      dispatch(alertActions.setError({ error: errAlert }));
      let timeout = setTimeout(() => {
        removeAllErrorAlerts();
      }, 1000 * SHOW_ALERT_MESSAGE);

      return () => {
        clearTimeout(timeout);
      };
    },
    [dispatch, removeAllErrorAlerts]
  );
  const handleMessage = useCallback(
    (msgAlert) => {
      if (msgAlert === null) return;
      dispatch(alertActions.setMessage({ message: msgAlert }));
      let timeout = setTimeout(() => {
        removeAllMessageAlerts();
      }, 1000 * SHOW_ALERT_MESSAGE);

      return () => {
        clearTimeout(timeout);
      };
    },
    [dispatch, removeAllMessageAlerts]
  );

  ////////////////////////////////////
  // CHECK ARTICLE ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorArticle);
  }, [errorArticle, handleError]);
  useEffect(() => {
    handleMessage(messageArticle);
  }, [messageArticle, handleMessage]);

  ////////////////////////////////////
  // CHECK AUTH ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorAuth);
  }, [errorAuth, handleError]);
  useEffect(() => {
    handleMessage(messageAuth);
  }, [messageAuth, handleMessage]);

  ////////////////////////////////////
  // CHECK CHAT ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorChat);
  }, [errorChat, handleError]);
  useEffect(() => {
    handleMessage(messageChat);
  }, [messageChat, handleMessage]);

  ////////////////////////////////////
  // CHECK USER ARTICLE ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorUserArticle);
  }, [errorUserArticle, handleError]);
  useEffect(() => {
    handleMessage(messageUserArticle);
  }, [messageUserArticle, handleMessage]);

  ////////////////////////////////////
  // CHECK REPORTS ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorReport);
  }, [errorReport, handleError]);
  useEffect(() => {
    handleMessage(messageReport);
  }, [messageReport, handleMessage]);

  // CHECK ADMIN ALERTS AND SET THEM //
  useEffect(() => {
    handleError(errorAdmin);
  }, [errorAdmin, handleError]);
  useEffect(() => {
    handleMessage(messageAdmin);
  }, [messageAdmin, handleMessage]);

  // SET ALERTS //
  const setMessage = (msg) => {
    dispatch(alertActions.setMessage({ message: msg }));
    setTimeout(() => {
      dispatch(alertActions.setMessage({ message: null }));
    }, 1000 * SHOW_ALERT_MESSAGE);
  };

  const setError = (err) => {
    dispatch(alertActions.setError({ error: err }));
    setTimeout(() => {
      dispatch(alertActions.setError({ error: null }));
    }, 1000 * SHOW_ALERT_MESSAGE);
  };

  return {
    error,
    message,
    setMessage,
    setError,
  };
};

export default useAlert;
