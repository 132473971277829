import React from "react";

import classes from "./SingleImage.module.css";

const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_SUFFIX_LARGE;
const IMAGE_URL_PATH = process.env.REACT_APP_IMAGE_URL;

const SingleImage = (props) => {
  const setActiveImgHandler = (e) => {
    e.preventDefault();
    props.setActiveImage(props.data.index);
  };

  return (
    <li
      className={classes.container}
      onClick={setActiveImgHandler}
      style={
        props.data.index === props.data.activeImg
          ? { transform: "scale(1.1)", opacity: "1" }
          : null
      }
    >
      <img
        className={classes["img"]}
        src={`${IMAGE_URL_PATH}/articles${IMAGE_PREFIX}/${props.data.image}`}
        alt={props.data.title}
      />
    </li>
  );
};

export default SingleImage;
