import React from "react";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./ThirdForm.module.css";
import { LuImagePlus } from "react-icons/lu";

// COMPONENTS
import ImagesUpload from "./ImagesUpload";

const ThirdForm = (props) => {
  const { translations } = useTranslation(); // Use translations
  const { imagesPREV } = props.data;

  return (
    <div className={classes.container}>
      <h3>
        {translations.msg_thirdform_images || "Images"}
        {` ${imagesPREV.length + props.data.fetchedImages.length} / 12`}
      </h3>
      <div className={classes["images-container"]}>
        {imagesPREV.length > 0 && (
          <LuImagePlus className={classes["images-container__background"]} />
        )}
        <ImagesUpload
          imagesPREV={imagesPREV}
          data={props.data}
          formType={props.formType}
        />
      </div>
    </div>
  );
};

export default ThirdForm;
