import React, { useEffect, useState } from "react";

import classes from "./Category.module.css";
import { IoMdArrowDropright } from "react-icons/io";

//COMPONENTS
import SelectInput from "../../UI/SelectInput";

// CUSTOM HOOKS
import useCategoryTranslator from "../../custom-hooks/useCategoryTranslator";

// CONTEXT
import { useTranslation } from "../../TranslationContext";

const Category = (props) => {
  const CT = useCategoryTranslator();
  const { translations } = useTranslation();
  const {
    //REFS
    categoryRef,
    subCategoryRef,
    //VALUES
    category,
    //HANDLERS
    setCategoryHandler,
    setSubCategoryHandler,
    //LISTS
    subCategories,
    _categoriesRenderer,
  } = props.data;
  const [subCatDisabled, setSubCatDisabled] = useState(true);

  useEffect(() => {
    if (!(props.formType === "edit-article")) {
      setSubCatDisabled(
        subCategories.length > 0 && subCategories[0].label !== "" ? false : true
      );
    }
  }, [props.formType, subCategories]);

  useEffect(() => {
    if (!categoryRef.current || !category.value) return;
    let pickedCat = CT._catListForm.find((c) => c.value === category.value);
    categoryRef.current.setValue(pickedCat || CT._catListForm[0]);
  }, [categoryRef, category, CT._catListForm]);

  return (
    <div className={classes.container}>
      <h4>Category</h4>
      <div className={classes["selects"]}>
        <SelectInput
          data={{
            name: "",
            options: CT._catListForm,
            isSearchable: window.innerWidth <= 800 ? false : true,
            isDisabled: props.formType === "edit-article",
            defaultValue: CT._catListForm[0],
            ref: categoryRef,
          }}
          onChange={setCategoryHandler}
        />
        <div className={classes.arrow}>
          <IoMdArrowDropright className={classes.icon} />
        </div>
        <p className={classes.subcategory}>Subcategory</p>
        <SelectInput
          data={{
            name: "",
            options: subCategories.length > 0 ? subCategories : [],
            isSearchable: window.innerWidth <= 800 ? false : true,
            isDisabled: subCatDisabled,
            defaultValue: subCategories[0],
            ref: subCategoryRef,
          }}
          onChange={setSubCategoryHandler}
        />
      </div>
    </div>
  );
};

export default Category;
