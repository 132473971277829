import React, { useState } from "react";

import classes from "./MainAuthBtn.module.css";

const MainAuthBtn = (props) => {
  const [mouseOver, setMouseOver] = useState(false);

  const mouseIsOver = (e) => {
    setMouseOver(true);
  };

  const mouseIsntOver = (e) => {
    setMouseOver(false);
  };
  return (
    <button
      type="submit"
      onMouseEnter={mouseIsOver}
      onMouseLeave={mouseIsntOver}
      className={classes["btn"]}
    >
      <div
        className={`${classes.background} ${mouseOver ? classes.rotate : ""}`}
      />
      <p>{props.name}</p>
    </button>
  );
};

export default MainAuthBtn;
