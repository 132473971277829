import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./UserCard.module.css";

//STORE
import { blockUser, unblockUser } from "../../../store/reducers/admin-slice";

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const URL = process.env.REACT_APP_IMAGE_URL;

export const SingeSpec = (props) => {
  return (
    <div
      className={`${props.class} ${classes["single-spec"]}`}
      style={props.style}
    >
      <h5>{props.data.label}:</h5>
      {!props.data.isCheck && <p>{props.data.value}</p>}
      {props.data.isCheck && (
        <div>
          {props.data.value ? (
            <MdCheckBox className={classes["icon"]} />
          ) : (
            <MdCheckBoxOutlineBlank className={classes["icon"]} />
          )}
        </div>
      )}
    </div>
  );
};

const _statusList = ["active", "blocked", "inactive", "deleted"];
const allowed = ["admin", "moderator"];

const UserCard = (props) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, token } = useSelector((state) => state.auth);

  const unblockUserHandler = (e) => {
    e.preventDefault();
    if (!user._id || !isAuthenticated) return;
    if (props.data.status === 2 && allowed.includes(user.role)) {
      dispatch(unblockUser({ userId: props.data._id }, token));
    }
  };

  const blockUserHandler = (e) => {
    e.preventDefault();
    console.log("block");
    if (!user._id || !isAuthenticated) return;
    console.log("block2");
    if (props.data.status === 1 && allowed.includes(user.role)) {
      console.log("block3");
      dispatch(blockUser({ userId: props.data._id }, token));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes["image-container"]}>
        <img src={`${URL}/users/${props.data.image}`} alt={props.data._id} />
      </div>
      <div className={classes["user-data"]}>
        <h3>General:</h3>
        <SingeSpec data={{ label: "ID", value: props.data._id }} />
        <SingeSpec data={{ label: "Role", value: props.data.role }} />
        <SingeSpec
          data={{ label: "Dispay Name", value: props.data.username }}
        />
        <SingeSpec data={{ label: "Email", value: props.data.email }} />
        <SingeSpec data={{ label: "Phone", value: props.data.phone }} />
        <SingeSpec
          data={{
            label: "Company",
            value: props.data.isCompany,
            isCheck: true,
          }}
        />
        <SingeSpec data={{ label: "Website", value: props.data.website }} />
        <SingeSpec
          data={{ label: "Status", value: _statusList[props.data.status - 1] }}
        />
        <h3>Address:</h3>
        <SingeSpec data={{ label: "City", value: props.data.city }} />
        <SingeSpec data={{ label: "Postal Code", value: props.data.zip }} />
        <SingeSpec data={{ label: "Street 1", value: props.data.street1 }} />
        <SingeSpec data={{ label: "Street 2", value: props.data.street2 }} />
        <SingeSpec
          data={{
            label: "Created At",
            value: new Date(props.data.createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
          }}
        />
        <div className={classes.btns}>
          {props.data.status === 2 ? (
            <button
              className={classes.btn}
              style={
                props.data.status === 1
                  ? { backgroundColor: "var(--inactive)", cursor: "default" }
                  : {}
              }
              onClick={unblockUserHandler}
            >
              Unblock
            </button>
          ) : (
            <button
              className={classes.btn}
              style={
                props.data.status === 2
                  ? { backgroundColor: "var(--inactive)", cursor: "default" }
                  : {}
              }
              onClick={blockUserHandler}
            >
              Block
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
