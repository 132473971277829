import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./ChatsContainer.module.css";

//STORE
import { getReports } from "../../store/reducers/report-slice";
import { reportActions } from "../../store/redux-store";

//COMPONENTS
import SingleReport from "./single-report/SingleReport";
import Pagination from "../../UI/paggination/Pagination";

const NUM_OF_REPORTS_PER_PAGE = 10;

const SingleMessage = (props) => {
  return (
    <div className={classes["single-message"]}>
      <p>{`User ID: ${props.data.sender}`}</p>
      <p>{`Message: ${props.data.text}`}</p>
    </div>
  );
};

const ChatsContainer = (props) => {
  const dispatch = useDispatch();
  const { chatsReports } = useSelector((state) => state.report);
  const { token, isAuthenticated } = useSelector((state) => state.auth);
  const [_reportsRenderer, _setReportsRenderer] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [_msgRenderer, _setMsgRenderer] = useState([]);

  useEffect(() => {
    let _list = [];
    messagesList.forEach((m, i) =>
      _list.push(<SingleMessage key={i} data={m} />)
    );
    _setMsgRenderer(_list);
  }, [messagesList]);

  const setMessageListHandler = (msgList) => {
    setMessagesList(msgList);
  };

  const showMessageHandler = () => {
    setShowMessages(true);
  };
  const hideMessageHandler = () => {
    setShowMessages(false);
    setMessagesList([]);
  };

  useEffect(() => {
    if ((token, isAuthenticated)) {
      dispatch(
        getReports(
          {
            queryString: `?reportType=chat&page=${chatsReports.currentPage}&limit=${NUM_OF_REPORTS_PER_PAGE}`,
            reportType: "chat",
          },
          token
        )
      );
    }
  }, [dispatch, chatsReports.currentPage, isAuthenticated, token]);

  useEffect(() => {
    const _reportsList = chatsReports.reports.map((r) => {
      return (
        <SingleReport
          showMessageHandler={showMessageHandler}
          hideMessageHandler={hideMessageHandler}
          setMessageListHandler={setMessageListHandler}
          data={r}
          key={r._id}
        />
      );
    });
    _setReportsRenderer(_reportsList);
  }, [chatsReports.reports, _setReportsRenderer]);

  const firstPageHandler = (e) => {
    e.preventDefault();
    dispatch(reportActions.setArticlesReports({ data: { currentPage: 1 } }));
  };

  const lastPageHandler = (e) => {
    e.preventDefault();
    dispatch(
      reportActions.setArticlesReports({
        data: {
          currentPage: Math.ceil(
            chatsReports.totalNumOfReports / NUM_OF_REPORTS_PER_PAGE
          ),
        },
      })
    );
  };

  const pageHandler = (e) => {
    e.preventDefault();
    dispatch(
      reportActions.setArticlesReports({ data: { currentPage: e.target.id } })
    );
  };

  return (
    <div className={classes.container}>
      {showMessages && (
        <div className={classes["messages-container"]}>
          <p className={classes.close} onClick={hideMessageHandler}>
            X
          </p>
          <div className={classes["messages-container__msgs"]}>
            {_msgRenderer}
          </div>
        </div>
      )}
      <h4>Chats reports</h4>
      <p>{`There is currently ${chatsReports.totalNumOfReports} reports.`}</p>
      <div className={classes.reports}>{_reportsRenderer}</div>
      <Pagination
        style={{ alignSelf: "center" }}
        currentPage={chatsReports.currentPage}
        numOfPages={Math.ceil(
          chatsReports.totalNumOfReports / NUM_OF_REPORTS_PER_PAGE
        )}
        firstPageHandler={firstPageHandler}
        lastPageHandler={lastPageHandler}
        pageHandler={pageHandler}
      />
    </div>
  );
};
export default ChatsContainer;
