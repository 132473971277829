import { useState } from "react";

import classes from "./NumberInput.module.css";

const NumberInput = (props) => {
  const onFocusHandler = (e) => {
    e.preventDefault();
    e.target.select();
  };
  return (
    <div className={classes.container}>
      {props.data.name && (
        <label htmlFor={props.data.name}>{props.data.name}</label>
      )}
      <input
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        type="number"
        name={props.data.name}
        id={props.data.name}
        placeholder={props.data.placeholder}
        value={props.data.value}
        onFocus={onFocusHandler}
        onBlur={props.onBlur}
      ></input>
      {props.data.error && (
        <p className={classes.error}>{`* ${props.data.error}`}</p>
      )}
    </div>
  );
};

export default NumberInput;
