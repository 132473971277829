import React from "react";
import { useNavigate } from "react-router";

import classes from "./LoginBtn.module.css";

const LoginBtn = (props) => {
  const navigate = useNavigate();

  const handleRedirect = (e) => {
    e.preventDefault();
    props.expandMenu(false);
    props.expandProfile(false);
    navigate(props.to);
  };

  return (
    <div className={classes.container} onClick={handleRedirect}>
      {props.text}
    </div>
  );
};

export default LoginBtn;
