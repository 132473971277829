import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import categories, { Categories } from "../utils/categories";
import { getYears, miles as _milesList } from "../utils/vehicle-filters";

//STORE
import { articleActions, userArticleActions } from "../store/redux-store";

const _status = {
  active: 1,
  pending: 2,
  pause: 3,
  deleted: 4,
  other: 5,
};

const _years = getYears();
const _yearsList = [];
_years.forEach((y, i) => _yearsList.push({ value: i + 1, label: y }));

const useFilterArticles = (props) => {
  const dispatch = useDispatch();
  const _categories = new Categories(categories);

  let actions;
  if (props.type === "home") actions = articleActions;
  if (props.type === "user-articles") actions = userArticleActions;

  //SELECTORS
  const { sort, currentCategory, currentSubCategory } = useSelector((state) =>
    props.for === "user-articles" ? state.userArticle : state.article
  );

  //REFS
  const categoryRef = useRef(null);
  const subcategoryRef = useRef(null);
  const sortRef = useRef(null);

  //VALUES
  const [showFilters, setShowFilters] = useState(
    window.innerWidth <= 800 ? false : true
  );
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [search, setSearch] = useState("");
  const [itemCondition, setItemCondition] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isUsed, setIsUsed] = useState(true);

  const [carCompany, setCarCompany] = useState(0);
  const [carModel, setCarModel] = useState(0);
  const [year, setYear] = useState(0);
  const [miles, setMiles] = useState(0);
  const [engine, setEngine] = useState(0);
  const [transmition, setTransmition] = useState(0);
  const [carDoors, setCarDoors] = useState(0);
  const [carColor, setCarColor] = useState(0);
  const [minCc, setMinCc] = useState("");
  const [maxCc, setMaxCc] = useState("");
  const [minKw, setMinKw] = useState("");
  const [maxKw, setMaxKw] = useState("");

  const [realEstateStatus, setRealEstateStatus] = useState(0);
  const [rooms, setRooms] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [balcony, setBalcony] = useState(true);
  const [elevator, setElevator] = useState(true);
  const [garage, setGarage] = useState(true);

  const [REaditionalFilters, setREaditionalFilters] = useState(false);

  //CATEGORY
  const _selectedCategory = _categories.getCategory(currentCategory);
  // const _selectedCategory = categories.find((c) => c.index === currentCategory);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({
    value: _selectedCategory?.index,
    label: _selectedCategory?.name,
  });

  const setSubCatListHandler = useCallback((list) => {
    setSubCategoryList(list);
  }, []);

  const subCatListInit =
    _categories.getFormatedSubcategoriesList(currentCategory);
  const [subCategoryList, setSubCategoryList] = useState(subCatListInit);
  const [selectedSubCatOption, setSelectedSubCatOption] = useState(0);

  useEffect(() => {
    setSelectedSubCatOption(0);
  }, [selectedCategoryOption.value]);

  const [selectedSortOption, setSelectedSortOption] = useState(sort);

  useEffect(() => {
    setCarModel(0);
  }, [carCompany]);

  const searchFilteredArticlesHandler = () => {
    if (props.for === "user-articles") {
      dispatch(actions.setCurrentArticlePage({ value: 1 }));
    }
    dispatch(actions.setSearchFilters({ itemCondition }));
    dispatch(actions.setSearchFilters({ minPrice }));
    dispatch(actions.setSearchFilters({ maxPrice }));
    dispatch(actions.setSearch({ value: search }));
    if (currentCategory === 6) {
      dispatch(
        actions.setSearchFilters({
          carcompany: carCompany === 0 ? null : carCompany,
        })
      );
      dispatch(
        actions.setSearchFilters({
          carmodel: carModel === 0 ? null : carModel,
        })
      );
      dispatch(
        actions.setSearchFilters({
          engynetype: engine === 0 ? null : engine,
        })
      );
      const _pickedYear =
        year === 0 ? 0 : _yearsList.find((y) => y.value === year);
      dispatch(
        actions.setSearchFilters({
          minYear: _pickedYear === 0 ? null : _pickedYear.label,
        })
      );
      const _pickedMiles =
        miles === 0 ? 0 : _milesList.find((m) => m.value === miles);
      dispatch(
        actions.setSearchFilters({
          maxMiles: _pickedMiles === 0 ? null : +_pickedMiles.label,
        })
      );
      dispatch(
        actions.setSearchFilters({
          transmition: transmition === 0 ? null : transmition,
        })
      );
      dispatch(
        actions.setSearchFilters({
          car_color: carColor === 0 ? null : carColor,
        })
      );
      dispatch(
        actions.setSearchFilters({
          car_doors: carDoors === 0 ? null : carDoors,
        })
      );
      dispatch(actions.setSearchFilters({ minCc: minCc === 0 ? null : minCc }));
      dispatch(actions.setSearchFilters({ maxCc: maxCc === 0 ? null : maxCc }));
      dispatch(actions.setSearchFilters({ minKw: minKw === 0 ? null : minKw }));
      dispatch(actions.setSearchFilters({ maxKw: maxKw === 0 ? null : maxKw }));
    }
    if (currentCategory === 11) {
      dispatch(
        actions.setSearchFilters({
          realestatestatus: realEstateStatus === 0 ? null : realEstateStatus,
        })
      );
      dispatch(actions.setSearchFilters({ rooms: rooms === 0 ? null : rooms }));
      dispatch(
        actions.setSearchFilters({
          minArea: minArea === 0 ? null : minArea,
        })
      );
      dispatch(
        actions.setSearchFilters({
          maxArea: maxArea === 0 ? null : maxArea,
        })
      );
      dispatch(
        actions.setSearchFilters({
          balcony: REaditionalFilters ? balcony : null,
        })
      );
      dispatch(
        actions.setSearchFilters({
          garage: REaditionalFilters ? garage : null,
        })
      );
      dispatch(
        actions.setSearchFilters({
          elevator: REaditionalFilters ? elevator : null,
        })
      );
    }
  };

  const toggleFiltersHandler = (e) => {
    e.preventDefault();
    setShowFilters(!showFilters);
  };

  const _categoriesRenderer = categories.map((c) => {
    return { value: c.index, label: c.name };
  });

  useEffect(() => {
    const value = selectedCategoryOption.value;

    if (value !== 6) {
      // dispatch(actions.turnOffVehicleFilters());
    }
    dispatch(actions.setCurrentCategory({ value }));
    // console.log("postavlja subCat na 0");
    dispatch(actions.setCurrentSubCategory({ value: 0 }));

    if (props.for === "user-articles") {
      dispatch(actions.setCurrentArticlePage({ value: 1 }));
    }
  }, [selectedCategoryOption, dispatch, actions, props.for]);

  // console.log(currentSubCategory);
  useEffect(() => {
    const value = selectedSubCatOption;

    // console.log(`postavlja subCat na ${value}`);
    dispatch(actions.setCurrentSubCategory({ value }));

    if (props.for === "user-articles") {
      dispatch(actions.setCurrentArticlePage({ value: 1 }));
    }
  }, [selectedSubCatOption, dispatch, actions, props.for]);

  useEffect(() => {
    const value = selectedSortOption.value
      ? selectedSortOption.value
      : selectedSortOption;
    dispatch(actions.setSort({ value }));
    if (props.for === "user-articles") {
      dispatch(actions.setCurrentArticlePage({ value: 1 }));
    }
  }, [selectedSortOption, dispatch, actions, props.for]);

  const setIsNewHandler = (value) => {
    setIsNew(value);
    setIsUsed(!value);
  };

  const setIsUsedHandler = (value) => {
    setIsUsed(value);
    setIsNew(!value);
  };

  const setIsBothHandler = (value) => {
    setIsUsed(value);
    setIsNew(value);
  };

  useEffect(() => {
    if (isNew && isUsed) {
      setItemCondition(null);
    } else if (isNew && !isUsed) {
      setItemCondition("new");
    } else if (!isNew && isUsed) {
      setItemCondition("used");
    } else {
      setItemCondition(null);
    }
  }, [isNew, isUsed]);

  useEffect(() => {
    if (!categoryRef.current?.getValue()) return;
    if (
      categoryRef.current?.getValue()[0]?.value !== selectedCategoryOption.value
    ) {
      categoryRef.current.setValue(selectedCategoryOption);
    }
  }, [props.actions, selectedCategoryOption]);

  useEffect(() => {
    return () => {
      dispatch(actions.setCurrentCategory({ value: 0 }));
      dispatch(actions.setCurrentSubCategory({ value: 0 }));
      dispatch(actions.setSort({ value: "-createdAt" }));
      dispatch(actions.resetSearchFilters());
    };
  }, [dispatch, actions]);

  const getFormattedArticlesText = () => {
    return (
      props.translations.msg_filterscontainer_found_articles ||
      "We found {0} articles for you."
    ).replace("{0}", props.numOfArticles);
  };

  return {
    //LISTS
    _categoriesRenderer,
    // _sortOptions,

    //REFS
    categoryRef,
    subcategoryRef,
    sortRef,

    //VALUES
    selectedCategoryOption,
    subCategoryList,
    selectedSubCatOption,
    search,
    currentCategory,
    currentSubCategory,
    sort,
    minPrice,
    maxPrice,
    isNew,
    isUsed,
    carCompany,
    carModel,
    year,
    miles,
    engine,
    transmition,
    carDoors,
    carColor,
    minCc,
    maxCc,
    minKw,
    maxKw,
    realEstateStatus,
    rooms,
    minArea,
    maxArea,
    balcony,
    elevator,
    garage,
    REaditionalFilters,
    //VALUE SET
    setSelectedCategoryOption,
    setSubCategoryList,
    setSelectedSubCatOption,
    setSearch,
    setSelectedSortOption,
    setMinPrice,
    setMaxPrice,
    setCarCompany,
    setCarModel,
    setYear,
    setMiles,
    setEngine,
    setTransmition,
    setCarDoors,
    setCarColor,
    setMinCc,
    setMaxCc,
    setMinKw,
    setMaxKw,
    setRealEstateStatus,
    setRooms,
    setMinArea,
    setMaxArea,
    setBalcony,
    setElevator,
    setGarage,
    setREaditionalFilters,
    //HANDLERS
    searchFilteredArticlesHandler,
    toggleFiltersHandler,
    setIsNewHandler,
    setIsUsedHandler,
    setIsBothHandler,
    setSubCatListHandler,

    //FUNCTIONS
    getFormattedArticlesText,
  };
};

export default useFilterArticles;
