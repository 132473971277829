import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import classes from "./Login.module.css";
import { useTranslation } from "../../TranslationContext";

import { login } from "../../store/reducers/auth-store";

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

//STORE
import { alertActions } from "../../store/redux-store";

import MainAuthBtn from "./MainAuthBtn";

// import { FcGoogle } from "react-icons/fc";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const submitFormHandler = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }, navigate, null, translations));
    props.setCanConnect(true);
  };

  const setEmailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const setPasswordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const toggleShowPassHandler = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  return (
    <div className={classes.container}>
      <h2>{translations.msg_login_title}</h2>
      <form className={classes.form} onSubmit={submitFormHandler}>
        <div className={classes["input-field"]}>
          <label htmlFor="email"></label>
          <input
            className={classes.input}
            id="email"
            type="text"
            name="email"
            placeholder={translations.msg_login_placeholder_email}
            value={email}
            onChange={setEmailHandler}
          ></input>
        </div>
        <div className={classes["input-field"]}>
          <label htmlFor="password"></label>
          <input
            className={classes.input}
            id="password"
            type={!showPass ? "password" : "text"}
            name="password"
            placeholder={translations.msg_login_placeholder_password}
            value={password}
            onChange={setPasswordHandler}
          ></input>
          {!showPass ? (
            <IoMdEye className={classes.icon} onClick={toggleShowPassHandler} />
          ) : (
            <IoMdEyeOff
              className={classes.icon}
              onClick={toggleShowPassHandler}
            />
          )}
        </div>
        <Link to="/auth/forgot-password" className={classes["password-forgot"]}>
          {translations.msg_login_forgot_password}
        </Link>
        <MainAuthBtn name={translations.msg_login_button} />
        {/* <span>OR</span>
        <div className={classes["gmail-login"]}>
          <FcGoogle className={classes["gmail-icon"]} />
        </div> */}
      </form>
    </div>
  );
};

export default Login;
