import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let URL_PATH = process.env.REACT_APP_URL;

const initialAdminState = {
  errorAdmin: null,
  messageAdmin: null,
  user: {},
  article: {},
  chat: {},
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {
    setErrorAdmin(state, action) {
      state.errorAdmin = action.payload.value;
    },
    setMessageAdmin(state, action) {
      state.messageAdmin = action.payload.value;
    },
    setUser(state, action) {
      state.user = action.payload.data;
    },
    setArticle(state, action) {
      state.article = action.payload.data;
    },
    setChat(state, action) {
      state.chat = action.payload.data;
    },
  },
});

export const getUserById = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "GET",
        url: `${URL_PATH}/admin/user/${userInputs.userId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setUser({ data: response.data.data }));
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const blockUser = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "PATCH",
        url: `${URL_PATH}/admin/user/block/${userInputs.userId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setUser({ data: response.data.data }));
        dispatch(
          adminSlice.actions.setMessageAdmin({
            value: `You blocked user (${response.data.data._id}).`,
          })
        );
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const unblockUser = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "PATCH",
        url: `${URL_PATH}/admin/user/unblock/${userInputs.userId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setUser({ data: response.data.data }));
        dispatch(
          adminSlice.actions.setMessageAdmin({
            value: `You unblocked user (${response.data.data._id}).`,
          })
        );
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const getChatById = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "GET",
        url: `${URL_PATH}/admin/chat/${userInputs.chatId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setChat({ data: response.data.data }));
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const getArticleById = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "GET",
        url: `${URL_PATH}/admin/article/${userInputs.articleId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setArticle({ data: response.data.data }));
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const changeArticleStatus = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "PATCH",
        url: `${URL_PATH}/admin/article/status/${userInputs.articleId}`,
        data: { status: userInputs.status },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setArticle({ data: response.data.data }));
        dispatch(
          adminSlice.actions.setMessageAdmin({
            value: `Article (${response.data.data._id}) is ${
              response.data.data.status === 5 ? "blocked" : "unblocked"
            }.`,
          })
        );
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};
export const changeSubscriptionType = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const axiosOptions = {
        method: "PATCH",
        url: `${URL_PATH}/admin/article/subscription/${userInputs.articleId}`,
        data: { subscriptionType: userInputs.subscriptionType },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(adminSlice.actions.setArticle({ data: response.data.data }));
        dispatch(
          adminSlice.actions.setMessageAdmin({
            value: `Article (${response.data.data._id}) is ${
              response.data.data.subscriptionType === 0
                ? "unpromoted"
                : "promoted"
            }.`,
          })
        );
      }
    } catch (e) {
      console.log(e);
      if (e.response.data?.message) {
        dispatch(
          adminSlice.actions.setErrorAdmin({ value: e.response.data.message })
        );
      }
    }
  };
};

export default adminSlice;
