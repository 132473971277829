import React from "react";
import { Link } from "react-router-dom";

import classes from "./Logo.module.css";

// CONTEXT
import { useTranslation } from "../../TranslationContext";

import config from "../../website-config";

import logoImg from "../../assets/logo/logo.png";

const Logo = (props) => {
  const { translations } = useTranslation();
  return (
    <Link to="/" className={classes.logo} onClick={props.onClick}>
      {/* <div className={classes["red-box"]}>
        <p>{translations.msg_header_logo || "H&P"}</p>
      </div> */}
      <div className={classes["logo-box"]}>
        <img
          src={logoImg}
          alt={`${config.companyName} logo`}
          className={classes["logo"]}
        />
      </div>
    </Link>
  );
};

export default Logo;
