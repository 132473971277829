import { io } from "socket.io-client";

export let socket;

const socketCon = (userData) => {
  socket = io(`${process.env.REACT_APP_SERVER_URL}`, { withCredentials: true });

  socket.on("connection", (socket) => {});

  socket.on("message notification");

  socket.on("connect", (data) => {
    socket.emit("storeClientInfo", { _id: userData._id });
  });

  socket.on("message", (msgData) => console.log(msgData));
};

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket.IO has not been initialized.");
  }
  return socket;
};

export default socketCon;
