import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./GeneralContainer.module.css";

import { FiSearch } from "react-icons/fi";

//STORE
import {
  getUserById,
  getArticleById,
  getChatById,
} from "../../../store/reducers/admin-slice";
import { adminActions } from "../../../store/redux-store";

import UserCard from "./UserCard";
import ArticleCard from "./ArticleCard";
import ChatCard from "./ChatCard";

const GeneralContainer = (props) => {
  const dispatch = useDispatch();
  const { user, article, chat } = useSelector((state) => state.admin);
  const {
    user: admin,
    isAuthenticated,
    token,
  } = useSelector((state) => state.auth);
  const [userIdInput, setUserIdInput] = useState("");
  const [articleIdInput, setArticleIdInput] = useState("");
  const [chatIdInput, setChatIdInput] = useState("");

  //USER
  const setUserInputHandler = (e) => {
    e.preventDefault();
    setUserIdInput(e.target.value);
  };
  const fetchUserHandler = (e) => {
    e.preventDefault();
    if (userIdInput === "") {
      dispatch(adminActions.setUser({ data: null }));
      return;
    }
    if (admin.role === "admin" && isAuthenticated) {
      dispatch(getUserById({ userId: userIdInput }, token));
    }
  };

  //ARTICLE
  const setArticleInputHandler = (e) => {
    e.preventDefault();
    setArticleIdInput(e.target.value);
  };
  const fetchArticleHandler = (e) => {
    e.preventDefault();
    if (articleIdInput === "") {
      dispatch(adminActions.setArticle({ data: null }));
      return;
    }
    if (admin.role === "admin" && isAuthenticated) {
      dispatch(getArticleById({ articleId: articleIdInput }, token));
    }
  };

  //CHAT
  // const setChatInputHandler = (e) => {
  //   e.preventDefault();
  //   setChatIdInput(e.target.value);
  // };
  // const fetchChatHandler = (e) => {
  //   e.preventDefault();
  //   if (admin.role === "admin" && isAuthenticated) {
  //     dispatch(getChatById({ chatId: chatIdInput }, token));
  //   }
  // };

  useEffect(() => {
    return () => {
      dispatch(adminActions.setArticle({ data: null }));
      dispatch(adminActions.setUser({ data: null }));
      // dispatch(adminActions.setChat({ data: null }));
    };
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <h4>User</h4>
        <form className={classes.search} onSubmit={fetchUserHandler}>
          <input
            type="text"
            className={classes.input}
            placeholder={"Input user ID"}
            value={userIdInput}
            onChange={setUserInputHandler}
          />
          <button type="submit">
            <FiSearch />
          </button>
        </form>
        {user?._id && <UserCard data={user} />}
      </div>
      <div className={classes.section}>
        <h4>Article</h4>
        <form className={classes.search} onSubmit={fetchArticleHandler}>
          <input
            type="text"
            className={classes.input}
            placeholder={"Input article ID"}
            value={articleIdInput}
            onChange={setArticleInputHandler}
          />
          <button type="submit">
            <FiSearch />
          </button>
        </form>
        {article?._id && <ArticleCard data={article} />}
      </div>
      {/* <div className={classes.section}>
        <h4>Chat</h4>
        <form className={classes.search} onSubmit={fetchChatHandler}>
          <input
            type="text"
            className={classes.input}
            placeholder={"Input chat ID"}
            value={chatIdInput}
            onChange={setChatInputHandler}
          />
          <button type="submit">
            <FiSearch />
          </button>
        </form>
        {chat._id && <ChatCard data={chat} />}
      </div> */}
    </div>
  );
};

export default GeneralContainer;
