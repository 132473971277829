import React from "react";

import classes from "./TermsOfUseSection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const TermsOfUseSection = (props) => {
  const { translations } = useTranslation();

  return (
    <section
      id={"tos"}
      className={classes.container}
      style={{ backgroundColor: "var(--background)" }}
    >
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_agb || "Terms of Use"}</h3>
        <div className={classes["body"]}>
          <p className={classes["description"]}>
            {translations.tos?.text_1 || ""}
          </p>

          <h5>{translations.tos?.subtitle_1 || "1. Umfang"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st1_text_1 || ""}
          </p>

          <h5>{translations.tos?.subtitle_2 || "2. Rechte und Pflichten"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_2 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_3 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_4 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_5 || ""}
          </p>

          <p className={classes["description"]}>
            {translations.tos?.st2_text_6 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_7 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_8 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_9 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st2_text_10 || ""}
          </p>

          <h5>{translations.tos?.subtitle_3 || "3. Produktrechte"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_2 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_3 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_4 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_5 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_6 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_7 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_8 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_9 || ""}
          </p>
          <ul className={classes["description"]}>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_1 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_2 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_3 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_4 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_5 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_6 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_7 || ""}
            </li>
            <li className={classes["description"]}>
              {translations.tos?.st3_list_8 || ""}
            </li>
          </ul>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_10 || ""}
          </p>

          <p className={classes["description"]}>
            {translations.tos?.st3_text_11 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st3_text_12 || ""}
          </p>

          <h5>{translations.tos?.subtitle_4 || "4. Dienstleistungen"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st4_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st4_text_2 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st4_text_3 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st4_text_4 || ""}
          </p>

          <h5>
            {translations.tos?.subtitle_5 || "5. Lizenz von Nutzerinhalte"}
          </h5>
          <p className={classes["description"]}>
            {translations.tos?.st5_text_1 || ""}
          </p>

          <h5>{translations.tos?.subtitle_6 || "6. Schutz der Daten"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st6_text_1 || ""}
          </p>
          <h5>{translations.tos?.subtitle_7 || "7. Die Haftung"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st7_text_1 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st7_text_2 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st7_text_3 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st7_text_4 || ""}
          </p>
          <p className={classes["description"]}>
            {translations.tos?.st7_text_5 || ""}
          </p>

          <h5>
            {translations.tos?.subtitle_8 || "8. Veränderung und Kündigung"}
          </h5>
          <p className={classes["description"]}>
            {translations.tos?.st8_text_1 || ""}
          </p>

          <h5>{translations.tos?.subtitle_10 || "10. Links"}</h5>
          <p className={classes["description"]}>
            {translations.tos?.st10_text_1 || ""}
          </p>

          <h5>
            {translations.tos?.subtitle_11 ||
              "11. Gerichtsstand von anwendbarem Recht"}
          </h5>
          <p className={classes["description"]}>
            {translations.tos?.st11_text_1 || ""}
          </p>
          <p className={classes["description"]}>{translations.tos?.text_2}</p>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUseSection;
