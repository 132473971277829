import React from "react";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useTranslation } from "../../../TranslationContext"; // Import useTranslation

import classes from "./CategoriesList.module.css";

//STORE
// import { getPromotedArticles } from "../../../store/reducers/article-slice";

import { IoMdCar } from "react-icons/io";
import { FaBuilding, FaToolbox, FaDog } from "react-icons/fa6";
import { GiClothes } from "react-icons/gi";
import { MdComputer, MdChair } from "react-icons/md";

const _catForSmallerScreens = [6, 11, 2, 3];

const SingleCategory = (props) => {
  const fetchPromotedArticlesHandler = () => {
    props.setCurrCat({
      value: props.data.value,
      label: props.data.label,
    });
  };

  // let rnd = Math.ceil(Math.random() * 360);

  return (
    <li
      onClick={fetchPromotedArticlesHandler}
      className={`${
        _catForSmallerScreens.includes(props.data.value) ? "" : classes["hide"]
      } ${classes["single-category"]}`}
      style={
        props.currCat === props.data.value
          ? {
              borderColor: "var(--accent-color)",
              color: "var(--main-color)",
              backgroundColor: "var(--background)",
            }
          : null
      }
    >
      {props.icon}
      <p className={classes.label}>{props.data.label}</p>
      {/* <div
        className={`${classes.hide} ${classes.cube}`}
        style={
          props.currCat === props.data.value
            ? { rotate: "0deg", backgroundColor: "var(--background)" }
            : { rotate: `${rnd}deg` }
        }
      /> */}
    </li>
  );
};

const CategoriesList = (props) => {
  const { translations } = useTranslation(); // Use translations
  // const dispatch = useDispatch();
  // const [currCat, setCurrCat] = useState(6);

  const _selectedCategories = [
    {
      label: translations.category_6 || "Cars",
      value: 6,
      icon: <IoMdCar className={classes.icon} />,
    },
    {
      label: translations.category_11 || "Real Estate",
      value: 11,
      icon: <FaBuilding className={classes.icon} />,
    },
    {
      label: translations.category_10 || "Furniture",
      value: 10,
      icon: <MdChair className={classes.icon} />,
    },
    {
      label: translations.category_12 || "Clothing & Shoes",
      value: 12,
      icon: <GiClothes className={classes.icon} />,
    },
    {
      label: translations.category_5 || "Tech",
      value: 5,
      icon: <MdComputer className={classes.icon} />,
    },
    {
      label: translations.category_17 || "Tools",
      value: 17,
      icon: <FaToolbox className={classes.icon} />,
    },
    {
      label: translations.category_21 || "Animals",
      value: 21,
      icon: <FaDog className={classes.icon} />,
    },
  ];

  // useEffect(() => {
  //   dispatch(
  //     getPromotedArticles({ category: props.selectedCategoryOption.value })
  //   );
  // }, [dispatch, props.selectedCategoryOption]);

  const _catRenderer = _selectedCategories.map((c) => {
    return (
      <SingleCategory
        data={c}
        key={c.value}
        icon={c.icon}
        setCurrCat={props.setSelectedCategoryOption}
        currCat={props.selectedCategoryOption.value}
      />
    );
  });

  return <ul className={classes.container}>{_catRenderer}</ul>;
};

export default CategoriesList;
