import React, { useEffect } from "react";

import classes from "./VehicleFields.module.css";

//COMPONENTS
import SelectInput from "../../UI/SelectInput";
import NumberInput from "../../UI/NumberInput";

//CUSTOM HOOKS
import useVehicleTranslations from "../../custom-hooks/useVehicleTranslations";

// CONTEXT
import { useTranslation } from "../../TranslationContext";

const VehicleFields = (props) => {
  const { translations } = useTranslation();
  const VT = useVehicleTranslations();
  const {
    //LISTS
    _carCompanies,
    carmodelsList,
    // _engineList,
    // _transmitionList,
    _yearsList,
    //VALUES
    ccInput,
    kwInput,
    milesInput,
    carcompany,
    carmodel,
    yearInput,
    engineInput,
    transmitionInput,
    carDoorsInput,
    carColorInput,
    //HANDLERS
    changeCcHandler,
    changeKwHandler,
    changeCarcompanyHandler,
    changecarModelHandler,
    changeEngineHandler,
    changeTransmitionHandler,
    changeYearHandler,
    changeMilesHandler,
    changeDoorsHandler,
    changeCarColorHandler,
    //VALIDATION
    checkCcValidation,
    checkKwValidation,
    checkMilesValidation,
    //ERRORS
    ccError,
    kwError,
    milesError,
    //REFS
    carcompanyRef,
    carmodelRef,
    engineRef,
    transmitionRef,
    yearRef,
    doorsRef,
    carColorRef,
  } = props.data;

  useEffect(() => {
    if (!carcompanyRef.current) return;
    carcompanyRef.current.setValue(carcompany);
  }, [carcompany, carcompanyRef]);

  useEffect(() => {
    if (!carmodelRef.current) return;
    carmodelRef.current.setValue(carmodel);
  }, [carmodel, carmodelRef]);

  useEffect(() => {
    if (!yearRef.current) return;
    yearRef.current.setValue(yearInput);
  }, [yearInput, yearRef]);

  useEffect(() => {
    if (!engineRef.current || VT.engineForm.length === 0) return;
    let pickedEngine = VT.engineForm.find(
      (e) => e.value === engineInput?.value
    );
    engineRef.current.setValue(pickedEngine);
  }, [engineRef, VT.engineForm, engineInput]);

  useEffect(() => {
    if (!transmitionRef.current || VT.transmitionForm.length === 0) return;
    let pickedTransmition = VT.transmitionForm.find(
      (t) => t.value === transmitionInput?.value
    );
    transmitionRef.current.setValue(pickedTransmition);
  }, [transmitionRef, VT.transmitionForm, transmitionInput]);

  // DOORS
  useEffect(() => {
    if (VT.doorsForm.length === 0) return;
    let pickedDoors = VT.doorsForm.find((c) => c.value === carDoorsInput.value);

    if (doorsRef.current && VT.doorsForm.length > 0) {
      doorsRef.current.setValue(pickedDoors);
    }
  }, [VT.doorsForm, carDoorsInput, doorsRef]);

  // COLOR
  useEffect(() => {
    if (VT.carColorForm.length === 0) return;
    let pickedColor = VT.carColorForm.find(
      (c) => c.value === carColorInput.value
    );

    if (carColorRef.current && VT.carColorForm.length > 0) {
      carColorRef.current.setValue(pickedColor);
    }
  }, [VT.carColorForm, carColorInput, carColorRef]);

  return (
    <div className={classes.container}>
      <h3>Vehicle</h3>
      <SelectInput
        data={{
          name: translations.msg_vehicle_company || "Marke",
          options: _carCompanies,
          isSearchable: true,
          defaultValue: _carCompanies[0],
          ref: carcompanyRef,
        }}
        onChange={changeCarcompanyHandler}
      />
      <SelectInput
        data={{
          name: translations.msg_vehicle_model || "Model",
          options: carmodelsList,
          isSearchable: true,
          defaultValue: _carCompanies[0],
          isDisabled: carmodelsList.length === 0,
          ref: carmodelRef,
        }}
        onChange={changecarModelHandler}
      />
      <div className={classes["more-fields"]}>
        <SelectInput
          data={{
            name: translations.msg_vehicle_engine || "Engine",
            options: VT.engineForm,
            isSearchable: true,
            defaultValue: VT.engineForm[0],
            ref: engineRef,
          }}
          onChange={changeEngineHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehicle_transmition || "Transmition",
            options: VT.transmitionForm,
            isSearchable: true,
            defaultValue: VT.transmitionForm[0],
            ref: transmitionRef,
          }}
          onChange={changeTransmitionHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehicle_year || "Erstzulassung",
            options: _yearsList,
            isSearchable: false,
            defaultValue: _yearsList[0],
            ref: yearRef,
          }}
          onChange={changeYearHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehicle_doors || "Doors",
            options: VT.doorsForm,
            isSearchable: false,
            defaultValue: VT.doorsForm[0],
            ref: doorsRef,
          }}
          onChange={changeDoorsHandler}
        />
        <SelectInput
          data={{
            name: translations.msg_vehicle_color || "Color",
            options: VT.carColorForm,
            isSearchable: false,
            defaultValue: VT.carColorForm[0],
            ref: carColorRef,
          }}
          onChange={changeCarColorHandler}
        />
        <NumberInput
          data={{
            name: `${translations.msg_vehicle_miles || "Kilometer"} (m2)`,
            value: milesInput,
            placeholder: "",
            error: milesError,
          }}
          onKeyDown={props.onKeyDown}
          onChange={changeMilesHandler}
          onBlur={checkMilesValidation}
        />
        <NumberInput
          data={{
            name: `${translations.msg_vehicle_cc || "Habraum"} (cc)`,
            value: ccInput,
            placeholder: "",
            error: ccError,
          }}
          onKeyDown={props.onKeyDown}
          onChange={changeCcHandler}
          onBlur={checkCcValidation}
        />
        <NumberInput
          data={{
            name: `${translations.msg_vehicle_kw || "Leistung"} (kw)`,
            value: kwInput,
            placeholder: "",
            error: kwError,
          }}
          onKeyDown={props.onKeyDown}
          onChange={changeKwHandler}
          onBlur={checkKwValidation}
        />
      </div>
    </div>
  );
};

export default VehicleFields;
