export const reports = {
  en: {
    article: {
      1: "Offensive language, hate speech, or content that promotes discrimination or violence.",
      2: "Articles containing inaccurate information, misleading claims, or false facts.",
      3: "Content that has been copied from another source without proper attribution or permission.",
      4: "Articles that are spammy, contain excessive advertisements, or are unrelated to the website's topic.",
      5: "Articles that violate the website's terms of service or community guidelines.",
      6: "Articles that infringe upon the copyright of others, including using copyrighted images or text without permission.",
      7: "Content that is sexually explicit, contains nudity, or is otherwise inappropriate for the website's audience.",
      8: "Articles that contain personal attacks, harassment, or cyberbullying directed at individuals or groups.",
      9: "Articles that pose a risk to users' safety or well-being, such as promoting dangerous activities or self-harm.",
      10: "Content that may be triggering or harmful to individuals with certain sensitivities, such as discussions of violence, trauma, or mental health issues.",
      100: "Other",
    },
    user: {
      1: "Engaging in harassment, bullying, or abusive behavior towards other users.",
      2: "Creating an account that impersonates another person or entity, or using a fake identity.",
      3: "Sending unsolicited messages, spamming articles, or engaging in other forms of disruptive behavior.",
      4: "Violating the website or platform's community guidelines, terms of service, or rules of conduct.",
      5: "Uploading inappropriate profile pictures, bios, or other profile content.",
      6: "Engaging in behavior that poses a risk to other users' safety or well-being.",
      7: "Posting copyrighted material without permission or infringing upon the intellectual property rights of others.",
      8: "Engaging in fraudulent activities, such as phishing scams, pyramid schemes, or other forms of fraud.",
      9: "Posting content that promotes hate speech, discrimination, or intolerance towards individuals or groups based on race, ethnicity, religion, gender, sexual orientation, or other characteristics.",
      100: "Other",
    },
    chat: {
      1: "Receiving messages that are harassing, bullying, or threatening in nature.",
      2: "Messages that contain hate speech, discriminatory language, or promote intolerance towards individuals or groups based on race, ethnicity, religion, gender, sexual orientation, or other characteristics.",
      3: "Receiving spam messages, unsolicited advertisements, or other unwanted content.",
      4: "Messages that attempt to scam or defraud the recipient, such as phishing attempts, pyramid schemes, or other fraudulent activities.",
      5: "Messages from accounts impersonating another person or entity, or pretending to be someone they're not.",
      6: "Messages that violate the privacy of the recipient, such as sharing personal information without consent or sharing private conversations publicly.",
      7: "Messages that contain threats of violence, promote violence, or engage in violent language towards the recipient or others.",
      8: "Messages that violate the platform's community guidelines, terms of service, or rules of conduct.",
      100: "Other",
    },
  },
  de: {
    article: {
      1: "Beleidigende Sprache, Hassrede oder Inhalte, die Diskriminierung oder Gewalt fördern.",
      2: "Artikel mit ungenauen Informationen, irreführenden Behauptungen oder falschen Fakten.",
      3: "Inhalte, die ohne ordnungsgemäße Quellenangabe oder Erlaubnis aus einer anderen Quelle kopiert wurden.",
      4: "Artikel, die spammy sind, übermäßige Werbung enthalten oder nicht zum Thema der Website passen.",
      5: "Artikel, die gegen die Nutzungsbedingungen oder Gemeinschaftsrichtlinien der Website verstoßen.",
      6: "Artikel, die das Urheberrecht anderer verletzen, einschließlich der Verwendung urheberrechtlich geschützter Bilder oder Texte ohne Erlaubnis.",
      7: "Inhalte, die sexuell explizit sind, Nacktheit enthalten oder anderweitig für die Zielgruppe der Website unangemessen sind.",
      8: "Artikel, die persönliche Angriffe, Belästigungen oder Cybermobbing gegen Einzelpersonen oder Gruppen enthalten.",
      9: "Artikel, die ein Risiko für die Sicherheit oder das Wohlbefinden der Benutzer darstellen, wie die Förderung gefährlicher Aktivitäten oder Selbstverletzung.",
      10: "Inhalte, die für Personen mit bestimmten Empfindlichkeiten auslösend oder schädlich sein können, wie Diskussionen über Gewalt, Trauma oder psychische Gesundheitsprobleme.",
      100: "Andere",
    },
    user: {
      1: "Belästigung, Mobbing oder missbräuchliches Verhalten gegenüber anderen Benutzern.",
      2: "Erstellung eines Kontos, das eine andere Person oder Organisation imitiert, oder die Verwendung einer falschen Identität.",
      3: "Versenden von unerwünschten Nachrichten, Spam-Artikel oder andere Formen von störendem Verhalten.",
      4: "Verstoß gegen die Gemeinschaftsrichtlinien, Nutzungsbedingungen oder Verhaltensregeln der Website oder Plattform.",
      5: "Hochladen unangemessener Profilbilder, Bios oder anderer Profilinhalte.",
      6: "Verhalten, das ein Risiko für die Sicherheit oder das Wohlbefinden anderer Benutzer darstellt.",
      7: "Veröffentlichung urheberrechtlich geschützten Materials ohne Erlaubnis oder Verletzung der geistigen Eigentumsrechte anderer.",
      8: "Beteiligung an betrügerischen Aktivitäten wie Phishing-Betrug, Schneeballsystemen oder anderen Formen von Betrug.",
      9: "Veröffentlichung von Inhalten, die Hassrede, Diskriminierung oder Intoleranz gegenüber Einzelpersonen oder Gruppen aufgrund von Rasse, ethnischer Zugehörigkeit, Religion, Geschlecht, sexueller Orientierung oder anderen Merkmalen fördern.",
      100: "Andere",
    },
    chat: {
      1: "Erhalt von Nachrichten, die belästigend, mobbend oder bedrohlich sind.",
      2: "Nachrichten, die Hassrede, diskriminierende Sprache enthalten oder Intoleranz gegenüber Einzelpersonen oder Gruppen aufgrund von Rasse, ethnischer Zugehörigkeit, Religion, Geschlecht, sexueller Orientierung oder anderen Merkmalen fördern.",
      3: "Erhalt von Spam-Nachrichten, unerwünschten Werbeanzeigen oder anderem unerwünschtem Inhalt.",
      4: "Nachrichten, die versuchen, den Empfänger zu betrügen oder zu betrügen, wie Phishing-Versuche, Schneeballsysteme oder andere betrügerische Aktivitäten.",
      5: "Nachrichten von Konten, die eine andere Person oder Organisation imitieren oder vorgeben, jemand zu sein, der sie nicht sind.",
      6: "Nachrichten, die die Privatsphäre des Empfängers verletzen, wie das Teilen persönlicher Informationen ohne Zustimmung oder das öffentliche Teilen privater Gespräche.",
      7: "Nachrichten, die Gewaltandrohungen enthalten, Gewalt fördern oder gewalttätige Sprache gegenüber dem Empfänger oder anderen verwenden.",
      8: "Nachrichten, die gegen die Gemeinschaftsrichtlinien, Nutzungsbedingungen oder Verhaltensregeln der Plattform verstoßen.",
      100: "Andere",
    },
  },
  fr: {
    article: {
      1: "Langage offensant, discours de haine ou contenu promouvant la discrimination ou la violence.",
      2: "Articles contenant des informations inexactes, des affirmations trompeuses ou des faits erronés.",
      3: "Contenu copié d'une autre source sans attribution ou permission appropriée.",
      4: "Articles qui sont spammy, contiennent des publicités excessives ou ne sont pas liés au sujet du site.",
      5: "Articles qui violent les conditions d'utilisation ou les directives communautaires du site.",
      6: "Articles qui enfreignent les droits d'auteur d'autrui, y compris l'utilisation d'images ou de textes protégés par des droits d'auteur sans autorisation.",
      7: "Contenu sexuellement explicite, contenant de la nudité ou autrement inapproprié pour le public du site.",
      8: "Articles contenant des attaques personnelles, du harcèlement ou du cyberharcèlement envers des individus ou des groupes.",
      9: "Articles présentant un risque pour la sécurité ou le bien-être des utilisateurs, comme la promotion d'activités dangereuses ou d'automutilation.",
      10: "Contenu pouvant être déclencheur ou nocif pour les personnes ayant certaines sensibilités, telles que les discussions sur la violence, le traumatisme ou les problèmes de santé mentale.",
      100: "Autre",
    },
    user: {
      1: "Participer à des actes de harcèlement, d'intimidation ou de comportement abusif envers d'autres utilisateurs.",
      2: "Créer un compte qui imite une autre personne ou entité, ou utiliser une fausse identité.",
      3: "Envoyer des messages non sollicités, spammer des articles ou adopter d'autres comportements perturbateurs.",
      4: "Violer les directives communautaires, les conditions d'utilisation ou les règles de conduite du site ou de la plateforme.",
      5: "Télécharger des photos de profil, des biographies ou d'autres contenus de profil inappropriés.",
      6: "Adopter un comportement présentant un risque pour la sécurité ou le bien-être des autres utilisateurs.",
      7: "Publier du matériel protégé par des droits d'auteur sans autorisation ou enfreindre les droits de propriété intellectuelle d'autrui.",
      8: "Participer à des activités frauduleuses, telles que les escroqueries par hameçonnage, les systèmes pyramidaux ou d'autres formes de fraude.",
      9: "Publier du contenu promouvant des discours de haine, la discrimination ou l'intolérance envers des individus ou des groupes en raison de leur race, ethnicité, religion, sexe, orientation sexuelle ou d'autres caractéristiques.",
      100: "Autre",
    },
    chat: {
      1: "Recevoir des messages harcelants, intimidants ou menaçants.",
      2: "Messages contenant des discours de haine, un langage discriminatoire ou promouvant l'intolérance envers des individus ou des groupes en raison de leur race, ethnicité, religion, sexe, orientation sexuelle ou d'autres caractéristiques.",
      3: "Recevoir des messages indésirables, des publicités non sollicitées ou d'autres contenus indésirables.",
      4: "Messages tentant d'escroquer ou de frauder le destinataire, tels que les tentatives de hameçonnage, les systèmes pyramidaux ou d'autres activités frauduleuses.",
      5: "Messages provenant de comptes imitant une autre personne ou entité, ou prétendant être quelqu'un qu'ils ne sont pas.",
      6: "Messages violant la vie privée du destinataire, tels que le partage d'informations personnelles sans consentement ou le partage public de conversations privées.",
      7: "Messages contenant des menaces de violence, promouvant la violence ou utilisant un langage violent envers le destinataire ou d'autres personnes.",
      8: "Messages violant les directives communautaires, les conditions d'utilisation ou les règles de conduite de la plateforme.",
      100: "Autre",
    },
  },
  it: {
    article: {
      1: "Linguaggio offensivo, discorsi d'odio o contenuti che promuovono discriminazione o violenza.",
      2: "Articoli contenenti informazioni inaccurate, affermazioni fuorvianti o fatti falsi.",
      3: "Contenuti copiati da un'altra fonte senza adeguata attribuzione o permesso.",
      4: "Articoli che sono spam, contengono pubblicità eccessive o non sono pertinenti all'argomento del sito.",
      5: "Articoli che violano i termini di servizio o le linee guida della comunità del sito.",
      6: "Articoli che violano i diritti d'autore di altri, inclusi l'uso di immagini o testi protetti senza permesso.",
      7: "Contenuti sessualmente espliciti, che contengono nudità o sono inappropriati per il pubblico del sito.",
      8: "Articoli che contengono attacchi personali, molestie o cyberbullismo rivolti a individui o gruppi.",
      9: "Articoli che rappresentano un rischio per la sicurezza o il benessere degli utenti, come la promozione di attività pericolose o autolesionismo.",
      10: "Contenuti che possono essere scatenanti o dannosi per individui con determinate sensibilità, come discussioni su violenza, trauma o problemi di salute mentale.",
      100: "Altro",
    },
    user: {
      1: "Coinvolgimento in molestie, bullismo o comportamenti abusivi nei confronti di altri utenti.",
      2: "Creazione di un account che impersona un'altra persona o entità, o uso di un'identità falsa.",
      3: "Invio di messaggi non richiesti, spamming di articoli o adozione di altre forme di comportamento disruptivo.",
      4: "Violazione delle linee guida della comunità, dei termini di servizio o delle regole di condotta del sito o della piattaforma.",
      5: "Caricamento di immagini del profilo inappropriati, biografie o altri contenuti del profilo.",
      6: "Adozione di comportamenti che rappresentano un rischio per la sicurezza o il benessere degli altri utenti.",
      7: "Pubblicazione di materiale protetto da copyright senza autorizzazione o violazione dei diritti di proprietà intellettuale di altri.",
      8: "Coinvolgimento in attività fraudolente, come truffe di phishing, schemi piramidali o altre forme di frode.",
      9: "Pubblicazione di contenuti che promuovono discorsi di odio, discriminazione o intolleranza verso individui o gruppi in base a razza, etnia, religione, genere, orientamento sessuale o altre caratteristiche.",
      100: "Altro",
    },
    chat: {
      1: "Ricezione di messaggi che sono molesti, intimidatori o minacciosi per natura.",
      2: "Messaggi che contengono discorsi di odio, linguaggio discriminatorio o promuovono l'intolleranza verso individui o gruppi in base a razza, etnia, religione, genere, orientamento sessuale o altre caratteristiche.",
      3: "Ricezione di messaggi spam, pubblicità non richieste o altri contenuti indesiderati.",
      4: "Messaggi che tentano di truffare o frodare il destinatario, come tentativi di phishing, schemi piramidali o altre attività fraudolente.",
      5: "Messaggi da account che impersonano un'altra persona o entità, o fingono di essere qualcuno che non sono.",
      6: "Messaggi che violano la privacy del destinatario, come la condivisione di informazioni personali senza consenso o la divulgazione pubblica di conversazioni private.",
      7: "Messaggi che contengono minacce di violenza, promuovono la violenza o utilizzano linguaggio violento verso il destinatario o altre persone.",
      8: "Messaggi che violano le linee guida della piattaforma, i termini di servizio o le regole di condotta.",
      100: "Altro",
    },
  },
};
