import React, { useEffect } from "react";
import styles from "./Profile.module.css";
import { useSelector, useDispatch } from "react-redux";

//STORE
import { getUserArticlesStats } from "../store/reducers/auth-store";

//COMPONENTS
import UserProfileContainer from "../components/user-profile/UserProfileContainer";
import Footer from "../components/footer/Footer";

const Profile = () => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user._id && isAuthenticated) {
      dispatch(getUserArticlesStats(user._id));
    }
  }, [dispatch, isAuthenticated, user._id]);

  return (
    <div className={styles.container}>
      <UserProfileContainer />
      <Footer />
    </div>
  );
};

export default Profile;
