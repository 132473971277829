import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import cities from "../../utils/cities";

let URL;
console.log(process.env.REACT_APP_URL);

URL = process.env.REACT_APP_URL;

const initialArticleState = {
  errorArticle: null,
  messageArticle: null,
  //////////////////////////////////////////
  //HOME PAGE
  //////////////////////////////////////////
  queryStr: "?page=1&sort=-createdAt",
  refreshArticles: false,
  updateAllArticles: false,
  fetchArticles: false,
  fetchAfterCreateArticle: false,
  articles: [],
  promotedArticles: [],
  numOfArticles: 0,
  articlesPerPage: 30,
  currentArticlePage: 1,
  numOfPages: 1,
  //  -- FILTERS
  currentCategory: 0,
  currentSubCategory: 0,
  search: "",
  searchFilters: {
    minPrice: 0,
    maxPrice: 0,
    state: null,
    itemCondition: null,
    // new: true,
    // used: true,
    //VEHICLES
    carcompany: null,
    carmodel: null,
    engine: null,
    minYear: null,
    maxMiles: null,
    transmition: null,
    car_color: null,
    car_doors: null,
    minCc: null,
    maxCc: null,
    cc: null,
    minKw: null,
    maxKw: null,
    kw: null,
    //REAL-ESTATE
    realestatestatus: null,
    rooms: null,
    minArea: null,
    maxArea: null,
  },
  sort: "-createdAt",
  //////////////////////////////////////////
  //USERS ARTICLES
  //////////////////////////////////////////
  refreshMyArticles: false,
  myArticles: [],
  numOfUsersArticles: {},
  //////////////////////////////////////////
  //CREATE ARTICLE
  //////////////////////////////////////////
  createArticle: {
    title: "",
    description: "",
    price: "",
    state: "new",
    city: `${cities[0]}`,
    zip: "",
    mainImage: "",
    images: [],
    category: "",
    subCategory: 0,
  },
  //////////////////////////////////////////
  //SINGLE ARTICLE
  //////////////////////////////////////////
  article: null,

  //////////////////////////////////////////
  // TERMS OF USE
  //////////////////////////////////////////
  numOfActiveArticles: 0,
};

const articleSlice = createSlice({
  name: "article",
  initialState: initialArticleState,
  reducers: {
    setFetchArticles(state, action) {
      state.fetchArticles = action.payload.value;
    },
    setFetchAfterCreateArticle(state, action) {
      state.fetchAfterCreateArticle = action.payload.value;
    },
    setRefreshArticles(state, action) {
      state.refreshArticles = action.payload.value;
    },
    setErrorArticle(state, action) {
      state.errorArticle = action.payload.value;
    },
    setMessageArticle(state, action) {
      state.messageArticle = action.payload.value;
    },
    setArticles(state, action) {
      state.articles = action.payload.data;
    },
    setPromotedArticles(state, action) {
      state.promotedArticles = action.payload.data;
    },
    setMyArticles(state, action) {
      state.myArticles = action.payload.data;
    },
    setRefreshMyArticles(state, action) {
      state.refreshMyArticles = action.payload.value;
    },
    setNumOfArticles(state, action) {
      state.numOfArticles = action.payload.value;
    },
    setQueryStr(state, action) {
      state.queryStr = action.payload.value;
    },
    setAddMoreArticles(state, action) {
      let updatedArticles = [];
      state.articles.forEach((a) => updatedArticles.push(a));
      action.payload.data.forEach((a) => updatedArticles.push(a));
      state.articles = updatedArticles;
    },
    setCurrentArticlePage(state, action) {
      state.currentArticlePage = action.payload.value;
    },
    setArticlesPerPage(state, action) {
      state.articlesPerPage = action.payload.value;
    },
    setNumOfPages(state, action) {
      state.numOfPages = Math.ceil(
        action.payload.value / state.articlesPerPage
      );
    },
    setCurrentCategory(state, action) {
      state.currentCategory = action.payload.value;
    },
    setCurrentSubCategory(state, action) {
      state.currentSubCategory = action.payload.value;
    },
    setSearch(state, action) {
      state.search = action.payload.value;
    },
    setSearchFilters(state, action) {
      const key = Object.keys(action.payload);
      const value = action.payload[`${Object.keys(action.payload)}`];

      state.searchFilters[key[0]] = value;

      if (!value) {
        state.searchFilters[key[0]] = 0;
      }
    },
    turnOffVehicleFilters(state) {
      state.searchFilters.carcompany = null;
      state.searchFilters.minYear = null;
      state.searchFilters.maxMiles = null;
      state.searchFilters.minCc = null;
      state.searchFilters.maxCc = null;
      state.searchFilters.cc = null;
      state.searchFilters.minKw = null;
      state.searchFilters.maxKw = null;
      state.searchFilters.kw = null;
      state.car_color = null;
      state.car_doors = null;
    },
    turnOnVehicleFilters(state) {
      state.searchFilters.carcompany = null;
      state.searchFilters.minYear = null;
      state.searchFilters.maxMiles = null;
      state.searchFilters.minCc = null;
      state.searchFilters.maxCc = null;
      state.searchFilters.cc = null;
      state.searchFilters.minKw = null;
      state.searchFilters.maxKw = null;
      state.searchFilters.kw = null;
      state.car_color = null;
      state.car_doors = null;
    },
    resetSearchFilters(state) {
      state.searchFilters = {
        minPrice: 0,
        maxPrice: 0,
        state: null,
        itemCondition: null,
        //VEHICLES
        carcompany: null,
        engine: null,
        minYear: null,
        maxMiles: null,
        transmition: null,
        car_color: null,
        car_doors: null,
        minCc: null,
        maxCc: null,
        cc: null,
        minKw: null,
        maxKw: null,
        kw: null,
      };
    },
    setSort(state, action) {
      state.sort = action.payload.value;
    },
    setCreateArticle(state, action) {
      const field = Object.keys(action.payload)[0];
      state.createArticle[field] = action.payload[field];
    },
    setArticle(state, action) {
      state.article = action.payload.data;
    },
    resetCreateArticle(state) {
      state.createArticle = {
        title: "",
        description: "",
        price: "",
        city: `${cities[0]}`,
        zip: "",
        mainImage: "",
        images: [],
        category: "",
        subCategory: 0,
      };
    },
    setUpdateAllArticles(state, action) {
      state.updateAllArticles = action.payload.value;
    },
    setNumOfUsersArticles(state, action) {
      const newState = {};
      action.payload.data.forEach((state) => {
        newState[state._id] = state.count;
      });
      state.numOfUsersArticles = newState;
    },
    setNumOfActiveArticles(state, action) {
      state.numOfActiveArticles = action.payload.value;
    },
  },
});

export const getArticles = (userInputs = false, add = false) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/getArticles${
          userInputs.queryString ? userInputs.queryString : ""
        }`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        if (add) {
          //KADA DODAJE ARTIKLE NA POSTOJECE
          dispatch(
            articleSlice.actions.setAddMoreArticles({
              data: response.data.data.articles.articles,
            })
          );
          dispatch(articleSlice.actions.setUpdateAllArticles({ value: false }));
        } else {
          //KADA PRAVI PRVI FETCH
          dispatch(
            articleSlice.actions.setArticles({
              data: response.data.data.articles.articles,
            })
          );
          dispatch(articleSlice.actions.setCurrentArticlePage({ value: 1 }));
        }
        dispatch(
          articleSlice.actions.setNumOfPages({
            value: response.data.data.totalAmountOfArticles,
          })
        );
        dispatch(
          articleSlice.actions.setNumOfArticles({
            value: response.data.data.totalAmountOfArticles,
          })
        );
      }

      return response;
    } catch (err) {
      console.log(err);
      if (!err.response) return;
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const getArticle = (userInputs) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/${userInputs.articleId}`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(articleSlice.actions.setArticle({ data: response.data.data }));
      }
    } catch (err) {
      console.log(err);
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const getNumOfActiveArticles = (userInputs) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/getNumOfActiveArticles`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          articleSlice.actions.setNumOfActiveArticles({
            value: response.data.data.totalNumOfActiveArticles || 0,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const updateArticle = (userInputs, token, navigate) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/articles/${userInputs.articleId}`,
        data: userInputs.data,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };
      axiosOptions.headers["Content-Type"] = "multipart/form-data";

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(articleSlice.actions.setArticle({ data: response.data.data }));
        dispatch(
          articleSlice.actions.setMessageArticle({
            value: `${response.data.data.title} is updated successfully!`,
          })
        );
        navigate(`/article?articleId=${response.data.data._id}`);
      }
    } catch (err) {
      console.log(err);
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const createNewArticle = (userInputs, token, navigate) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "POST",
        url: `${URL}/articles/create`,
        data: userInputs,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };
      axiosOptions.headers["Content-Type"] = "multipart/form-data";

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        articleSlice.actions.resetCreateArticle();
        dispatch(
          articleSlice.actions.setMessageArticle({
            value: `You created article successfully: ${response.data.data.title}`,
          })
        );
        dispatch(
          articleSlice.actions.setFetchAfterCreateArticle({ value: true })
        );
        navigate(`/article?articleId=${response.data.data._id}`);
      }
    } catch (err) {
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
      console.log(err);
    }
  };
};

export const getPromotedArticles = (userInputs) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/getRandomPromoted?category=${userInputs.category}`,
        headers: {
          "Accept-Language": lang,
        },
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          articleSlice.actions.setPromotedArticles({
            data: response.data.data.articles,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        articleSlice.actions.setErrorArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export default articleSlice;
