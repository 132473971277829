import { Fragment, useEffect, useState } from "react";

import classes from "./ImagesContainer.module.css";

//COMPONENTS
import SingleImage from "./SingleImage";

const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_SUFFIX_LARGE;
const IMAGE_URL_PATH = process.env.REACT_APP_IMAGE_URL;

const ImagesContainer = (props) => {
  const [activeImage, setActiveImage] = useState(0);
  const [imgList, setImgList] = useState([]);
  const [imagesRenderer, setImagesRenderer] = useState([]);

  useEffect(() => {
    if (!props.data.images) return;
    let imagesList = [...props.data.images];
    // imagesList.unshift(props.data.mainImage);
    setImgList(imagesList);
  }, [props.data]);

  useEffect(() => {
    if (imgList.length <= 0) return;

    const imgs = imgList.map((i, index) => {
      return (
        <SingleImage
          key={index}
          data={{ image: i, index, activeImg: activeImage }}
          setActiveImage={setActiveImage}
        />
      );
    });

    setImagesRenderer(imgs);
  }, [imgList, activeImage]);

  return (
    <Fragment>
      {props.data.images.length > 0 ? (
        <div className={classes.container}>
          <div className={classes.bg} />
          <div className={classes["main-image"]}>
            <img
              className={classes["main-img"]}
              src={`${IMAGE_URL_PATH}/articles${IMAGE_PREFIX}/${imgList[activeImage]}`}
              alt={props.data.title}
            />
          </div>
          <div className={classes["images-container"]}>
            <div className={classes["left-fade"]} />
            <ul className={classes["images-container__overflow"]}>
              {imagesRenderer}
            </ul>
            <div className={classes["right-fade"]} />
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <h4>There is no images for this article.</h4>
        </div>
      )}
    </Fragment>
  );
};

export default ImagesContainer;
