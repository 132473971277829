import React from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";

import classes from "./Pagination.module.css";

//COMPONENTS
import PaginationBtn from "./PaginationBtn";

const Pagination = (props) => {
  const currentPage = +props.currentPage;
  const numOfPages = +props.numOfPages;

  //PROVIDE THIS HANDLERS
  // const pageHandler
  // const firstPageHandler
  // const lastPageHandler
  //AND VALUES
  // currentPage
  // numOfPages

  return (
    <div className={classes["pagination-container"]} style={props.style}>
      <ul className={classes.paginator}>
        <li
          id="1"
          key="left-arrow"
          className={classes["margin__left"]}
          onClick={props.firstPageHandler}
        >
          <AiOutlineDoubleLeft className={classes["paginator-icon"]} />
        </li>

        {/* TEST ODAVDE */}

        {/*
        {currentPage === 6 && numOfPages <= 9 && (
          <PaginnationBtn curPage={currentPage - 4} onClick={pageHandler} />
        )}
        {(currentPage === 6 || currentPage === 5) && numOfPages <= 9 && (
          <PaginnationBtn curPage={currentPage - 4} onClick={pageHandler} />
        )}
        {(currentPage === 6 || currentPage === 5 || currentPage === 4) &&
          numOfPages <= 9 && (
            <PaginnationBtn curPage={currentPage - 3} onClick={pageHandler} />
          )}
        {(currentPage === 6 ||
          currentPage === 5 ||
          currentPage === 4 ||
          currentPage === 3) &&
          numOfPages <= 9 && (
            <PaginnationBtn curPage={currentPage - 2} onClick={pageHandler} />
          )}
        {(currentPage === 2 ||
          currentPage === 3 ||
          currentPage === 4 ||
          currentPage === 5 ||
          currentPage === 6) &&
          numOfPages <= 9 && (
            <PaginnationBtn curPage={currentPage - 1} onClick={pageHandler} />
          )}
          */}

        {/* TEST DOVDE */}

        {currentPage >= 5 && (numOfPages >= 9 || currentPage <= 9) && (
          <PaginationBtn
            curPage={currentPage - 4}
            onClick={props.pageHandler}
          />
        )}
        {currentPage >= 4 && (numOfPages >= 9 || currentPage <= 9) && (
          <PaginationBtn
            curPage={currentPage - 3}
            onClick={props.pageHandler}
          />
        )}
        {currentPage >= 3 && (numOfPages >= 9 || currentPage <= 9) && (
          <PaginationBtn
            curPage={currentPage - 2}
            onClick={props.pageHandler}
          />
        )}
        {currentPage >= 2 && (numOfPages >= 9 || currentPage <= 9) && (
          <PaginationBtn
            curPage={currentPage - 1}
            onClick={props.pageHandler}
          />
        )}

        <PaginationBtn
          curPage={currentPage}
          onClick={props.pageHandler}
          className={classes.active}
        />

        {numOfPages >= currentPage + 1 && numOfPages >= 2 && (
          <PaginationBtn
            curPage={currentPage + 1}
            onClick={props.pageHandler}
          />
        )}
        {numOfPages >= currentPage + 2 && numOfPages >= 3 && (
          <PaginationBtn
            curPage={currentPage + 2}
            onClick={props.pageHandler}
          />
        )}
        {numOfPages >= currentPage + 3 && numOfPages >= 4 && (
          <PaginationBtn
            curPage={currentPage + 3}
            onClick={props.pageHandler}
          />
        )}
        {numOfPages >= currentPage + 4 && numOfPages >= 5 && (
          <PaginationBtn
            curPage={currentPage + 4}
            onClick={props.pageHandler}
          />
        )}
        {currentPage <= 4 && numOfPages >= 7 && (
          <PaginationBtn
            curPage={currentPage + 5}
            onClick={props.pageHandler}
          />
        )}
        {currentPage <= 3 && numOfPages >= 8 && (
          <PaginationBtn
            curPage={currentPage + 6}
            onClick={props.pageHandler}
          />
        )}
        {currentPage <= 2 && numOfPages >= 9 && (
          <PaginationBtn
            curPage={currentPage + 7}
            onClick={props.pageHandler}
          />
        )}
        {currentPage <= 1 && numOfPages >= 10 && (
          <PaginationBtn
            curPage={currentPage + 8}
            onClick={props.pageHandler}
          />
        )}

        {currentPage === 1 && numOfPages <= 9 && numOfPages >= 6 && (
          <PaginationBtn curPage={numOfPages} onClick={props.pageHandler} />
        )}
        <li
          id={numOfPages}
          key="right-arrow"
          className={classes["margin__right"]}
          onClick={props.lastPageHandler}
        >
          <AiOutlineDoubleRight className={classes["paginator-icon"]} />
        </li>
      </ul>
      {/* <div className={classes.paginnation}>
        {currentPage} / {numOfPages}
      </div> */}
    </div>
  );
};

export default Pagination;
