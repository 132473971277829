import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//STORE
import {
  getUserData,
  getFetchedUserArticles,
} from "../store/reducers/user-slice";
import { userActions } from "../store/redux-store";

const useUser = () => {
  const dispatch = useDispatch();
  const { numOfPages, articlesPerPage } = useSelector((state) => state.user);
  const [userId, setUserId] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (userId) {
      dispatch(getUserData({ userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      dispatch(
        getFetchedUserArticles({
          queryString: `?page=1&limit=${articlesPerPage}&creator=${userId}`,
        })
      );
    }
  }, [dispatch, articlesPerPage, userId]);

  useEffect(() => {
    if (userId) {
      return () => {
        dispatch(userActions.setFetchedUser({ data: {} }));
      };
    }
  }, [dispatch, userId]);

  const changeSeatchInputHandler = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const fetchArticlesHandler = (e) => {
    e.preventDefault();
    dispatch(
      getFetchedUserArticles({
        queryString: `?page=1&creator=${userId}${
          search ? "&search=" + search : ""
        }`,
      })
    );
  };

  const fetchPagination = (page) => {
    dispatch(
      getFetchedUserArticles({
        queryString: `?page=${page}&creator=${userId}${
          search ? "&search=" + search : ""
        }`,
      })
    );
    dispatch(userActions.setCurrentArticlePage({ value: page }));
  };

  const firstPageHandler = (e) => {
    e.preventDefault();
    fetchPagination(1);
  };
  const lastPageHandler = (e) => {
    e.preventDefault();
    fetchPagination(numOfPages);
  };
  const pageHandler = (e) => {
    e.preventDefault();
    fetchPagination(e.target.id);
  };

  return {
    //VALUES
    userId,
    search,

    //SET VALUES
    setUserId,
    setSearch,

    //HANDLERS
    changeSeatchInputHandler,
    fetchArticlesHandler,
    firstPageHandler,
    lastPageHandler,
    pageHandler,
  };
};

export default useUser;
