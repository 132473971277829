import React from "react";

import classes from "./ImprintSection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const ImprintSection = (props) => {
  const { translations } = useTranslation();

  return (
    <section
      id={"imprint"}
      className={classes.container}
      style={{ backgroundColor: "var(--white)" }}
    >
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_imprint || "Imprint"}</h3>
        <div className={classes["body"]}>
          <h5>Impressum</h5>
          <p className={classes["description"]}>IMS TRADE GMBH</p>
        </div>
      </div>
    </section>
  );
};

export default ImprintSection;
