import { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux";

import classes from "./ArticlesContainer.module.css";

// COMPONENTS
import ArticleCard from "../articleCard/ArticleCard";

const ArticlesContainer = (props) => {
  const { articles } = useSelector((state) => state.article);

  useEffect(() => {
    const cardsContainer = document.getElementById("cards-container");
    if (cardsContainer && cardsContainer.firstChild) {
      props.setCardWidth(cardsContainer.firstChild.offsetWidth);
      // console.log(cardsContainer.firstChild.offsetWidth);
    }
  }, [props]);
  const renderArticle = articles.map((a) => {
    return <ArticleCard data={a} key={a._id} id={a._id} />;
  });

  return (
    <div id="cards-container" className={classes.container}>
      {renderArticle}
    </div>
  );
};

export default ArticlesContainer;
