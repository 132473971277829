import React from "react";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./ArticleDetails.module.css";

const ArticleDetails = (props) => {
  const { translations } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <h5>{translations.msg_articledetails_description || "Description"}:</h5>
        <p>{props.data.description}</p>
      </div>
    </div>
  );
};

export default ArticleDetails;
