import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Messages.module.css";

//STORE
import { getChats } from "../store/reducers/chat-slice";
import { chatActions } from "../store/redux-store";

//COMPONENTS
import MessagesContainer from "../components/messages/MessagesContainer";

const Messages = (props) => {
  const { user, token, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token || !isAuthenticated) return;
    if (user?.chats?.length <= 0) return;
    dispatch(getChats(token));
  }, [dispatch, user, token, isAuthenticated]);

  useEffect(() => {
    if (!token || !isAuthenticated) return;
    if (user?.chats?.length <= 0) return;
    if (props.refresh) {
      dispatch(getChats(token));
      props.setRefresh(false);
    }
  }, [props, dispatch, token, user, isAuthenticated]);

  useEffect(() => {
    return () => {
      dispatch(chatActions.setOpenedChat({}));
    };
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <MessagesContainer setRefresh={props.setRefresh} />
    </div>
  );
};

export default Messages;
