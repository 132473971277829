import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "../../TranslationContext";

import classes from "./AuthCard.module.css";

import SideAuthBtn from "./SideAuthBtn";

const AuthCard = (props) => {
  const location = useLocation();
  const { translations } = useTranslation();

  return (
    <div className={classes.container}>
      {location.pathname.split("/")[2] === "login" && (
        <h3>{translations.msg_authcard_signup}</h3>
      )}
      {location.pathname.split("/")[2] === "signup" && (
        <h3>{translations.msg_authcard_login}</h3>
      )}
      {location.pathname.split("/")[2] === "login" && (
        <h4>{translations.msg_authcard_no_account}</h4>
      )}
      {location.pathname.split("/")[2] === "signup" && (
        <h4>{translations.msg_authcard_have_account}</h4>
      )}
      {location.pathname.split("/")[2] === "login" && (
        <SideAuthBtn
          name={translations.msg_authcard_signup}
          url="/auth/signup"
        />
      )}
      {location.pathname.split("/")[2] === "signup" && (
        <SideAuthBtn name={translations.msg_authcard_login} url="/auth/login" />
      )}
    </div>
  );
};

export default AuthCard;
