import React, { useEffect, useState } from "react";
import classes from "./UserArticles.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

//STORE
import { userArticleActions } from "../store/redux-store";

//COMPONENTS
import ActiveArticlesContainer from "../components/user-articles/ActiveArticlesContainer";
import PendingArticlesContainer from "../components/user-articles/PendingArticlesContainer";
import PausedArticlesContainer from "../components/user-articles/PausedArticlesContainer";
import RejectedArticlesContainer from "../components/user-articles/RejectedArticlesContainer";
import PickArticlesButtons from "../components/user-articles/PickArticlesButtons";
import FiltersContainer from "../components/main/articles/filters/FiltersContainer";
import Footer from "../components/footer/Footer";
import Loading from "../UI/Loading";
// import Paggi

//CUSTOM HOOKS
import useQueryStrUserArticle from "../custom-hooks/useQueryStrUserArticles";
import Pagination from "../UI/paggination/Pagination";
import useFilterArticles from "../custom-hooks/useFilterArticles";

const UserArticles = () => {
  const dispatch = useDispatch();
  const { articles, numOfArticles, numOfPages, currentArticlePage } =
    useSelector((state) => state.userArticle);
  const { showLoading, setShowLoading, fetchArticlesHandler } =
    useQueryStrUserArticle();
  const filterArticles = useFilterArticles({
    type: "user-articles",
    numOfArticles: numOfArticles,
    for: "user-articles",
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchSettings = (page) => {
    dispatch(userArticleActions.setCurrentArticlePage({ value: page }));
    dispatch(userArticleActions.setFetchArticles({ value: true }));
    scrollToTop();
  };

  const pageHandler = (e) => {
    fetchSettings(e.target.id);
  };

  const firstPageHandler = (e) => {
    fetchSettings(1);
  };

  const lastPageHandler = (e) => {
    fetchSettings(numOfPages);
  };

  return (
    <div className={classes.container}>
      <PickArticlesButtons
        setShowLoading={setShowLoading}
        fetchArticlesHandler={fetchArticlesHandler}
      />
      <FiltersContainer
        actions={userArticleActions}
        numOfArticles={numOfArticles}
        hook={filterArticles}
        for="user-articles"
      />
      <Routes>
        <Route path="active" element={<ActiveArticlesContainer />} />
        <Route path="pending" element={<PendingArticlesContainer />} />
        <Route path="pause" element={<PausedArticlesContainer />} />
        <Route path="deleted" element={<RejectedArticlesContainer />} />
      </Routes>
      {showLoading && <Loading />}
      {articles.length > 0 && (
        <Pagination
          currentPage={currentArticlePage}
          numOfPages={numOfPages}
          firstPageHandler={firstPageHandler}
          lastPageHandler={lastPageHandler}
          pageHandler={pageHandler}
        />
      )}
      <Footer />
    </div>
  );
};

export default UserArticles;
