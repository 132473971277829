import React, { useEffect } from "react";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation
import { useSelector } from "react-redux";

import classes from "./ArticleFormContainer.module.css";

// COMPONENTS
import FirstForm from "./FirstForm";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";
import VehicleFields from "./VehicleFields";
import RealEstateFields from "./RealEstateFields";
import MainAd from "../advertisment/MainAd";

// CUSTOM HOOKS
import useArticleForm from "../../custom-hooks/useArticleForm";

// import adsClass from "../../adsList";

// let _adsList = adsClass.getAdsFor104x9();

// let startingAdNum = Math.floor(Math.random() * (_adsList.length - 1));

const ArticleFormContainer = (props) => {
  const { translations } = useTranslation(); // Use translations
  const articleFormHook = useArticleForm();
  const { user } = useSelector((state) => state.auth);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    articleFormHook.setFormTypeHandler(props.formType);
  }, [props.formType, articleFormHook]);

  useEffect(() => {
    articleFormHook.setCanSetSelectFields(true);
  }, [articleFormHook]);

  return (
    <form className={classes.container}>
      {user.status === 2 && (
        <div className={classes["block-error"]}>
          {translations.user_blocked_msg || "Your account is blocked!"}
        </div>
      )}
      {/* <MainAd
        type={"horizontal"}
        size="104x9"
        ads={_adsList}
        startingAd={startingAdNum}
      /> */}
      {props.formType === "create-article" && (
        <h2>
          {translations.msg_articleformcontainer_create_title ||
            "Create New Article"}
        </h2>
      )}
      {props.formType === "edit-article" && (
        <h2>
          {translations.msg_articleformcontainer_edit_title || "Edit Article"}
        </h2>
      )}
      <div className={classes["form-body"]}>
        <FirstForm
          data={articleFormHook}
          formType={props.formType}
          onKeyDown={handleKeyDown}
        />
        {articleFormHook.category.value === 6 && (
          <VehicleFields data={articleFormHook} onKeyDown={handleKeyDown} />
        )}
        {articleFormHook.category.value === 11 && (
          <RealEstateFields data={articleFormHook} onKeyDown={handleKeyDown} />
        )}
        <SecondForm data={articleFormHook} onKeyDown={handleKeyDown} />
        <ThirdForm data={articleFormHook} formType={props.formType} />
      </div>
      <div className={classes.buttons}>
        {props.formType === "create-article" && (
          <button
            className={classes.btn}
            onClick={articleFormHook.createArticleHandler}
          >
            {translations.msg_articleformcontainer_create_button || "Create"}
          </button>
        )}
        {props.formType === "edit-article" && (
          <button
            className={classes.btn}
            onClick={articleFormHook.updateArticleHandler}
          >
            {translations.msg_articleformcontainer_update_button || "Update"}
          </button>
        )}
      </div>
    </form>
  );
};

export default ArticleFormContainer;
