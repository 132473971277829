import { useState } from "react";

import classes from "./Input.module.css";

const Input = (props) => {
  const onFocusHandler = (e) => {
    e.preventDefault();
    if (props.data.type === "number") {
      e.target.select();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.single}>
        <label htmlFor={props.data.name}>{`${props.data.name}:`}</label>
        <input
          disabled={props.data.isDisabled}
          type={props.data.type}
          name={props.data.name}
          value={props.data.value || ""}
          onChange={props.onChange}
          onFocus={onFocusHandler}
          onBlur={props.onBlur ? props.onBlur : () => {}}
          spellCheck={false}
          style={props.data.error ? { borderColor: "var(--error-msg)" } : {}}
        ></input>
      </div>
      {props.data.error && <p className={classes.error}>{props.data.error}</p>}
    </div>
  );
};

export default Input;
