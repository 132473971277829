import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./ProfileBody.module.css";

// COMPONENTS
import Input from "./Input";
import BuyCoins from "./BuyCoins";

import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { RiImageAddFill } from "react-icons/ri";

const imageURL_PATH = `${process.env.REACT_APP_USER_LOGOS}`;

const ProfileBody = (props) => {
  const { translations } = useTranslation(); // Use translations
  const navigate = useNavigate();

  const changeUsernameHandler = (e) => {
    e.preventDefault();
    props.data.username.set(e.target.value);
  };
  const changeCityHandler = (e) => {
    e.preventDefault();
    props.data.city.set(e.target.value);
  };
  const changeZipHandler = (e) => {
    e.preventDefault();
    props.data.zip.set(e.target.value);
  };
  const changeStreet1Handler = (e) => {
    e.preventDefault();
    props.data.street1.set(e.target.value);
  };
  const changeStreet2Handler = (e) => {
    e.preventDefault();
    props.data.street2.set(e.target.value);
  };
  const changeOldPasswordHandler = (e) => {
    e.preventDefault();
    props.data.oldPassword.set(e.target.value);
  };
  const changeNewPasswordHandler = (e) => {
    e.preventDefault();
    props.data.newPassword.set(e.target.value);
  };
  const changeConfirmNewPasswordHandler = (e) => {
    e.preventDefault();
    props.data.confirmNewPassword.set(e.target.value);
  };
  const changeIsCompanyHandler = () => {
    props.data.isCompany.set(!props.data.isCompany.get);
  };
  const changeWebsiteHandler = (e) => {
    e.preventDefault();
    props.data.website.set(e.target.value);
  };
  const changePhoneHandler = (e) => {
    e.preventDefault();
    props.data.phone.set(e.target.value);
  };
  const changePhoneVisibleHandler = () => {
    props.data.phoneVisible.set(!props.data.phoneVisible.get);
  };

  const changeEmailVisibleHandler = () => {
    props.data.emailVisible.set(!props.data.emailVisible.get);
  };

  const changeReceiveEmailsHandler = (e) => {
    e.preventDefault();
    props.data.receiveEmails.set(!props.data.receiveEmails.get);
  };

  const discardChangesHandler = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const changePasswordHandler = (e) => {
    e.preventDefault();
    props.handlers.updatePasswordHandler();
    props.data.oldPassword.set("");
    props.data.newPassword.set("");
    props.data.confirmNewPassword.set("");
  };

  //IMAGE UPLOAD

  const { user } = useSelector((state) => state.auth);
  const [image, setImage] = useState("");
  const [showImageInput, setShowImageInput] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now());
  const [fetchImageAgain, setFetchImageAgain] = useState(false);

  const saveChangesHandler = (e) => {
    e.preventDefault();
    props.handlers.updateUserProfileHandler();
    setPhotoPreview(false);
    setFetchImageAgain(true);
    // window.location.reload();
  };

  useEffect(() => {
    const fetchImageAgainHandler = () => {
      setImageKey(Date.now());
      setFetchImageAgain(false);
    };

    if (!fetchImageAgain) return;

    const timer = setTimeout(() => {
      fetchImageAgainHandler();
    }, 2 * 1000);

    return () => clearTimeout(timer);
  }, [fetchImageAgain]);

  useEffect(() => {
    if (user.image) {
      setImage(user.image);
    }
  }, [user.image]);

  const showAddImageInputHandler = (e) => {
    setShowImageInput(true);
  };
  const hideAddImageInputHandler = (e) => {
    setShowImageInput(false);
  };

  //CHANGE IMAGE HANDLER
  useEffect(() => {
    if (props.data.image.get) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPhotoPreview(fileReader.result);
      };
      fileReader.readAsDataURL(props.data.image.get);
    }
  }, [props.data.image.get]);

  const uploadImageHandler = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      props.data.image.set(e.target.files[0]);
      return;
    }
  };

  return (
    <div className={classes.container}>
      {props.currActive === "general" && (
        <div className={classes.content}>
          <h5>{translations.profile_basic || "Basic"}:</h5>
          <div
            className={classes["image-container"]}
            onMouseOver={showAddImageInputHandler}
            onMouseLeave={hideAddImageInputHandler}
          >
            {photoPreview ? (
              <img src={photoPreview} alt={user.username} />
            ) : (
              <img
                src={`${imageURL_PATH}/${image}`}
                alt={`${props.data.username.get}`}
                key={imageKey}
              />
            )}
            {showImageInput && (
              <div className={classes["image-overlay"]}>
                <label htmlFor="image">
                  <RiImageAddFill />
                  <p>{translations.profile_new_image || "New Image"}</p>
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept=".jpg,.jpeg,.png"
                  onChange={uploadImageHandler}
                />
              </div>
            )}
          </div>
          <div className={classes["basic-info"]}>
            <div className={classes["single-info"]}>
              <p className={classes["label"]}>
                {translations.profile_email || "Email"}
              </p>
              <p>{props.data.user.email}</p>
            </div>
            {/* <div className={classes["single-info"]}>
              <p className={classes["label"]}>{"Coins "}</p>
              <p>{props.data.user.credit || 0}</p>
            </div> */}
            <div className={classes["single-info"]}>
              <p className={classes["label"]}>
                {translations.profile_created || "Created"}
              </p>
              <p>
                {new Date(props.data.user.createdAt).toLocaleDateString(
                  "en-GB",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                )}
              </p>
            </div>
          </div>
          <h5>{translations.msg_profilebody_details || "Details"}:</h5>
          <Input
            data={{
              name: translations.msg_profilebody_username || "username",
              value: props.data.username.get,
              type: "text",
            }}
            onChange={changeUsernameHandler}
          />
          <Input
            data={{
              name: translations.msg_profilebody_phone || "phone",
              value: props.data.phone.get,
              type: "tel",
            }}
            onChange={changePhoneHandler}
          />
          <div className={classes.checkbox} onClick={changeIsCompanyHandler}>
            <p>{translations.msg_profilebody_is_company || "Is Company"}</p>
            {props.data.isCompany.get ? (
              <ImCheckboxChecked />
            ) : (
              <ImCheckboxUnchecked />
            )}
          </div>
          <Input
            data={{
              name: translations.msg_profilebody_website || "website",
              value: props.data.website.get,
              type: "text",
              isDisabled: !props.data.isCompany.get,
            }}
            onChange={changeWebsiteHandler}
          />
          <h5>{translations.msg_profilebody_address || "Address"}:</h5>
          <Input
            data={{
              name: translations.msg_profilebody_city || "city",
              value: props.data.city.get,
              type: "text",
            }}
            onChange={changeCityHandler}
          />
          <Input
            data={{
              name: translations.msg_profilebody_zip || "zip",
              value: props.data.zip.get,
              type: "number",
              error: props.data.zip.error,
            }}
            onBlur={props.data.zip.validator || (() => {})}
            onChange={changeZipHandler}
          />
          <Input
            data={{
              name: translations.msg_profilebody_street1 || "street 1",
              value: props.data.street1.get,
              type: "text",
            }}
            onChange={changeStreet1Handler}
          />
          {/* <Input
            data={{
              name: translations.msg_profilebody_street2 || "street 2",
              value: props.data.street2.get,
              type: "text",
            }}
            onChange={changeStreet2Handler}
          /> */}
          <div className={classes.buttons}>
            <button
              type="button"
              onClick={discardChangesHandler}
              // style={{ borderBottomLeftRadius: "1rem" }}
            >
              {translations.msg_profilebody_discard_changes ||
                "Discard Changes"}
            </button>
            <button
              type="button"
              onClick={saveChangesHandler}
              // style={{ borderBottomRightRadius: "1rem" }}
            >
              {translations.msg_profilebody_save || "Save"}
            </button>
          </div>
        </div>
      )}
      {props.currActive === "privacy&security" && (
        <div className={classes.content}>
          <h5>{translations.profile_privacy || "Privacy"}</h5>
          <div
            className={classes.checkbox}
            onClick={changeReceiveEmailsHandler}
          >
            <p>
              {translations.profile_email_notifications ||
                "Email Notifications"}
            </p>
            {props.data.receiveEmails.get ? (
              <ImCheckboxChecked />
            ) : (
              <ImCheckboxUnchecked />
            )}
          </div>
          <div className={classes.checkbox} onClick={changePhoneVisibleHandler}>
            <p>
              {translations.msg_profilebody_phone_visible || "Phone visible"}
            </p>
            {props.data.phoneVisible.get ? (
              <ImCheckboxChecked />
            ) : (
              <ImCheckboxUnchecked />
            )}
          </div>
          {/* <div className={classes.checkbox} onClick={changeEmailVisibleHandler}>
            <p>{translations.profile_email_visible || "Email visible"}</p>
            {props.data.emailVisible.get ? (
              <ImCheckboxChecked />
            ) : (
              <ImCheckboxUnchecked />
            )}
          </div> */}
          <div className={classes.buttons}>
            <button
              type="button"
              onClick={discardChangesHandler}
              // style={{ borderBottomLeftRadius: "1rem" }}
            >
              {translations.msg_profilebody_discard_changes ||
                "Discard Changes"}
            </button>
            <button
              type="button"
              onClick={saveChangesHandler}
              // style={{ borderBottomRightRadius: "1rem" }}
            >
              {translations.msg_profilebody_save || "Save"}
            </button>
          </div>
          <h5>{translations.msg_profilebody_password || "Password"}:</h5>
          <Input
            data={{
              name: translations.msg_profilebody_old_password || "Old Password",
              value: props.data.oldPassword.get,
              type: "password",
            }}
            onChange={changeOldPasswordHandler}
          />
          <div />
          <Input
            data={{
              name: translations.msg_profilebody_new_password || "New Password",
              value: props.data.newPassword.get,
              type: "password",
            }}
            onChange={changeNewPasswordHandler}
          />
          <div />
          <Input
            data={{
              name:
                translations.msg_profilebody_confirm_password ||
                "Confirm Password",
              value: props.data.confirmNewPassword.get,
              type: "password",
            }}
            onChange={changeConfirmNewPasswordHandler}
          />
          <div className={classes.buttons}>
            <button type="button" onClick={changePasswordHandler}>
              {translations.msg_profilebody_change_password ||
                "Change Password"}
            </button>
          </div>
        </div>
      )}
      {/* VRATITI KASNIJE */}
      {/* {props.currActive === "buy-coins" && (
        <div className={classes.content}>
          <BuyCoins />
        </div>
      )} */}
    </div>
  );
};

export default ProfileBody;
