import React from "react";

import classes from "./MarketingSection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

import config from "../../website-config";

const MarketingSection = (props) => {
  const { translations } = useTranslation();

  return (
    <section
      id={"marketing"}
      className={classes.container}
      style={{ backgroundColor: "var(--background)" }}
    >
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_marketing || "Marketing"}</h3>
        <div className={classes["body"]}>
          <h5>{config.marketingEmail}</h5>
          <p className={classes["description"]}></p>
        </div>
      </div>
    </section>
  );
};

export default MarketingSection;
