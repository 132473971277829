import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./ReportContainer.module.css";

import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";

//STORE
import { reportArticle } from "../../store/reducers/report-slice";

//COMPONENTS
import TextAreaInput from "../../UI/TextAreaInput";

import { useModal } from "../../ModalContext";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const SingleReport = (props) => {
  return (
    <li
      className={classes["single__report-container"]}
      onClick={() => props.onClick(props.data.value)}
      id={props.data.value}
    >
      {props.data.reason !== props.data.value && (
        <BiCheckbox className={classes.icon} />
      )}
      {props.data.reason === props.data.value && (
        <BiCheckboxSquare className={classes.icon} />
      )}
      <p>{props.data.label}</p>
    </li>
  );
};

const ReportContainer = (props) => {
  const { translations } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const { closeModal } = useModal();
  const [reportId, setReportId] = useState("");
  const [reason, setReason] = useState(1);
  const [reasonDescription, setReasonDescription] = useState("");
  const [_reportsList, _setReportsList] = useState([]);

  const changeReasonHandler = (value) => {
    setReason(value);
  };

  useEffect(() => {
    let repList = [];
    Object.keys(props.reportsList).forEach((a) => {
      repList.push(
        <SingleReport
          onClick={changeReasonHandler}
          data={{
            value: +a,
            label: props.reportsList[a],
            reason: reason,
          }}
          key={a}
        />
      );
    });
    _setReportsList(repList);
  }, [props.reportsList, reason]);

  useEffect(() => {
    if (props.data._id) {
      setReportId(props.data._id);
    }
  }, [props]);

  const changeReasonDescHandler = (e) => {
    e.preventDefault();

    setReasonDescription(e.target.value);
  };

  const createReportHandler = (e) => {
    e.preventDefault();
    let _userInputs = {};
    _userInputs.reportType = props.type;
    _userInputs.reason = reason;
    if (reason === 100) {
      _userInputs.reasonDescription = reasonDescription;
    }
    _userInputs.reporting = user._id;
    _userInputs[`${props.type}Id`] = reportId;
    dispatch(reportArticle(_userInputs, token, closeModal));
  };
  console.log(props.type);
  return (
    <div className={classes.container}>
      {/* <h4>{`${translations.report_report || "Report"} ${props.type}`}</h4> */}
      {props.type === translations.report_article && (
        <h4>{translations.report_report_article || `Report Article`}</h4>
      )}
      {props.type === translations.report_user && (
        <h4>{translations.report_report_user || `Report User`}</h4>
      )}
      {props.type === translations.report_chat && (
        <h4>{translations.report_report_chat || `Report Chat`}</h4>
      )}
      <p>{`${
        props.type.slice(0, 1).toUpperCase() +
        props.type.slice(1, props.type.length)
      } ID: ${reportId}`}</p>
      <ul>{_reportsList}</ul>
      <TextAreaInput
        data={{
          name: translations.report_description || "report-description",
          isDisabled: reason !== 100,
          value: reasonDescription,
          placeholder:
            translations.report_description_placeholder ||
            "Provide us with detail information...",
        }}
        onChange={changeReasonDescHandler}
      />
      <button type="button" onClick={createReportHandler}>
        {translations.report_send_report || "Send Report"}
      </button>
    </div>
  );
};

export default ReportContainer;
