import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./SingleMessage.module.css";

//DATE FORMATER
import { formatDateDifference } from "../../helper/date-time-helper";

//CONTEXT
import { useTranslation } from "../../TranslationContext";

import { PiChecks } from "react-icons/pi";

const SingleMessage = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { translations } = useTranslation();
  const [time, setTime] = useState();
  // const [message, setMessage] = useState("");
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const sentByYou = props.data.sender === user._id;

  const style = sentByYou
    ? `${classes.me} ${classes.container}`
    : `${classes.user} ${classes.container}`;

  useEffect(() => {
    const updateTime = () => {
      setTime(formatDateDifference(props.data.createdAt, translations));
    };

    updateTime();

    const intervalId = setInterval(updateTime, 15 * 1000);

    return () => clearInterval(intervalId);
  }, [props.data.createdAt, translations]);

  const renderMessage = () => {
    return props.data.text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        // URL found, render it as an anchor element
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // Regular text, render it as is
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <div className={style}>
      <p className={classes.text}>{renderMessage()}</p>
      <div className={classes.details}>
        {/* TODO implementirati kasnije */}
        {/* {props.data.seen && <PiChecks className={classes.seen} />} */}
        <p className={classes.date}>{time}</p>
      </div>
    </div>
  );
};

export default SingleMessage;
