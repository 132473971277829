import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../TranslationContext";

import classes from "./OpenedChat.module.css";

// COMPONENTS
import SingleMessage from "./SingleMessage";
import ReportContainer from "../report-modal/ReportContainer";
import EndChat from "./EndChat";

// STORE
import {
  sendMessage,
  seenMessages,
  endChat,
} from "../../store/reducers/chat-slice";
import { chatActions } from "../../store/redux-store";

//MODAL
import { useModal } from "../../ModalContext";

//UTILS
import { reports } from "../../utils/reports";

//HELPERS
import { asciiCheck, validateEncoding } from "../../helper/input-validation";

//ICONS
import { BiDotsVertical } from "react-icons/bi";
import { CiChat1 } from "react-icons/ci";
import { IoSend } from "react-icons/io5";

const OpenedChat = (props) => {
  const { translations, language } = useTranslation();
  const { openModal, closeModal } = useModal();
  const [recipient, setRecipient] = useState(null);
  const { openedChat, sendMessageTemplate } = useSelector(
    (state) => state.chat
  );
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (showOptions && !e.target.closest(".chat-options")) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showOptions]);

  const showReportChatModal = () => {
    const _list = reports[language].chat
      ? reports[language].chat
      : reports["de"].chat;
    const modalContent = (
      <ReportContainer
        data={openedChat}
        type={"chat"}
        reportsList={_list}
        // reportsList={reports.chat}
      />
    );
    openModal(modalContent);
    setShowOptions(false);
  };

  const finalizeChatEnd = () => {
    closeModal();
    props.setRefresh(true);
  };

  const endChatHandler = (e) => {
    e.preventDefault();
    dispatch(endChat({ chatId: openedChat._id }, token, finalizeChatEnd));
  };

  const cancelEndChatHandler = (e) => {
    e.preventDefault();

    setShowOptions(false);
    closeModal();
  };

  const showEndChatModal = () => {
    const modalContent = (
      <EndChat
        data={openedChat}
        onClick={endChatHandler}
        cancel={cancelEndChatHandler}
      />
    );
    openModal(modalContent);
    setShowOptions(false);
  };

  useEffect(() => {
    return () => {
      dispatch(chatActions.resetSendMessageTemplate());
      dispatch(chatActions.setOpenedChat({ data: {} }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!openedChat?._id) return;
    let unseenMessage = false;
    openedChat.messages.forEach((message) => {
      if (message.recipient === user._id && message.seen === false) {
        unseenMessage = true;
      }
    });
    if (unseenMessage) {
      dispatch(seenMessages({ chatId: openedChat._id, user: user }, token));
    }
  }, [dispatch, openedChat, user, token]);

  useEffect(() => {
    setRecipient(
      openedChat.userTwo?._id === user?._id
        ? openedChat.userOne
        : openedChat.userTwo
    );
  }, [setRecipient, openedChat.userOne, openedChat.userTwo, user._id]);

  useEffect(() => {
    if (!user?._id || !recipient) return;
    dispatch(chatActions.setSendMessageTemplate({ recipient: recipient._id }));
    dispatch(chatActions.setSendMessageTemplate({ recipient: recipient._id }));
    dispatch(
      chatActions.setSendMessageTemplate({
        articleId: openedChat.articleId?._id,
      })
    );
  }, [dispatch, openedChat, setRecipient, user._id, recipient]);

  const sendMessagehandler = (e) => {
    e.preventDefault();

    if (sendMessageTemplate.text === "") {
      dispatch(
        chatActions.setErrorChat({
          value:
            translations.msg_openedchat_empty_message ||
            "You can't send empty message!",
        })
      );
      return;
    }

    if (asciiCheck(sendMessageTemplate.text)) {
      dispatch(chatActions.setErrorChat({ value: `Message is not valid!` }));
      return;
    }

    if (!validateEncoding(sendMessageTemplate.text)) {
      dispatch(chatActions.setErrorChat({ value: `Message is not valid!` }));
      return;
    }

    dispatch(sendMessage(sendMessageTemplate, token));
    dispatch(chatActions.resetSendMessageTemplate());
  };

  const messagesRenderer = openedChat?.messages?.map((msg) => {
    return <SingleMessage key={msg._id} id={msg._id} data={msg} />;
  });

  const setMessageTextHandler = (e) => {
    e.preventDefault();
    dispatch(chatActions.setSendMessageTemplate({ text: e.target.value }));
  };

  useEffect(() => {
    if (!openedChat.messages) return;
    const msgContainer = document.getElementById("messages-container");
    msgContainer.scrollTop = msgContainer.scrollHeight;
  });

  useEffect(() => {
    if (!openedChat.messages) return;
    const msgContainer = document.getElementById("messages-container");
    msgContainer.scrollTop = msgContainer.scrollHeight;
  }, [openedChat.messages]);

  const showDetailsHandler = (e) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  let unableToChat = openedChat.articleId ? false : true;
  unableToChat = unableToChat || openedChat.ended;

  return openedChat._id ? (
    <div className={`${classes.container}`} style={props.style}>
      <div className={classes["details"]}>
        <div className={classes["article-details"]}>
          <h4>
            {openedChat.title || translations.msg_openedchat_none || "None"}
          </h4>
          <p>
            CHF{" "}
            {openedChat.articleId
              ? openedChat.articleId.price
              : translations.msg_openedchat_none || "None"}
          </p>
        </div>
        <div
          className={`chat-options ${classes["chat-options"]}`}
          onClick={showDetailsHandler}
        >
          <BiDotsVertical className={classes.icon} />
          {showOptions && (
            <ul
              className={classes["options-dropdown"]}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <li>{translations.msg_openedchat_delete || "Delete"}</li>
              {!openedChat.ended && (
                <li onClick={showEndChatModal}>
                  {translations.msg_openedchat_end_chat || "End Chat"}
                </li>
              )}
              <li onClick={showReportChatModal}>
                {translations.msg_openedchat_report || "Report"}
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className={classes.messages} id="messages-container">
        {messagesRenderer}
        {openedChat.ended && (
          <p className={classes.ended}>
            {translations.msg_openedchat_chat_ended || "Chat is ended!"}
          </p>
        )}
      </div>
      <form
        className={classes["message-form"]}
        style={openedChat.ended ? { backgroundColor: "#f6f6f6" } : {}}
      >
        <textarea
          disabled={unableToChat}
          // disabled={openedChat.articleId ? false : true}
          spellCheck={false}
          onChange={setMessageTextHandler}
          className={openedChat.articleId ? "" : classes["inactive-input"]}
          placeholder={
            openedChat.articleId
              ? translations.msg_openedchat_enter_message ||
                "Enter your message"
              : translations.msg_openedchat_article_deleted ||
                "Article is deleted"
          }
          value={openedChat.articleId ? sendMessageTemplate.text : ""}
        ></textarea>
        {openedChat.articleId ? (
          <button onClick={sendMessagehandler}>
            <p className={classes["btn-text"]}>
              {translations.msg_openedchat_send || "Send"}
            </p>
            <IoSend className={classes.send} />
          </button>
        ) : (
          <p className={classes.inactive}>
            <p className={classes["btn-text"]}>
              {translations.msg_openedchat_send || "Send"}
            </p>
            <IoSend className={classes.send} />
          </p>
        )}
      </form>
    </div>
  ) : (
    <div className={classes["no-chat"]} style={props.style}>
      <CiChat1 className={classes["chat-icon"]} />
    </div>
  );
};

export default OpenedChat;
