import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "../../TranslationContext";

import classes from "./SingleArticleContainer.module.css";

//STORE
import { chatActions } from "../../store/redux-store";
import { sendMessage } from "../../store/reducers/chat-slice";

//COMPONENTS
import ImagesContainer from "./ImagesContainer";
import ArticleBody from "./ArticleBody";
import ArticleOptions from "./ArticleOptions";
import ReportContainer from "../report-modal/ReportContainer";
import MessageSend from "./MessageSend";

import { reports } from "../../utils/reports";

//MODAL
import { useModal } from "../../ModalContext";
import Carousel from "./Carousel";

const SingleArticleContainer = (props) => {
  const dispatch = useDispatch();
  const { translations, language } = useTranslation();
  const { article } = useSelector((state) => state.article);
  const { sendMessageTemplate } = useSelector((state) => state.chat);
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);

  const { openModal, closeModal } = useModal();

  const showReportArticleModal = () => {
    if (!isAuthenticated && !user._id) return;
    const _list = reports[language].article
      ? reports[language].article
      : reports["de"].article;
    const modalContent = (
      <ReportContainer
        data={article}
        type={translations.report_article || "article"}
        reportsList={_list}
        // reportsList={reports.article}
      />
    );
    openModal(modalContent);
  };

  const showReportPlayerModal = () => {
    if (!isAuthenticated && !user._id) return;
    const _list = reports[language].user
      ? reports[language].user
      : reports["de"].user;
    const modalContent = (
      <ReportContainer
        data={article.creator}
        type={translations.report_user || "user"}
        reportsList={_list}
        // reportsList={reports.user}
      />
    );
    openModal(modalContent);
  };

  const showPhoneModal = () => {
    if (!isAuthenticated && !user._id) return;
    const modalContent = (
      <div className={classes["modal-phone"]}>
        <h4>{translations.msg_singlearticlecontainer_phone || "Phone"}</h4>
        <p>{`+${article.creator.phone}`}</p>
      </div>
    );
    openModal(modalContent);
  };

  //SEND MESSAGE OVERLAY
  const onChangeTextAreaHandler = (e) => {
    e.preventDefault();
    dispatch(chatActions.setSendMessageTemplate({ text: e.target.value }));
  };

  const sendMessageHandler = (e) => {
    e.preventDefault();
    // console.log("test", sendMessageTemplate);
    if (isAuthenticated && user?._id === article.creator._id) return;
    // console.log("test");
    if (sendMessageTemplate.text === "") {
      dispatch(
        chatActions.setErrorChat({
          value:
            translations.msg_singlearticlecontainer_empty_message ||
            "You can't send empty message!",
        })
      );
      return;
    }
    dispatch(sendMessage(sendMessageTemplate, token, closeModal));
    // dispatch(chatActions.resetSendMessageTemplate());
    // closeModal();
  };
  // console.log(article);
  const showMessageModal = () => {
    if (!isAuthenticated && !user._id) return;
    const modalContent = (
      <MessageSend
        onChangeTextAreaHandler={onChangeTextAreaHandler}
        sendMessageHandler={sendMessageHandler}
      />
    );
    openModal(modalContent);
  };

  return (
    <Fragment>
      {article?.subscriptionType === 1 && (
        <div className={classes.promoted}>
          {translations.article_promoted || "Promoted"}
        </div>
      )}
      {article?.title && (
        <div className={classes.container}>
          <ImagesContainer data={article} />
          <Carousel images={article.images} />
          <ArticleOptions
            data={article}
            refresh={props.refresh}
            showModal={showReportArticleModal}
          />
          <ArticleBody
            data={article}
            show={{
              phoneModal: showPhoneModal,
              messageModal: showMessageModal,
              reportUserModal: showReportPlayerModal,
            }}
          />
          <div className={classes.price}>
            <h5>
              {translations.msg_singlearticlecontainer_price || "Price"}:{" "}
            </h5>
            <p>{`CHF ${article.price}`}</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SingleArticleContainer;
