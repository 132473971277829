import { useEffect, useState } from "react";

import { useTranslation } from "../TranslationContext";

import { realEstateStatus } from "../utils/real-estate-filters";

const useRealEstateTranslator = () => {
  const { translations } = useTranslation();
  const [translatedRES, setTranslatedRES] = useState([]);
  const [translatedRESFORM, setTranslatedRESFORM] = useState([]);

  useEffect(() => {
    let _translatedRES = [];
    _translatedRES.push({ value: 0, label: translations[`restatus_0`] });
    realEstateStatus.forEach((s) =>
      _translatedRES.push({
        value: s.index,
        label: translations[`restatus_${s.index}`],
      })
    );
    setTranslatedRES(_translatedRES);

    let _translatedRESFORM = [];
    realEstateStatus.forEach((s) =>
      _translatedRESFORM.push({
        value: s.index,
        label: translations[`restatus_${s.index}`],
      })
    );
    setTranslatedRESFORM(_translatedRESFORM);
  }, [translations]);

  return {
    translatedRES,
    translatedRESFORM,
  };
};

export default useRealEstateTranslator;
