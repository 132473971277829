import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";

import classes from "./VerifyEmail.module.css";

//STORE
import { verifyEmail } from "../../store/reducers/auth-store";

//COMPONENTS
import MainAuthBtn from "./MainAuthBtn";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const VerifyEmail = (props) => {
  const { translations } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verificationToken = useParams("verificationToken");

  const verifyEmailHandler = (e) => {
    e.preventDefault();
    dispatch(
      verifyEmail(
        { token: verificationToken.token },
        navigate,
        props.setCheckEmail
      )
    );
  };

  return (
    <div className={classes.container}>
      <h2>{translations.msg_signup_verify_email || "Verify your Email!"}</h2>
      <form className={classes.form} onSubmit={verifyEmailHandler}>
        <MainAuthBtn
          name={translations.msg_signup_verify_email_btn || "Verify account"}
        />
      </form>
    </div>
  );
};

export default VerifyEmail;
