import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classes from "./UserProfileContainer.module.css";

//COMPONENTS
import ProfileHeader from "./ProfileHeader";
import ProfileBody from "./ProfileBody";
import Navs from "./Navs";

//CUSTOM HOOKS
import useProfile from "../../custom-hooks/useProfile";

const UserProfileContainer = (props) => {
  const { data, handlers } = useProfile();
  const [currActive, setCurrActive] = useState("general");

  const changeTabHandler = (e) => {
    e.preventDefault();
    setCurrActive(e.target.id);
    handlers.resetStateInputs();
  };

  return (
    <div className={classes.container}>
      <Navs onClick={changeTabHandler} currActive={currActive} />
      <div className={classes.body}>
        <ProfileBody data={data} currActive={currActive} handlers={handlers} />
      </div>
    </div>
  );
};

export default UserProfileContainer;
