import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import classes from "./Article.module.css";

//STORE
import { getArticle } from "../store/reducers/article-slice";

//COMPONENTS
import SingleArticleContainer from "../components/single-article/SignelArticleContainer";
import Footer from "../components/footer/Footer";
import { chatActions, articleActions } from "../store/redux-store";

function Article() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { article } = useSelector((state) => state.article);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [refreshArticle, setRefreshArticle] = useState(false);

  useEffect(() => {
    const id = searchParams.get("articleId");
    dispatch(getArticle({ articleId: id }));
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (refreshArticle) {
      const id = searchParams.get("articleId");
      dispatch(getArticle({ articleId: id }));
      setRefreshArticle(false);
    }
  }, [dispatch, searchParams, refreshArticle]);

  useEffect(() => {
    if (isAuthenticated && user._id && article) {
      dispatch(
        chatActions.setSendMessageTemplate({ recipient: article.creator?._id })
      );
      dispatch(chatActions.setSendMessageTemplate({ title: article.title }));
      dispatch(chatActions.setSendMessageTemplate({ articleId: article._id }));
    }
  }, [dispatch, article, isAuthenticated, user]);

  useEffect(() => {
    return () => {
      dispatch(articleActions.setArticle({ data: null }));
    };
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <SingleArticleContainer refresh={setRefreshArticle} />
      <Footer />
    </div>
  );
}

export default Article;
