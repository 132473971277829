import React from "react";

import classes from "./Navs.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const Navs = (props) => {
  const { translations } = useTranslation();

  return (
    <div className={classes.container}>
      <ul className={classes.navs}>
        <li
          onClick={props.onClick}
          id="general"
          className={classes.nav}
          style={
            props.currActive === "general"
              ? {
                  backgroundColor: "var(--background)",
                  borderColor: "var(--main-color)",
                  color: "var(--text-color)",
                }
              : {}
          }
        >
          {translations.profile_general || "General"}
        </li>
        <li
          onClick={props.onClick}
          id="privacy&security"
          className={classes.nav}
          style={
            props.currActive === "privacy&security"
              ? {
                  backgroundColor: "var(--background)",
                  borderColor: "var(--main-color)",
                  color: "var(--text-color)",
                }
              : {}
          }
        >
          {translations.profile_privacy_and_security || "Privacy & Security"}
        </li>
        {/* <li
          onClick={props.onClick}
          id="buy-coins"
          className={classes.nav}
          style={
            props.currActive === "buy-coins"
              ? {
                  backgroundColor: "var(--background)",
                  borderColor: "var(--main-color)",
                  color: "var(--text-color)",
                }
              : {}
          }
        >
          Buy Coins
        </li> */}
      </ul>
    </div>
  );
};

export default Navs;
