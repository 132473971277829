import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation
import { signup } from "../../store/reducers/auth-store";

import classes from "./Signup.module.css";
import MainAuthBtn from "./MainAuthBtn";

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const Signup = (props) => {
  const { translations } = useTranslation(); // Use translations
  const { checkEmail, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  console.log(user, checkEmail);
  const submitFormHandler = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
      passwordConfirm,
      role: "customer",
    };
    dispatch(signup(userData, navigate, props.setCheckEmail, translations));
    props.setCanConnect(true);
  };

  const toggleShowPassHandler = () => {
    setShowPass(!showPass);
  };
  const toggleShowConfirmPassHandler = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  return (
    <div className={classes.container}>
      <h2>{translations.msg_signup_title || "Sign Up"}</h2>
      {!checkEmail && (
        <form className={classes.form} onSubmit={submitFormHandler}>
          <div className={classes["input-field"]}>
            <input
              className={classes.input}
              type="email"
              placeholder={translations.msg_signup_email || "Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={classes["input-field"]}>
            <input
              className={classes.input}
              type={!showPass ? "password" : "text"}
              placeholder={translations.msg_signup_password || "Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {!showPass ? (
              <IoMdEye
                className={classes.icon}
                onClick={toggleShowPassHandler}
              />
            ) : (
              <IoMdEyeOff
                className={classes.icon}
                onClick={toggleShowPassHandler}
              />
            )}
          </div>
          <div
            className={classes["input-field"]}
            style={{ marginBottom: "2rem" }}
          >
            <input
              className={classes.input}
              type={!showConfirmPass ? "password" : "text"}
              placeholder={
                translations.msg_signup_confirm_password || "Confirm Password"
              }
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            {!showConfirmPass ? (
              <IoMdEye
                className={classes.icon}
                onClick={toggleShowConfirmPassHandler}
              />
            ) : (
              <IoMdEyeOff
                className={classes.icon}
                onClick={toggleShowConfirmPassHandler}
              />
            )}
          </div>
          <MainAuthBtn name={translations.msg_signup_button || "Sign Up"} />
        </form>
      )}
      {checkEmail && (
        <p className={classes.text}>
          {translations.msg_signup_check_email ||
            "Check your Email to finalize your account!"}
        </p>
      )}
    </div>
  );
};

export default Signup;
