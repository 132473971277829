import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import cities from "../../utils/cities";

let URL;
console.log(process.env.REACT_APP_URL);

URL = process.env.REACT_APP_URL;

const initialUserArticleState = {
  errorUserArticle: null,
  messageUserArticle: null,
  //////////////////////////////////////////
  //HOME PAGE
  //////////////////////////////////////////
  queryStr: "?page=1&sort=-createdAt",
  refreshArticles: false,
  fetchArticles: false,
  refreshMyArticles: false,
  toFirstPage: false,
  articles: [],
  myArticles: [],
  numOfArticles: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  numOfUserArticles: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  articlesPerPage: 30,
  myArticlesPerPage: 30,
  currentArticlePage: 1,
  currentMyArticlePage: 1,
  numOfPages: 1,
  numOfMyPages: 1,
  //  -- FILTERS
  currentCategory: 0,
  currentSubCategory: 0,
  search: "",
  searchFilters: {
    minPrice: 0,
    maxPrice: 0,
    status: 1,
    state: null,
    itemCondition: null,
    // new: true,
    // used: true,
    //VEHICLES
    carcompany: null,
    carmodel: null,
    engine: null,
    minYear: null,
    maxMiles: null,
    transmition: null,
    minCc: null,
    maxCc: null,
    cc: null,
    minKw: null,
    maxKw: null,
    kw: null,
    //REAL-ESTATE
    realestatestatus: null,
    rooms: null,
    minArea: null,
    maxArea: null,
  },
  sort: "-createdAt",
};

const userArticleSlice = createSlice({
  name: "userArticle",
  initialState: initialUserArticleState,
  reducers: {
    setErrorUserArticle(state, action) {
      state.errorUserArticle = action.payload.value;
    },
    setMessageUserArticle(state, action) {
      state.messageUserArticle = action.payload.value;
    },
    setFetchArticles(state, action) {
      state.fetchArticles = action.payload.value;
    },
    setRefreshArticles(state, action) {
      state.refreshArticles = action.payload.value;
    },
    // setRefreshMyArticles(state, action) {
    //   state.refreshMyArticles = action.payload.value;
    // },
    setToFirtsPage(state, action) {
      state.toFirstPage = action.payload.value;
    },
    setArticles(state, action) {
      state.articles = action.payload.data;
    },
    // setMyArticles(state, action) {
    //   state.myArticles = action.payload.data;
    // },
    setNumOfArticles(state, action) {
      const newState = {};
      action.payload.data.forEach((state) => {
        newState[state._id] = state.count;
      });
      state.numOfArticles = newState;
    },
    // setNumOfUserArticles(state, action) {
    //   const newState = {};
    //   action.payload.data.forEach((state) => {
    //     newState[state._id] = state.count;
    //   });
    //   state.numOfUserArticles = newState;
    // },
    setQueryStr(state, action) {
      state.queryStr = action.payload.value;
    },
    setCurrentArticlePage(state, action) {
      state.currentArticlePage = action.payload.value;
    },
    // setCurrentMyArticlePage(state, action) {
    //   state.currentMyArticlePage = action.payload.value;
    // },
    setNumOfPages(state, action) {
      state.numOfPages = Math.ceil(
        action.payload.value / state.articlesPerPage
      );
    },
    setArticlesPerPage(state, action) {
      state.myArticlesPerPage = action.payload.value;
    },
    // setMyArticlesPerPage(state, action) {
    //   state.myArticlesPerPage = action.payload.value;
    // },
    // setNumOfPages(state, action) {
    //   state.numOfPages = Math.ceil(
    //     action.payload.value / state.articlesPerPage
    //   );
    // },
    setCurrentCategory(state, action) {
      state.currentCategory = action.payload.value;
    },
    setCurrentSubCategory(state, action) {
      state.currentSubCategory = action.payload.value;
    },
    setSearch(state, action) {
      state.search = action.payload.value;
    },
    setSearchFilters(state, action) {
      const key = Object.keys(action.payload);
      const value = action.payload[`${Object.keys(action.payload)}`];

      state.searchFilters[key[0]] = value;

      if (!value) {
        state.searchFilters[key[0]] = 0;
      }
    },
    turnOffVehicleFilters(state) {
      state.searchFilters.carcompany = null;
      state.searchFilters.minYear = null;
      state.searchFilters.maxMiles = null;
      state.searchFilters.minCc = null;
      state.searchFilters.maxCc = null;
      state.searchFilters.cc = null;
      state.searchFilters.minKw = null;
      state.searchFilters.maxKw = null;
      state.searchFilters.kw = null;
    },
    resetSearchFilters(state) {
      state.searchFilters = {
        minPrice: 0,
        maxPrice: 0,
        state: null,
        status: 1,
        itemCondition: null,
        //VEHICLES
        carcompany: null,
        engine: null,
        minYear: null,
        maxMiles: null,
        transmition: null,
        minCc: null,
        maxCc: null,
        cc: null,
        minKw: null,
        maxKw: null,
        kw: null,
      };
    },
    setSort(state, action) {
      state.sort = action.payload.value;
    },
    setUpdateAllArticles(state, action) {
      state.updateAllArticles = action.payload.value;
    },
  },
});

export const getMyArticles = (userInputs, token, fn) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "GET",
        url: `${URL}/articles/getUsersArticles${
          userInputs.queryString ? userInputs.queryString : ""
        }`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          userArticleSlice.actions.setArticles({
            data: response.data.data.articles.articles,
          })
        );
        dispatch(
          userArticleSlice.actions.setNumOfArticles({
            data: response.data.data.numOfArticles,
          })
        );
        dispatch(
          userArticleSlice.actions.setNumOfPages({
            value: response.data.data.totalAmountOfArticles,
          })
        );
        fn();
      }
    } catch (err) {
      fn();
      console.log(err);
      dispatch(
        userArticleSlice.actions.setErrorUserArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const changeStatus = (
  userInputs,
  token,
  changedStatus = "modified",
  fn = false
) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/articles/status/${userInputs.articleId}`,
        data: { status: userInputs.status },
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        // DODATI DA UPDATEUJE ARTICLE KOJEM JE STATUS PROMIJENJEN
        dispatch(userArticleSlice.actions.setRefreshArticles({ value: true }));
        dispatch(
          userArticleSlice.actions.setMessageUserArticle({
            value: `You ${changedStatus} article successfully!`,
          })
        );
        if (fn) {
          fn();
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(
        userArticleSlice.actions.setErrorUserArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const promoteArticle = (userInputs, token, fn) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "PATCH",
        url: `${URL}/articles/promote/${userInputs.articleId}`,
        data: userInputs,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          userArticleSlice.actions.setMessageUserArticle({
            value: `Article promotion is changed successfully!`,
          })
        );
        fn();
      }
    } catch (err) {
      console.log(err);
      dispatch(
        userArticleSlice.actions.setErrorUserArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export const deleteArticle = (userInputs, token) => {
  return async (dispatch) => {
    try {
      const lang = localStorage.getItem("language");

      const axiosOptions = {
        method: "DELETE",
        url: `${URL}/articles/${userInputs.articleId}`,
        headers: {
          "Accept-Language": lang,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      };

      const response = await axios(axiosOptions);

      if (response.status === 200) {
        dispatch(
          userArticleSlice.actions.setMessageUserArticle({
            value: `You delete article successfully!`,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        userArticleSlice.actions.setErrorUserArticle({
          value: err.response.data.message,
        })
      );
    }
  };
};

export default userArticleSlice;
