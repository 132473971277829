import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "../../TranslationContext";

import classes from "./PickArticlesButtons.module.css";

//STORE
import { userArticleActions } from "../../store/redux-store";

import {
  FaFileCircleCheck,
  FaFileCircleExclamation,
  FaFileCircleQuestion,
  FaFilePen,
} from "react-icons/fa6";

const _ACTIVE = 1;
const _PENDING = 2;
const _PAUSED = 3;
const _REMOVED = 4;

const PickArticlesButtons = (props) => {
  const { translations } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currActive, setCurrActive] = useState();
  const [articlesLength, setArticlesLength] = useState(null);
  const { numOfArticles } = useSelector((state) => state.userArticle);

  useEffect(() => {
    setCurrActive(location.pathname.split("/")[2]);
  }, [location.pathname]);

  useEffect(() => {
    setArticlesLength(numOfArticles);
  }, [numOfArticles]);

  const onClickHandler = (e) => {
    const id = +e.target.closest("div").id;
    dispatch(userArticleActions.setSearchFilters({ status: id }));
    dispatch(userArticleActions.setCurrentArticlePage({ value: 1 }));
    dispatch(userArticleActions.setFetchArticles({ value: true }));
  };

  return (
    <div className={classes.container}>
      <div className={classes["inner-container"]}>
        <div className={classes["btn-container"]} id={_ACTIVE}>
          <Link
            to={"/userArticles/active"}
            className={`${classes.btns} ${
              currActive === "active" ? classes.active : ""
            }`}
            onClick={onClickHandler}
          >
            <FaFileCircleCheck />
            {translations.msg_pickarticlesbuttons_active || "Active"}
            <span>{`(${
              articlesLength && articlesLength["1"] ? articlesLength["1"] : 0
            })`}</span>
          </Link>
        </div>
        <div className={classes["btn-container"]} id={_PAUSED}>
          <Link
            to={"/userArticles/pause"}
            onClick={onClickHandler}
            className={`${classes.btns} ${
              currActive === "pause" ? classes.pause : ""
            }`}
          >
            <FaFilePen />
            {translations.msg_pickarticlesbuttons_pause || "Pause"}
            <span>{`(${
              articlesLength && articlesLength["3"] ? articlesLength["3"] : 0
            })`}</span>
          </Link>
        </div>
        <div className={classes["btn-container"]} id={_PENDING}>
          <Link
            to={"/userArticles/pending"}
            className={`${classes.btns} ${
              currActive === "pending" ? classes.pending : ""
            }`}
            onClick={onClickHandler}
          >
            <FaFileCircleQuestion />
            {translations.msg_pickarticlesbuttons_pending || "Pending"}
            <span>{`(${
              articlesLength && articlesLength["2"] ? articlesLength["2"] : 0
            })`}</span>
          </Link>
        </div>
        <div className={classes["btn-container"]} id={_REMOVED}>
          <Link
            to={"/userArticles/deleted"}
            className={`${classes.btns} ${
              currActive === "deleted" ? classes.deleted : ""
            }`}
            onClick={onClickHandler}
          >
            <FaFileCircleExclamation />
            {translations.msg_pickarticlesbuttons_deleted || "Deleted"}
            <span>{`(${
              articlesLength && articlesLength["4"] ? articlesLength["4"] : 0
            })`}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PickArticlesButtons;
