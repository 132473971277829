import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classes from "./AboutUsSection.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const duration = 1000;

const AboutUsSection = (props) => {
  const { translations } = useTranslation();
  const { numOfActiveArticles } = useSelector((state) => state.article);
  const [noaa, setNoaa] = useState(0);
  const [helperSuffix, setHelperSuffix] = useState("");

  useEffect(() => {
    let start = null;
    const initialCount = 0;
    let helper = 0;
    // let helperSuffix = "";

    if (numOfActiveArticles > 1000) {
      helper = Math.floor(numOfActiveArticles / 1000);
      setHelperSuffix("k");
    }

    const difference = helper - initialCount;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const increment = Math.min(progress / duration, 1);
      setNoaa(Math.floor(initialCount + increment * difference));

      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, [numOfActiveArticles]);
  // console.log(numOfActiveArticles);
  return (
    <section id={"about-us"} className={classes.container}>
      <div className={classes["inner-container"]}>
        <h3>{translations.tos_about_us || "About Us"}</h3>
        <div className={classes["body"]}>
          <h5>
            {translations.about_us?.subtitle_1 ||
              `Willkommen auf Schweizer-Gratis-Inserate.ch – Von Schweizern für
            Schweizer`}
          </h5>
          <p className={classes["description"]}>
            {translations.about_us?.text_1 || ""}
          </p>
          <h5>
            {translations.about_us?.subtitle_2 ||
              `Freiwillige Arbeit und Spendenfinanzierung`}
          </h5>
          <p className={classes["description"]}>
            {translations.about_us?.text_2 || ``}
          </p>
          <h5>
            {translations.about_us?.subtitle_3 ||
              "Neue Funktionen und ständige Verbesserungen"}
          </h5>
          <p className={classes["description"]}>
            {translations.about_us?.text_3 || ``}
            <ul className={classes["list"]}>
              <li className={classes["list-item"]}>
                <strong>
                  {translations.about_us?.list_1_strong ||
                    "Benutzerfreundliche Oberfläche: "}
                </strong>
                {translations.about_us?.list_1 || ""}
              </li>
              <li className={classes["list-item"]}>
                <strong>
                  {translations.about_us?.list_2_strong ||
                    "Erweiterte Suchfunktionen: "}
                </strong>
                {translations.about_us?.list_2 || ""}
              </li>
              <li className={classes["list-item"]}>
                <strong>
                  {translations.about_us?.list_3_strong ||
                    "Sichere Transaktionen: "}
                </strong>
                {translations.about_us?.list_3 || ""}
              </li>
            </ul>
          </p>
          <div className={classes["counters"]}>
            <div className={classes["counter"]}>
              <h4>{`${noaa}${helperSuffix}+`}</h4>
              <p>{translations.tos_active_articles || "Active Articles"}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
