import { useEffect, useState } from "react";
import { useTranslation } from "../../../../TranslationContext";

import classes from "./PriceInput.module.css";

const PriceInput = (props) => {
  const { translations } = useTranslation();

  //MIN PRICE HANDLERS
  const setMinPrice = (e) => {
    e.preventDefault();
    let priceInput = +e.target.value > 999999999 ? 999999999 : e.target.value;
    props.data.minPrice.set(priceInput);
  };

  const onFocusMinPrice = (e) => {
    e.preventDefault();
    e.target.select();
  };

  const checkMinPriceHandler = (e) => {
    e.preventDefault();
  };

  //MAX PRICE HANDLERS
  const setMaxPrice = (e) => {
    e.preventDefault();
    let priceInput = +e.target.value > 1000000000 ? 1000000000 : e.target.value;
    props.data.maxPrice.set(priceInput);
  };

  const onFocusMaxPrice = (e) => {
    e.preventDefault();
    e.target.select();
  };

  const checkMaxPriceHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputs}>
        <input
          onChange={setMinPrice}
          type="number"
          name="min-price"
          id="min-price"
          placeholder={translations.msg_priceinput_from || "Von"}
          value={props.data.minPrice.get}
          onFocus={onFocusMinPrice}
          onBlur={checkMinPriceHandler}
        ></input>
        <div className={classes.dash} />
        <input
          onChange={setMaxPrice}
          type="number"
          name="max-price"
          id="max-price"
          placeholder={translations.msg_priceinput_to || "Bis"}
          value={props.data.maxPrice.get}
          onFocus={onFocusMaxPrice}
          onBlur={checkMaxPriceHandler}
        ></input>
      </div>
    </div>
  );
};

export default PriceInput;
