import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./MessageSend.module.css";

//CONTEXT
import { useTranslation } from "../../TranslationContext";

//STORE
import { chatActions } from "../../store/redux-store";
import { sendMessage } from "../../store/reducers/chat-slice";

//COMPONENTS
import TextAreaInput from "../../UI/TextAreaInput";

//MODAL
import { useModal } from "../../ModalContext";

//HELPER
import { asciiCheck, validateEncoding } from "../../helper/input-validation";

const MessageSend = (props) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const { translations } = useTranslation();
  const { sendMessageTemplate } = useSelector((state) => state.chat);
  const { isAuthenticated, user, token } = useSelector((state) => state.auth);
  const { article } = useSelector((state) => state.article);

  const sendMessageHandler = (e) => {
    e.preventDefault();
    if (isAuthenticated && user?._id === article.creator._id) return;

    if (sendMessageTemplate.text === "") {
      dispatch(
        chatActions.setErrorChat({
          value:
            translations.msg_singlearticlecontainer_empty_message ||
            "You can't send empty message!",
        })
      );
      return;
    }

    if (asciiCheck(sendMessageTemplate.text)) {
      dispatch(chatActions.setErrorChat({ value: `Message is not valid!` }));
      return;
    }

    if (!validateEncoding(sendMessageTemplate.text)) {
      dispatch(chatActions.setErrorChat({ value: `Message is not valid!` }));
      return;
    }

    dispatch(sendMessage(sendMessageTemplate, token, closeModal));
  };
  return (
    <Fragment>
      <div className={classes["modal-message"]}>
        <h4>
          {translations.msg_singlearticlecontainer_send_message ||
            "Send Message"}
        </h4>
        <TextAreaInput
          data={{
            name: translations.msg_singlearticlecontainer_message || "message",
            value: sendMessageTemplate.text,
            placeholder:
              translations.msg_singlearticlecontainer_enter_message ||
              "Enter message...",
          }}
          onChange={props.onChangeTextAreaHandler}
        />
      </div>
      <div className={classes["btn"]} onClick={sendMessageHandler}>
        {translations.msg_singlearticlecontainer_send || "Send"}
      </div>
    </Fragment>
  );
};

export default MessageSend;
