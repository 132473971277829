import React from "react";

import classes from "./SideAuthBtn.module.css";
import { Link } from "react-router-dom";

const SideAuthBtn = (props) => {
  return (
    <Link to={props.url} className={classes["btn"]}>
      {props.name}
    </Link>
  );
};

export default SideAuthBtn;
