import React from "react";

import classes from "./CheckEmail.module.css";

// CUSTOM HOOKS
import { useTranslation } from "../../TranslationContext";

const CheckEmail = (props) => {
  const { translations } = useTranslation();

  return (
    <div className={classes.container}>
      <h2>{translations.msg_signup_verify_email || "Verify your Email!"}</h2>
      <p className={classes.text}>
        {translations.msg_signup_verify_email || ""}
      </p>
    </div>
  );
};

export default CheckEmail;
