import { createSlice } from "@reduxjs/toolkit";

const initialAlertState = {
  error: null,
  message: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState: initialAlertState,
  reducers: {
    setError(state, action) {
      state.error = action.payload.error;
    },
    setMessage(state, action) {
      state.message = action.payload.message;
    },
  },
});

export default alertSlice;
