import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "../../TranslationContext"; // Import useTranslation

import classes from "./SingleUserArticle.module.css";

// COMPONENTS
import PauseOverlay from "./PauseOverlay";
import DeleteOverlay from "./DeleteOverlay";
import ActivateOverlay from "./ActivateOverlay";
import PromoteOverlay from "./PromoteOverlay";

import { PiMoney } from "react-icons/pi";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const SingleUserArticle = (props) => {
  const { translations } = useTranslation(); // Use translations
  const navigate = useNavigate();
  const [showPauseOverlay, setShowPauseOverlay] = useState(false);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
  const [showActivateOverlay, setShowActivateOverlay] = useState(false);
  const [showPromoteOverlay, setShowPromoteOverlay] = useState(false);

  const editArticleHandler = (e) => {
    e.preventDefault();
    navigate(`/article/edit/${props.data._id}`);
  };

  const showPauseOverlayHandler = (e) => {
    e.preventDefault();
    setShowPauseOverlay(true);
  };

  const hidePauseOverlayHandler = (e) => {
    e.preventDefault();
    setShowPauseOverlay(false);
  };

  const showDeleteOverlayHandler = (e) => {
    e.preventDefault();
    setShowDeleteOverlay(true);
  };

  const hideDeleteOverlayHandler = (e) => {
    e.preventDefault();
    setShowDeleteOverlay(false);
  };

  const showActivateOverlayHandler = (e) => {
    e.preventDefault();
    setShowActivateOverlay(true);
  };

  const hideActivateOverlayHandler = (e) => {
    e.preventDefault();
    setShowActivateOverlay(false);
  };

  const showPromoteOverlayHandler = (e) => {
    e.preventDefault();
    setShowPromoteOverlay(true);
  };

  const hidePromoteOverlayHandler = (e) => {
    e.preventDefault();
    setShowPromoteOverlay(false);
  };

  return (
    <div key={props.data._id} className={classes.container}>
      {showPauseOverlay && (
        <PauseOverlay
          data={props.data}
          hidePauseOverlayHandler={hidePauseOverlayHandler}
        />
      )}
      {showDeleteOverlay && (
        <DeleteOverlay
          data={props.data}
          hideDeleteOverlayHandler={hideDeleteOverlayHandler}
        />
      )}
      {showActivateOverlay && (
        <ActivateOverlay
          data={props.data}
          hideActivateOverlayHandler={hideActivateOverlayHandler}
        />
      )}
      {showPromoteOverlay && (
        <PromoteOverlay
          data={props.data}
          hidePromoteOverlayHandler={hidePromoteOverlayHandler}
          setShowPromoteOverlay={setShowPromoteOverlay}
        />
      )}
      <Link
        to={`/article?articleId=${props.data?._id}`}
        className={classes.header}
      >
        <h3>{props.data?.title}</h3>
      </Link>
      <div className={classes.body}>
        <div className={classes["image-container"]}>
          <img
            src={
              props.data?.mainImage
                ? `${IMAGE_URL}/articles/${props.data?.mainImage}`
                : `${IMAGE_URL}/articles/default.jpeg`
            }
            // src={`${process.env.REACT_APP_IMAGE_URL}/articles/${props.data.mainImage}`}
            alt={props.data?.title}
            className={classes.articleImage}
          />
        </div>
        <div className={classes.info}>
          <p className={classes["single-info"]}>
            <PiMoney />
            <span>CHF {props.data?.price}</span>
          </p>
          <p className={classes["single-info"]}>
            <FaRegEye />
            <span>{props.data?.clicks}</span>
          </p>
          <p className={classes["single-info"]}>
            <MdOutlineDateRange />
            <span>
              {new Date(props.data?.createdAt).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </span>
          </p>
          {props.data.subscriptionType === 1 && (
            <p
              style={{ backgroundColor: "var(--accent-color)", color: "#fff" }}
              className={classes["single-info"]}
            >
              <MdOutlineDateRange />
              <span>{`Promoted until: ${new Date(
                props.data?.subscriptionExpiresAt
              ).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}`}</span>
            </p>
          )}
        </div>
      </div>
      <div className={classes.description}>
        <h4>
          {translations.msg_singleuserarticle_description || "Description"}:
        </h4>
        <p>
          {props.data?.description?.length > 100
            ? `${props.data.description.slice(0, 150)}...`
            : props.data.description}
        </p>
      </div>
      <div className={classes.footer}>
        {props.data?.status !== 2 && props.data?.status !== 4 && (
          <div className={classes.actions}>
            <div
              className={classes.rectangularArea}
              onClick={editArticleHandler}
              id={props.data?._id}
            >
              {translations.msg_singleuserarticle_edit || "Bearbeiten"}
            </div>
            {props.data?.status === 1 && (
              <div
                className={classes.rectangularArea}
                onClick={showPauseOverlayHandler}
              >
                {translations.msg_singleuserarticle_pause || "Pausieren"}
              </div>
            )}
            {props.data?.status === 3 && (
              <div
                className={classes.rectangularArea}
                onClick={showActivateOverlayHandler}
              >
                {translations.msg_singleuserarticle_activate || "Activate"}
              </div>
            )}
            <div
              className={classes.rectangularArea}
              onClick={showDeleteOverlayHandler}
            >
              {translations.msg_singleuserarticle_delete || "Löschen"}
            </div>
            {props.data?.status === 1 && (
              <div
                className={`${classes.rectangularArea} ${classes.goldText}`}
                onClick={showPromoteOverlayHandler}
              >
                {translations.msg_singleuserarticle_highlight || "Hervorheben"}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleUserArticle;
