export const getYears = () => {
  let years = [];
  let currYear = new Date(Date.now());

  for (let i = 1960; i < currYear.getFullYear() + 1; i++) {
    years.unshift(`${i}`);
  }

  return years;
};

export const miles = [
  { value: 1, label: "5000" },
  { value: 2, label: "10000" },
  { value: 3, label: "20000" },
  { value: 4, label: "30000" },
  { value: 5, label: "40000" },
  { value: 6, label: "50000" },
  { value: 7, label: "60000" },
  { value: 8, label: "70000" },
  { value: 9, label: "80000" },
  { value: 10, label: "90000" },
  { value: 11, label: "100000" },
  { value: 12, label: "125000" },
  { value: 13, label: "150000" },
  { value: 14, label: "175000" },
  { value: 15, label: "200000" },
  { value: 16, label: "250000" },
  { value: 17, label: "300000" },
];

export const transmition = [
  { value: 1, label: "Automatik" },
  { value: 2, label: "Schaltgetriebe" },
];

export const engine = [
  { value: 1, label: "Benzin" },
  { value: 2, label: "Diesel" },
  { value: 3, label: "Electric" },
  { value: 4, label: "Hybrid" },
  { value: 5, label: "Gas" },
];

export const doors = [
  { value: 1, label: "2" },
  { value: 2, label: "3" },
  { value: 3, label: "5" },
];

export const colors = [
  { value: 1, label: "Red" },
  { value: 2, label: "Blue" },
  { value: 3, label: "Green" },
  { value: 4, label: "Yellow" },
  { value: 5, label: "Purple" },
  { value: 6, label: "Black" },
  { value: 7, label: "White" },
  { value: 8, label: "Silver" },
  { value: 9, label: "Orange" },
  { value: 10, label: "Gold" },
];
