export const asciiCheck = (msg) => {
  const asciiPattern = /([^\s]{3,}\s?){3,}/gm;
  const matchCount = (msg.match(asciiPattern) || []).length;
  const threshold = 3;

  return matchCount >= threshold;
};

export const validateEncoding = (msg) => {
  try {
    const uft8Encoding = new TextEncoder().encode(msg);
    return true;
  } catch (e) {
    return false;
  }
};
