import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import classes from "./User.module.css";

//COMPONENTS
import UserContainer from "../components/user/UserContainer";
import Footer from "../components/footer/Footer";

//STORE
import {
  getUserData,
  getFetchedUserArticles,
} from "../store/reducers/user-slice";
import { userActions } from "../store/redux-store";

const User = (props) => {
  const params = useParams();
  const { fetchedUser } = useSelector((state) => state.user);

  useEffect(() => {
    props.hook.setUserId(params.userId);
  });
  console.log(fetchedUser);
  return (
    <div className={classes.container}>
      <UserContainer
        hook={props.hook}
        data={{ search: { get: props.hook.search, set: props.hook.setSearch } }}
      />
      <Footer />
    </div>
  );
};

export default User;
